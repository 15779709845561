import { IconKey } from 'assets/iconLibrary'
import Icon from '../../Icon/Icon/Icon'
import { DesignColor } from 'themes'
import { useRef } from 'react'
import { tooltipDirection, tooltipKey } from 'assets/tooltips'
import Tooltip from '../../Tooltip/Tooltip'
import useHovered from 'hooks/ui/useHovered'
import useAction from 'hooks/editor/useAction'

interface IconBlockProps {
  icon: IconKey
  iconRotation?: number
  tooltipKey?: tooltipKey
  tooltipDirection?: 'left' | 'right' | 'center'

  hasOverride?: boolean
  overrideTint?: boolean
  selected?: boolean
  dim?: boolean
  disabled?: boolean

  onClick?: () => void
  onMouseDown?: () => void

  buttonRef?: React.RefObject<HTMLDivElement>
}

export default function IconBlock({
  icon,
  iconRotation = 0,
  tooltipKey,
  tooltipDirection = 'center',
  hasOverride = false,
  overrideTint = false,
  selected = false,
  dim = false,
  disabled = false,
  onClick,
  onMouseDown,
  buttonRef,
}: IconBlockProps) {
  const action = useAction()

  const localRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered<boolean>({
    ref: buttonRef || localRef,
  })

  const opacity = getOpacity(disabled, dim)
  const background = getBackground(
    selected,
    disabled,
    hasOverride,
    overrideTint
  )
  const boxShadow = getBoxShadow((hovered || false) && !action, disabled)
  const iconColor = getIconColor(
    (hovered || false) && !action,
    disabled,
    hasOverride
  )

  return (
    <TooltipWrapper tooltipKey={tooltipKey} tooltipDirection={tooltipDirection}>
      <div
        ref={buttonRef || localRef}
        style={{
          width: 24,
          height: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 4,
          flexShrink: 0,
          opacity,
          background,
          boxShadow,
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        <div
          style={{
            transform: `rotate(${iconRotation}deg)`,
          }}
        >
          <Icon icon={icon} size={16} color={iconColor} />
        </div>
      </div>
    </TooltipWrapper>
  )
}

function TooltipWrapper({
  children,
  tooltipKey,
  tooltipDirection,
}: {
  children: React.ReactNode
  tooltipKey?: tooltipKey
  tooltipDirection?: tooltipDirection
}) {
  if (!tooltipKey) return <>{children}</>
  return (
    <Tooltip tooltipKey={tooltipKey} direction={tooltipDirection}>
      {children}
    </Tooltip>
  )
}

function getIconColor(hover: boolean, disabled: boolean, overridden: boolean) {
  return hover && !disabled
    ? DesignColor('inputHighlight')
    : DesignColor(overridden ? 'overrideParent' : 'text1')
}

function getBackground(
  selected: boolean,
  disabled: boolean,
  overridden: boolean,
  overrideTint: boolean
) {
  if (disabled) return 'none'
  if (selected) {
    return overridden || overrideTint
      ? DesignColor('overrideTint')
      : DesignColor('inputTint')
  }
  return 'none'
}

function getBoxShadow(hover: boolean, disabled: boolean) {
  if (disabled) return 'none'
  if (hover) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
}

function getOpacity(disabled: boolean, dim: boolean) {
  return disabled || dim ? 0.4 : 1
}
