import { IconKey } from 'assets/iconLibrary'
import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import LayoutInput from 'components/Library/Components/Inputs/LayoutInput/LayoutInput'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import IconSetBlock from 'components/Library/Containers/IconSetBlock/IconSetBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { tooltipKey } from 'assets/tooltips'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { AttributeAutolayoutDirection } from 'application/attributes'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'

export default function LayoutSettings() {
  const autolayout = usePanelHelper(editor.getUI().getLayoutPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!autolayout.settings || !autolayout.settings.attributes) return <></>

  const { setMode, setDirection, setAlign, setCounter, setGap, slideGap } =
    autolayout.handlers
  const { attributes, modes } = autolayout.settings

  const mode = attributes['autolayout.mode'] || 'none'
  const direction = attributes['autolayout.direction'] || 'row'
  const main = attributes['autolayout.align.main'] || 'start'
  const counter = attributes['autolayout.align.counter'] || 'start'
  const gap = attributes['autolayout.gap'] || 0

  const { hasOverride, sectionHasOverride } = overrides.handlers

  return (
    <PanelSection>
      <PanelRow>
        <OverrideReset section={'autolayout'}>
          <TitleButtonBlock
            width={120}
            title={'Layout'}
            active={true}
            hasOverride={sectionHasOverride('autolayout')}
          />
        </OverrideReset>
        <OverrideReset keys={['autolayout.mode']}>
          <IconSetBlock align={'right'} paddingR={0}>
            <IconBlock
              icon={'DisplayBlock'}
              tooltipKey={'DisplayBlock'}
              selected={mode === 'block'}
              onClick={() => {
                if (!modes.includes('block')) return
                setMode('block')
              }}
              disabled={!modes.includes('block')}
              hasOverride={hasOverride(['autolayout.mode'])}
            />
            <IconBlock
              icon={'DisplayHidden'}
              tooltipKey={'DisplayHidden'}
              selected={mode === 'none'}
              onClick={() => {
                if (!modes.includes('none')) return
                setMode('none')
              }}
              disabled={!modes.includes('none')}
              hasOverride={hasOverride(['autolayout.mode'])}
            />
            <IconBlock
              icon={'DisplayFlex'}
              tooltipKey={'DisplayFlex'}
              selected={mode === 'flex'}
              onClick={() => {
                if (!modes.includes('flex')) return
                setMode('flex')
              }}
              disabled={!modes.includes('flex')}
              hasOverride={hasOverride(['autolayout.mode'])}
            />
          </IconSetBlock>
        </OverrideReset>
      </PanelRow>
      {mode === 'flex' && (
        <>
          <PanelRow height={64} paddingH={8} paddingV={0}>
            <PanelBlock height={64} direction={'column'} gap={8} paddingV={4}>
              <IconSetBlock>
                {directionModes.map((d) => (
                  <OverrideReset
                    key={d.direction}
                    keys={['autolayout.direction']}
                  >
                    <IconBlock
                      key={d.direction}
                      icon={d.iconKey}
                      tooltipKey={d.tooltipKey}
                      selected={d.direction === direction}
                      hasOverride={hasOverride(['autolayout.direction'])}
                      onClick={() => setDirection(d.direction)}
                    />
                  </OverrideReset>
                ))}
              </IconSetBlock>
              <OverrideReset keys={['autolayout.gap']}>
                <NumberBlock
                  icon={'Gap'}
                  iconRotation={direction === 'column' ? 90 : 0}
                  tooltipKey={'FlexGap'}
                  value={gap}
                  setValue={setGap}
                  increment={(v) => slideGap(v)}
                  decrement={(v) => slideGap(-v)}
                  min={0}
                  hasOverride={hasOverride(['autolayout.gap'])}
                />
              </OverrideReset>
            </PanelBlock>
            <PanelBlock height={64} paddingL={8} paddingR={4}>
              <OverrideReset
                keys={['autolayout.align.main', 'autolayout.align.counter']}
              >
                <LayoutInput
                  main={main}
                  counter={counter}
                  direction={direction}
                  setAlign={setAlign}
                  hasOverride={hasOverride([
                    'autolayout.align.main',
                    'autolayout.align.counter',
                  ])}
                />
              </OverrideReset>
            </PanelBlock>
            <PanelBlock
              width={24}
              height={64}
              direction={'column'}
              paddingV={4}
              gap={8}
            >
              <PanelBlock width={24} height={24} />
              <OverrideReset keys={['autolayout.align.counter']}>
                <IconBlock
                  icon="SpreadGap"
                  iconRotation={direction === 'column' ? 90 : 0}
                  tooltipKey={'FlexSpaceBetween'}
                  tooltipDirection="left"
                  selected={counter === 'spaced'}
                  hasOverride={hasOverride(['autolayout.align.counter'])}
                  onClick={() =>
                    setCounter(counter === 'spaced' ? 'start' : 'spaced')
                  }
                />
              </OverrideReset>
            </PanelBlock>
          </PanelRow>
        </>
      )}
    </PanelSection>
  )
}

const directionModes: {
  direction: AttributeAutolayoutDirection
  tooltipKey: tooltipKey
  iconKey: IconKey
}[] = [
  {
    direction: 'row',
    tooltipKey: 'FlexRow',
    iconKey: 'Row',
  },
  {
    direction: 'column',
    tooltipKey: 'FlexColumn',
    iconKey: 'Column',
  },
  {
    direction: 'wrap',
    tooltipKey: 'FlexRowWrap',
    iconKey: 'Wrap',
  },
]
