import { DesignColor } from 'themes'
import { IconKey } from 'assets/iconLibrary'
import { useRef, useState } from 'react'
import ButtonIcon from '../Icon/ButtonIcon/ButtonIcon'
import Icon from '../Icon/Icon/Icon'
import Text, { textStyles } from 'components/Library/Text/Text'
import DropdownDrawer from './DropdownDrawer'
import { dropdownWidth } from 'components/Library/Containers/constants'
import useCommit from 'hooks/editor/useCommit'
import { tooltipDirection, tooltipKey } from 'assets/tooltips'
import Tooltip from '../Tooltip/Tooltip'
import { warningKey } from 'assets/warnings'
import useHovered from 'hooks/ui/useHovered'
import useAction from 'hooks/editor/useAction'

export type dropdownOption<T> = {
  value: T
  text: string
  dropdownText?: string
  subtext?: string

  icon?: IconKey

  styles?: textStyles

  tooltipKey?: tooltipKey
  tooltipDirection?: tooltipDirection
}

interface DropdownProps<T> {
  width?: dropdownWidth
  drawerWidth?: dropdownWidth

  options: dropdownOption<T>[]
  hasIcon?: boolean
  tooltipKey?: tooltipKey
  tooltipDirection?: tooltipDirection

  selected: T | null
  select: (value: T) => void

  mixed?: boolean
  mixedIcon?: IconKey
  warning?: warningKey

  noValue?: string
  noValueIcon?: IconKey

  defaultIcon?: IconKey

  empty?: boolean
  disabled?: boolean
  commit?: boolean

  dim?: boolean
  hasOverride?: boolean
}

export default function Dropdown<T>({
  width = 96,
  drawerWidth = undefined,
  options,
  hasIcon = false,
  tooltipKey,
  tooltipDirection,
  selected = null,
  select,
  mixed = false,
  mixedIcon,
  warning = undefined,
  empty = false,
  disabled = false,
  commit = false,
  noValue,
  noValueIcon,
  defaultIcon,
  dim,
  hasOverride = false,
}: DropdownProps<T>) {
  const commitUpdate = useCommit()

  const dropdownRef = useRef<HTMLDivElement>(null)

  const action = useAction()
  const { hovered, setHovered } = useHovered<boolean>({ ref: dropdownRef })
  const [open, setOpen] = useState(false)

  const selectedOption = getDisplayOption(options, selected)

  const handleSetOpen = () => {
    if (disabled) return
    if (open) return
    setOpen(true)
    setHovered(false)
  }

  const handleSelect = (value: T) => {
    setOpen(false)
    select(value)
    if (commit) commitUpdate()
  }

  const boxShadow = getWrapperBoxShadow((hovered || false) && !action, disabled)
  const hoverActive = (hovered || false) && !action

  return (
    <TooltipWrapper
      tooltipKey={tooltipKey}
      tooltipDirection={tooltipDirection}
      warningKey={warning}
      open={open}
    >
      <div
        ref={dropdownRef}
        style={{
          width,
          height: 24,
          paddingLeft: hasIcon ? 4 : 8,
          paddingRight: 8,
          boxSizing: 'border-box',
          display: 'flex',
          alignContent: 'start',
          justifyItems: 'center',
          gap: 4,
          borderRadius: 4,
          boxShadow: boxShadow,
          opacity: dim || disabled ? 0.4 : 1,
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleSetOpen}
      >
        {mixed && (
          <>
            {mixedIcon && (
              <ButtonIcon icon={mixedIcon} color={DesignColor('text2')} />
            )}
            <ContentContainer hovered={hoverActive} disabled={disabled}>
              <Text>Mixed</Text>
              <Icon
                icon={'DropdownArrowDown'}
                size={10}
                color={
                  hoverActive && !disabled
                    ? DesignColor('text1')
                    : empty
                    ? DesignColor('text3')
                    : DesignColor('text2')
                }
              />
            </ContentContainer>
          </>
        )}
        {!mixed && warning && selectedOption && (
          <>
            {(defaultIcon || selectedOption.icon) && (
              <ButtonIcon icon={'Warning'} color={DesignColor('warning')} />
            )}
            <ContentContainer hovered={hoverActive} disabled={disabled}>
              <Text
                color={DesignColor('warning')}
                styles={selectedOption.styles}
              >
                {selectedOption.text}
              </Text>
              <Icon
                icon={'DropdownArrowDown'}
                size={10}
                color={DesignColor('warning')}
              />
            </ContentContainer>
          </>
        )}
        {!mixed && !warning && selectedOption && (
          <>
            {selectedOption.icon && (
              <ButtonIcon
                icon={selectedOption.icon}
                color={DesignColor(hasOverride ? 'overrideParent' : 'text2')}
              />
            )}
            {defaultIcon && !selectedOption.icon && (
              <ButtonIcon
                icon={defaultIcon}
                color={DesignColor(hasOverride ? 'overrideParent' : 'text2')}
              />
            )}
            <ContentContainer hovered={hoverActive} disabled={disabled}>
              <Text
                color={DesignColor(
                  hasOverride
                    ? empty
                      ? 'overrideParent2'
                      : 'overrideParent'
                    : empty
                    ? 'text3'
                    : 'text1'
                )}
                styles={selectedOption.styles}
              >
                {selectedOption.text}
              </Text>
              <Icon
                icon="DropdownArrowDown"
                size={10}
                color={
                  hasOverride
                    ? empty
                      ? DesignColor('overrideParent2')
                      : DesignColor('overrideParent')
                    : hoverActive && !disabled
                    ? DesignColor('text1')
                    : empty
                    ? DesignColor('text3')
                    : DesignColor('text2')
                }
              />
            </ContentContainer>
          </>
        )}
        {!mixed && !selectedOption && noValue && (
          <>
            {noValueIcon && (
              <ButtonIcon icon={noValueIcon} color={DesignColor('text2')} />
            )}
            <ContentContainer hovered={hoverActive} disabled={disabled}>
              <Text color={DesignColor('text2')}>{noValue}</Text>
              <Icon
                icon="DropdownArrowDown"
                size={10}
                color={
                  hoverActive && !disabled
                    ? DesignColor('text1')
                    : empty
                    ? DesignColor('text3')
                    : DesignColor('text2')
                }
              />
            </ContentContainer>
          </>
        )}
        {open && (
          <DropdownDrawer
            width={drawerWidth || width}
            close={() => setOpen(false)}
            dropdownRef={dropdownRef}
            options={options}
            selected={selected}
            select={handleSelect}
          />
        )}
      </div>
    </TooltipWrapper>
  )
}

function TooltipWrapper({
  tooltipKey,
  tooltipDirection,
  warningKey,
  open,
  children,
}: {
  tooltipKey?: tooltipKey
  tooltipDirection?: tooltipDirection
  warningKey?: warningKey
  open: boolean
  children: React.ReactNode
}) {
  if (warningKey) {
    return (
      <Tooltip
        warningKey={warningKey}
        direction={tooltipDirection}
        disabled={open}
        long={true}
      >
        {children}
      </Tooltip>
    )
  } else if (tooltipKey) {
    return (
      <Tooltip
        tooltipKey={tooltipKey}
        direction={tooltipDirection}
        disabled={open}
      >
        {children}
      </Tooltip>
    )
  } else {
    return <>{children}</>
  }
}

function ContentContainer({
  children,
  hovered,
  disabled,
}: {
  children?: React.ReactNode
  hovered: boolean
  disabled?: boolean
}) {
  return (
    <div
      style={{
        width: '100%',
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: hovered && !disabled ? 'space-between' : 'start',
      }}
    >
      {children}
    </div>
  )
}

function getWrapperBoxShadow(hovered: boolean, disabled: boolean): string {
  if (disabled) return 'none'
  if (hovered) return `inset 0 0 0 1px ${DesignColor('inputHighlight')}`
  return 'none'
}

function getDisplayOption<T>(
  options: dropdownOption<T>[],
  selected: T | null
): dropdownOption<T> | null {
  return options.find((o) => o.value === selected) || null
}
