import { DesignColor } from 'themes'
import Icon from '../Icon/Icon/Icon'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'

interface LayerInteractionBlockProps {
  interaction: boolean
  openInteractions: () => void

  hidden: boolean
}

export default function LayerInteractionBlock({
  interaction,
  openInteractions,
  hidden,
}: LayerInteractionBlockProps) {
  const buttonRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered<boolean>({ ref: buttonRef })

  if (!interaction) return <></>

  return (
    <div
      ref={buttonRef}
      style={{
        width: 24,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={() => openInteractions()}
    >
      <Icon
        icon={'Lightning'}
        size={16}
        color={getColor(hovered || false, hidden)}
      />
    </div>
  )
}

function getColor(hovered: boolean, hidden: boolean): string {
  if (hidden) return DesignColor('text3')
  if (hovered) return DesignColor('inputHighlight')
  return DesignColor('text1')
}
