import { DesignColor } from 'themes'
import LoadingTitle from './LoadingTitle/LoadingTitle'
import { panelShadow } from 'components/Library/Containers/constants'

export default function LoadingGraphic() {
  return (
    <div
      style={{
        position: 'relative',
        width: 370,
        height: 370,
        paddingBottom: 24,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 24,
        borderRadius: 8,
        background: DesignColor('panelBackground'),
        border: `1px solid ${DesignColor('panelBorder')}`,
        boxShadow: panelShadow,
      }}
    >
      <LoadingTitle />
    </div>
  )
}
