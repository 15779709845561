import { DesignColor } from 'themes'
import { useState } from 'react'

export interface OnboardingCTAProps {
  children?: React.ReactNode

  onClick?: () => void
}

export default function OnboardingCTA({
  children,
  onClick,
}: OnboardingCTAProps) {
  const [hover, setHover] = useState(false)
  const textColor = hover
    ? DesignColor('inputHighlight')
    : DesignColor('inverseText1')

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 16px',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        background: DesignColor('inverseBackground'),
        cursor: 'pointer',
        borderRadius: 8,
      }}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <p
        style={{
          fontSize: 14,
          color: textColor,
          fontFamily: 'Inter',
          cursor: 'pointer',
        }}
      >
        {children}
      </p>
    </div>
  )
}
