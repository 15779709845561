import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import PositionRow from './PositionRow/PositionRow'
import OpacityRow from './OpacityRow/OpacityRow'
import RoundingRow from './RoundingRow/RoundingRow'
import ScreenSizeRow from './ScreenSizeRow/ScreenSizeRow'
import PositionModeRow from './PositionModeRow/PositionModeRow'
import SizeRow from './SizeRow/SizeRow'
import FlexChildRow from './FlexChildRow/FlexChildRow'

export default function GeneralSettings() {
  return (
    <PanelSection>
      <ScreenSizeRow />
      <PositionModeRow />
      <PositionRow />
      <SizeRow />
      <FlexChildRow />
      <OpacityRow />
      <RoundingRow />
    </PanelSection>
  )
}
