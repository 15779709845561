import PanelRowButton from 'components/Library/Components/Button/PanelRowButton/PanelRowButton'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TabsBlock from 'components/Library/Components/Tabs/TabsBlock'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { panelPosition } from 'components/Library/Containers/constants'
import useClosePopup from 'hooks/ui/useClosePopup'
import { useRef, useState } from 'react'
import {
  ScreenSizeType,
  StandardScreens,
  UniqueScreens,
} from 'application/shapes'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { PortalPreview } from 'components/Pages/Editor/PortalRootPreview/PortalRootPreview'

interface ScreenSizePopupProps {
  buttonRef: React.RefObject<HTMLDivElement>
  close: () => void
}

export default function ScreenSizePopup({
  buttonRef,
  close,
}: ScreenSizePopupProps) {
  const popupRef = useRef<HTMLDivElement>(null)
  const [tab, setTab] = useState<ScreenSizeType>('desktop')

  useClosePopup({
    ref: popupRef,
    close,
    exceptionRef: buttonRef,
  })

  const { handlers } = usePanelHelper(editor.getUI().getPreviewPanel())
  const { setSize } = handlers

  return (
    <PortalPreview>
      <PanelContainer panelRef={popupRef} position={getPosition()}>
        <PanelSection>
          <PanelRow>
            <TabsBlock
              tabs={['desktop', 'tablet', 'phone']}
              selected={tab}
              select={(t) => setTab(t as ScreenSizeType)}
            />
            <IconBlock icon="X" onClick={close} />
          </PanelRow>
        </PanelSection>
        <PanelSection>
          {StandardScreens[tab].map((screen) => (
            <PanelRowButton
              key={screen.label}
              text={screen.label}
              subtext={`${screen.w}x${screen.h}`}
              onClick={() => setSize(screen.w, screen.h, true)}
            />
          ))}
        </PanelSection>
        <PanelSection bottom={true}>
          {UniqueScreens[tab].map((screen) => (
            <PanelRowButton
              key={screen.label}
              text={screen.label}
              subtext={`${screen.w}x${screen.h}`}
              onClick={() => setSize(screen.w, screen.h, true)}
            />
          ))}
        </PanelSection>
      </PanelContainer>
    </PortalPreview>
  )
}

function getPosition(): panelPosition {
  return {
    position: 'absolute',
    top: 64,
    left: 8,
    zIndex: 10,
  }
}
