import {
  AttributeAutolayoutAlign,
  AttributeAutolayoutDirection,
  AttributeAutolayoutMode,
  MultiselectStyleMap,
  StyleMap,
  isContainerType,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { AddAutolayoutAction } from 'editor/action/node/autolayout'
import { CommandHandler } from 'application/client'
import { NodeAttributesAction } from 'application/action/attributes'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { getFixedLeft, getFixedTop } from 'application/units'
import { ReadOnlyNode } from 'application/node'

type LayoutPanelKeys =
  | 'autolayout.mode'
  | 'autolayout.direction'
  | 'autolayout.align.main'
  | 'autolayout.align.counter'
  | 'autolayout.align.counter'
  | 'autolayout.gap'

type AutolayoutPanelAttributes = Pick<
  MultiselectStyleMap,
  LayoutPanelKeys
> | null

export interface LayoutPanelState {
  attributes: AutolayoutPanelAttributes
  modes: AttributeAutolayoutMode[]
}

export interface LayoutPanelHandlers {
  setMode: (mode: AttributeAutolayoutMode) => void
  setDirection: (value: AttributeAutolayoutDirection) => void
  setAlign: (
    main: AttributeAutolayoutAlign,
    counter: AttributeAutolayoutAlign
  ) => void
  setMain: (main: AttributeAutolayoutAlign) => void
  setCounter: (value: AttributeAutolayoutAlign) => void
  setGap: (value: number) => void
  slideGap: (value: number) => void
}

export class LayoutPanel extends StyleAttributePanel<
  LayoutPanelState,
  LayoutPanelHandlers,
  LayoutPanelKeys
> {
  private addAutolayoutAction: AddAutolayoutAction

  constructor(
    commandHandler: CommandHandler,
    action: NodeAttributesAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    addAutolayoutAction: AddAutolayoutAction
  ) {
    super(commandHandler, action, document, documentSelection)
    this.addAutolayoutAction = addAutolayoutAction
  }

  getSettings(): LayoutPanelState {
    return {
      attributes: this.attributes,
      modes: this.getModeOptions(),
    }
  }

  getHandlers(): LayoutPanelHandlers {
    return {
      setMode: this.setMode,
      setDirection: this.setDirection,
      setAlign: this.setAlign,
      setMain: this.setMain,
      setCounter: this.setCounter,
      setGap: this.setGap,
      slideGap: this.slideGap,
    }
  }

  private getModeOptions = (): AttributeAutolayoutMode[] => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return []

    const modes: AttributeAutolayoutMode[] = ['block', 'none']
    const flex = nodes.every((node) => this.isModeAllowed(node, 'flex'))
    if (flex) modes.push('flex')

    return modes
  }

  private setMode = (mode: AttributeAutolayoutMode): void => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    switch (mode) {
      case 'flex':
        for (const node of nodes) {
          this.setAbsoluteChildrenToAuto(node)
          this.setOne(node.getId(), this.getAutolayoutAttributes(node))
        }
        break
      case 'block':
        for (const node of nodes) {
          this.setAutoChildrenToAbsolute(node)
          this.setOne(node.getId(), { 'autolayout.mode': mode })
        }
        break
      case 'none':
        this.setMulti({ 'autolayout.mode': mode })
        break
    }

    this.commit()
  }

  private setDirection = (value: AttributeAutolayoutDirection): void => {
    const nodes = this.getNodes()

    for (const node of nodes) {
      const direction = node.getStyleAttribute('autolayout.direction')
      if (direction === value) continue

      const main = node.getStyleAttribute('autolayout.align.main')
      const counter = node.getStyleAttribute('autolayout.align.counter')

      if (
        counter === 'spaced' ||
        (value === 'wrap' && direction === 'row') ||
        (value === 'row' && direction === 'wrap')
      ) {
        this.setOne(node.getId(), {
          'autolayout.direction': value,
        })
      } else {
        this.setOne(node.getId(), {
          'autolayout.direction': value,
          'autolayout.align.main': counter,
          'autolayout.align.counter': main,
        })
      }
    }

    this.commit()
  }

  private setAlign = (
    main: AttributeAutolayoutAlign,
    counter: AttributeAutolayoutAlign
  ): void => {
    this.setMulti({
      'autolayout.align.main': main,
      'autolayout.align.counter': counter,
    })
    this.commit()
  }

  private setMain = (main: AttributeAutolayoutAlign): void => {
    this.setMulti({ 'autolayout.align.main': main })
    this.commit()
  }

  private setCounter = (value: AttributeAutolayoutAlign): void => {
    this.setMulti({ 'autolayout.align.counter': value })
    this.commit()
  }

  private setGap = (value: number): void => {
    this.setMulti({ 'autolayout.gap': value })
  }

  private slideGap = (value: number): void => {
    this.slideMulti('autolayout.gap', value)
  }

  private setAutoChildrenToAbsolute = (node: ReadOnlyNode): void => {
    const children = node.getChildren()
    if (!children) return

    for (const childId of children) {
      const child = this.document.getNode(childId)
      if (!child) continue

      const positionMode = child.getStyleAttribute('position.mode')
      if (positionMode !== 'auto') continue

      const fixedTop = getFixedTop(child, this.document)
      const fixedLeft = getFixedLeft(child, this.document)

      this.setOne(childId, {
        'position.mode': 'absolute',
        ...fixedTop,
        ...fixedLeft,
        'position.right.auto': 'auto',
        'position.bottom.auto': 'auto',
      })
    }
  }

  private setAbsoluteChildrenToAuto = (node: ReadOnlyNode): void => {
    const children = node.getChildren()
    if (!children) return

    for (const childId of children) {
      const child = this.document.getNode(childId)
      if (!child) continue

      const positionMode = child.getStyleAttribute('position.mode')
      if (positionMode !== 'absolute') continue

      this.setOne(childId, {
        'position.mode': 'auto',
        'position.top.auto': 'auto',
        'position.left.auto': 'auto',
        'position.right.auto': 'auto',
        'position.bottom.auto': 'auto',
      })
    }
  }

  private getAutolayoutAttributes = (node: ReadOnlyNode): Partial<StyleMap> => {
    const attributes: Partial<StyleMap> = { 'autolayout.mode': 'flex' }
    const direction = node.getStyleAttribute('autolayout.direction')
    const main = node.getStyleAttribute('autolayout.align.main')
    const counter = node.getStyleAttribute('autolayout.align.counter')
    const gap = node.getStyleAttribute('autolayout.gap')
    const isPage = node.getBaseAttribute('type') === 'page'
    const initialDirection = isPage ? 'column' : 'row'
    if (!direction) attributes['autolayout.direction'] = initialDirection
    if (!main) attributes['autolayout.align.main'] = 'start'
    if (!counter) attributes['autolayout.align.counter'] = 'start'
    if (gap === undefined) attributes['autolayout.gap'] = 0
    return attributes
  }

  private isModeAllowed = (
    node: ReadOnlyNode,
    mode: AttributeAutolayoutMode
  ): boolean => {
    switch (mode) {
      case 'block':
      case 'none':
        return true
      case 'flex':
        const type = node.getBaseAttribute('type')
        return isContainerType(type)
    }
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 1_000
  }
}
