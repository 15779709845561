import { WriteDocument } from 'application/document'
import { PositionNode, PositionNodeMap } from '../types'
import {
  getAlignItems,
  getGap,
  getJustifyContent,
  getLayoutDirection,
  getLayoutMode,
  getPadding,
} from 'application/layout/utils'
import { ReadOnlyNode } from 'application/node'
import { divideChildren, getNodes, maxNodeSize, sumNodeSizes } from './utils'
import {
  AttributeAutolayoutAlign,
  AttributeAutolayoutDirection,
  isAbsolutePositionMode,
} from 'application/attributes'
import { getPositionNode } from '../utils'

type LineSize = { start: number; end: number }

export class FlexPositionCalculator {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  calculate = (id: string, positionMap: PositionNodeMap): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const childrenIds = node.getChildren()
    if (!childrenIds) return

    const position = getPositionNode(id, positionMap)
    if (!position) return

    const direction = getLayoutDirection(node)
    const align = getAlignItems(node)
    const justify = getJustifyContent(node)
    const gap = getGap(node)

    const alignStart = this.getAlignStart(node, position, direction)
    const alignEnd = this.getAlignEnd(node, position, direction)
    const justifyStart = this.getJustifyStart(node, position, direction)
    const justifyEnd = this.getJustifyEnd(node, position, direction)

    const children = getNodes(childrenIds, this.document)
    const dividedChildren = divideChildren(
      node,
      children,
      direction,
      justifyEnd - justifyStart
    )
    const lines = this.computeLineSizes(
      alignStart,
      alignEnd,
      gap,
      dividedChildren
    )

    for (let i = 0; i < dividedChildren.length; i++) {
      const line = lines[i]
      const subset = dividedChildren[i]
      const flowSubset = subset.filter((c) => this.isChildInFlow(c))
      const lineJustify = this.computeJustifyInitial(
        justifyStart,
        justifyEnd,
        gap,
        flowSubset,
        direction,
        justify
      )
      const actualGap = this.computeGap(
        justifyStart,
        justifyEnd,
        flowSubset,
        justify,
        direction,
        gap
      )

      let currentJustify = lineJustify
      for (const child of subset) {
        const childPosition = getPositionNode(child.getId(), positionMap)
        if (!childPosition) continue

        const childAlign = this.computeChildAlign(
          line.start,
          line.end,
          child,
          direction,
          align
        )

        this.setChildAlign(childAlign, direction, childPosition)
        this.setChildJustify(currentJustify, direction, childPosition)

        if (this.isChildInFlow(child)) {
          const nextJustify = this.computeNextJustify(
            currentJustify,
            child,
            direction,
            actualGap
          )
          currentJustify = nextJustify
        } else {
          const outOfFlowJustify = this.computeOutOfFlowJustify(
            justifyStart,
            justifyEnd,
            child,
            direction,
            justify
          )
          this.setChildJustify(outOfFlowJustify, direction, childPosition)
        }
      }
    }
  }

  private setChildAlign = (
    value: number,
    direction: AttributeAutolayoutDirection,
    position: PositionNode
  ): void => {
    switch (direction) {
      case 'row':
      case 'wrap':
        position.y = value
        break
      case 'column':
        position.x = value
        break
    }
  }

  private setChildJustify = (
    value: number,
    direction: AttributeAutolayoutDirection,
    position: PositionNode
  ): void => {
    switch (direction) {
      case 'row':
      case 'wrap':
        position.x = value
        break
      case 'column':
        position.y = value
        break
    }
  }

  private computeNextJustify = (
    current: number,
    child: ReadOnlyNode,
    direction: AttributeAutolayoutDirection,
    gap: number
  ): number => {
    switch (direction) {
      case 'row':
      case 'wrap':
        const w = child.getBaseAttribute('w')
        return current + w + gap
      case 'column':
        const h = child.getBaseAttribute('h')
        return current + h + gap
    }
  }

  private computeChildAlign = (
    start: number,
    end: number,
    child: ReadOnlyNode,
    direction: AttributeAutolayoutDirection,
    align: AttributeAutolayoutAlign
  ): number => {
    const alignSelf = child.getStyleAttribute('flex.alignSelf')
    if (alignSelf === 'stretch') return start

    switch (direction) {
      case 'row':
      case 'wrap':
        const h = child.getBaseAttribute('h')
        switch (align) {
          case 'end':
            return end - h
          case 'center':
            return start + (end - start - h) / 2
          case 'start':
          default:
            return start
        }
      case 'column':
        const w = child.getBaseAttribute('w')
        switch (align) {
          case 'end':
            return end - w
          case 'center':
            return start + (end - start - w) / 2
          case 'start':
          default:
            return start
        }
    }
  }

  private computeOutOfFlowJustify = (
    start: number,
    end: number,
    child: ReadOnlyNode,
    direction: AttributeAutolayoutDirection,
    justify: AttributeAutolayoutAlign
  ): number => {
    switch (direction) {
      case 'row':
      case 'wrap':
        const w = child.getBaseAttribute('w')
        switch (justify) {
          case 'end':
            return end - w
          case 'center':
            return start + (end - start - w) / 2
          case 'start':
          default:
            return start
        }
      case 'column':
        const h = child.getBaseAttribute('h')
        switch (justify) {
          case 'end':
            return end - h
          case 'center':
            return start + (end - start - h) / 2
          case 'start':
          default:
            return start
        }
    }
  }

  private computeJustifyInitial = (
    start: number,
    end: number,
    gap: number,
    children: ReadOnlyNode[],
    direction: AttributeAutolayoutDirection,
    justify: AttributeAutolayoutAlign
  ): number => {
    const totalGap = gap * (children.length - 1)
    switch (direction) {
      case 'row':
      case 'wrap':
        const w = sumNodeSizes(children, 'w')
        switch (justify) {
          case 'end':
            return end - w - totalGap
          case 'center':
            return start + (end - start - w - totalGap) / 2
          case 'start':
          case 'spaced':
            return start
        }
        break
      case 'column':
        const h = sumNodeSizes(children, 'h')
        switch (justify) {
          case 'end':
            return end - h - totalGap
          case 'center':
            return start + (end - start - h - totalGap) / 2
          case 'start':
          case 'spaced':
            return start
        }
        break
    }
  }

  private computeGap = (
    start: number,
    end: number,
    children: ReadOnlyNode[],
    justify: AttributeAutolayoutAlign,
    direction: AttributeAutolayoutDirection,
    gap: number
  ): number => {
    switch (direction) {
      case 'row':
      case 'wrap':
        switch (justify) {
          case 'start':
          case 'center':
          case 'end':
            return gap
          case 'spaced':
            const sum = sumNodeSizes(children, 'w')
            return Math.max((end - start - sum) / (children.length - 1), gap)
        }
        break
      case 'column':
        switch (justify) {
          case 'start':
          case 'center':
          case 'end':
            return gap
          case 'spaced':
            const sum = sumNodeSizes(children, 'h')
            return Math.max((end - start - sum) / (children.length - 1), gap)
        }
        break
    }
  }

  private computeLineSizes = (
    start: number,
    end: number,
    gap: number,
    dividedChildren: ReadOnlyNode[][]
  ): LineSize[] => {
    const heights = dividedChildren.map((subset) => maxNodeSize(subset, 'h'))
    const rowGap = gap * (heights.length - 1)
    const totalRowHeight = heights.reduce((acc, h) => acc + h, 0)
    const remainingHeight = end - start - totalRowHeight - rowGap

    const addedHeight = remainingHeight / heights.length
    for (let i = 0; i < heights.length; i++) {
      heights[i] += addedHeight
    }

    const lineSizes: LineSize[] = []
    let currentStart = start
    for (let i = 0; i < heights.length; i++) {
      const h = heights[i]
      lineSizes.push({ start: currentStart, end: currentStart + h })
      currentStart += h + gap
    }

    return lineSizes
  }

  private getAlignStart = (
    node: ReadOnlyNode,
    position: PositionNode,
    direction: AttributeAutolayoutDirection
  ): number => {
    switch (direction) {
      case 'row':
      case 'wrap':
        const top = getPadding(node, 'top')
        return position.y + top
      case 'column':
        const left = getPadding(node, 'left')
        return position.x + left
    }
  }

  private getAlignEnd = (
    node: ReadOnlyNode,
    position: PositionNode,
    direction: AttributeAutolayoutDirection
  ): number => {
    switch (direction) {
      case 'row':
      case 'wrap':
        const h = node.getBaseAttribute('h')
        const bottom = getPadding(node, 'bottom')
        return position.y + h - bottom
      case 'column':
        const w = node.getBaseAttribute('w')
        const right = getPadding(node, 'right')
        return position.x + w - right
    }
  }

  private getJustifyStart = (
    node: ReadOnlyNode,
    position: PositionNode,
    direction: AttributeAutolayoutDirection
  ): number => {
    switch (direction) {
      case 'row':
      case 'wrap':
        const left = getPadding(node, 'left')
        return position.x + left
      case 'column':
        const top = getPadding(node, 'top')
        return position.y + top
    }
  }

  private getJustifyEnd = (
    node: ReadOnlyNode,
    position: PositionNode,
    direction: AttributeAutolayoutDirection
  ): number => {
    switch (direction) {
      case 'row':
      case 'wrap':
        const w = node.getBaseAttribute('w')
        const right = getPadding(node, 'right')
        return position.x + w - right
      case 'column':
        const h = node.getBaseAttribute('h')
        const bottom = getPadding(node, 'bottom')
        return position.y + h - bottom
    }
  }

  private isChildInFlow = (node: ReadOnlyNode): boolean => {
    const layout = getLayoutMode(node)
    if (layout === 'none') return false

    const absolute = isAbsolutePositionMode(node)
    if (absolute) return false

    return true
  }
}
