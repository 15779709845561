import { useEffect, useRef, useState } from 'react'
import useCommit from 'hooks/editor/useCommit'
import { DesignColor } from 'themes'
import Text from 'components/Library/Text/Text'
import _ from 'lodash'
import { AttributeType } from 'application/attributes'

interface LayerNameInputProps {
  type: AttributeType

  editing: boolean
  setEditing: (editing: boolean) => void

  name: string
  setName: (name: string) => void

  hidden: boolean
}

export default function LayerNameInput({
  type,
  editing,
  setEditing,
  name,
  setName,
  hidden,
}: LayerNameInputProps) {
  const commit = useCommit()
  const [localName, setLocalName] = useState(name)

  const inputRef = useRef<HTMLInputElement>(null)
  const color = getColor(hidden)

  const handleSetEditing = (e: boolean) => {
    if (e) {
      setEditing(true)
    } else {
      if (localName !== '') setName(localName)
      setEditing(false)
      if (localName !== '') commit()
    }
  }

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
      inputRef.current?.select()
    }
  }, [editing])

  useEffect(() => {
    if (editing) return
    setLocalName(name)
  }, [editing, localName, name, setName])

  return (
    <div
      style={{
        height: 32,
        padding: '0px 4px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        overflow: 'hidden',
        flexGrow: 1,
        flexShrink: 1,
      }}
      onDoubleClick={() => handleSetEditing(true)}
    >
      {editing ? (
        <NameInput
          inputRef={inputRef}
          color={color}
          name={localName}
          setName={setLocalName}
          startEditing={() => handleSetEditing(true)}
          doneEditing={() => handleSetEditing(false)}
        />
      ) : (
        <Text color={color}>{name === '' ? _.capitalize(type) : name}</Text>
      )}
    </div>
  )
}

function NameInput({
  inputRef,
  color,
  name,
  setName,
  startEditing,
  doneEditing,
}: {
  inputRef: React.RefObject<HTMLInputElement>
  color: string
  name: string
  setName: (name: string) => void
  startEditing: () => void
  doneEditing: () => void
}) {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      e.preventDefault()
      doneEditing()
    }
  }

  return (
    <input
      ref={inputRef}
      type="text"
      value={name}
      onChange={(e) => setName(e.target.value)}
      onPaste={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={() => startEditing()}
      onMouseDown={(e) => e.stopPropagation()}
      onKeyDown={handleKeyDown}
      onBlur={() => doneEditing()}
      style={{
        flex: 1,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        padding: 0,
        margin: 0,
        border: 'none',
        outline: 'none',
        background: 'transparent',
        color: color,
        fontFamily: 'Inter',
        fontWeight: 'regular',
        fontSize: 11,
      }}
    />
  )
}

function getColor(hidden: boolean): string {
  return hidden ? DesignColor('text3') : DesignColor('text1')
}
