import { DesignColor } from 'themes'
import { IconKey } from 'assets/iconLibrary'
import { useRef, useState, useEffect } from 'react'
import Icon from 'components/Library/Components/Icon/Icon/Icon'

export interface OnboardingInputFieldProps {
  label: string
  icon?: IconKey
  placeholder: string
  value: string

  setValue: (value: string) => void
}

export default function ProjectSettingField({
  label,
  icon,
  placeholder,
  value,
  setValue,
}: OnboardingInputFieldProps) {
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [localValue, setLocalValue] = useState(value)
  const inputRef = useRef<HTMLInputElement>(null)

  const borderColor =
    hover || focus ? DesignColor('inputHighlight') : DesignColor('panelBorder')

  const handleClick = () => {
    inputRef.current?.focus()
  }

  const handleBlur = () => {
    setValue(localValue)
    setFocus(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur()
    }
  }

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        gap: 8,
      }}
    >
      <p
        style={{
          fontSize: 12,
          color: DesignColor('text2'),
          fontFamily: 'inter',
        }}
      >
        {label}
      </p>
      <div
        style={{
          width: '100%',
          padding: '8px 12px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          gap: '10px',
          background: 'none',
          boxShadow: `inset 0px 0px 0px 1px ${borderColor}`,
          borderRadius: 8,
          cursor: 'text',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onFocus={() => setFocus(true)}
        onBlur={handleBlur}
        onClick={handleClick}
      >
        {icon && <Icon icon={icon} size={24} color={DesignColor('text2')} />}
        <input
          ref={inputRef}
          type="text"
          value={localValue}
          onChange={(e) => setLocalValue(e.target.value)}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          style={{
            width: '100%',
            padding: 0,
            margin: 0,
            fontSize: 14,
            fontFamily: 'Inter',
            color: DesignColor('text1'),
            background: 'none',
            border: 'none',
            outline: 'none',
          }}
        />
      </div>
    </div>
  )
}
