import { TemplateInfo } from 'application/service'
import TemplateCard from './TemplateCard'
import { useEffect, useRef } from 'react'

interface TemplatesDisplayProps {
  templates: TemplateInfo[]

  scroll: number
  setScroll: (scroll: number) => void

  select(template: TemplateInfo): void
  copy(template: TemplateInfo): void
}

export default function TemplatesDisplay({
  templates,
  scroll,
  setScroll,
  select,
  copy,
}: TemplatesDisplayProps) {
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: scroll })
    }
  }, [scroll, scrollRef])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', () => {
        if (scrollRef.current) {
          setScroll(scrollRef.current.scrollTop)
        }
      })
    }
  }, [scrollRef, setScroll])

  return (
    <div
      ref={scrollRef}
      style={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gridAutoRows: 'auto',
        padding: '24px',
        boxSizing: 'border-box',
        gap: 24,
        flexWrap: 'wrap',
        overflowY: 'scroll',
      }}
    >
      {templates.map((template, index) => (
        <TemplateCard
          key={`${template.name}-${index}`}
          template={template}
          select={select}
          copy={copy}
        />
      ))}
    </div>
  )
}
