import { ReadOnlyDocument } from 'application/document'

export interface IdGenerator {
  next(): string
}

export class NodeIdGenerator implements IdGenerator {
  private document: ReadOnlyDocument
  private max: number = 0

  constructor(document: ReadOnlyDocument) {
    this.document = document
    this.findMax()
  }

  next(): string {
    this.findMax()
    this.max++
    return this.max.toString()
  }

  private findMax(): void {
    const currentMax = this.document.getNodes().reduce((max, node) => {
      const id = parseInt(node.getId())
      if (isNaN(id)) return max
      return Math.max(max, id)
    }, 0)
    this.max = Math.max(this.max, currentMax)
  }
}
