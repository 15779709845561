import ToolbarTextInput from 'components/Library/Components/Toolbar/ToolbarTextInput/ToolbarTextInput'
import { activeProjectAtom } from 'components/Pages/Projects/Projects'
import useIsPlayground from 'hooks/playground/useIsPlayground'
import useBackendService from 'hooks/services/useBackendService'
import { useRecoilState } from 'recoil'

export default function ProjectNameInput() {
  const [project, setProject] = useRecoilState(activeProjectAtom)
  const backendService = useBackendService()
  const playground = useIsPlayground()

  if (playground || !project) {
    return (
      <ToolbarTextInput
        value={'Playground'}
        setValue={() => Promise.resolve()}
        editable={false}
      />
    )
  }

  const updateName = async (v: string) => {
    const updated = await backendService.updateProject(project.id, {
      name: v,
    })
    setProject(updated)
  }

  return <ToolbarTextInput value={project.name} setValue={updateName} />
}
