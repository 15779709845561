import {
  AttributeSizeAuto,
  AttributeSizeMinMaxMode,
  MultiselectStyleMap,
  isAbsolutePositionMode,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'
import { truncate } from 'application/math'
import { getPadding } from 'application/layout/utils'

type SizePanelKeys =
  | 'size.w'
  | 'size.h'
  | 'size.w.percent'
  | 'size.h.percent'
  | 'size.h.min'
  | 'size.h.max'
  | 'size.w.min'
  | 'size.w.max'
  | 'size.w.auto'
  | 'size.h.auto'

type SizePanelAttributes = Pick<MultiselectStyleMap, SizePanelKeys> | null

export interface SizePanelState {
  attributes: SizePanelAttributes
  minWMode: 'Mixed' | AttributeSizeMinMaxMode
  minHMode: 'Mixed' | AttributeSizeMinMaxMode
  maxWMode: 'Mixed' | AttributeSizeMinMaxMode
  maxHMode: 'Mixed' | AttributeSizeMinMaxMode
  widthDisplay: 'Mixed' | number
  heightDisplay: 'Mixed' | number
  widthOptions: ('min' | 'max')[]
  heightOptions: ('min' | 'max')[]
  widthAutoOptions: AttributeSizeAuto[]
  heightAutoOptions: AttributeSizeAuto[]
}

export interface SizePanelHandlers {
  setSize: (value: number, mode: 'w' | 'h') => void
  slideSize: (value: number, mode: 'w' | 'h') => void
  addMinMax: (type: 'min' | 'max', mode: 'w' | 'h') => void
  removeMinMax: (type: 'min' | 'max', mode: 'w' | 'h') => void
  setMinMax: (value: number, type: 'min' | 'max', mode: 'w' | 'h') => void
  slideMinMax: (value: number, type: 'min' | 'max', mode: 'w' | 'h') => void
  setSizeAuto: (value: AttributeSizeAuto, mode: 'w' | 'h') => void
}

export class SizePanel extends StyleAttributePanel<
  SizePanelState,
  SizePanelHandlers,
  SizePanelKeys
> {
  getSettings(): SizePanelState {
    return {
      attributes: this.attributes,
      minWMode: this.getMinMaxMode('w', 'min'),
      minHMode: this.getMinMaxMode('h', 'min'),
      maxWMode: this.getMinMaxMode('w', 'max'),
      maxHMode: this.getMinMaxMode('h', 'max'),
      widthDisplay: this.getDisplay('w'),
      heightDisplay: this.getDisplay('h'),
      widthOptions: this.getMinMaxOptions('w'),
      heightOptions: this.getMinMaxOptions('h'),
      widthAutoOptions: this.getAutoWOptions(),
      heightAutoOptions: this.getAutoHOptions(),
    }
  }

  getHandlers(): SizePanelHandlers {
    return {
      setSize: this.setSize,
      slideSize: this.slideSize,
      setSizeAuto: this.setSizeAuto,
      addMinMax: this.addMinMax,
      removeMinMax: this.removeMinMax,
      setMinMax: this.setMinMax,
      slideMinMax: this.slideMinMax,
    }
  }

  private setSize = (value: number, mode: 'w' | 'h'): void => {
    const nodes = this.getNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      const auto = node.getStyleAttribute(`size.${mode}.auto`)
      switch (auto) {
        case 'percent':
          this.setSizePercent(node, mode, value)
          break
        default:
          this.setSizeFixed(node, mode, value)
          break
      }
    }
  }

  private setSizeAuto = (value: AttributeSizeAuto, mode: 'w' | 'h'): void => {
    const pairs = this.getNodesAndParents()
    for (const [node, parent] of pairs) {
      if (!parent) continue
      switch (value) {
        case 'auto':
        case 'fill':
          this.setOne(node.getId(), {
            [`size.${mode}.auto`]: value,
            [`size.${mode}.percent`]: undefined,
            [`size.${mode}`]: undefined,
          })
          break
        case 'percent':
          const percent = this.computePercent(node, parent, mode)
          this.setOne(node.getId(), {
            [`size.${mode}.auto`]: value,
            [`size.${mode}.percent`]: percent,
            [`size.${mode}`]: undefined,
          })
          break
        case 'fixed':
          this.setOne(node.getId(), {
            [`size.${mode}.auto`]: value,
            [`size.${mode}.percent`]: undefined,
            [`size.${mode}`]: node.getBaseAttribute(mode),
          })
          break
      }
    }
    this.commit()
  }

  private slideSize = (value: number, mode: 'w' | 'h'): void => {
    const nodes = this.getNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      const auto = node.getStyleAttribute(`size.${mode}.auto`)
      switch (auto) {
        case 'fixed':
          const currentPx = node.getStyleAttribute(`size.${mode}`)
          if (currentPx === undefined) break
          this.setSizeFixed(node, mode, Math.max(currentPx + value, 0))
          break
        case 'percent':
          const currentPer = node.getStyleAttribute(`size.${mode}.percent`)
          if (currentPer === undefined) break
          this.setSizePercent(
            node,
            mode,
            Math.max(truncate(currentPer + value, 0), 0)
          )
          break
        case 'auto':
        case 'fill':
          const currentValue = node.getBaseAttribute(mode)
          this.setSizeFixed(node, mode, currentValue)
      }
    }
  }

  private setSizeFixed(
    node: ReadOnlyNode,
    mode: 'w' | 'h',
    value: number
  ): void {
    this.setOne(node.getId(), {
      [`size.${mode}`]: value,
      [`size.${mode}.auto`]: 'fixed',
    })
  }

  private setSizePercent(
    node: ReadOnlyNode,
    mode: 'w' | 'h',
    value: number
  ): void {
    this.setOne(node.getId(), {
      [`size.${mode}.percent`]: value,
      [`size.${mode}.auto`]: 'percent',
    })
  }

  private addMinMax = (type: 'min' | 'max', mode: 'w' | 'h'): void => {
    const options = this.getMinMaxOptions(mode)
    if (!options.includes(type)) return

    const nodes = this.getNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      this.setOne(node.getId(), {
        [`size.${mode}.${type}`]: node.getBaseAttribute(mode),
        [`size.${mode}.${type}.mode`]: 'fixed',
      })
    }

    this.commit()
  }

  private removeMinMax = (type: 'min' | 'max', mode: 'w' | 'h'): void => {
    const nodes = this.getNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      this.setOne(node.getId(), {
        [`size.${mode}.${type}`]: undefined,
        [`size.${mode}.${type}.mode`]: 'none',
      })
    }
    this.commit()
  }

  private setMinMax = (
    value: number,
    type: 'min' | 'max',
    mode: 'w' | 'h'
  ): void => {
    const nodes = this.getNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      this.setOne(node.getId(), {
        [`size.${mode}.${type}`]: value,
      })
    }
  }

  private slideMinMax = (
    value: number,
    type: 'min' | 'max',
    mode: 'w' | 'h'
  ): void => {
    this.slideMulti(`size.${mode}.${type}`, value)
  }

  private getDisplay = (mode: 'w' | 'h'): 'Mixed' | number => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'Mixed'

    const values = nodes.map((n) => n.getBaseAttribute(mode))
    for (let i = 1; i < values.length; i++) {
      if (values[i] !== values[0]) return 'Mixed'
    }

    return values[0]
  }

  private getMinMaxMode = (
    mode: 'w' | 'h',
    type: 'min' | 'max'
  ): 'Mixed' | AttributeSizeMinMaxMode => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'Mixed'

    const values = nodes
      .map((n) => n.getStyleAttribute(`size.${mode}.${type}.mode`))
      .map((v) => v || 'none')
    for (let i = 1; i < values.length; i++) {
      if (values[i] !== values[0]) return 'Mixed'
    }

    return values[0]
  }

  private getMinMaxOptions = (mode: 'w' | 'h'): ('min' | 'max')[] => {
    if (!this.attributes) return []

    const options: ('min' | 'max')[] = []

    if (this.attributes[`size.${mode}.min`] === undefined) options.push('min')
    if (this.attributes[`size.${mode}.max`] === undefined) options.push('max')

    return options
  }

  private getAutoWOptions = (): AttributeSizeAuto[] => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return []

    const modes: AttributeSizeAuto[] = ['auto', 'fixed']
    if (this.canAllPercentW()) {
      modes.push('percent')
      modes.push('fill')
    }

    return modes
  }

  private getAutoHOptions = (): AttributeSizeAuto[] => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return []

    const modes: AttributeSizeAuto[] = ['auto', 'fixed']
    if (this.canAllPercentH()) {
      modes.push('percent')
      modes.push('fill')
    }

    return modes
  }

  private computePercent(
    node: ReadOnlyNode,
    parent: ReadOnlyNode,
    mode: 'w' | 'h'
  ): number | null {
    const availableSpace = this.computeAvailableSpace(parent, mode)
    if (availableSpace === null) return null

    const size = node.getBaseAttribute(mode)
    const percent = (size / availableSpace) * 100

    return truncate(percent, 1)
  }

  protected getSlideMax = (): number => {
    return 50_000
  }

  protected getSlideMin = (): number => {
    return 0
  }

  private computeAvailableSpace(
    node: ReadOnlyNode,
    mode: 'w' | 'h'
  ): number | null {
    const children = node.getChildren()
    if (!children) return null

    const padding1Key = mode === 'w' ? 'left' : 'top'
    const padding2Key = mode === 'w' ? 'right' : 'bottom'

    const direction = node.getStyleAttribute('autolayout.direction')
    const counter = node.getStyleAttribute('autolayout.align.counter')
    const padding1 = getPadding(node, padding1Key)
    const padding2 = getPadding(node, padding2Key)
    const size = node.getBaseAttribute(mode)
    const gap = node.getStyleAttribute('autolayout.gap') || 0

    let availableSpace = size - padding1 - padding2

    if (
      mode === 'w' &&
      direction === 'row' &&
      children.length > 0 &&
      counter !== 'spaced' &&
      node.getStyleAttribute('size.w.auto') !== 'auto'
    ) {
      const autoChildren = this.getAutolayoutChildren(node)
      availableSpace -= gap * (autoChildren.length - 1)
    }

    if (
      mode === 'h' &&
      direction === 'column' &&
      children.length > 0 &&
      counter !== 'spaced' &&
      node.getStyleAttribute('size.w.auto') !== 'auto'
    ) {
      const autoChildren = this.getAutolayoutChildren(node)
      availableSpace -= gap * (autoChildren.length - 1)
    }

    return availableSpace
  }

  private getAutolayoutChildren = (node: ReadOnlyNode): ReadOnlyNode[] => {
    const ids = node.getChildren()
    if (!ids) return []

    const children = ids
      .map((c) => this.document.getNode(c))
      .filter((c) => c) as ReadOnlyNode[]

    return children.filter((c) => !isAbsolutePositionMode(c))
  }

  private canAllPercentW = (): boolean => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return false

    for (const [, parent] of pairs) {
      if (!parent) return false
      if (parent.getBaseAttribute('type') === 'canvas') return false
    }

    return true
  }

  private canAllPercentH = (): boolean => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return false

    for (const [, parent] of pairs) {
      if (!parent) return false
      if (parent.getBaseAttribute('type') === 'canvas') return false
    }

    return true
  }
}
