import { AttributeType } from 'application/attributes'
import { IconKey } from 'assets/iconLibrary'
import { tooltipKey } from 'assets/tooltips'
import ToolbarIconButton from 'components/Library/Components/Toolbar/ToolbarIconButton/ToolbarIconButton'
import TooltipPopup from 'components/Library/Components/TooltipPopup/TooltipPopup'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import { ActionDraw } from 'editor'
import useAction from 'hooks/editor/useAction'
import useActionInitiator from 'hooks/editor/useActionInitiator'
import { atom, useRecoilState } from 'recoil'

export const templateTooltipState = atom({
  key: 'templateTooltip',
  default: false,
})

export default function ToolButtons() {
  const action = useAction()
  const { draw, cancel, toggleLibrary } = useActionInitiator()

  const [tooltip, setTooltip] = useRecoilState(templateTooltipState)

  const handleClick = (type: AttributeType) => {
    if (drawTools.some((t) => t.action === action && t.type === type)) {
      cancel()
    } else {
      draw(type)
    }
  }

  return (
    <PanelContainer
      id={'tool-buttons'}
      width={'hug'}
      height={48}
      paddingH={10}
      direction={'row'}
    >
      {drawTools.map((t) => (
        <ToolbarIconButton
          key={t.name}
          icon={t.icon}
          tooltipKey={t.tooltipKey}
          active={action === t.action}
          onClick={() => handleClick(t.type)}
        />
      ))}
      <TooltipPopup active={tooltip} tooltipKey={'TutorialTemplates'}>
        <ToolbarIconButton
          icon={'Grid'}
          tooltipKey="AddTemplates"
          active={action === 'openTemplateLibrary'}
          onClick={() => {
            setTooltip(false)
            toggleLibrary('openTemplateLibrary')
          }}
        />
      </TooltipPopup>
    </PanelContainer>
  )
}

const drawTools: {
  name: string
  action: ActionDraw
  type: AttributeType
  tooltipKey: tooltipKey
  icon: IconKey
  hotkey: string
}[] = [
  {
    name: 'Page',
    action: 'drawPage',
    type: 'page',
    tooltipKey: 'Page',
    icon: 'Page',
    hotkey: 'P',
  },
  {
    name: 'Frame',
    action: 'drawFrame',
    type: 'frame',
    tooltipKey: 'Frame',
    icon: 'Frame',
    hotkey: 'F',
  },
  {
    name: 'Rectangle',
    action: 'drawRectangle',
    type: 'rectangle',
    tooltipKey: 'Rectangle',
    icon: 'Rectangle',
    hotkey: 'R',
  },
  {
    name: 'Ellipse',
    action: 'drawEllipse',
    type: 'ellipse',
    tooltipKey: 'Ellipse',
    icon: 'Ellipse',
    hotkey: 'E',
  },
  {
    name: 'Text',
    action: 'drawText',
    type: 'text',
    tooltipKey: 'Text',
    icon: 'Text',
    hotkey: 'T',
  },
  {
    name: 'Image',
    action: 'drawImage',
    type: 'image',
    tooltipKey: 'Image',
    icon: 'Image',
    hotkey: 'I',
  },
]
