import { MultiselectStyleMap, isContainerType } from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'
import { getPadding } from 'application/layout/utils'

type PaddingPanelKeys =
  | 'padding.mode'
  | 'padding.left'
  | 'padding.right'
  | 'padding.top'
  | 'padding.bottom'

type PaddingPanelAttributes = Pick<MultiselectStyleMap, PaddingPanelKeys> | null

export type PaddingSide = 'left' | 'right' | 'top' | 'bottom'

export interface PaddingPanelState {
  attributes: PaddingPanelAttributes
  left: number | 'Mixed'
  right: number | 'Mixed'
  top: number | 'Mixed'
  bottom: number | 'Mixed'
  activationMode: 'add' | 'remove' | 'none'
}

export interface PaddingPanelHandlers {
  activate: () => void
  deactivate: () => void
  setPadding: (value: number, side: PaddingSide, split: boolean) => void
  collapsePadding: () => void
  slidePadding: (value: number, side: PaddingSide, split: boolean) => void
}

export class PaddingPanel extends StyleAttributePanel<
  PaddingPanelState,
  PaddingPanelHandlers,
  PaddingPanelKeys
> {
  getSettings(): PaddingPanelState {
    return {
      attributes: this.attributes,
      left: this.getSideValue('left'),
      right: this.getSideValue('right'),
      top: this.getSideValue('top'),
      bottom: this.getSideValue('bottom'),
      activationMode: this.getActivationMode(),
    }
  }

  getHandlers(): PaddingPanelHandlers {
    return {
      activate: this.activate,
      deactivate: this.deactivate,
      setPadding: this.setPadding,
      collapsePadding: this.collapsePadding,
      slidePadding: this.slidePadding,
    }
  }

  private getActivationMode = (): 'add' | 'remove' | 'none' => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'none'

    return nodes.filter(this.nodeHasPadding).length === 0 ? 'add' : 'remove'
  }

  private getSideValue = (side: PaddingSide): number | 'Mixed' => {
    const nodes = this.getNodes().filter(this.nodeHasPadding)
    if (nodes.length === 0) return 0

    let value = nodes[0].getStyleAttribute(`padding.${side}`) || 0
    for (const node of nodes) {
      const nodeValue = node.getStyleAttribute(`padding.${side}`) || 0
      if (nodeValue !== value) return 'Mixed'
      value = nodeValue
    }

    return value
  }

  private activate = (): void => {
    this.setMulti({
      'padding.mode': 'active',
      'padding.left': 0,
      'padding.right': 0,
      'padding.top': 0,
      'padding.bottom': 0,
    })
    this.commit()
  }

  private deactivate = (): void => {
    this.setMulti({ 'padding.mode': 'none' })
    this.commit()
  }

  private setPadding = (
    value: number,
    mode: PaddingSide,
    split: boolean
  ): void => {
    const nodes = this.getNodes().filter(this.nodeHasPadding)

    for (const node of nodes) {
      switch (mode) {
        case 'left':
          if (split) {
            this.setOne(node.getId(), { 'padding.left': value })
          } else {
            this.setOne(node.getId(), {
              'padding.left': value,
              'padding.right': value,
            })
          }
          break
        case 'top':
          if (split) {
            this.setOne(node.getId(), { 'padding.top': value })
          } else {
            this.setOne(node.getId(), {
              'padding.top': value,
              'padding.bottom': value,
            })
          }
          break
        case 'right':
          this.setOne(node.getId(), { 'padding.right': value })
          break
        case 'bottom':
          this.setOne(node.getId(), { 'padding.bottom': value })
      }
    }
  }

  private collapsePadding = (): void => {
    const nodes = this.getNodes().filter(this.nodeHasPadding)

    for (const node of nodes) {
      const left = getPadding(node, 'left')
      const right = getPadding(node, 'right')
      const top = getPadding(node, 'top')
      const bottom = getPadding(node, 'bottom')
      const maxH = Math.max(left, right)
      const maxV = Math.max(top, bottom)

      this.setOne(node.getId(), {
        'padding.left': maxH,
        'padding.right': maxH,
        'padding.top': maxV,
        'padding.bottom': maxV,
      })
    }

    this.commit()
  }

  private slidePadding = (
    value: number,
    mode: 'left' | 'right' | 'top' | 'bottom',
    split: boolean
  ): void => {
    const nodes = this.getNodes().filter(this.nodeHasPadding)

    if (split) {
      for (const node of nodes) {
        this.slideOne(node.getId(), `padding.${mode}`, value)
      }
    } else {
      for (const node of nodes) {
        switch (mode) {
          case 'top':
            this.slideOne(node.getId(), 'padding.top', value)
            this.slideOne(node.getId(), 'padding.bottom', value)
            break
          case 'left':
            this.slideOne(node.getId(), 'padding.left', value)
            this.slideOne(node.getId(), 'padding.right', value)
            break
        }
      }
    }
  }

  private nodeHasPadding = (node: ReadOnlyNode): boolean => {
    return node.getStyleAttribute('padding.mode') === 'active'
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => isContainerType(node.getBaseAttribute('type'))
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 1_000
  }
}
