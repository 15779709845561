export type warningKey = 'Sticky' | 'FontMissing' | 'AspectRatio'

type warningMap = {
  [key in warningKey]: {
    text: string
  }
}

export const WarningMap: warningMap = {
  Sticky: {
    text: `Sticky only works if clip content is turned off on all layers above this`,
  },
  FontMissing: {
    text: `Font Missing or Renamed`,
  },
  AspectRatio: {
    text: 'Aspect ratio requires either width or height to be auto',
  },
}
