import LayerIcon from './LayerIcon'
import LayerNameInput from './LayerNameInput'
import LayerLockBlock from './LayerLockBlock'
import { AttributeType } from 'application/attributes'
import LayerInteractionBlock from './LayerInteractionBlock'

interface LayerRowContentProps {
  depth: number

  layerKey: string

  name: string
  setName: (name: string) => void

  editing: boolean
  setEditing: (editing: boolean) => void

  type: AttributeType

  canOpen: boolean
  open: boolean
  toggleOpen: () => void

  parentLocked: boolean
  locked: boolean
  setLocked: (locked: boolean) => void

  hidden: boolean
  parentHidden: boolean

  selected: boolean
  parentSelected: boolean

  panelHovered: boolean
  hovered: boolean

  interactions: boolean
  openInteractions: () => void
}

export default function LayerRowContent({
  depth,
  name,
  setName,
  editing,
  setEditing,
  type,
  canOpen,
  open,
  toggleOpen,
  parentLocked,
  locked,
  setLocked,
  parentHidden,
  hidden,
  selected,
  parentSelected,
  panelHovered,
  interactions,
  openInteractions,
}: LayerRowContentProps) {
  return (
    <>
      <LayerIcon
        type={type}
        hidden={hidden || parentHidden}
        depth={depth}
        selected={selected}
        parentSelected={parentSelected}
        arrow={panelHovered && canOpen}
        open={open}
        toggleOpen={toggleOpen}
      />
      <LayerNameInput
        type={type}
        name={name}
        setName={setName}
        editing={editing}
        setEditing={setEditing}
        hidden={hidden || parentHidden}
      />
      <LayerInteractionBlock
        interaction={interactions}
        openInteractions={openInteractions}
        hidden={hidden || parentHidden}
      />
      <LayerLockBlock
        parentLocked={parentLocked}
        locked={locked}
        setLocked={setLocked}
        hidden={hidden || parentHidden}
      />
    </>
  )
}
