import { DesignColor } from 'themes'
import { useEffect, useRef, useState } from 'react'
import { tooltipKey } from 'assets/tooltips'
import Tooltip from '../../Tooltip/Tooltip'

interface ToolbarTextInputProps {
  width?: 68 | 152

  value: string
  setValue: (value: string) => Promise<void>

  centered?: boolean
  editable?: boolean
  disabled?: boolean
  doubleClick?: boolean

  tooltipKey?: tooltipKey
}

export default function ToolbarTextInput({
  width = 152,
  value,
  setValue,
  disabled = false,
  editable = true,
  centered = false,
  doubleClick = false,
  tooltipKey,
}: ToolbarTextInputProps) {
  const [editing, setEditing] = useState(false)
  const [hover, setHover] = useState(false)

  const [localValue, setLocalValue] = useState(value)
  const [select, setSelect] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const textColor = getTextColor(editing, hover, disabled)
  const background = getBackground(editing, hover)
  const boxShadow = getBoxShadow(editing)

  const handleBlur = async () => {
    if (!editable) return
    await setValue(localValue)
    setEditing(false)
  }

  const handleFocus = () => {
    if (!editable) return
    setLocalValue(value)
    setEditing(true)
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!editing) e.preventDefault()
  }

  const handleMouseUp = (e: React.MouseEvent) => {
    if (editing || !editable) return
    if (!e.detail || (e.detail <= 1 && doubleClick)) return
    if (!select && inputRef.current) {
      e.preventDefault()
      inputRef.current.select()
      setSelect(true)
      setEditing(true)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation()
    if (e.key === 'Enter') inputRef.current?.blur()
  }

  useEffect(() => {
    if (editing) return
    setLocalValue(value)
    setSelect(false)
  }, [editing, value])

  useEffect(() => {
    if (disabled) setEditing(false)
  }, [disabled])

  return (
    <TooltipWrapper tooltipKey={tooltipKey}>
      <input
        ref={inputRef}
        type="text"
        value={editing ? localValue : value}
        onChange={(e) => setLocalValue(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onKeyDown={handleKeyDown}
        onDoubleClick={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        disabled={disabled || !editable}
        style={{
          width: width,
          height: '48px',
          padding: '0px 10px',
          margin: 0,
          boxSizing: 'border-box',
          border: 'none',
          outline: 'none',
          fontFamily: 'Inter',
          fontWeight: 'regular',
          fontSize: 11,
          color: textColor,
          cursor: 'default',
          textAlign: centered ? 'center' : 'left',
          opacity: disabled ? 0.4 : 1,
          userSelect: 'none',
          background,
          boxShadow,
        }}
      />
    </TooltipWrapper>
  )
}

function TooltipWrapper({
  tooltipKey,
  children,
}: {
  tooltipKey?: tooltipKey
  children: React.ReactNode
}) {
  if (!tooltipKey) return <>{children}</>
  return <Tooltip tooltipKey={tooltipKey}>{children}</Tooltip>
}

function getTextColor(editing: boolean, hover: boolean, disabled: boolean) {
  if (disabled) return DesignColor('text2')
  if (editing) return DesignColor('text1')
  if (hover) return DesignColor('inputHighlight')
  return DesignColor('text1')
}

function getBackground(editing: boolean, hover: boolean) {
  if (editing || hover) return DesignColor('inputTint')
  return 'transparent'
}

function getBoxShadow(editing: boolean) {
  if (editing) return `inset 0px -1px 0px 0px ${DesignColor('inputHighlight')}`
  return 'none'
}
