import { LayoutDependencyGraphFactory, LayoutEngineCalculator } from '../types'
import { IntrinsicSizeInitializer } from './initialize/intrinsic'
import { AspectRatioInitializer } from './initialize/ratio'
import { getNonDependentNodes, removeNode } from '../dependency/utils'
import { ContentSizeInitializer } from './initialize/content'
import { LayoutCalculator } from './layout/layout'
import { NodeSizeStateMap } from './node/map'
import { WriteDocument } from 'application/document'
import { MinMaxSizeInitializer } from './initialize/minMax'
import { FixedSizeInitializer } from './initialize/fixed'
import { DirtyInitializer } from './initialize/dirty'

export class SizeLayoutEngine implements LayoutEngineCalculator {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap
  private bottomUpFactory: LayoutDependencyGraphFactory
  private intrinsicSizeInitializer: IntrinsicSizeInitializer
  private aspectRatioInitializer: AspectRatioInitializer
  private contentSizeInitializer: ContentSizeInitializer
  private minMaxSizeInitializer: MinMaxSizeInitializer
  private fixedSizeInitializer: FixedSizeInitializer
  private dirtyInitializer: DirtyInitializer
  private layoutCalculator: LayoutCalculator

  constructor(
    document: WriteDocument,
    sizeMap: NodeSizeStateMap,
    bottomUpFactory: LayoutDependencyGraphFactory,
    intrinsicSizeInitializer: IntrinsicSizeInitializer,
    aspectRatioInitializer: AspectRatioInitializer,
    contentSizeInitializer: ContentSizeInitializer,
    minMaxSizeInitializer: MinMaxSizeInitializer,
    fixedSizeInitializer: FixedSizeInitializer,
    dirtyInitializer: DirtyInitializer,
    layoutCalculator: LayoutCalculator
  ) {
    this.document = document
    this.sizeMap = sizeMap
    this.bottomUpFactory = bottomUpFactory
    this.intrinsicSizeInitializer = intrinsicSizeInitializer
    this.aspectRatioInitializer = aspectRatioInitializer
    this.contentSizeInitializer = contentSizeInitializer
    this.minMaxSizeInitializer = minMaxSizeInitializer
    this.fixedSizeInitializer = fixedSizeInitializer
    this.dirtyInitializer = dirtyInitializer
    this.layoutCalculator = layoutCalculator
  }

  layout(nodes: Set<string>): void {
    this.sizeMap.clear()
    this.addInitialValues(nodes)
    const updated = this.runLayout(nodes)
    if (updated) this.setComputedValues()
  }

  private addInitialValues = (ids: Set<string>): void => {
    const bottomUpGraph = this.bottomUpFactory.create(ids)
    let bottomUpTerminalNodes = getNonDependentNodes(bottomUpGraph)
    while (bottomUpTerminalNodes.length > 0) {
      for (const terminalNode of bottomUpTerminalNodes) {
        this.contentSizeInitializer.initialize(terminalNode.id)
        this.minMaxSizeInitializer.initialize(terminalNode.id)
        this.intrinsicSizeInitializer.initialize(terminalNode.id)
        this.fixedSizeInitializer.initialize(terminalNode.id)
        this.aspectRatioInitializer.initialize(terminalNode.id)
        this.dirtyInitializer.initialize(terminalNode.id)
        removeNode(terminalNode, bottomUpGraph)
      }
      bottomUpTerminalNodes = getNonDependentNodes(bottomUpGraph)
    }
  }

  private runLayout = (ids: Set<string>): boolean => {
    const canvasChildren: string[] = []
    for (const id of ids) {
      const canvasParent = this.getCanvasParent(id)
      if (canvasParent && !canvasChildren.includes(canvasParent)) {
        canvasChildren.push(canvasParent)
      }
    }

    for (const id of canvasChildren) {
      this.layoutCalculator.calculate(id)
    }

    return canvasChildren.length > 0
  }

  private setComputedValues = (): void => {
    const all = this.sizeMap.getAll()
    for (const size of all) {
      const node = this.document.getNode(size.getId())
      if (!node) continue

      const w = size.getW()
      const h = size.getH()

      const currentW = node.getBaseAttribute('w')
      const currentH = node.getBaseAttribute('h')

      if (w !== undefined && w !== currentW) node.setBaseAttribute('w', w)
      if (h !== undefined && h !== currentH) node.setBaseAttribute('h', h)
    }
  }

  private getCanvasParent = (id: string): string | undefined => {
    const node = this.document.getNode(id)
    if (!node || node.getBaseAttribute('type') === 'root') return undefined

    const parent = this.document.getParent(node)
    if (!parent || parent.getBaseAttribute('type') === 'root') return undefined
    if (parent.getBaseAttribute('type') === 'canvas') return node.getId()

    const grandParent = this.document.getParent(parent)
    if (!grandParent) return parent.getId()
    if (grandParent.getBaseAttribute('type') === 'canvas') return parent.getId()

    return this.getCanvasParent(parent.getId())
  }
}
