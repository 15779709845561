import { DesignColor } from 'themes'
import { useState } from 'react'

export interface OnboardingRedirectBlockProps {
  text: string
  buttonLabel: string
  onClick: () => void
}

export default function OnboardingRedirectBlock({
  text,
  buttonLabel,
  onClick,
}: OnboardingRedirectBlockProps) {
  const [hover, setHover] = useState(false)
  const textColor = hover ? DesignColor('inputHighlight') : DesignColor('text1')
  const borderColor = hover
    ? DesignColor('inputHighlight')
    : DesignColor('panelBorder')

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <p
        style={{
          fontSize: 14,
          color: DesignColor('text2'),
          fontFamily: 'inter',
        }}
      >
        {text}
      </p>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '12px 16px',
          boxSizing: 'border-box',
          boxShadow: `inset 0px 0px 0px 1px ${borderColor}`,
          cursor: 'pointer',
          borderRadius: 8,
        }}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <p
          style={{
            fontSize: 14,
            color: textColor,
            fontFamily: 'Inter',
            cursor: 'pointer',
          }}
        >
          {buttonLabel}
        </p>
      </div>
    </div>
  )
}
