import { PostReparentRule } from '../types'
import { ReadOnlyDocument } from 'application/document'
import { Command } from 'application/client'
import { getLayoutMode } from 'application/layout/utils'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class RelativeInPageRule implements PostReparentRule {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument

  constructor(commandHandler: CommandHandler, document: ReadOnlyDocument) {
    this.commandHandler = commandHandler
    this.document = document
  }

  postReparent = (ids: string[], parentId: string | null): void => {
    if (!parentId) return

    const parent = this.document.getNode(parentId)
    if (!parent || parent.getBaseAttribute('type') !== 'page') return

    const parentLayout = getLayoutMode(parent)

    const commands: Command[] = []
    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue

      switch (parentLayout) {
        case 'block':
        case 'flex':
          commands.push({
            type: 'setNodeAttribute',
            params: {
              id: node.getId(),
              base: {},
              style: {
                'position.mode': 'auto',
                'position.top.auto': 'auto',
                'position.left.auto': 'auto',
                'position.right.auto': 'auto',
                'position.bottom.auto': 'auto',
                'size.w.auto': 'fill',
              },
            },
          })
          break
      }
    }

    this.commandHandler.handle(commands)
  }
}
