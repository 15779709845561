import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import { panelShadow } from '../Containers/constants'
import { DesignColor } from 'themes'
import { useRef } from 'react'
import useClosePopup from 'hooks/ui/useClosePopup'

interface MenuFullscreenPopupContainerProps {
  open: boolean
  children: React.ReactNode

  setOpen: (open: boolean) => void
}

export default function MenuFullscreenPopupContainer({
  open,
  children,
  setOpen,
}: MenuFullscreenPopupContainerProps) {
  const popupRef = useRef(null)

  useClosePopup({
    ref: popupRef,
    close: () => setOpen(false),
  })

  if (!open) return <></>

  return (
    <Portal>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
        }}
      />
      <div
        ref={popupRef}
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          padding: '24px',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
          backgroundColor: DesignColor('panelBackground'),
          boxShadow: [
            panelShadow,
            `inset 0 0 0 1px ${DesignColor('panelBorder')}`,
          ].join(', '),
          borderRadius: 8,
        }}
      >
        {children}
      </div>
    </Portal>
  )
}
