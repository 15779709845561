import {
  AttributeSizeRatioMode,
  MultiselectStyleMap,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'
import { warningKey } from 'assets/warnings'

type SizeRatioPanelKeys = 'size.ratio' | 'size.ratio.mode'

type SizeRatioPanelAttributes = Pick<
  MultiselectStyleMap,
  SizeRatioPanelKeys
> | null

export type PanelAspectRatioType = 'Mixed' | 'intrinsic' | 'none'

export interface SizeRatioPanelState {
  attributes: SizeRatioPanelAttributes
  ratioType: PanelAspectRatioType
  ratioWarning: warningKey | undefined
}

export interface SizePanelHandlers {
  setRatio: (value: number) => void
  setRatioMode: (value: AttributeSizeRatioMode) => void
}

export class SizeRatioPanel extends StyleAttributePanel<
  SizeRatioPanelState,
  SizePanelHandlers,
  SizeRatioPanelKeys
> {
  getSettings(): SizeRatioPanelState {
    return {
      attributes: this.attributes,
      ratioType: this.getRatioType(),
      ratioWarning: this.getRatioWarning(),
    }
  }

  getHandlers(): SizePanelHandlers {
    return {
      setRatio: this.setRatio,
      setRatioMode: this.setRatioMode,
    }
  }

  private getRatioType(): PanelAspectRatioType {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'Mixed'

    const first = this.getNodeRatioType(nodes[0])
    for (let i = 0; i < nodes.length; i++) {
      if (this.getNodeRatioType(nodes[i]) !== first) {
        return 'Mixed'
      }
    }

    return first
  }

  private getRatioWarning(): warningKey | undefined {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return undefined

    for (const [node, parent] of pairs) {
      if (!parent) continue
      const hasWarning = this.getNodeRatioWarning(node)
      if (hasWarning) return 'AspectRatio'
    }
  }

  private setRatio = (value: number): void => {
    this.setMulti({ 'size.ratio': value })
  }

  private setRatioMode = (value: AttributeSizeRatioMode): void => {
    this.setMulti({ 'size.ratio.mode': value })
  }

  private getNodeRatioType(node: ReadOnlyNode): PanelAspectRatioType {
    switch (node.getBaseAttribute('type')) {
      case 'image':
        return 'intrinsic'
      default:
        return 'none'
    }
  }

  private getNodeRatioWarning(node: ReadOnlyNode): boolean {
    const type = this.getNodeRatioType(node)
    if (type === 'Mixed' || type === 'intrinsic') return false

    const wIsAuto = node.getStyleAttribute('size.w.auto') === 'auto'
    const hIsAuto = node.getStyleAttribute('size.h.auto') === 'auto'
    const auto = wIsAuto || hIsAuto

    const ratioMode = node.getStyleAttribute('size.ratio.mode')
    switch (type) {
      case 'none':
        if (ratioMode === 'fixed' || ratioMode === 'custom') {
          return !auto
        }
        return false
    }
  }
}
