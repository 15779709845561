import ColorRow from './Rows/ColorRow'
import { useRef, useState } from 'react'
import FillPopup from '../FillPopup/FillPopup'
import MultistepGradientRow from './Rows/MultistepGradientRow'
import {
  AttributeFill,
  AttributeFillType,
  AttributeGradientType,
} from 'application/attributes'
import ImageRow from './Rows/ImageRow'

interface FillInputRowProps {
  fill: AttributeFill
  updateFill: (fill: AttributeFill) => void

  startUpdate?: () => void
  endUpdate?: () => void

  modes: AttributeFillType[]
  gradientOptions?: AttributeGradientType[]

  hasOverride?: boolean
}

export default function FillInputRow(props: FillInputRowProps) {
  const [open, setOpen] = useState(false)
  const displayRef = useRef<HTMLDivElement>(null)

  const handleSetOpen = (open: boolean) => {
    setOpen(open)
    if (open) {
      props.startUpdate?.()
    } else {
      props.endUpdate?.()
    }
  }

  return (
    <>
      {getDisplay(props, open, handleSetOpen, displayRef)}
      {open && (
        <FillPopup
          setOpen={handleSetOpen}
          exceptionRef={displayRef}
          {...props}
        />
      )}
    </>
  )
}

function getDisplay(
  props: FillInputRowProps,
  open: boolean,
  setOpen: (open: boolean) => void,
  displayRef: React.RefObject<HTMLDivElement>
) {
  const { fill, updateFill, hasOverride } = props
  switch (fill.type) {
    case 'color':
      return (
        <ColorRow
          displayRef={displayRef}
          fill={fill}
          updateFill={updateFill}
          open={open}
          setOpen={setOpen}
          hasOverride={hasOverride}
        />
      )
    case 'gradient':
      return (
        <MultistepGradientRow
          displayRef={displayRef}
          fill={fill}
          open={open}
          setOpen={setOpen}
          hasOverride={hasOverride}
        />
      )
    case 'image':
      return (
        <ImageRow
          fill={fill}
          updateFill={updateFill}
          open={open}
          setOpen={setOpen}
          displayRef={displayRef}
          hasOverride={hasOverride}
        />
      )
  }
}
