import { DesignColor } from 'themes'
import { panelShadow } from 'components/Library/Containers/constants'
import Icon from 'components/Library/Components/Icon/Icon/Icon'
import Text from 'components/Library/Text/Text'

export default function ReconnectingGraphic() {
  return (
    <div
      style={{
        position: 'relative',
        width: 'fit-content',
        height: 'fit-content',
        padding: 32,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        borderRadius: 8,
        background: DesignColor('panelBackground'),
        border: `1px solid ${DesignColor('panelBorder')}`,
        boxShadow: panelShadow,
      }}
    >
      <Icon icon={'RepaintLogo'} size={24} color={DesignColor('text1')} />
      <Text styles={{ size: 12 }} color={DesignColor('text1')}>
        Reconnecting
      </Text>
    </div>
  )
}
