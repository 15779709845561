import { NodeUpdateListener, ReadOnlyNode } from 'application/node'
import { TextSize, TextSizeCalculator } from './types'
import { ShapedText, Shaper } from 'application/text'
import { BaseMap, getTextAttributes } from 'application/attributes'
import { attributesToTextEditorState } from 'application/textEditor'
import { ReadOnlyDocument } from 'application/document'

export class TextSizeCalculatorImpl
  implements TextSizeCalculator, NodeUpdateListener
{
  private document: ReadOnlyDocument
  private shaper: Shaper
  private intrinsicCache: { [key: string]: TextSize }
  private cache: { [key: string]: { [key: number]: TextSize } }

  constructor(document: ReadOnlyDocument, shaper: Shaper) {
    this.document = document
    this.shaper = shaper
    this.intrinsicCache = {}
    this.cache = {}
  }

  onBaseAttribute = <K extends keyof BaseMap>(id: string, key: K): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const type = node.getBaseAttribute('type')
    if (type !== 'text') return

    switch (key) {
      case 'w':
      case 'h':
      case 'text.content':
        this.clearNode(id)
        break
    }
  }

  onStyleAttribute = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const type = node.getBaseAttribute('type')
    if (type !== 'text') return

    this.clearNode(id)
  }

  onActiveBreakpoint = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const type = node.getBaseAttribute('type')
    if (type !== 'text') return

    this.clearNode(id)
  }

  calculateIntrinsic = (node: ReadOnlyNode): TextSize => {
    if (this.intrinsicCache[node.getId()]) {
      return this.intrinsicCache[node.getId()]
    }

    const textAttributes = getTextAttributes(
      node.getBaseAttributes(),
      node.getStyleAttributes()
    )
    if (!textAttributes) return { w: 0, h: 0, minW: 0 }

    const state = attributesToTextEditorState(
      node.getBaseAttributes(),
      node.getStyleAttributes()
    )
    if (!state) return { w: 0, h: 0, minW: 0 }

    const shapedText = this.shaper.getShapedText(state.content)
    if (!shapedText) return { w: 0, h: 0, minW: 0 }

    const size = this.getTextSize(shapedText)
    this.intrinsicCache[node.getId()] = size
    return size
  }

  calculateHeight = (node: ReadOnlyNode, width: number): number => {
    if (this.cache[node.getId()] && this.cache[node.getId()][width]) {
      return this.cache[node.getId()][width].h
    }

    const textAttributes = getTextAttributes(
      node.getBaseAttributes(),
      node.getStyleAttributes()
    )
    if (!textAttributes) return 0

    const state = attributesToTextEditorState(
      node.getBaseAttributes(),
      node.getStyleAttributes()
    )
    if (!state) return 0

    const shapedText = this.shaper.getShapedText(state.content, width)
    if (!shapedText) return 0

    const size = this.getTextSize(shapedText)
    if (!this.cache[node.getId()]) this.cache[node.getId()] = {}
    this.cache[node.getId()][width] = size

    return size.h
  }

  private clearNode = (id: string): void => {
    delete this.intrinsicCache[id]
    delete this.cache[id]
  }

  private getTextSize = (shapedText: ShapedText): TextSize => {
    let maxWord = 0
    let start = true
    let xStart = 0
    let xCurrent = 0
    for (const row of shapedText.rows) {
      for (const c of row.characters) {
        if (start) {
          xStart = c.x
          start = false
        }
        if (c.space || c.newline) {
          maxWord = Math.max(maxWord, xCurrent - xStart)
          xStart = 0
          xCurrent = 0
          start = true
        } else {
          xCurrent = c.x + c.w
        }
      }
    }

    return {
      w: shapedText.w,
      h: shapedText.h,
      minW: Math.max(maxWord, xCurrent - xStart),
    }
  }
}
