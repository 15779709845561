import { Command } from 'application/client'
import { ProportionMap } from './types'
import { SelectionSide } from 'application/selection/types'
import { Delta, Rectangle } from 'application/shapes'
import { BaseMap, StyleMap } from 'application/attributes'
import { truncate } from 'application/math'
import { ReadOnlyDocument } from 'application/document'
import {
  getModeForWidth,
  isOverrideContainer,
} from 'application/breakpoint/utils'
import {
  getAdjustedBottom,
  getAdjustedLeft,
  getAdjustedRight,
  getAdjustedTop,
} from 'application/units'
import { getLayoutDirection, getLayoutMode } from 'application/layout/utils'

interface CommandHandler {
  handle: (command: Command[]) => void
}

type ResizedRectangle = {
  x?: number
  y?: number
  w?: number
  h?: number
}

type ResizedRectangleMap = { [key: string]: ResizedRectangle }

export class NodeResizeAction {
  private document: ReadOnlyDocument
  private commandHandler: CommandHandler

  constructor(document: ReadOnlyDocument, commandHandler: CommandHandler) {
    this.document = document
    this.commandHandler = commandHandler
  }

  resize = (
    nodeIds: string[],
    proportions: ProportionMap,
    window: Rectangle,
    delta: Delta,
    side: SelectionSide
  ): void => {
    const updatedWindow = this.computeDelta(window, delta, side)
    const sizeMap = this.computeResizedRectangles(
      nodeIds,
      proportions,
      updatedWindow,
      side
    )
    const resizeCommands = this.buildResizeCommands(nodeIds, sizeMap)
    const breakpointModeCommands = this.buildBreakpointModeCommands(
      nodeIds,
      sizeMap
    )
    this.commandHandler.handle([...breakpointModeCommands, ...resizeCommands])
  }

  private computeResizedRectangles = (
    ids: string[],
    proportions: ProportionMap,
    window: Rectangle,
    side: SelectionSide
  ): ResizedRectangleMap => {
    const deltas: ResizedRectangleMap = {}

    for (const id of ids) {
      const proportion = proportions[id]
      if (!proportion) continue

      const rectangle: ResizedRectangle = {}

      if (side.includes('left') || side.includes('right')) {
        rectangle.x = truncate(window.x + window.w * proportion.x, 0)
        rectangle.w = truncate(window.w * proportion.w, 0)
      }

      if (side.includes('top') || side.includes('bottom')) {
        rectangle.y = truncate(window.y + window.h * proportion.y, 0)
        rectangle.h = truncate(window.h * proportion.h, 0)
      }

      deltas[id] = rectangle
    }

    return deltas
  }

  private computeDelta = (
    window: Rectangle,
    delta: Delta,
    side: SelectionSide
  ): Rectangle => {
    let x = 0
    let y = 0
    let w = 0
    let h = 0

    let dx = delta.dx
    let dy = delta.dy

    if (side.includes('left') && window.w - dx < 1) {
      dx = window.w - 1
    } else if (side.includes('right') && window.w + dx < 1) {
      dx = -window.w + 1
    }

    if (side.includes('top') && window.h - dy < 1) {
      dy = window.h - 1
    } else if (side.includes('bottom') && window.h + dy < 1) {
      dy = -window.h + 1
    }

    if (side.includes('left')) {
      x = dx
      w = -dx
    } else if (side.includes('right')) {
      w = dx
    }

    if (side.includes('top')) {
      y = dy
      h = -dy
    } else if (side.includes('bottom')) {
      h = dy
    }

    x += window.x
    y += window.y
    w += window.w
    h += window.h

    return { x, y, w, h }
  }

  private buildResizeCommands = (
    ids: string[],
    rectangleMap: ResizedRectangleMap
  ): Command[] => {
    const commands: Command[] = []

    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue

      const parent = this.document.getParent(node)
      if (!parent) continue

      const rectangle = rectangleMap[id]
      if (!rectangle) continue

      const styleUpdate: Partial<StyleMap> = {}
      const baseUpdate: Partial<BaseMap> = {}
      if (rectangle.x !== undefined) baseUpdate['x'] = rectangle.x
      if (rectangle.y !== undefined) baseUpdate['y'] = rectangle.y
      if (rectangle.w !== undefined) {
        baseUpdate['w'] = rectangle.w
        styleUpdate['size.w.auto'] = 'fixed'
        styleUpdate['size.w'] = rectangle.w
      }
      if (rectangle.h !== undefined) {
        styleUpdate['size.h.auto'] = 'fixed'
        styleUpdate['size.h'] = rectangle.h
        baseUpdate['h'] = rectangle.h
      }

      const parentLayout = getLayoutMode(parent)
      if (parentLayout === 'flex') {
        const direction = getLayoutDirection(parent)
        switch (direction) {
          case 'row':
          case 'wrap':
            if (rectangle.w !== undefined) {
              styleUpdate['flex.grow'] = 0
              styleUpdate['flex.shrink'] = 0
            }
            if (rectangle.h !== undefined) {
              styleUpdate['flex.alignSelf'] = 'auto'
            }
            break
          case 'column':
            if (rectangle.h !== undefined) {
              styleUpdate['flex.grow'] = 0
              styleUpdate['flex.shrink'] = 0
            }
            if (rectangle.w !== undefined) {
              styleUpdate['flex.alignSelf'] = 'auto'
            }
            break
        }
      }

      const x = rectangle.x || node.getBaseAttribute('x')
      const y = rectangle.y || node.getBaseAttribute('y')
      const w = rectangle.w || node.getBaseAttribute('w')
      const h = rectangle.h || node.getBaseAttribute('h')
      if (rectangle.x) {
        const adjustedLeft = getAdjustedLeft(node, this.document, x)
        for (const key in adjustedLeft) {
          const typedKey = key as keyof StyleMap
          this.addStyleToMap(styleUpdate, typedKey, adjustedLeft[typedKey])
        }
      }
      if (rectangle.y) {
        const adjustedTop = getAdjustedTop(node, this.document, y)
        for (const key in adjustedTop) {
          const typedKey = key as keyof StyleMap
          this.addStyleToMap(styleUpdate, typedKey, adjustedTop[typedKey])
        }
      }
      if (rectangle.w) {
        const adjustedRight = getAdjustedRight(node, this.document, x + w)
        for (const key in adjustedRight) {
          const typedKey = key as keyof StyleMap
          this.addStyleToMap(styleUpdate, typedKey, adjustedRight[typedKey])
        }
      }
      if (rectangle.h) {
        const adjustedBottom = getAdjustedBottom(node, this.document, y + h)
        for (const key in adjustedBottom) {
          const typedKey = key as keyof StyleMap
          this.addStyleToMap(styleUpdate, typedKey, adjustedBottom[typedKey])
        }
      }

      commands.push({
        type: 'setNodeAttribute',
        params: { id: id, base: baseUpdate, style: styleUpdate },
      })
    }

    return commands
  }

  private buildBreakpointModeCommands = (
    ids: string[],
    rectangleMap: ResizedRectangleMap
  ): Command[] => {
    const commands: Command[] = []
    for (const id of ids) {
      const rectangle = rectangleMap[id]
      if (!rectangle || !rectangle.w) continue

      const node = this.document.getNode(id)
      if (!node) continue

      if (!isOverrideContainer(node, this.document)) continue
      if (node.getBaseAttribute('type') !== 'page') continue

      const breakpointMode = getModeForWidth(rectangle.w)
      commands.push({
        type: 'setNodeSelector',
        params: {
          id: node.getId(),
          breakpoint: breakpointMode,
        },
      })

      const descendants = this.document.getDescendants(node)
      for (const descendant of descendants) {
        commands.push({
          type: 'setNodeSelector',
          params: {
            id: descendant.getId(),
            breakpoint: breakpointMode,
          },
        })
      }
    }

    return commands
  }

  private addStyleToMap<K extends keyof StyleMap>(
    map: Partial<StyleMap>,
    key: K,
    value: StyleMap[K]
  ): void {
    map[key] = value
  }
}
