import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { AlignmentGap } from '../types'
import { getRectangle, isDisplayNone } from 'application/attributes'
import {
  Point,
  Rectangle,
  intersects,
  pointInRectangle,
} from 'application/shapes'

interface ViewableRectangle {
  getViewableRectangle: () => Rectangle
}

export class AlignmentGapsCalculator {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private viewableRectangle: ViewableRectangle

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    viewableRectangle: ViewableRectangle
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.viewableRectangle = viewableRectangle
  }

  create = (): AlignmentGap[] => {
    const unselectedChildren = this.getUnselectedChildren()

    return unselectedChildren
      .flatMap((children) => this.computePairwiseGaps(children))
      .filter((gap) => gap.size >= 1)
      .filter(this.filterOutOfView)
  }

  private computePairwiseGaps = (nodes: ReadOnlyNode[]): AlignmentGap[] => {
    const gaps: AlignmentGap[] = []
    const rectangles = nodes.map((n) => getRectangle(n))
    const rectangleSets = this.getUniqueSets(rectangles)

    rectangleSets.forEach((pair) => {
      gaps.push(...this.computeVerticalGaps(pair))
      gaps.push(...this.computeHorizontalGaps(pair))
    })

    return gaps
  }

  private getUniqueSets = (
    rectangles: Rectangle[]
  ): [Rectangle, Rectangle][] => {
    const sets: [Rectangle, Rectangle][] = []
    for (let i = 0; i < rectangles.length; i++) {
      for (let j = i + 1; j < rectangles.length; j++) {
        sets.push([rectangles[i], rectangles[j]])
      }
    }
    return sets
  }

  private getUnselectedChildren = (): ReadOnlyNode[][] => {
    const parentIds: Set<string> = new Set()
    this.documentSelection.getSelected().forEach((node) => {
      const parent = this.document.getParent(node)
      if (!parent) return
      parentIds.add(parent.getId())
    })

    return Array.from(parentIds).map((id) => {
      const node = this.document.getNode(id)
      if (!node) return []

      const children = node.getChildren()
      if (!children) return []

      return children
        .map((key) => this.document.getNode(key))
        .filter(
          (n) =>
            n &&
            !this.documentSelection.isSelected(n.getId()) &&
            !isDisplayNone(n)
        ) as ReadOnlyNode[]
    })
  }

  private computeVerticalGaps = (
    pair: [Rectangle, Rectangle]
  ): AlignmentGap[] => {
    const gaps: AlignmentGap[] = []

    const sorted = pair.sort((a, b) => a.y - b.y)
    const r1 = sorted[0]
    const r2 = sorted[1]

    if (intersects(r1, r2)) return gaps
    if (r1.x + r1.w <= r2.x || r1.x >= r2.x + r2.w) return gaps

    const start = Math.max(r1.x, r2.x)
    const end = Math.min(r1.x + r1.w, r2.x + r2.w)
    if (start >= end) return gaps

    const gapSize = Math.abs(r1.y + r1.h - r2.y)

    gaps.push({
      side: 'top',
      position: r1.y - gapSize,
      bounds: [start, end],
      lines: this.computeDisplayLines(gapSize, sorted, 'top'),
      size: gapSize,
    })

    gaps.push({
      side: 'centerH',
      position: r1.y + r1.h + gapSize / 2,
      bounds: [start, end],
      lines: this.computeDisplayLines(gapSize, sorted, 'centerH'),
      size: gapSize,
    })

    gaps.push({
      side: 'bottom',
      position: r2.y + r2.h + gapSize,
      bounds: [start, end],
      lines: this.computeDisplayLines(gapSize, sorted, 'bottom'),
      size: gapSize,
    })

    return gaps
  }

  private computeHorizontalGaps = (
    pair: [Rectangle, Rectangle]
  ): AlignmentGap[] => {
    const gaps: AlignmentGap[] = []

    const sorted = pair.sort((a, b) => a.x - b.x)
    const r1 = sorted[0]
    const r2 = sorted[1]

    if (intersects(r1, r2)) return gaps
    if (r1.y + r1.h <= r2.y || r1.y >= r2.y + r2.h) return gaps

    const start = Math.max(r1.y, r2.y)
    const end = Math.min(r1.y + r1.h, r2.y + r2.h)
    if (start >= end) return gaps

    const gapSize = Math.abs(r1.x + r1.w - r2.x)

    gaps.push({
      side: 'left',
      position: r1.x - gapSize,
      bounds: [start, end],
      lines: this.computeDisplayLines(gapSize, sorted, 'left'),
      size: gapSize,
    })

    gaps.push({
      side: 'centerV',
      position: r1.x + r1.w + gapSize / 2,
      bounds: [start, end],
      lines: this.computeDisplayLines(gapSize, sorted, 'centerV'),
      size: gapSize,
    })

    gaps.push({
      side: 'right',
      position: r2.x + r2.w + gapSize,
      bounds: [start, end],
      lines: this.computeDisplayLines(gapSize, sorted, 'right'),
      size: gapSize,
    })

    return gaps
  }

  private computeDisplayLines = (
    distance: number,
    pair: [Rectangle, Rectangle],
    position: 'top' | 'bottom' | 'left' | 'right' | 'centerV' | 'centerH'
  ): [Point, Point][] => {
    const [r1, r2] = pair

    const maxTop = Math.max(r1.y, r2.y)
    const minBottom = Math.min(r1.y + r1.h, r2.y + r2.h)
    const maxLeft = Math.max(r1.x, r2.x)
    const minRight = Math.min(r1.x + r1.w, r2.x + r2.w)

    const midY = maxTop + (minBottom - maxTop) / 2
    const midX = maxLeft + (minRight - maxLeft) / 2

    switch (position) {
      case 'top':
        return [
          [
            { x: midX, y: r1.y - distance },
            { x: midX, y: r1.y },
          ],
          [
            { x: midX, y: r1.y + r1.h },
            { x: midX, y: r2.y },
          ],
        ]
      case 'centerH':
        return [
          [
            { x: midX, y: r1.y + r1.h },
            { x: midX, y: r2.y },
          ],
        ]
      case 'bottom':
        return [
          [
            { x: midX, y: r2.y + r2.h },
            { x: midX, y: r2.y + r2.h + distance },
          ],
          [
            { x: midX, y: r2.y },
            { x: midX, y: r1.y + r1.h },
          ],
        ]
      case 'left':
        return [
          [
            { x: r1.x - distance, y: midY },
            { x: r1.x, y: midY },
          ],
          [
            { x: r1.x + r1.w, y: midY },
            { x: r2.x, y: midY },
          ],
        ]
      case 'centerV':
        return [
          [
            { x: r1.x + r1.w, y: midY },
            { x: r2.x, y: midY },
          ],
        ]
      case 'right':
        return [
          [
            { x: r2.x + r2.w, y: midY },
            { x: r2.x + r2.w + distance, y: midY },
          ],
          [
            { x: r2.x, y: midY },
            { x: r1.x + r1.w, y: midY },
          ],
        ]
    }
  }

  private filterOutOfView = (gap: AlignmentGap): boolean => {
    const viewableRectangle = this.viewableRectangle.getViewableRectangle()
    return gap.lines.every((line) => {
      const [p1, p2] = line
      return (
        pointInRectangle(p1, viewableRectangle) &&
        pointInRectangle(p2, viewableRectangle)
      )
    })
  }
}
