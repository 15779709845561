import { WriteDocument } from 'application/document'
import { TextSizeCalculator } from '../types'
import { NodeSizeStateMap } from '../node/map'
import { NodeSizeState } from '../node/node'
import { ReadOnlyNode } from 'application/node'

export class IntrinsicSizeInitializer {
  private document: WriteDocument
  private textSize: TextSizeCalculator
  private sizeMap: NodeSizeStateMap

  constructor(
    document: WriteDocument,
    textSize: TextSizeCalculator,
    sizeMap: NodeSizeStateMap
  ) {
    this.document = document
    this.textSize = textSize
    this.sizeMap = sizeMap
  }

  initialize = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size) return

    switch (node.getBaseAttribute('type')) {
      case 'text':
        this.computeTextIntrinsicSize(id, size)
        break
      case 'image':
        this.computeImageIntrinsicSize(id)
        break
    }
  }

  private computeTextIntrinsicSize = (
    id: string,
    size: NodeSizeState
  ): void => {
    const node = this.document.getNode(id)
    if (!node || node.getBaseAttribute('type') !== 'text') return

    const textSize = this.textSize.calculateIntrinsic(node)

    size.setMinContentW(textSize.minW)
    size.setMinContentH(textSize.h)
    size.setMaxContentW(textSize.w)
    size.setMaxContentH(textSize.h)
  }

  private computeImageIntrinsicSize = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node || node.getBaseAttribute('type') !== 'image') return

    const size = this.sizeMap.get(id)
    if (!size) return

    size.setIsReplaced(true)

    const w = node.getStyleAttribute('image.originalSize.w')
    const h = node.getStyleAttribute('image.originalSize.h')
    if (w === undefined || h === undefined) return

    const fixedW = this.computeFixedValue(node, 'w')
    const fixedH = this.computeFixedValue(node, 'h')
    const aspectRatio = this.getRatio(w, h, node)

    if (fixedW !== undefined && fixedH !== undefined) {
      this.setMinMaxContent(fixedW, fixedH, size)
    } else if (fixedW !== undefined && fixedH === undefined) {
      this.setMinMaxContent(fixedW, fixedW / aspectRatio, size)
    } else if (fixedW === undefined && fixedH !== undefined) {
      this.setMinMaxContent(fixedH * aspectRatio, fixedH, size)
    } else {
      this.setMinMaxContent(w, h, size)
    }
  }

  private setMinMaxContent = (
    w: number,
    h: number,
    size: NodeSizeState
  ): void => {
    size.setWInitial(w)
    size.setHInitial(h)
    size.setMinContentW(0)
    size.setMinContentH(h)
    size.setMaxContentW(w)
    size.setMaxContentH(h)
  }

  private getRatio(
    originalW: number,
    originalH: number,
    node: ReadOnlyNode
  ): number {
    const mode = node.getStyleAttribute('size.ratio.mode')
    switch (mode) {
      case 'fixed':
      case 'custom':
        const ratio = node.getStyleAttribute('size.ratio')
        if (ratio) return ratio
        break
    }

    return originalW / originalH
  }

  private computeFixedValue = (
    node: ReadOnlyNode,
    axis: 'w' | 'h'
  ): number | undefined => {
    const auto = node.getStyleAttribute(`size.${axis}.auto`)
    switch (auto) {
      case 'fixed':
        const px = node.getStyleAttribute(`size.${axis}`)
        if (px === undefined) return undefined
        return px
    }
  }
}
