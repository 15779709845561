import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'
import { NodeSizeState } from '../node/node'
import {
  getLayoutDirection,
  getLayoutMode,
  isFlexFixed,
} from 'application/layout/utils'

export class AspectRatioInitializer {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap

  constructor(document: WriteDocument, sizeMap: NodeSizeStateMap) {
    this.document = document
    this.sizeMap = sizeMap
  }

  initialize = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size) return

    switch (node.getBaseAttribute('type')) {
      case 'image':
        this.initializeImageRatio(id)
        break
    }

    this.initializeAspectRatio(id, size)
    this.initializeDeterminesRatio(id, size)
  }

  private initializeImageRatio = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node || node.getBaseAttribute('type') !== 'image') return

    const size = this.sizeMap.get(id)
    if (!size) return

    const w = node.getStyleAttribute('image.originalSize.w')
    const h = node.getStyleAttribute('image.originalSize.h')
    if (!w || !h) return

    size.setRatio(w / h)
  }

  private initializeAspectRatio = (id: string, size: NodeSizeState): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const mode = node.getStyleAttribute('size.ratio.mode')
    if (mode !== 'custom' && mode !== 'fixed') return

    const aspectRatio = node.getStyleAttribute('size.ratio')
    if (!aspectRatio) return

    size.setRatio(aspectRatio)
  }

  private initializeDeterminesRatio = (
    id: string,
    size: NodeSizeState
  ): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const w = this.doesWidthDetermineRatio(id)
    size.setWDeterminesRatio(w)

    const h = this.doesHeightDetermineRatio(id)
    size.setHDeterminesRatio(h)
  }

  private doesWidthDetermineRatio = (id: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    const parent = this.document.getParent(node)
    if (!parent) return false
    if (parent.getBaseAttribute('type') === 'canvas') return true

    const size = this.sizeMap.get(id)
    if (!size) return false

    const wAuto = node.getStyleAttribute('size.w.auto')
    switch (wAuto) {
      case 'fixed':
      case 'percent':
      case 'fill':
        return true
      case 'auto':
        const parentDirection = getLayoutDirection(parent)
        const parentMode = getLayoutMode(parent)
        const flexFixed = isFlexFixed(node)
        switch (parentDirection) {
          case 'row':
          case 'wrap':
            return !flexFixed
          case 'column':
            return parentMode === 'block'
        }
    }
  }

  private doesHeightDetermineRatio = (id: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    const parent = this.document.getParent(node)
    if (!parent) return false
    if (parent.getBaseAttribute('type') === 'canvas') return true

    const size = this.sizeMap.get(id)
    if (!size) return false

    const hAuto = node.getStyleAttribute('size.h.auto')
    const parentHAuto = parent.getStyleAttribute('size.h.auto')
    switch (hAuto) {
      case 'fixed':
        return true
      case 'percent':
      case 'fill':
        return parentHAuto === 'fixed'
      case 'auto':
        const parentDirection = getLayoutDirection(parent)
        const flexFixed = isFlexFixed(node)
        switch (parentDirection) {
          case 'row':
          case 'wrap':
            return false
          case 'column':
            const parentHAuto = parent.getStyleAttribute('size.h.auto')
            if (parentHAuto !== 'fixed') return false
            return !flexFixed
        }
    }
  }
}
