import { DesignColor } from 'themes'
import { IconKey } from 'assets/iconLibrary'
import Text from 'components/Library/Text/Text'
import ButtonIcon from '../../Icon/ButtonIcon/ButtonIcon'

interface TextBlockProps {
  width?: 72 | 96 | 120 | 168 | 192 | 216 | 'fill' | 'hug'

  icon?: IconKey

  mode: 'label1' | 'label2' | 'title'
  justify?: 'start' | 'center' | 'end'

  children?: React.ReactNode
}

export default function TextBlock({
  width = 96,
  icon,
  mode,
  justify = 'start',
  children,
}: TextBlockProps) {
  const textColor = getTextColor(mode)
  const textWeight = getTextWeight(mode)

  return (
    <div
      style={{
        width: getWidth(width),
        height: 24,
        paddingLeft: icon ? 4 : 8,
        paddingRight: 8,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: justify,
        gap: 4,
        textOverflow: 'ellipsis',
        flexShrink: width === 'fill' ? 1 : 0,
      }}
    >
      {icon && <ButtonIcon icon={icon} color={DesignColor('text2')} />}
      <Text color={textColor} styles={{ weight: textWeight }}>
        {children}
      </Text>
    </div>
  )
}

function getTextColor(mode: 'label1' | 'label2' | 'title') {
  switch (mode) {
    case 'label1':
    case 'title':
      return DesignColor('text1')
    case 'label2':
      return DesignColor('text2')
  }
}

function getTextWeight(mode: 'label1' | 'label2' | 'title') {
  switch (mode) {
    case 'label1':
    case 'label2':
      return 'regular'
    case 'title':
      return 'bold'
  }
}

function getWidth(width: number | 'fill' | 'hug') {
  if (width === 'hug') return 'auto'
  if (width === 'fill') return '100%'
  return width
}
