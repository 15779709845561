import { SelectorPseudo } from 'application/attributes'
import { OverrideMode } from 'application/breakpoint/types'
import { IconKey } from 'assets/iconLibrary'
import { tooltipKey } from 'assets/tooltips'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import IconSetBlock from 'components/Library/Containers/IconSetBlock/IconSetBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function BreakpointModeSettings() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getBreakpointModePanel()
  )
  if (!settings) return <></>
  const {
    breakpoint,
    pseudo,
    options,
    breakpointHasOverrides,
    pseudoHasOverrides,
  } = settings
  const { setMode, setPseudo } = handlers

  return (
    <PanelRow>
      <IconSetBlock width={120}>
        {breakpointIcons.map(({ m, icon, tooltipKey }, i) => (
          <OverrideReset key={m} all={m === breakpoint && m !== 'default'}>
            <IconBlock
              key={i}
              icon={icon}
              tooltipKey={tooltipKey}
              selected={m === breakpoint}
              disabled={!options.includes(m)}
              onClick={() => {
                if (!options.includes(m)) return
                setMode(m)
              }}
              hasOverride={breakpointHasOverrides[m]}
              overrideTint={m !== 'default'}
            />
          </OverrideReset>
        ))}
      </IconSetBlock>
      <IconSetBlock width={120} align={'right'} paddingR={0}>
        {pseudoIcons.map(({ m, icon, tooltipKey }, i) => (
          <IconBlock
            key={i}
            icon={icon}
            tooltipKey={tooltipKey}
            selected={m === pseudo}
            onClick={() => setPseudo(m)}
            hasOverride={pseudoHasOverrides[m]}
            overrideTint={m !== 'none'}
          />
        ))}
      </IconSetBlock>
    </PanelRow>
  )
}

const breakpointIcons: {
  m: OverrideMode
  icon: IconKey
  tooltipKey?: tooltipKey
}[] = [
  { m: 'default', icon: 'Desktop', tooltipKey: 'BreakpointDefault' },
  { m: 'tablet', icon: 'Tablet', tooltipKey: 'BreakpointTablet' },
  { m: 'landscape', icon: 'MobileH', tooltipKey: 'BreakpointLandscape' },
  { m: 'mobile', icon: 'Mobile', tooltipKey: 'BreakpointMobile' },
]

const pseudoIcons: {
  m: SelectorPseudo
  icon: IconKey
  tooltipKey?: tooltipKey
}[] = [
  { m: 'none', icon: 'StateDefault', tooltipKey: 'StateDefault' },
  { m: 'hover', icon: 'StateHover', tooltipKey: 'StateHover' },
]
