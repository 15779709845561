import {
  AttributeAutolayoutDirection,
  isAutolayoutChild,
  isDisplayNone,
} from 'application/attributes'
import { ReadOnlyDocument } from 'application/document'
import { getGap } from 'application/layout/utils'
import { ReadOnlyNode } from 'application/node'

export function sumNodeSizes(nodes: ReadOnlyNode[], mode: 'w' | 'h'): number {
  let sum = 0
  for (const node of nodes) {
    const size = node.getBaseAttribute(mode)
    if (!size) continue
    sum += size
  }
  return sum
}

export function maxNodeSize(nodes: ReadOnlyNode[], mode: 'w' | 'h'): number {
  let max = 0
  for (const node of nodes) {
    const size = node.getBaseAttribute(mode)
    if (!size) continue
    max = Math.max(max, size)
  }
  return max
}

export function getNodes(
  ids: string[],
  document: ReadOnlyDocument
): ReadOnlyNode[] {
  return ids
    .map((id) => document.getNode(id))
    .filter((n) => n) as ReadOnlyNode[]
}

export function divideChildren(
  node: ReadOnlyNode,
  children: ReadOnlyNode[],
  direction: AttributeAutolayoutDirection,
  max: number
): ReadOnlyNode[][] {
  if (direction !== 'wrap') return [children]

  const filteredChildren = children.filter((child) => {
    return isAutolayoutChild(child, node) && !isDisplayNone(child)
  })

  const gap = getGap(node)

  const divided: ReadOnlyNode[][] = []
  let current: ReadOnlyNode[] = []

  let total = 0
  for (const child of filteredChildren) {
    const size = child.getBaseAttribute('w')
    if (!size) continue
    total += size
    if (total > max || max <= 0) {
      divided.push(current)
      current = []
      total = size + gap
    } else {
      total += gap
    }
    current.push(child)
  }
  if (current.length > 0) {
    divided.push(current)
  }
  if (divided.length > 0 && divided[0].length === 0) {
    divided.shift()
  }
  return divided
}
