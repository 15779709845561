import { ReadOnlyNode } from 'application/node'
import { AttributeAutolayoutAlign } from 'application/attributes'

export function getLayoutMode(node: ReadOnlyNode): 'flex' | 'block' | 'none' {
  const display = node.getStyleAttribute('autolayout.mode')
  if (display === 'flex') return 'flex'
  if (display === 'block') return 'block'
  return 'none'
}

export function getLayoutDirection(
  node: ReadOnlyNode
): 'row' | 'column' | 'wrap' {
  const direction = node.getStyleAttribute('autolayout.direction')
  if (direction === 'row') return 'row'
  if (direction === 'wrap') return 'wrap'
  return 'column'
}

export function getAlignItems(node: ReadOnlyNode): AttributeAutolayoutAlign {
  return node.getStyleAttribute('autolayout.align.main') || 'start'
}

export function getJustifyContent(
  node: ReadOnlyNode
): AttributeAutolayoutAlign {
  return node.getStyleAttribute('autolayout.align.counter') || 'start'
}

export function getGap(node: ReadOnlyNode): number {
  return node.getStyleAttribute('autolayout.gap') || 0
}

export function getPadding(
  node: ReadOnlyNode,
  side: 'left' | 'right' | 'top' | 'bottom'
): number {
  const paddingMode = node.getStyleAttribute('padding.mode')
  if (paddingMode !== 'active') return 0
  const padding = node.getStyleAttribute(`padding.${side}`)
  return padding || 0
}

export function getLayoutAxis(node: ReadOnlyNode): 'w' | 'h' {
  const direction = getLayoutDirection(node)
  switch (direction) {
    case 'row':
    case 'wrap':
      return 'w'
    case 'column':
      return 'h'
  }
}

export function getCrossAxis(axis: 'w' | 'h'): 'w' | 'h' {
  return axis === 'w' ? 'h' : 'w'
}

export function isLayoutWrap(node: ReadOnlyNode): boolean {
  return node.getStyleAttribute('autolayout.direction') === 'wrap'
}

export function isFlexFixed(node: ReadOnlyNode): boolean {
  return !isFlexGrow(node) && !isFlexShrink(node)
}

export function isFlexGrow(node: ReadOnlyNode): boolean {
  const grow = node.getStyleAttribute('flex.grow')
  if (grow && grow > 0) return true

  return false
}

export function isFlexShrink(node: ReadOnlyNode): boolean {
  const shrink = node.getStyleAttribute('flex.shrink')
  if (shrink && shrink > 0) return true

  return false
}
