import FullscreenDimContainer from 'components/Library/Containers/FullscreenDimContainer/FullscreenDimContainer'
import { Portal } from '../../PortalRoot/PortalRoot'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import { useEffect, useRef } from 'react'
import useClosePopup from 'hooks/ui/useClosePopup'
import PanelColumn from 'components/Library/Containers/PanelColumn/PanelColumn'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelRowButton from 'components/Library/Components/Button/PanelRowButton/PanelRowButton'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import GeneralSettings from './GeneralSettings/GeneralSettings'
import PageSettings from './PageSettings/PageSettings'
import DomainSettings from './DomainSettings/DomainSettings'
import PlanSettings from './PlanSettings/PlanSettings'
import { atom, useRecoilState } from 'recoil'
import FontSettings from './FontSettings/FontSettings'
import { WebsitePanelSettings } from 'editor/ui/websitePanel'

export const settingsTab = atom<string>({
  key: 'settingsTab',
  default: 'general',
})

interface SettingsPopupProps {
  close: () => void
}

export default function SettingsPopup({ close }: SettingsPopupProps) {
  const popupRef = useRef<HTMLDivElement>(null)
  useClosePopup({ ref: popupRef, close: close })

  const [tab, setTab] = useRecoilState(settingsTab)

  const { settings, handlers } = usePanelHelper(
    editor.getUI().getWebsitePanel()
  )
  const validTab = isValidTab(tab, settings)

  const { load, stop } = handlers

  useEffect(() => {
    load().then((result) => {
      if (!result) setTimeout(() => load(), 200)
    })

    return () => stop()
  }, [load, stop])

  useEffect(() => {
    if (!validTab) setTab('general')
  }, [validTab, setTab])

  if (!settings || !validTab) return <></>

  return (
    <Portal>
      <FullscreenDimContainer>
        <PanelContainer
          panelRef={popupRef}
          id={'website-settings'}
          position={{
            position: 'absolute',
            top: 48,
            bottom: 48,
          }}
          width={'696px'}
          height={'calc(100% - 96px)'}
          direction="row"
        >
          <PanelColumn>
            <PanelSection>
              <PanelRow>
                <TextBlock width={192} mode="title">
                  Website Settings
                </TextBlock>
              </PanelRow>
              <PanelRowButton
                text={'General'}
                selected={tab === 'general'}
                onClick={() => setTab('general')}
              />
              <PanelRowButton
                text={'Domain'}
                selected={tab === 'domain'}
                onClick={() => setTab('domain')}
              />
              <PanelRowButton
                text={'Plan'}
                selected={tab === 'plan'}
                onClick={() => setTab('plan')}
              />
              <PanelRowButton
                text={'Fonts'}
                selected={tab === 'fonts'}
                onClick={() => setTab('fonts')}
              />
            </PanelSection>
            <PanelSection>
              <PanelRow>
                <TextBlock width={192} mode="title">
                  Pages
                </TextBlock>
              </PanelRow>
              {settings.homepageOptions.map((option) => (
                <PanelRowButton
                  key={option.id}
                  text={option.name}
                  selected={tab === option.id}
                  onClick={() => setTab(option.id)}
                />
              ))}
            </PanelSection>
          </PanelColumn>
          <PanelColumn width={'fill'} right>
            {tab === 'general' && (
              <GeneralSettings
                domain={settings.domain}
                status={settings.status}
                homepageId={settings.homepageId}
                homepageOptions={settings.homepageOptions}
                setHomepage={handlers.setHomepage}
                faviconUrl={settings.faviconUrl}
                headContent={settings.headContent}
                setFavicon={handlers.setFavicon}
                socialImageUrl={settings.socialImageUrl}
                setSocialImage={handlers.setSocialImage}
                setHeadContent={handlers.setHeadContent}
                close={close}
              />
            )}
            {tab === 'domain' && (
              <DomainSettings
                defaultDomain={settings.defaultDomain}
                validated={settings.validated}
                premium={settings.premium}
                premiumDomain={settings.premiumDomain}
                setPremiumDomain={handlers.setPremiumDomain}
                ip1={settings.ip1}
                ip2={settings.ip2}
                fileserver={settings.fileserver}
                close={close}
              />
            )}
            {tab === 'plan' && (
              <PlanSettings
                premium={settings.premium}
                startCheckout={handlers.startCheckout}
                startManage={handlers.startManage}
                close={close}
              />
            )}
            {tab === 'fonts' && (
              <FontSettings
                fonts={settings.fonts}
                uploadFont={handlers.uploadFont}
                updateFontInfo={handlers.updateFontInfo}
                updateFontFile={handlers.updateFontFile}
                deleteFont={handlers.deleteFont}
                close={close}
              />
            )}
            {!['general', 'domain', 'plan', 'fonts'].includes(tab) && (
              <PageSettings
                pageId={tab}
                name={
                  settings.homepageOptions.find((o) => o.id === tab)?.name || ''
                }
                settings={settings.pageSettings[tab]}
                setPageSettings={handlers.setPageInfo}
                close={close}
              />
            )}
          </PanelColumn>
        </PanelContainer>
      </FullscreenDimContainer>
    </Portal>
  )
}

function isValidTab(tab: string, settings: WebsitePanelSettings | null) {
  if (!settings) return false
  if (!['general', 'domain', 'plan', 'fonts'].includes(tab)) {
    if (!settings.pageSettings[tab]) return false
  }
  return true
}
