import { isContainerType } from 'application/attributes'
import { ClientUpdateListener, Update } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { EditorProjectService } from 'editor/project/project'

const maxWidthOffset = 100
const maxHeightOffset = 132

export type PreviewPanelState = {
  open: boolean
  width: number
  height: number
  zoom: number
  fullscreen: boolean
  name: string
  link: string
}

export type PreviewPanelHandlers = {
  setOpen: (open: boolean) => void
  setWidth: (width: number, resetZoom?: boolean) => void
  setHeight: (height: number, resetZoom?: boolean) => void
  setSize: (width: number, height: number, resetZoom?: boolean) => void
  setZoom: (zoom: number) => void
  setFullscreen: (fullscreen: boolean) => void
}

type PreviewPanelListener = (state: PreviewPanelState) => void

type PreviewDimensions = {
  width: number
  height: number
  zoom: number
}

export class PreviewPanel implements ClientUpdateListener {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private projectService: EditorProjectService
  private urlPrefix: string

  private listeners: { [key: string]: PreviewPanelListener }
  private open: boolean = false
  private width: number
  private height: number
  private zoom: number
  private fullscreen: boolean
  private containerId: string | null
  private name: string
  private link: string

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    projectService: EditorProjectService,
    urlPrefix: string
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.projectService = projectService
    this.urlPrefix = urlPrefix

    this.listeners = {}
    this.width = window.innerWidth - 100
    this.height = window.innerHeight - 132
    this.zoom = 1
    this.fullscreen = false
    this.containerId = null
    this.name = ''
    this.link = ''
  }

  getSettings = (): PreviewPanelState => {
    return {
      open: this.open,
      width: this.width,
      height: this.height,
      zoom: this.zoom,
      fullscreen: this.fullscreen,
      name: this.name,
      link: this.link,
    }
  }

  getHandlers = (): PreviewPanelHandlers => {
    return {
      setOpen: this.setOpen,
      setWidth: this.setWidth,
      setHeight: this.setHeight,
      setSize: this.setSize,
      setZoom: this.setZoom,
      setFullscreen: this.setFullscreen,
    }
  }

  subscribe = (key: string, listener: PreviewPanelListener) => {
    this.listeners[key] = listener
  }

  unsubscribe = (key: string) => {
    delete this.listeners[key]
  }

  setOpen = (open: boolean) => {
    if (this.open === open) return
    if (open) {
      const containerId = this.getContainerId()
      const defaultContainerId = this.getDefaultContainerId()
      if (containerId) {
        this.containerId = containerId
      } else if (defaultContainerId) {
        this.containerId = defaultContainerId
      }
      this.updateDimensions()
      this.updateLink()
      this.updateName()
      this.open = open
      this.notifyListeners()
    } else {
      this.open = open
      this.notifyListeners()
    }
  }

  getTypes = (): Update['type'][] => {
    return ['selection']
  }

  onUpdate = (updates: Update[]): void => {
    let selection = false
    for (const update of updates) {
      if (update.type === 'selection') {
        selection = true
      }
    }
    if (!selection) return

    const containerId = this.getContainerId()
    if (containerId !== null) this.containerId = containerId
  }

  private setWidth = (width: number, resetZoom: boolean = false) => {
    this.width = width
    if (resetZoom) this.zoom = this.computeZoom(width, this.height)
    this.notifyListeners()
  }

  private setHeight = (height: number, resetZoom: boolean = false) => {
    this.height = height
    if (resetZoom) this.zoom = this.computeZoom(this.width, height)
    this.notifyListeners()
  }

  private setSize = (
    width: number,
    height: number,
    resetZoom: boolean = false
  ) => {
    this.width = width
    this.height = height
    if (resetZoom) this.zoom = this.computeZoom(width, height)
    this.notifyListeners()
  }

  private setZoom = (zoom: number) => {
    this.zoom = zoom
    this.notifyListeners()
  }

  private setFullscreen = (fullscreen: boolean) => {
    this.fullscreen = fullscreen
    if (!fullscreen) this.updateDimensions()
    this.notifyListeners()
  }

  private notifyListeners = () => {
    const state = this.getSettings()
    for (const key in this.listeners) {
      this.listeners[key](state)
    }
  }

  private getContainerId = (): string | null => {
    const selection = this.documentSelection.getSelected()
    if (selection.length === 0) return null

    const last = selection[selection.length - 1]
    if (this.validPreviewContainer(last)) return last.getId()

    const ancestors = this.document.getAncestors(last)
    for (const ancestor of ancestors) {
      if (this.validPreviewContainer(ancestor)) return ancestor.getId()
    }

    return null
  }

  private getDefaultContainerId = (): string | null => {
    if (!this.containerId) return this.getValidCanvasChildren()
    return null
  }

  private getValidCanvasChildren = (): string | null => {
    const canvasId = this.documentSelection.getSelectedCanvas()
    const canvas = this.document.getNode(canvasId)
    if (!canvas) return null

    const children = canvas.getChildren()
    if (!children) return null

    for (const childId of children) {
      const child = this.document.getNode(childId)
      if (!child) continue
      if (this.validPreviewContainer(child)) return child.getId()
    }

    return null
  }

  private updateLink = (): void => {
    if (!this.containerId) return

    const documentId = this.projectService.getDocumentId()
    if (!documentId) return

    const projectId = this.projectService.getProjectId()
    if (!projectId) return

    this.link =
      this.urlPrefix +
      `/api/preview?projectId=${projectId}&documentId=${documentId}&nodeId=${
        this.containerId
      }&timestamp=${Date.now()}`
  }

  private updateName = (): void => {
    if (!this.containerId) return

    const node = this.document.getNode(this.containerId)
    if (!node) return

    this.name = node.getBaseAttribute('name') || ''
  }

  private validPreviewContainer = (node: ReadOnlyNode): boolean => {
    const parent = this.document.getParent(node)
    if (!parent) return false

    return (
      parent.getBaseAttribute('type') === 'canvas' &&
      isContainerType(parent.getBaseAttribute('type'))
    )
  }

  private updateDimensions = () => {
    const { width, height, zoom } = this.computeDimensions()
    this.width = width
    this.height = height
    this.zoom = zoom
  }

  private computeDimensions = (): PreviewDimensions => {
    if (!this.containerId) return { width: 0, height: 0, zoom: 1 }

    const node = this.document.getNode(this.containerId)
    if (!node) return { width: 0, height: 0, zoom: 1 }

    const maxWidth = window.innerWidth - maxWidthOffset
    const maxHeight = window.innerHeight - maxHeightOffset

    let width = node.getBaseAttribute('w')
    let height = node.getBaseAttribute('h')
    let zoom = 1

    if (width < maxWidth && height < maxHeight) return { width, height, zoom }
    if (height > maxHeight) {
      if (width > 1201) {
        height = (width / 16) * 9
      } else if (width > 768) {
        height = (width / 3) * 4
      } else {
        height = (width / 9) * 16
      }
    }

    if (width < maxWidth && height < maxHeight) return { width, height, zoom }
    if (width > maxWidth || height > maxHeight) {
      zoom = this.computeZoom(width, height)
    }

    return {
      width: Math.round(width),
      height: Math.round(height),
      zoom: Math.min(zoom, 1),
    }
  }

  private computeZoom = (width: number, height: number): number => {
    const maxWidth = window.innerWidth - maxWidthOffset
    const maxHeight = window.innerHeight - maxHeightOffset
    return Math.min(maxWidth / width, maxHeight / height, 1)
  }
}
