import ToolbarIconButton from 'components/Library/Components/Toolbar/ToolbarIconButton/ToolbarIconButton'
import Tooltip from 'components/Library/Components/Tooltip/Tooltip'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function FullscreenButton() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getPreviewPanel()
  )
  if (!settings) return <></>

  const { fullscreen } = settings
  const { setFullscreen } = handlers

  return (
    <Tooltip tooltipKey="PreviewFullscreen" direction="left">
      <ToolbarIconButton
        icon={'Fullscreen'}
        onClick={() => setFullscreen(!fullscreen)}
      />
    </Tooltip>
  )
}
