import Icon from 'components/Library/Components/Icon/Icon/Icon'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import Text from 'components/Library/Text/Text'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'
import { DesignColor } from 'themes'

interface CanvasTitleProps {
  open: boolean
  setOpen: (open: boolean) => void
  addCanvas: () => void
}

export default function CanvasTitle({
  open,
  setOpen,
  addCanvas,
}: CanvasTitleProps) {
  const rowRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered<boolean>({ ref: rowRef })

  return (
    <PanelRow
      rowRef={rowRef}
      width={'fill'}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseUp={() => setOpen(!open)}
      cursor={'pointer'}
    >
      <PanelBlock width={192} paddingH={8} gap={2}>
        <Text
          styles={{ weight: 'bold' }}
          color={DesignColor(hovered || false || open ? 'text1' : 'text2')}
        >
          Canvas
        </Text>
        <Icon
          icon={open ? 'DropdownArrowUp' : 'DropdownArrowDown'}
          size={10}
          color={DesignColor(hovered || false ? 'text1' : 'text2')}
        />
      </PanelBlock>
      <IconBlock
        icon={'Plus'}
        onClick={() => {
          if (!open) setOpen(true)
          addCanvas()
        }}
      />
    </PanelRow>
  )
}
