import { WriteDocument } from 'application/document'
import { RuleValidator, Violation } from '../types'

export class SizeRulesValidator implements RuleValidator {
  private validators: ((
    nodeId: string,
    document: WriteDocument
  ) => Violation[])[]

  constructor() {
    this.validators = [this.checkDynamicSizeOnCanvas]
  }

  validate(nodeId: string, document: WriteDocument): Violation[] {
    const violations: Violation[] = []

    for (const validator of this.validators) {
      const nodeViolations = validator(nodeId, document)
      violations.push(...nodeViolations)
    }

    return violations
  }

  private checkDynamicSizeOnCanvas = (
    nodeId: string,
    document: WriteDocument
  ): Violation[] => {
    const node = document.getNode(nodeId)
    if (node === undefined) return []

    const parent = document.getParent(node)
    if (parent === undefined) return []
    if (parent.getBaseAttribute('type') !== 'canvas') return []

    const type = node.getBaseAttribute('type')
    const wAuto = node.getStyleAttribute('size.w.auto')
    const hAuto = node.getStyleAttribute('size.h.auto')

    if (
      wAuto === 'percent' ||
      wAuto === 'fill' ||
      (wAuto === 'auto' && !['image', 'text'].includes(type)) ||
      hAuto === 'percent' ||
      hAuto === 'fill'
    ) {
      return [
        {
          type: 'dynamic_size_on_canvas',
          metadata: {
            nodeId: node.getId(),
          },
        },
      ]
    }

    return []
  }
}
