import { StyleMap, isAutolayoutChild } from 'application/attributes'
import { Command, SetNodeAttribute } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { Delta } from 'application/shapes'
import {
  getAdjustedBottom,
  getAdjustedLeft,
  getAdjustedRight,
  getAdjustedTop,
} from 'application/units'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class PositionDeltaAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.selection = selection
  }

  move = (
    direction: 'up' | 'down' | 'left' | 'right',
    scaled: boolean
  ): void => {
    switch (direction) {
      case 'up':
        this.moveByDelta({ dx: 0, dy: scaled ? -10 : -1 })
        break
      case 'down':
        this.moveByDelta({ dx: 0, dy: scaled ? 10 : 1 })
        break
      case 'left':
        this.moveByDelta({ dx: scaled ? -10 : -1, dy: 0 })
        break
      case 'right':
        this.moveByDelta({ dx: scaled ? 10 : 1, dy: 0 })
        break
    }
  }

  private moveByDelta = (delta: Delta): void => {
    const selected = this.getSelectedNonAutolayoutChildren()

    const commands = this.buildMoveCommand(selected, delta)
    this.commandHandler.handle(commands)
    this.commandHandler.handle([{ type: 'commit' }])
  }

  private getSelectedNonAutolayoutChildren = (): ReadOnlyNode[] => {
    return this.selection.getSelected().filter((n) => {
      const parent = this.document.getParent(n)
      if (!parent) return true
      return !isAutolayoutChild(n, parent)
    })
  }

  private buildMoveCommand = (
    nodes: ReadOnlyNode[],
    delta: Delta
  ): SetNodeAttribute[] => {
    const commands: SetNodeAttribute[] = []
    for (const node of nodes) {
      const parent = this.document.getParent(node)
      if (!parent) continue

      const x = node.getBaseAttribute('x')
      const y = node.getBaseAttribute('y')
      const w = node.getBaseAttribute('w')
      const h = node.getBaseAttribute('h')

      const update: Partial<StyleMap> = {
        ...getAdjustedTop(node, this.document, y + delta.dy),
        ...getAdjustedLeft(node, this.document, x + delta.dx),
        ...getAdjustedRight(node, this.document, x + w + delta.dx),
        ...getAdjustedBottom(node, this.document, y + h + delta.dy),
      }

      commands.push({
        type: 'setNodeAttribute',
        params: {
          id: node.getId(),
          base: {},
          style: update,
        },
      })
    }

    return commands
  }
}
