import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'

export class FixedSizeInitializer {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap

  constructor(document: WriteDocument, sizeMap: NodeSizeStateMap) {
    this.document = document
    this.sizeMap = sizeMap
  }

  initialize = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size) return

    const wAuto = node.getStyleAttribute('size.w.auto')
    switch (wAuto) {
      case 'fixed':
        const w = node.getStyleAttribute('size.w')
        if (w === undefined) break
        size.setMaxContentW(w)
        size.setW(w)
        break
    }

    const hAuto = node.getStyleAttribute('size.h.auto')
    switch (hAuto) {
      case 'fixed':
        const h = node.getStyleAttribute('size.h')
        if (h === undefined) break
        size.setMaxContentH(h)
        size.setH(h)
        break
    }
  }
}
