import { WriteDocument } from 'application/document'
import { LayoutEngineCalculator } from '../types'
import { getPositionNodeMap } from './utils'
import { BasePositionCalculator } from './layout/layout'
import { OffsetPositionCalculator } from './offset/offset'

export class PositionLayoutEngine implements LayoutEngineCalculator {
  private document: WriteDocument
  private base: BasePositionCalculator
  private offset: OffsetPositionCalculator

  constructor(
    document: WriteDocument,
    base: BasePositionCalculator,
    offset: OffsetPositionCalculator
  ) {
    this.document = document
    this.base = base
    this.offset = offset
  }

  layout(nodes: Set<string>): void {
    const positionNodeMap = getPositionNodeMap()

    this.base.calculate(nodes, positionNodeMap)
    this.offset.calculate(nodes, positionNodeMap)

    for (const positionNode of Object.values(positionNodeMap)) {
      const node = this.document.getNode(positionNode.id)
      if (!node) continue

      const parent = this.document.getParent(node)
      if (!parent) continue

      const x = positionNode.x
      const y = positionNode.y

      const currentX = node.getBaseAttribute('x')
      const currentY = node.getBaseAttribute('y')

      if (x !== currentX) node.setBaseAttribute('x', x)
      if (y !== currentY) node.setBaseAttribute('y', y)
    }
  }
}
