import { DesignColor } from 'themes'
import LoadingGraphic from './LoadingGraphic/LoadingGraphic'

interface SplashScreenProps {
  fading: boolean
}

export default function SplashScreen({ fading }: SplashScreenProps) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        pointerEvents: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        backgroundColor: DesignColor('canvasBackground'),
        backgroundImage: `linear-gradient(0deg, ${DesignColor(
          'gridlines'
        )} 1px, transparent 1px), linear-gradient(90deg, ${DesignColor(
          'gridlines'
        )} 1px, transparent 1px)`,
        backgroundSize: '100px 100px',
        backgroundPosition: '50px 50px',
        animation: fading ? 'fadeOut 0.5s ease' : 'none',
        zIndex: 5,
      }}
    >
      <LoadingGraphic />
    </div>
  )
}
