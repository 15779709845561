import { useEffect, useState } from 'react'
import { DesignColor } from 'themes'

interface TimelineLineProps {
  panelRef: React.RefObject<HTMLDivElement>
  startRef: React.RefObject<HTMLDivElement>
  endRef: React.RefObject<HTMLDivElement>
}

export default function TimelineLine({
  panelRef,
  startRef,
  endRef,
}: TimelineLineProps) {
  const [top, setTop] = useState(0)
  const [bottom, setBottom] = useState(0)
  const [left, setLeft] = useState(0)

  useEffect(() => {
    const panelRect = panelRef.current?.getBoundingClientRect()
    const startRect = startRef.current?.getBoundingClientRect()
    const endRect = endRef.current?.getBoundingClientRect()

    if (panelRect && startRect && endRect) {
      setTop(computeTop(panelRef, startRef))
      setBottom(computeBottom(panelRef, endRef))
      setLeft(computeLeft(panelRef, startRef))
    }
  }, [panelRef, startRef, endRef])

  return (
    <div
      style={{
        position: 'absolute',
        width: 1,
        height: bottom - top,
        top: top,
        left: left,
        background: DesignColor('panelBorder'),
      }}
    />
  )
}

function computeTop(
  panel: React.RefObject<HTMLDivElement>,
  start: React.RefObject<HTMLDivElement>
) {
  const panelRect = panel.current?.getBoundingClientRect()
  const startRect = start.current?.getBoundingClientRect()
  return (startRect?.top || 0) - (panelRect?.top || 0) + 10
}

function computeBottom(
  panel: React.RefObject<HTMLDivElement>,
  end: React.RefObject<HTMLDivElement>
) {
  const panelRect = panel.current?.getBoundingClientRect()
  const endRect = end.current?.getBoundingClientRect()
  return (endRect?.top || 0) - (panelRect?.top || 0) + 10
}

function computeLeft(
  panel: React.RefObject<HTMLDivElement>,
  start: React.RefObject<HTMLDivElement>
) {
  const panelRect = panel.current?.getBoundingClientRect()
  const startRect = start.current?.getBoundingClientRect()
  return (startRect?.left || 0) - (panelRect?.left || 0) + 37.5
}
