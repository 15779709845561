import { editor } from 'index'

export default function useSetProject() {
  const { setProject } = editor.getUI().getProjectService()

  return (projectId: string) => {
    setProject(projectId)

    let lastViewedProjects = localStorage.getItem('lastViewedProjects')
    if (!lastViewedProjects) {
      lastViewedProjects = '{}'
    }

    const lastViewedProjectsMap = JSON.parse(lastViewedProjects)
    lastViewedProjectsMap[projectId] = new Date().getTime()

    localStorage.setItem(
      'lastViewedProjects',
      JSON.stringify(lastViewedProjectsMap)
    )
  }
}
