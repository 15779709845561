import React, { useRef, useState } from 'react'
import { DesignColor } from 'themes'
import Icon from 'components/Library/Components/Icon/Icon/Icon'
import { IconKey } from 'assets/iconLibrary'
import { panelShadow } from '../Containers/constants'

interface MenuTextInputProps {
  value: string
  iconKey?: IconKey
  placeholder?: string
  autofocus?: boolean

  onChange: (value: string) => void
  onSubmit?: () => void
}

export default function MenuTextInput({
  value,
  iconKey,
  placeholder = 'Enter text',
  autofocus = false,
  onChange,
  onSubmit,
}: MenuTextInputProps) {
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const borderColor =
    hover || focus ? DesignColor('inputHighlight') : DesignColor('panelBorder')

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onSubmit) {
      onSubmit()
    }
  }

  return (
    <div
      style={{
        height: '32px',
        padding: '8px 10px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        background: DesignColor('panelBackground'),
        border: `1px solid ${borderColor}`,
        boxShadow: panelShadow,
        borderRadius: 8,
        cursor: 'text',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onClick={() => inputRef.current?.focus()}
    >
      {iconKey && (
        <Icon icon={iconKey} size={16} color={DesignColor('text2')} />
      )}
      <input
        ref={inputRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        style={{
          width: '100%',
          height: '100%',
          padding: 0,
          margin: 0,
          background: 'transparent',
          border: 'none',
          outline: 'none',
          color: DesignColor('text1'),
          fontSize: 12,
          fontFamily: 'inter',
        }}
        placeholder={placeholder}
        autoFocus={autofocus}
      />
    </div>
  )
}
