import { PasteSnapshot } from '../../types'
import { PasteTargetHandler } from '../paste'
import { WriteDocument } from 'application/document'

export class PasteOnOriginal implements PasteTargetHandler {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  paste = (snapshot: PasteSnapshot): void => {
    const addedIndexes: number[] = []
    for (const id of snapshot.ids) {
      this.addChild(id, snapshot, addedIndexes)
    }
  }

  private addChild = (
    id: string,
    snapshot: PasteSnapshot,
    addedIndexes: number[]
  ): void => {
    const node = snapshot.nodes[id]
    if (!node) return

    const parentId = node.getParent()
    if (!parentId) return

    const parent = this.document.getNode(parentId)
    if (!parent) return

    let index = snapshot.indexes[id]

    const autolayout = parent.getStyleAttribute('autolayout.direction')
    if (autolayout) index += 1

    const count = addedIndexes.filter((i) => i < index).length

    parent.addChild(id, index + count)
    addedIndexes.push(index)
  }
}
