import { Node, NodeMap, ReadOnlyNode } from 'application/node'
import {
  DocumentUpdateListener,
  ReadOnlyDocument,
  WriteDocument,
} from './types'

export class Document implements WriteDocument {
  private nodeMap: NodeMap
  private root: Node | undefined
  private listener: DocumentUpdateListener

  constructor(nodeMap: NodeMap) {
    this.nodeMap = nodeMap
    this.root = this.nodeMap['root']
    this.listener = {}
  }

  setListener(listener: DocumentUpdateListener): void {
    this.listener = listener
  }

  setNodeMap = (nodeMap: NodeMap): void => {
    this.nodeMap = nodeMap
    this.root = this.nodeMap['root']
  }

  getRoot(): Node | undefined {
    return this.root
  }

  getNode(id: string): Node | undefined {
    return this.nodeMap[id]
  }

  getNodes(): Node[] {
    return Object.values(this.nodeMap)
  }

  getParent(node: ReadOnlyNode): Node | undefined {
    const parent = node.getParent()
    if (!parent) return
    return this.nodeMap[parent]
  }

  getDescendants(node: ReadOnlyNode): Node[] {
    const children = node.getChildren()
    if (!children) return []

    const descendants: Node[] = []
    for (const child of children) {
      const childNode = this.nodeMap[child]
      if (!childNode) continue
      descendants.push(childNode)
      descendants.push(...this.getDescendants(childNode))
    }

    return descendants
  }

  getAncestors(node: ReadOnlyNode): Node[] {
    const parent = this.getParent(node)
    if (!parent) return []

    return [parent, ...this.getAncestors(parent)]
  }

  isDescendantOf(node: ReadOnlyNode, ancestor: ReadOnlyNode): boolean {
    if (node === ancestor) return true

    const parent = this.getParent(node)
    if (!parent) return false

    return this.isDescendantOf(parent, ancestor)
  }

  isAncestorOf(node: ReadOnlyNode, descendant: ReadOnlyNode): boolean {
    return this.isDescendantOf(descendant, node)
  }

  addNode(node: Node): void {
    if (this.nodeMap[node.getId()]) {
      throw new Error(`Node with id ${node.getId()} already exists`)
    }

    if (this.root === undefined && node.getId() === 'root') {
      this.root = node
    }

    this.nodeMap[node.getId()] = node

    if (this.listener.onNodeAdded) this.listener.onNodeAdded(node)
  }

  deleteNode(id: string): void {
    if (!this.nodeMap[id]) {
      throw new Error(`Node with id ${id} does not exist`)
    }

    if (this.root && this.root.getId() === id) {
      this.root = undefined
    }

    const node = this.nodeMap[id]
    delete this.nodeMap[id]

    if (this.listener.onNodeDeleted) this.listener.onNodeDeleted(node)
  }

  clone = (): Document => {
    const nodeMap: NodeMap = {}
    for (const id in this.nodeMap) {
      nodeMap[id] = this.nodeMap[id].clone(id)
    }

    return new Document(nodeMap)
  }

  cloneReadOnly = (): ReadOnlyDocument => {
    return this.clone()
  }
}
