import { DesignColor } from 'themes'
import { IconKey } from 'assets/iconLibrary'
import { useRef, useState } from 'react'
import DraggingIcon from './DraggingIcon/DraggingIcon'
import NumberInput from './NumberInput/NumberInput'
import useEditInput from 'hooks/editor/useEditInput'
import { tooltipKey } from 'assets/tooltips'
import Tooltip from '../Tooltip/Tooltip'
import useHovered from 'hooks/ui/useHovered'
import { dropdownOption } from '../Dropdown/Dropdown'
import DropdownArrow from './Dropdown/DropdownArrow'
import Dropdown from './Dropdown/Dropdown'
import useAction from 'hooks/editor/useAction'

interface DropdownNumberBlockProps<T> {
  width?: 72 | 96

  icon: IconKey
  iconRotation?: number
  tooltipKey?: tooltipKey

  value: 'Mixed' | number | undefined
  setValue: (value: number) => void
  increment?: (value: number) => void
  decrement?: (value: number) => void
  setEmpty?: () => void

  dropdownOptions: dropdownOption<T>[]
  selectOption: (value: T) => void
  selectedOption?: T

  unit?: string
  step?: number
  min?: number
  max?: number
  decimals?: number

  dim?: boolean
  disabled?: boolean
  empty?: boolean
  hasOverride?: boolean

  commit?: boolean
}

export default function DropdownNumberBlock<T>({
  width = 96,
  icon,
  iconRotation = 0,
  tooltipKey,
  value,
  setValue,
  increment,
  decrement,
  setEmpty,
  dropdownOptions,
  selectOption,
  selectedOption,
  unit,
  step = 1,
  min = 0,
  max = 50_000,
  decimals = 1,
  dim = false,
  empty = false,
  disabled = false,
  hasOverride = false,
  commit = true,
}: DropdownNumberBlockProps<T>) {
  const blockRef = useRef<HTMLDivElement>(null)
  const [editing, setEditing] = useEditInput(commit)

  const action = useAction()
  const { hovered, setHovered } = useHovered<boolean>({ ref: blockRef })
  const [dragging, setDragging] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const active =
    ((hovered && action === null) || editing || dragging) &&
    !dropdownOpen &&
    !disabled

  const handleOpenDropdown = () => {
    setEditing(true)
    setHovered(false)
    setDropdownOpen(true)
  }

  const handleSetNumberInputValue = (value: number) => {
    setValue(value)
    setEditing(false)
  }

  const handleSetEmpty = () => {
    if (setEmpty) {
      setEmpty()
      setEditing(false)
    }
  }

  const handleSelectDropdown = (value: T) => {
    setHovered(false)
    setDropdownOpen(false)
    selectOption(value)
    setEditing(false)
  }

  const handleSetDragging = (dragging: boolean) => {
    setEditing(dragging)
    setDragging(dragging)
  }

  const handleSetEditing = (editing: boolean) => {
    setEditing(editing)
  }

  const handleCloseDropdown = () => {
    setDropdownOpen(false)
    setEditing(false)
  }

  return (
    <TooltipWrapper tooltipKey={tooltipKey}>
      <Block
        blockRef={blockRef}
        width={width}
        active={active}
        disabled={dim}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <DraggingIcon
          icon={icon}
          iconRotation={iconRotation}
          editing={editing}
          dragging={dragging}
          setDragging={handleSetDragging}
          increment={increment || ((v: number) => {})}
          decrement={decrement || ((v: number) => {})}
          step={step}
          disabled={disabled}
          hasOverride={hasOverride}
        />
        <NumberInput
          dragging={dragging}
          editing={editing}
          setEditing={handleSetEditing}
          width={getNumberInputWidth(width, dropdownOptions.length > 0)}
          value={value}
          setValue={handleSetNumberInputValue}
          increment={increment || ((v: number) => {})}
          decrement={decrement || ((v: number) => {})}
          setEmpty={handleSetEmpty}
          unit={unit}
          step={step}
          min={min}
          max={max}
          decimals={decimals}
          disabled={disabled}
          empty={empty}
          hasOverride={hasOverride}
        />
        {dropdownOptions.length > 0 && !disabled && (
          <>
            <DropdownArrow
              visible={((hovered || false) && !action) || editing}
              onClick={handleOpenDropdown}
              hasOverride={hasOverride}
            />
            {dropdownOpen && (
              <Dropdown
                close={handleCloseDropdown}
                select={handleSelectDropdown}
                selected={selectedOption}
                options={dropdownOptions}
                blockRef={blockRef}
              />
            )}
          </>
        )}
      </Block>
    </TooltipWrapper>
  )
}

function TooltipWrapper({
  tooltipKey,
  children,
}: {
  tooltipKey?: tooltipKey
  children: React.ReactNode
}) {
  if (tooltipKey) {
    return (
      <Tooltip tooltipKey={tooltipKey} disabled={false}>
        {children}
      </Tooltip>
    )
  }
  return <>{children}</>
}

function Block({
  blockRef,
  width,
  active,
  disabled,
  children,
  onMouseEnter,
  onMouseLeave,
}: {
  blockRef: React.RefObject<HTMLDivElement>
  width: number
  active: boolean
  disabled: boolean
  children?: React.ReactNode
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}) {
  return (
    <div
      ref={blockRef}
      style={{
        width: width,
        height: 24,
        padding: '0 4px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        borderRadius: 4,
        opacity: disabled ? 0.4 : 1,
        boxShadow: active
          ? `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
          : 'none',
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  )
}

function getNumberInputWidth(width: 72 | 96, options: boolean) {
  if (width === 72) return 36
  return options ? 40 : 52
}
