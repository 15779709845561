import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'
import {
  computeMinPageHeight,
  divideChildren,
  filterInLayoutChildren,
} from '../utils'
import { ReadOnlyNode } from 'application/node'
import { NodeSizeState } from '../node/node'
import { isConstrained, isContainerType } from 'application/attributes'
import { getGap, getLayoutDirection } from 'application/layout/utils'

export class BlockChildAutoSize {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap

  constructor(document: WriteDocument, sizeMap: NodeSizeStateMap) {
    this.document = document
    this.sizeMap = sizeMap
  }

  calculate = (childId: string): void => {
    const child = this.document.getNode(childId)
    if (!child) return

    const childSize = this.sizeMap.get(childId)
    if (!childSize) return

    this.computeHeight(child, childSize)
  }

  private computeHeight = (child: ReadOnlyNode, size: NodeSizeState): void => {
    if (isConstrained(child, 'h')) return

    const hAuto = child.getStyleAttribute('size.h.auto')
    switch (hAuto) {
      case 'auto':
        this.computeAutoHeight(child, size)
        break
    }
  }

  private computeAutoHeight = (
    node: ReadOnlyNode,
    size: NodeSizeState
  ): void => {
    const type = node.getBaseAttribute('type')
    if (!isContainerType(type)) return

    const wSet = size.getW()
    const ratio = size.getRatio()
    if (wSet && ratio !== undefined) return

    const children = filterInLayoutChildren(
      node.getChildren() || [],
      this.document
    )

    const layoutDirection = getLayoutDirection(node)
    switch (layoutDirection) {
      case 'column':
        const type = node.getBaseAttribute('type')
        const sumChildH = children.reduce((sum, child) => {
          const childSize = this.sizeMap.get(child)
          if (!childSize) return sum
          return sum + (childSize.getH() || 0)
        }, 0)
        const totalGap = getGap(node) * (children.length - 1)
        switch (type) {
          case 'page':
            const minH = computeMinPageHeight(size)
            size.setInnerH(Math.max(sumChildH + totalGap, minH))
            break
          default:
            size.setInnerH(sumChildH + totalGap)
            break
        }
        break
      case 'row':
        const maxChildH = children.reduce((max, child) => {
          const childSize = this.sizeMap.get(child)
          if (!childSize) return max
          return Math.max(max, childSize.getH() || 0)
        }, 0)
        size.setInnerH(maxChildH)
        break
      case 'wrap':
        const w = size.getInnerW()
        if (w === undefined) break
        const dividedChildren = divideChildren(
          node,
          this.document,
          this.sizeMap,
          w
        )
        const maxChildHWrap = dividedChildren.reduce((sum, row) => {
          const max = row.reduce((max, child) => {
            const childSize = this.sizeMap.get(child)
            if (!childSize) return max
            return Math.max(max, childSize.getH() || 0)
          }, 0)
          return sum + max
        }, 0)
        size.setInnerH(maxChildHWrap)
        break
    }
  }
}
