import { useRef, useState } from 'react'
import PublishPopup from './PublishPopup/PublishPopup'
import ToolbarCTAButtonDark from 'components/Library/Components/Toolbar/ToolbarCTAButtonDark/ToolbarCTAButtonDark'

export default function PublishButton() {
  const buttonRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  return (
    <>
      <ToolbarCTAButtonDark
        buttonRef={buttonRef}
        text={'Publish'}
        active={open}
        onClick={() => setOpen(!open)}
      />
      {open && (
        <PublishPopup buttonRef={buttonRef} close={() => setOpen(false)} />
      )}
    </>
  )
}
