import {
  Action,
  ActionEventResult,
  ActionHandler,
  Done,
  NotDone,
} from '../types'
import { CommandParser, TextCommand } from 'application/textEditor'
import { CoordinatesConversion } from 'application/camera'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { Point, pointInRectangle } from 'application/shapes'
import { isCanvasClosest } from 'application/browser'
import { CommandHandler } from 'application/client'
import { EditInputTextEditor } from './input'
import { NodeDeleteAction, NodeSelectionAction } from 'application/action'

export class EditTextAction implements ActionHandler {
  private documentSelection: ReadOnlyDocumentSelection
  private selectionAction: NodeSelectionAction
  private deleteAction: NodeDeleteAction
  private commandHandler: CommandHandler
  private commandParser: CommandParser
  private coordinates: CoordinatesConversion
  private editingInput: EditInputTextEditor

  private dragging: boolean
  private uncommited: boolean
  private canceling: boolean

  constructor(
    documentSelection: ReadOnlyDocumentSelection,
    selectionAction: NodeSelectionAction,
    deleteAction: NodeDeleteAction,
    commandHandler: CommandHandler,
    commandParser: CommandParser,
    coordinates: CoordinatesConversion,
    editingInput: EditInputTextEditor
  ) {
    this.documentSelection = documentSelection
    this.selectionAction = selectionAction
    this.deleteAction = deleteAction
    this.commandHandler = commandHandler
    this.commandParser = commandParser
    this.coordinates = coordinates
    this.editingInput = editingInput

    this.dragging = false
    this.uncommited = false
    this.canceling = false
  }

  getType = (): Action => {
    return 'editText'
  }

  onMouseDown = (e: MouseEvent): ActionEventResult => {
    if (!isCanvasClosest(e)) return NotDone
    if (!this.isClickInSelection(e)) return NotDone
    if (this.editingInput.getEditing()) return NotDone

    const point = this.getPointOffset(e)
    const command = this.commandParser.onMouse('down', point)
    this.sendTextCommand(command)

    if (command.type === 'select') this.dragging = true

    return NotDone
  }

  onMouseMove = (e: MouseEvent): ActionEventResult => {
    if (!this.dragging) return NotDone
    if (this.editingInput.getEditing()) return NotDone

    const point = this.getPointOffset(e)
    const command = this.commandParser.onMouse('move', point)
    this.sendTextCommand(command)

    return NotDone
  }

  onMouseUp = (e: MouseEvent): ActionEventResult => {
    if (!isCanvasClosest(e)) return NotDone
    if (this.editingInput.getEditing()) return NotDone

    if (this.dragging) {
      const point = this.getPointOffset(e)
      const command = this.commandParser.onMouse('up', point)
      this.sendTextCommand(command)
      this.commit()
      this.dragging = false
      return NotDone
    }

    if (!this.isClickInSelection(e)) {
      this.sendStopCommand()
      this.deleteEmpty()
      this.commit()
      return Done
    }

    return NotDone
  }

  onKeyDown = (e: KeyboardEvent): ActionEventResult => {
    const command = this.commandParser.onKey(
      e.key,
      e.shiftKey,
      e.metaKey || e.ctrlKey
    )
    this.sendTextCommand(command)

    switch (command.type) {
      case 'none':
      case 'paste':
        break
      case 'history':
        e.preventDefault()
        break
      case 'insert':
        e.preventDefault()
        this.uncommited = true
        if (command.parameters.text.includes(' ')) this.commit()
        break
      case 'cut':
      case 'return':
      case 'delete':
        e.preventDefault()
        this.commit()
        break
      case 'select':
        e.preventDefault()
        break
      case 'exit':
        e.preventDefault()
        this.sendStopCommand()
        this.deleteEmpty()
        this.commit()
        break
      case 'arrow':
        e.preventDefault()
        break
    }

    return NotDone
  }

  onKeyUp = (): ActionEventResult => {
    return NotDone
  }

  onPaste = (e: ClipboardEvent): ActionEventResult => {
    if (!e.clipboardData) return NotDone
    e.preventDefault()

    this.sendTextCommand({
      type: 'insert',
      parameters: {
        text: e.clipboardData.getData('text'),
      },
    })
    this.commit()

    return NotDone
  }

  cancel = (): void => {
    if (this.canceling) return
    this.canceling = true

    this.sendStopCommand()
    this.deleteEmpty()
    this.commit()

    this.canceling = false
  }

  private isClickInSelection = (e: MouseEvent): boolean => {
    const rectangle = this.documentSelection.getSelectionRectangle()
    if (!rectangle) return false

    const point = this.coordinates.get(e)
    return pointInRectangle(point, rectangle)
  }

  private getPointOffset = (e: MouseEvent): Point => {
    const point = this.coordinates.get(e)
    const rectangle = this.documentSelection.getSelectionRectangle()
    if (!rectangle) return point

    return {
      x: point.x - rectangle.x,
      y: point.y - rectangle.y,
    }
  }

  private sendTextCommand = (command: TextCommand): void => {
    if (command.type === 'none') return
    if (command.type === 'exit') return
    if (command.type === 'history' && this.uncommited) this.commit()
    this.commandHandler.handle({ type: 'editTextCommand', params: { command } })
  }

  private sendStopCommand = (): void => {
    this.commandHandler.handle({ type: 'stopEditText' })
  }

  private commit = (): void => {
    this.uncommited = false
    this.commandHandler.handle({ type: 'commit' })
  }

  private deleteEmpty = (): void => {
    const selection = this.documentSelection.getSelected()
    if (selection.length !== 1) return

    const node = selection[0]
    const content = node.getBaseAttribute('text.content')
    if (content === undefined || content.length > 0) return

    this.selectionAction.selectNodes([], true)
    this.deleteAction.delete([node.getId()])
  }
}
