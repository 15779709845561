import { WriteDocument } from 'application/document'
import { LayoutDependencyGraphFactory } from 'application/layout/types'
import { FlexPositionCalculator } from './flex'
import { BlockPositionCalculator } from './block'
import { PositionNodeMap } from '../types'
import {
  getNonDependentNodes,
  removeNode,
} from 'application/layout/dependency/utils'
import { CanvasPositionCalculator } from './canvas'
import { getLayoutMode } from 'application/layout/utils'

export class BasePositionCalculator {
  private document: WriteDocument
  private topDownFactory: LayoutDependencyGraphFactory
  private canvasCalculator: CanvasPositionCalculator
  private flexCalculator: FlexPositionCalculator
  private blockCalculator: BlockPositionCalculator

  constructor(
    document: WriteDocument,
    topDownFactory: LayoutDependencyGraphFactory,
    canvasCalculator: CanvasPositionCalculator,
    flexCalculator: FlexPositionCalculator,
    blockCalculator: BlockPositionCalculator
  ) {
    this.document = document
    this.topDownFactory = topDownFactory
    this.canvasCalculator = canvasCalculator
    this.flexCalculator = flexCalculator
    this.blockCalculator = blockCalculator
  }

  calculate = (ids: Set<string>, positionMap: PositionNodeMap): void => {
    const graph = this.topDownFactory.create(ids)
    let terminalNodes = getNonDependentNodes(graph)
    while (terminalNodes.length > 0) {
      for (const terminalNode of terminalNodes) {
        this.computeBasePosition(terminalNode.id, positionMap)
        removeNode(terminalNode, graph)
      }
      terminalNodes = getNonDependentNodes(graph)
    }
  }

  private computeBasePosition = (
    id: string,
    positionMap: PositionNodeMap
  ): void => {
    const node = this.document.getNode(id)
    if (!node) return

    this.canvasCalculator.calculate(id, positionMap)

    const layoutMode = getLayoutMode(node)
    switch (layoutMode) {
      case 'block':
        this.blockCalculator.calculate(id, positionMap)
        break
      case 'flex':
        this.flexCalculator.calculate(id, positionMap)
        break
    }
  }
}
