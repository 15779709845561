import useLoadFonts from 'hooks/admin/useLoadFonts'
import useWebsocket from 'hooks/admin/useWebsocket'
import useSplashScreenTimeout from 'hooks/ui/useSplashScreenTimeout'
import { useEffect, useState } from 'react'
import { editor, recreateEditor } from 'index'
import IntercomMenu from 'components/Library/Support/IntercomMenu'
import PortalRoot from '../Editor/PortalRoot/PortalRoot'
import PortalRootPreview from '../Editor/PortalRootPreview/PortalRootPreview'
import WebglCanvas from '../Editor/WebglCanvas/WebglCanvas'
import LeftTopbar from '../Editor/LeftTopbar/LeftTopbar'
import LeftSidepanel from '../Editor/LeftSidepanel/LeftSidepanel'
import LeftToolbar from '../Editor/LeftToolbar/LeftToolbar'
import RightTopbar from '../Editor/RightTopbar/RightTopbar'
import RightSidepanel from '../Editor/RightSidepanel/RightSidepanel'
import ZoomInput from '../Editor/ZoomInput/ZoomInput'
import RightClickMenu from '../Editor/RightClickMenu/RightClickMenu'
import DevTools from '../Editor/DevTools/DevTools'
import Preview from '../Editor/Preview/Preview'
import SplashScreen from '../Editor/SplashScreen/SplashScreen'
import ReconnectingScreen from '../Editor/RefreshScreen/SplashScreen'
import useCreatePlayground from 'hooks/playground/useCreatePlayground'
import PlaygroundMessageScreen from './LoadingScreen'

export const SIDEBAR_TOP = 72
export const SIDEBAR_OFFSET = 8

export default function Playground() {
  useLoadFonts()
  useCreatePlayground()

  const connected = useWebsocket()
  const { timeout, fade } = useSplashScreenTimeout()

  const [loaded, setLoaded] = useState(false)
  const [fading, setFading] = useState(false)

  document.documentElement.classList.add('hideScrollbar')

  useEffect(() => {
    editor.init()
    setTimeout(() => {
      setFading(true)
    }, fade)
    setTimeout(() => {
      setLoaded(true)
    }, timeout)

    return () => {
      editor.cleanup()
      recreateEditor()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        userSelect: 'none',
      }}
    >
      <IntercomMenu />
      <PortalRoot />
      <PortalRootPreview />
      <WebglCanvas />
      {(loaded || fading) && (
        <>
          <LeftTopbar />
          <LeftSidepanel />
          <LeftToolbar />
          <RightTopbar />
          <RightSidepanel />
          <ZoomInput />
          <RightClickMenu />
          <DevTools />
          <Preview />
        </>
      )}
      <PlaygroundMessageScreen />
      {!loaded && <SplashScreen fading={fading} />}
      {loaded && !connected && <ReconnectingScreen />}
    </div>
  )
}
