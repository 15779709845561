import { NodeSizeStateFactory } from './generator'
import { NodeSizeState } from './node'

export class NodeSizeStateMap {
  private nodeSizeStateFactory: NodeSizeStateFactory
  private map: { [id: string]: NodeSizeState } = {}

  constructor(nodeSizeStateFactory: NodeSizeStateFactory) {
    this.nodeSizeStateFactory = nodeSizeStateFactory
  }

  clear = (): void => {
    this.map = {}
  }

  get = (id: string): NodeSizeState | null => {
    if (this.map[id]) return this.map[id]
    const nodeSizeState = this.nodeSizeStateFactory.create(id)
    if (!nodeSizeState) return null
    this.map[id] = nodeSizeState
    return nodeSizeState
  }

  getAll = (): NodeSizeState[] => {
    return Object.values(this.map)
  }
}
