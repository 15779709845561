import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'
import { TextSizeCalculator } from '../types'

export class TextAutoSize {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap
  private textSize: TextSizeCalculator

  constructor(
    document: WriteDocument,
    sizeMap: NodeSizeStateMap,
    textSize: TextSizeCalculator
  ) {
    this.document = document
    this.sizeMap = sizeMap
    this.textSize = textSize
  }

  calculate = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size) return

    const type = node.getBaseAttribute('type')
    if (type !== 'text') return

    const hAuto = node.getStyleAttribute('size.h.auto')
    if (hAuto !== 'auto') return

    const w = size.getW()
    if (w === undefined) return

    const h = this.textSize.calculateHeight(node, w)
    size.setH(h)
    size.setMinContentH(h)
    size.setMaxContentH(h)
  }
}
