import posthog from 'posthog-js'

const { REACT_APP_POSTHOG_ENV } = process.env
const env = getEnvTag()

export default function useAnalytics() {
  const metricSignup = () => {
    posthog.identify()
    posthog.capture('signup', { env })
  }

  const metricLogin = () => {
    posthog.identify()
    posthog.capture('login', { env })
  }

  const metricPlayground = () => {
    posthog.identify()
    posthog.capture('open_playground', { env })
  }

  const metricEditor = () => {
    posthog.capture('open_editor', { env })
  }

  const metricPublish = () => {
    posthog.capture('publish_site', { env })
  }

  const metricPreview = () => {
    posthog.capture('preview_site', { env })
  }

  const metricTemplate = () => {
    posthog.capture('add_template', { env })
  }

  return {
    metricSignup,
    metricLogin,
    metricPlayground,
    metricEditor,
    metricPublish,
    metricPreview,
    metricTemplate,
  }
}

function getEnvTag() {
  if (REACT_APP_POSTHOG_ENV === 'none') return 'local'
  if (REACT_APP_POSTHOG_ENV === 'dev') return 'dev'
  return 'prod'
}
