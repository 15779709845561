import {
  AttributeAutolayoutAlign,
  AttributeAutolayoutDirection,
  AttributeFlexAlignSelf,
  MultiselectStyleMap,
  isAutolayoutChild,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

type FlexChildPanelKeys = 'flex.grow' | 'flex.shrink' | 'flex.alignSelf'

type SizePanelAttributes = Pick<MultiselectStyleMap, FlexChildPanelKeys> | null

export type FlexChildMode = 'grow' | 'shrink' | 'fixed'

export interface FlexChildPanelState {
  attributes: SizePanelAttributes
  mode: 'Mixed' | FlexChildMode
  direction: 'Mixed' | AttributeAutolayoutDirection
  main: 'Mixed' | AttributeAutolayoutAlign
  counter: 'Mixed' | AttributeAutolayoutAlign
}

export interface FlexChildPanelHandlers {
  setMode: (value: FlexChildMode) => void
  setAlignSelf: (value: AttributeFlexAlignSelf) => void
}

export class FlexChildPanel extends StyleAttributePanel<
  FlexChildPanelState,
  FlexChildPanelHandlers,
  FlexChildPanelKeys
> {
  getSettings(): FlexChildPanelState {
    return {
      attributes: this.attributes,
      mode: this.getMode(),
      direction: this.getDirection(),
      main: this.getMain(),
      counter: this.getCounter(),
    }
  }

  getHandlers(): FlexChildPanelHandlers {
    return {
      setMode: this.setMode,
      setAlignSelf: this.setAlignSelf,
    }
  }

  private getMode(): 'Mixed' | FlexChildMode {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'fixed'

    const first = this.getNodeMode(nodes[0])
    for (let i = 1; i < nodes.length; i++) {
      const mode = this.getNodeMode(nodes[i])
      if (mode !== first) return 'Mixed'
    }

    return first
  }

  private getDirection(): 'Mixed' | AttributeAutolayoutDirection {
    const nodes = this.getNodesAndParents()
    if (nodes.length === 0) return 'Mixed'

    const parent = nodes[0][1]
    if (!parent) return 'Mixed'

    const first = parent.getStyleAttribute('autolayout.direction')
    if (first === undefined) return 'Mixed'

    for (let i = 1; i < nodes.length; i++) {
      const direction = nodes[i][1]?.getStyleAttribute('autolayout.direction')
      if (direction !== first) return 'Mixed'
    }

    return first
  }

  private getMain(): 'Mixed' | AttributeAutolayoutAlign {
    const nodes = this.getNodesAndParents()
    if (nodes.length === 0) return 'Mixed'

    const parent = nodes[0][1]
    if (!parent) return 'Mixed'

    const first = parent.getStyleAttribute('autolayout.align.main')
    if (first === undefined) return 'Mixed'

    for (let i = 1; i < nodes.length; i++) {
      const main = nodes[i][1]?.getStyleAttribute('autolayout.align.main')
      if (main !== first) return 'Mixed'
    }

    return first
  }

  private getCounter(): 'Mixed' | AttributeAutolayoutAlign {
    const nodes = this.getNodesAndParents()
    if (nodes.length === 0) return 'Mixed'

    const parent = nodes[0][1]
    if (!parent) return 'Mixed'

    const first = parent.getStyleAttribute('autolayout.align.counter')
    if (first === undefined) return 'Mixed'

    for (let i = 1; i < nodes.length; i++) {
      const counter = nodes[i][1]?.getStyleAttribute('autolayout.align.counter')
      if (counter !== first) return 'Mixed'
    }

    return first
  }

  private setMode = (mode: FlexChildMode): void => {
    this.setMulti({
      'flex.grow': mode === 'grow' ? 1 : 0,
      'flex.shrink': mode === 'shrink' || mode === 'grow' ? 1 : 0,
      'flex.basis': mode === 'grow' ? 'none' : 'auto',
    })
  }

  private setAlignSelf = (value: AttributeFlexAlignSelf): void => {
    const pairs = this.getNodesAndParents()
    for (const [node, parent] of pairs) {
      if (!parent) continue
      switch (parent.getStyleAttribute('autolayout.direction')) {
        case 'row':
        case 'wrap':
          switch (value) {
            case 'stretch':
              this.setOne(node.getId(), {
                'flex.alignSelf': value,
                'size.h.auto': 'auto',
                'size.h': undefined,
                'size.h.percent': undefined,
              })
              break
            default:
              this.setOne(node.getId(), {
                'flex.alignSelf': value,
              })
          }
          break
        case 'column':
        default:
          switch (value) {
            case 'stretch':
              this.setOne(node.getId(), {
                'flex.alignSelf': value,
                'size.w.auto': 'auto',
                'size.w': undefined,
                'size.w.percent': undefined,
              })
              break
            default:
              this.setOne(node.getId(), {
                'flex.alignSelf': value,
              })
          }
          break
      }
    }
  }

  private getNodeMode = (node: ReadOnlyNode): FlexChildMode => {
    const grow = node.getStyleAttribute('flex.grow')
    const shrink = node.getStyleAttribute('flex.shrink')
    if (grow === undefined || shrink === undefined) return 'fixed'
    if (grow === 0 && shrink === 0) return 'fixed'
    if (grow > 0) return 'grow'
    if (grow === 0 && shrink > 0) return 'shrink'
    return 'fixed'
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, parent) => (parent ? isAutolayoutChild(node, parent) : false)
  }
}
