import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'
import { getLayoutDirection } from 'application/layout/utils'
import { ReadOnlyNode } from 'application/node'
import { NodeSizeState } from '../node/node'
import {
  computeMaxContent,
  computeMinContent,
  computeRatioLockedAxis,
  filterInLayoutChildren,
  sumChildrenFlexBase,
} from '../utils'

export class FlexMainInitialSize {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap

  constructor(document: WriteDocument, sizeMap: NodeSizeStateMap) {
    this.document = document
    this.sizeMap = sizeMap
  }

  calculateMainBaseSize = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size) return

    const children = filterInLayoutChildren(
      node.getChildren() || [],
      this.document
    )
    if (children.length === 0) return

    const direction = getLayoutDirection(node)
    const main = direction === 'column' ? 'h' : 'w'
    const innerSize = this.getContainerInnerSize(node, size)

    this.setMainBaseSize(children, innerSize, main)
  }

  calculateMainHypoSize = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size) return

    const children = filterInLayoutChildren(
      node.getChildren() || [],
      this.document
    )
    if (children.length === 0) return

    const direction = getLayoutDirection(node)
    const main = direction === 'column' ? 'h' : 'w'

    this.setFlexChildrenHypoMainSize(children, main)
  }

  private setMainBaseSize = (
    children: string[],
    innerSize: number,
    main: 'w' | 'h'
  ): void => {
    for (const childId of children) {
      const child = this.document.getNode(childId)
      if (!child) continue

      const childSize = this.sizeMap.get(childId)
      if (!childSize) continue

      const flexBasis = child.getStyleAttribute('flex.basis')
      switch (main) {
        case 'w':
          switch (flexBasis) {
            case 'none':
              const hPadding = childSize.getPaddingHorizontal()
              childSize.setFlexBaseSize(hPadding)
              break
            case 'auto':
              const wAuto = child.getStyleAttribute('size.w.auto')
              const wLockedValue = computeRatioLockedAxis(childSize, 'w')
              switch (wAuto) {
                case 'fixed':
                  const px = child.getStyleAttribute('size.w')
                  if (px === undefined) break
                  childSize.setFlexBaseSize(px)
                  break
                case 'percent':
                  if (wLockedValue !== undefined) {
                    childSize.setFlexBaseSize(wLockedValue)
                  } else {
                    const percent = child.getStyleAttribute('size.w.percent')
                    if (percent === undefined) break
                    const value = innerSize * (percent / 100)
                    childSize.setFlexBaseSize(value)
                  }
                  break
                case 'fill':
                  if (wLockedValue) {
                    childSize.setFlexBaseSize(wLockedValue)
                  } else {
                    childSize.setFlexBaseSize(innerSize)
                  }
                  break
                case 'auto':
                  if (wLockedValue) {
                    childSize.setFlexBaseSize(wLockedValue)
                  } else {
                    const maxContent = computeMaxContent(
                      childId,
                      this.sizeMap,
                      this.document,
                      'w'
                    )
                    childSize.setFlexBaseSize(maxContent)
                  }

                  break
              }
              break
          }
          break
        case 'h':
          switch (flexBasis) {
            case 'none':
              const vPadding = childSize.getPaddingVertical()
              childSize.setFlexBaseSize(vPadding)
              break
            case 'auto':
              const hAuto = child.getStyleAttribute('size.h.auto')
              const hLockedValue = computeRatioLockedAxis(childSize, 'h')
              switch (hAuto) {
                case 'fixed':
                  const px = child.getStyleAttribute('size.h')
                  if (px === undefined) break
                  childSize.setFlexBaseSize(px)
                  break
                case 'percent':
                  if (hLockedValue !== undefined) {
                    childSize.setFlexBaseSize(hLockedValue)
                  } else {
                    const percent = child.getStyleAttribute('size.h.percent')
                    if (percent === undefined) break
                    const value = innerSize * (percent / 100)
                    childSize.setFlexBaseSize(value)
                  }
                  break
                case 'fill':
                  if (hLockedValue) {
                    childSize.setFlexBaseSize(hLockedValue)
                  } else {
                    childSize.setFlexBaseSize(innerSize)
                  }
                  break
                case 'auto':
                  const hAuto = child.getStyleAttribute('size.h.auto')
                  switch (hAuto) {
                    case 'auto':
                      const maxContent = computeMaxContent(
                        childId,
                        this.sizeMap,
                        this.document,
                        'h'
                      )
                      childSize.setFlexBaseSize(maxContent)
                      break
                  }
              }
          }
      }
    }
  }

  private setFlexChildrenHypoMainSize = (
    children: string[],
    main: 'w' | 'h'
  ): void => {
    for (const childId of children) {
      const childSize = this.sizeMap.get(childId)
      if (!childSize) continue

      const baseSize = childSize.getFlexBaseSize()
      if (baseSize === undefined) continue

      switch (main) {
        case 'w':
          const minW = computeMinContent(
            childId,
            this.sizeMap,
            this.document,
            'w'
          )
          const maxW = childSize.getMaxW()
          const minMax = Math.min(maxW, Math.max(minW, baseSize))
          childSize.setHypoMainSize(minMax)
          break
        case 'h':
          const minH = computeMinContent(
            childId,
            this.sizeMap,
            this.document,
            'h'
          )
          const maxH = childSize.getMaxH()
          const minMaxH = Math.min(maxH, Math.max(minH, baseSize))
          childSize.setHypoMainSize(minMaxH)
          break
      }
    }
  }

  private getContainerInnerSize = (
    node: ReadOnlyNode,
    size: NodeSizeState
  ): number => {
    const direction = getLayoutDirection(node)
    switch (direction) {
      case 'row':
      case 'wrap':
        const innerW = size.getInnerW()
        if (innerW !== undefined) return innerW
        return sumChildrenFlexBase(node, this.sizeMap, this.document, 'w')
      case 'column':
        const innerH = size.getInnerH()
        if (innerH !== undefined) return innerH
        return sumChildrenFlexBase(node, this.sizeMap, this.document, 'h')
    }
  }
}
