import { ReadOnlyDocumentSelection } from 'application/selection'
import { AlignmentLine } from '../types'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocument } from 'application/document'
import { Rectangle, pointInRectangle } from 'application/shapes'
import { isDisplayNone } from 'application/attributes'

type AlignmentLineMode = 't' | 'r' | 'b' | 'l' | 'ch' | 'cv'

const allModes: AlignmentLineMode[] = ['t', 'r', 'b', 'l', 'ch', 'cv']

interface ViewableRectangle {
  getViewableRectangle: () => Rectangle
}

export class AlignmentLinesCalculator {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private viewableRectangle: ViewableRectangle

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    viewableRectangle: ViewableRectangle
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.viewableRectangle = viewableRectangle
  }

  create = (): AlignmentLine[] => {
    const nodes = this.documentSelection.getSelected()
    if (nodes.length === 0) return []

    const selected = nodes.map((n) => n.getId())
    const visited = new Set<string>()

    return nodes
      .flatMap((n) => [
        ...this.getParentLines(n, selected, visited),
        ...this.getSiblingLines(n, selected, visited),
      ])
      .filter(this.filterOutOfView)
  }

  private getParentLines = (
    node: ReadOnlyNode,
    selected: string[],
    visited: Set<string>
  ): AlignmentLine[] => {
    const parent = this.document.getParent(node)
    if (!parent || visited.has(parent.getId())) return []
    if (['root', 'canvas'].includes(parent.getBaseAttribute('type'))) return []
    if (selected.includes(parent.getId())) return []
    if (isDisplayNone(parent)) return []

    visited.add(parent.getId())
    return allModes.map((mode) => this.createLine(parent, mode))
  }

  private getSiblingLines = (
    node: ReadOnlyNode,
    selected: string[],
    visited: Set<string>
  ): AlignmentLine[] => {
    const parent = this.document.getParent(node)
    if (!parent) return []

    const siblings = parent.getChildren()
    if (!siblings || siblings.length === 0) return []

    const siblingNodes = siblings
      .map((key) => this.document.getNode(key))
      .filter(
        (n) =>
          n &&
          !selected.includes(n.getId()) &&
          !visited.has(n.getId()) &&
          !isDisplayNone(n)
      ) as ReadOnlyNode[]

    siblingNodes.forEach((n) => visited.add(n.getId()))
    return siblingNodes.flatMap((s) =>
      allModes.map((m) => this.createLine(s, m))
    )
  }

  private createLine(
    node: ReadOnlyNode,
    mode: AlignmentLineMode
  ): AlignmentLine {
    const direction = ['t', 'b', 'ch'].includes(mode) ? 'h' : 'v'
    const main = this.getMain(node, mode)
    const counter1 = this.getCounter1(node, mode)
    const counter2 = this.getCounter2(node, mode)

    return {
      points: [
        {
          x: direction === 'h' ? counter1 : main,
          y: direction === 'h' ? main : counter1,
        },
        {
          x: direction === 'h' ? counter2 : main,
          y: direction === 'h' ? main : counter2,
        },
      ],
      counter: main,
      direction: direction,
    }
  }

  private getMain = (node: ReadOnlyNode, mode: AlignmentLineMode): number => {
    switch (mode) {
      case 't':
        return node.getBaseAttribute('y')
      case 'r':
        return node.getBaseAttribute('x') + node.getBaseAttribute('w')
      case 'b':
        return node.getBaseAttribute('y') + node.getBaseAttribute('h')
      case 'l':
        return node.getBaseAttribute('x')
      case 'ch':
        return node.getBaseAttribute('y') + node.getBaseAttribute('h') / 2
      case 'cv':
        return node.getBaseAttribute('x') + node.getBaseAttribute('w') / 2
    }
  }

  private getCounter1 = (
    node: ReadOnlyNode,
    mode: AlignmentLineMode
  ): number => {
    switch (mode) {
      case 't':
      case 'b':
      case 'ch':
        return node.getBaseAttribute('x')
      case 'r':
      case 'l':
      case 'cv':
        return node.getBaseAttribute('y')
    }
  }

  private getCounter2 = (
    node: ReadOnlyNode,
    mode: AlignmentLineMode
  ): number => {
    switch (mode) {
      case 't':
      case 'b':
      case 'ch':
        return node.getBaseAttribute('x') + node.getBaseAttribute('w')
      case 'r':
      case 'l':
      case 'cv':
        return node.getBaseAttribute('y') + node.getBaseAttribute('h')
    }
  }

  private filterOutOfView = (line: AlignmentLine): boolean => {
    const viewableRectangle = this.viewableRectangle.getViewableRectangle()
    const { points } = line
    return points.some((point) => pointInRectangle(point, viewableRectangle))
  }
}
