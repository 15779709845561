import {
  BaseMap,
  SelectorBreakpoint,
  SelectorName,
  SelectorPseudo,
  StyleMap,
} from 'application/attributes'
import { WriteDocument } from 'application/document'
import { NameGenerator } from 'application/name/generator'
import { Node, NodeFactory, NodeMap, ReadOnlyNode } from 'application/node'
import { ProjectDocumentNode } from 'application/service'

export class ClientDocumentHelper {
  private document: WriteDocument
  private nodeFactory: NodeFactory
  private nameGenerator: NameGenerator

  constructor(
    document: WriteDocument,
    nodeFactory: NodeFactory,
    nameGenerator: NameGenerator
  ) {
    this.document = document
    this.nodeFactory = nodeFactory
    this.nameGenerator = nameGenerator
  }

  setNodeMap = (nodeMap: NodeMap): void => {
    this.document.setNodeMap(nodeMap)
  }

  getNode = (id: string): Node | undefined => {
    return this.document.getNode(id)
  }

  addNode = (node: Node, increment: boolean): void => {
    const type = node.getBaseAttribute('type')
    const name = node.getBaseAttribute('name')
    const updatedName = this.nameGenerator.next(type, name, increment)
    node.setBaseAttribute('name', updatedName)

    this.document.addNode(node)
  }

  deleteNode = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    this.deleteNodeTree(node)
  }

  createNodeFromProjectNode = (node: ProjectDocumentNode): Node => {
    return this.nodeFactory.createLoadedNode(node)
  }

  createProjectNodeFromNode = (node: ReadOnlyNode): ProjectDocumentNode => {
    return {
      id: node.getId(),
      parent: node.getParent(),
      children: node.getChildren(),
      baseAttributes: node.getBaseAttributes(),
      defaultSelector: node.getDefaultSelector(),
      selectors: node.getSelectors(),
      activeBreakpoint: node.getActiveBreakpoint(),
      activePseudo: node.getActivePseudo(),
    }
  }

  createProjectDocumentFromDocument = (): {
    [key: string]: ProjectDocumentNode
  } => {
    const data: { [key: string]: ProjectDocumentNode } = {}
    for (const node of this.document.getNodes()) {
      data[node.getId()] = this.createProjectNodeFromNode(node)
    }
    return data
  }

  setNodeParent = (
    id: string,
    parentId: string | null,
    index: number | undefined
  ): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const oldParentId = node.getParent()
    if (oldParentId === parentId) return

    if (oldParentId) {
      const oldParent = this.document.getNode(oldParentId)
      if (oldParent) oldParent.removeChild(node.getId())
    }

    if (!parentId) {
      node.setParent(undefined)
      return
    }

    const newParent = this.document.getNode(parentId)
    if (!newParent) return

    newParent.addChild(node.getId(), index)
    node.setParent(newParent.getId())
  }

  setNodeChildOrder = (id: string, children: string[]): void => {
    const node = this.document.getNode(id)
    if (!node) return

    node.setChildren(children)
  }

  setNodeAttributes = (
    id: string,
    updateBase: Partial<BaseMap>,
    updateStyle: Partial<StyleMap>,
    selector?: SelectorName
  ): void => {
    const node = this.document.getNode(id)
    if (!node) return

    for (const [key, value] of Object.entries(updateBase)) {
      node.setBaseAttribute(key as keyof BaseMap, value)
    }

    for (const [key, value] of Object.entries(updateStyle)) {
      node.setStyleAttribute(key as keyof StyleMap, value, selector)
    }
  }

  resetNodeAttribute = (id: string, keys: (keyof StyleMap)[]): void => {
    const node = this.document.getNode(id)
    if (!node) return

    for (const key of keys) {
      node.resetStyleAttribute(key)
    }
  }

  setNodeSelector = (
    id: string,
    breakpoint?: SelectorBreakpoint,
    pseudo?: SelectorPseudo
  ): void => {
    const node = this.document.getNode(id)
    if (!node) return

    if (breakpoint) node.setActiveBreakpoint(breakpoint)
    if (pseudo) node.setActivePseudo(pseudo)
  }

  private deleteNodeTree = (node: ReadOnlyNode): void => {
    const children = node.getChildren()

    if (children) {
      for (const child of children) {
        const node = this.document.getNode(child)
        if (!node) continue

        this.deleteNodeTree(node)
      }
    }

    const parent = node.getParent()
    if (parent) {
      const parentNode = this.document.getNode(parent)
      if (parentNode) parentNode.removeChild(node.getId())
    }

    this.document.deleteNode(node.getId())
  }
}
