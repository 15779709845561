import { WriteDocument } from 'application/document'
import { BoxLayoutEngine } from './box'
import { TopDownDependencyGraphFactory } from '../dependency/down'
import { BoundsCalculator } from './bounds'
import { RenderBoxCalculator } from './render'

export class BoxLayoutEngineFactory {
  create = (document: WriteDocument): BoxLayoutEngine => {
    const topDownFactory = new TopDownDependencyGraphFactory(document)

    const box = new BoundsCalculator(document)
    const render = new RenderBoxCalculator(document)

    return new BoxLayoutEngine(document, topDownFactory, box, render)
  }
}
