import { IconKey } from 'assets/iconLibrary'
import { useCallback, useEffect, useRef, useState } from 'react'
import Icon from '../../Icon/Icon/Icon'
import { DesignColor } from 'themes'

export interface DraggingIconProps {
  icon: IconKey
  warning?: boolean
  iconRotation?: number

  editing: boolean
  dragging: boolean
  setDragging: (dragging: boolean) => void

  increment: (value: number) => void
  decrement: (value: number) => void

  min?: number
  max?: number
  step?: number

  disabled?: boolean
  hasOverride?: boolean
}

export default function DraggingIcon({
  icon,
  warning = false,
  iconRotation = 0,
  increment,
  decrement,
  editing,
  dragging,
  setDragging,
  step = 1,
  disabled = false,
  hasOverride = false,
}: DraggingIconProps) {
  const startY = useRef(0)
  const [totalChange, setTotalChange] = useState(0)
  const [hoverIcon, setHoverIcon] = useState(false)

  const cursor = disabled ? 'default' : 'ns-resize'
  const iconColor = getIconColor(hoverIcon, warning, hasOverride, disabled)
  const iconKey = warning ? 'Warning' : icon

  const handleDragEnd = useCallback(() => {
    if (disabled) return
    setDragging(false)
    setTotalChange(0)
    startY.current = 0
    document.body.style.cursor = 'auto'
  }, [setDragging, disabled])

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (disabled || e.button === 2) return
      e.preventDefault()
      startY.current = e.clientY
      setDragging(true)
      setTotalChange(0)
      document.body.style.cursor = 'ns-resize'
    },
    [disabled, setTotalChange, setDragging]
  )

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!dragging) return
      if (startY.current === 0) {
        startY.current = e.clientY
        setTotalChange(0)
      } else {
        const change = computeChange(startY.current, e.clientY, step)
        const diff = totalChange - change
        diff > 0 ? decrement(diff) : increment(-diff)
        setTotalChange((prev) => prev - diff)
      }
    },
    [dragging, step, totalChange, increment, decrement, startY]
  )

  useEffect(() => {
    if (dragging) {
      window.addEventListener('mouseup', handleDragEnd)
      window.addEventListener('mousemove', handleMouseMove)
    }
    return () => {
      window.removeEventListener('mouseup', handleDragEnd)
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [dragging, handleMouseMove, handleDragEnd])

  return (
    <div
      style={{
        height: 24,
        width: 24,
        cursor: cursor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onMouseDown={(e) => {
        if (editing) {
          e.stopPropagation()
          e.preventDefault()
          return
        }
        handleMouseDown(e)
      }}
      onMouseUp={(e) => {
        if (editing) {
          e.stopPropagation()
          e.preventDefault()
          return
        }
        handleDragEnd()
      }}
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={() => setHoverIcon(true)}
      onMouseLeave={() => setHoverIcon(false)}
    >
      <div
        style={{
          transform: `rotate(${iconRotation}deg)`,
        }}
      >
        <Icon icon={iconKey} size={16} color={iconColor} />
      </div>
    </div>
  )
}

function computeChange(startY: number, endY: number, step: number): number {
  const movement = startY - endY
  const nonLinearMovement =
    Math.sign(movement) * Math.pow(Math.abs(movement), 1.2)
  const change = nonLinearMovement * 0.1 * step
  const roundedChange = Math.round(change / step) * step
  return roundedChange
}

function getIconColor(
  active: boolean,
  warning: boolean,
  hasOverride: boolean,
  disabled: boolean
): string {
  if (disabled && !hasOverride) return DesignColor('overrideParent2')
  if (hasOverride) return DesignColor('overrideParent')
  if (warning) return DesignColor('warning')
  if (disabled) return DesignColor('text3')
  if (active) return DesignColor('inputHighlight')
  return DesignColor('text2')
}
