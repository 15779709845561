import { WriteDocument } from 'application/document'
import { PositionNodeMap } from '../types'
import { RelativeOffsetMap } from './types'
import { ReadOnlyNode } from 'application/node'
import {
  getBottom,
  getLeft,
  getRelativeOffset,
  getRight,
  getTop,
} from './utils'
import { getPositionNode } from '../utils'

export class RelativePositionCalculator {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  calculate = (
    id: string,
    positionMap: PositionNodeMap,
    offsetMap: RelativeOffsetMap
  ): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const parent = this.document.getParent(node)
    if (!parent) return

    const adjustedX = this.adjustX(node, parent)
    const adjustedY = this.adjustY(node, parent)

    const parentOffset = getRelativeOffset(parent.getId(), offsetMap)
    const offset = getRelativeOffset(id, offsetMap)
    const position = getPositionNode(id, positionMap)

    const offsetX = parentOffset.x + adjustedX
    const offsetY = parentOffset.y + adjustedY
    offset.x = offsetX
    offset.y = offsetY
    position.x += offsetX
    position.y += offsetY
  }

  private adjustX = (node: ReadOnlyNode, parent: ReadOnlyNode): number => {
    const left = getLeft(node, parent)
    const right = getRight(node, parent)
    if (left === null && right === null) {
      return 0
    } else if (left !== null) {
      return left
    } else if (right !== null) {
      const parentW = parent.getBaseAttribute('w')
      const nodeW = node.getBaseAttribute('w')
      return parentW - nodeW - right
    }
    return 0
  }

  private adjustY = (node: ReadOnlyNode, parent: ReadOnlyNode): number => {
    const top = getTop(node, parent)
    const bottom = getBottom(node, parent)
    if (top === null && bottom === null) {
      return 0
    } else if (top !== null) {
      return top
    } else if (bottom !== null) {
      const parentH = parent.getBaseAttribute('h')
      const nodeH = node.getBaseAttribute('h')
      return parentH - nodeH - bottom
    }
    return 0
  }
}
