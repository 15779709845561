import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

export const PortalPreview = ({ children }: { children: React.ReactNode }) => {
  const portalRoot =
    document.getElementById('portal-root-preview') || document.body
  return ReactDOM.createPortal(children, portalRoot)
}

export default function PortalRootPreview() {
  return <PortalRootPreviewStyle id="portal-root-preview" />
}

const PortalRootPreviewStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 3;
`
