import {
  Point,
  Rectangle,
  encapsulates,
  pointsToRectangle,
} from 'application/shapes'
import { NodeCreationHandler } from './creation/creation'
import {
  AttributeFill,
  AttributeType,
  BaseMap,
  StyleMap,
  canTypeInsertInto,
  createNewBlackFill,
  createNewWhiteFill,
  getRectangle,
} from 'application/attributes'
import { InsertionStrategyFactory } from './creation/strategy/strategyFactory'
import { ReadOnlyDocument } from 'application/document'
import { Command, SetNodeAttribute } from 'application/client'
import { ReadOnlyNode } from 'application/node'
import { Color, hsbaToRgba, rgbaToHsba } from 'application/color'
import { PositionMap } from './types'
import {
  getAdjustedBottomToNode,
  getAdjustedHeightToNode,
  getAdjustedLeftToNode,
  getAdjustedRightToNode,
  getAdjustedTopToNode,
  getAdjustedWidthToNode,
} from 'application/units'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class NodeCreateAction {
  private commandHandler: CommandHandler
  private nodeCreationHandler: NodeCreationHandler
  private strategyFactory: InsertionStrategyFactory
  private document: ReadOnlyDocument

  private progressiveFillStrategy: FillComputer

  constructor(
    commandHandler: CommandHandler,
    nodeCreationHandler: NodeCreationHandler,
    insertionStrategyFactory: InsertionStrategyFactory,
    document: ReadOnlyDocument
  ) {
    this.commandHandler = commandHandler
    this.nodeCreationHandler = nodeCreationHandler
    this.strategyFactory = insertionStrategyFactory
    this.document = document

    this.progressiveFillStrategy = new FillComputer()
  }

  draw = (type: AttributeType, start: Point, end: Point): string => {
    const point = this.getPosition(start, end)

    const strategy = this.strategyFactory.createClickedNodeStrategy(type, point)
    const rectangle = pointsToRectangle(start, end, true)
    const { base, style } = this.getDefaultAttributes(type, rectangle)

    const id = this.nodeCreationHandler.createOnCanvas(
      type,
      base,
      style,
      strategy
    )

    if (this.canWrap(id)) {
      this.wrap(id, rectangle)
    }

    this.setFill(id)

    return id
  }

  click = (type: AttributeType, point: Point): string => {
    const { w, h } = this.getDefaultSize(type)
    const delta = { dx: w / 2, dy: h / 2 }

    const strategy = this.strategyFactory.createClickedNodeStrategy(
      type,
      point,
      delta
    )
    const { base, style } = this.getDefaultAttributes(type)

    const id = this.nodeCreationHandler.createOnCanvas(
      type,
      base,
      style,
      strategy
    )

    this.setFill(id)

    return id
  }

  insert = (type: AttributeType, rectangle?: Rectangle): string => {
    const defaultRectangle = rectangle || this.getDefaultSize(type)
    const strategy = this.strategyFactory.createCanvasStrategy(defaultRectangle)
    const { base, style } = this.getDefaultAttributes(type, rectangle)

    return this.nodeCreationHandler.createOnCanvas(type, base, style, strategy)
  }

  canvas = (): string => {
    return this.nodeCreationHandler.createCanvas()
  }

  private getPosition = (start: Point, end: Point | undefined): Point => {
    if (!end) return start
    return {
      x: Math.round(Math.min(start.x, end.x)),
      y: Math.round(Math.min(start.y, end.y)),
    }
  }

  private getDefaultAttributes = (
    type: AttributeType,
    rectangle?: Rectangle
  ): {
    base: Partial<BaseMap>
    style: Partial<StyleMap>
  } => {
    const base: Partial<BaseMap> = {}
    const style: Partial<StyleMap> = {}
    if (rectangle) {
      base['w'] = rectangle.w
      base['h'] = rectangle.h
      style['size.h'] = rectangle.h
      style['size.w'] = rectangle.w
    } else {
      const { w, h } = this.getDefaultSize(type)
      base['w'] = w
      base['h'] = h
      style['size.h'] = h
      style['size.w'] = w
    }

    switch (type) {
      case 'text':
        if (rectangle) break
        style['size.w.auto'] = 'auto'
        style['size.h.auto'] = 'auto'
        break
    }

    return { base, style }
  }

  private getDefaultSize = (type: AttributeType): Rectangle => {
    switch (type) {
      case 'frame':
      case 'rectangle':
      case 'ellipse':
      case 'image':
        return { x: 0, y: 0, w: 100, h: 100 }
      case 'text':
        return { x: 0, y: 0, w: 0, h: 0 }
      case 'page':
        return { x: 0, y: 0, w: 1440, h: 1080 }
      default:
        return { x: 0, y: 0, w: 100, h: 100 }
    }
  }

  private canWrap = (id: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    switch (node.getBaseAttribute('type')) {
      case 'frame':
      case 'page':
        return true
      default:
        return false
    }
  }

  private wrap = (id: string, rectangle: Rectangle) => {
    const node = this.document.getNode(id)
    if (!node) return

    const parent = this.document.getParent(node)
    if (!parent) return

    const siblings = parent.getChildren()
    if (!siblings) return

    const inside = siblings.filter((s) => {
      if (s === id) return false

      const child = this.document.getNode(s)
      if (!child) return false

      const childRectangle = getRectangle(child)
      const encapsulated = encapsulates(rectangle, childRectangle)
      const canInsert = canTypeInsertInto(
        child.getBaseAttribute('type'),
        node.getBaseAttribute('type')
      )

      return encapsulated && canInsert
    })
    if (inside.length === 0) return

    const positions = this.getPositions(inside)
    const reparentCommands = this.buildReparentCommands(inside, id)
    const layoutCommands = this.buildLayoutCommands(id, positions)

    this.commandHandler.handle([...reparentCommands, ...layoutCommands])
  }

  private buildReparentCommands = (
    nodeIds: string[],
    parentId: string
  ): Command[] => {
    return nodeIds.map((id, index) => {
      return {
        type: 'setParent',
        params: {
          id: id,
          parentId: parentId,
          index: index,
        },
      }
    })
  }

  private buildLayoutCommands = (
    parentId: string,
    positions: PositionMap
  ): SetNodeAttribute[] => {
    const commands: SetNodeAttribute[] = []
    const wrapper = this.document.getNode(parentId)
    if (!wrapper) return commands

    for (const id in positions) {
      const node = this.document.getNode(id)
      if (!node) continue

      const x = positions[id].x
      const y = positions[id].y
      const w = node.getBaseAttribute('w')
      const h = node.getBaseAttribute('h')

      const adjustedTop = getAdjustedTopToNode(node, wrapper, y)
      const adjustedLeft = getAdjustedLeftToNode(node, wrapper, x)
      const adjustedBottom = getAdjustedBottomToNode(node, wrapper, y + h)
      const adjustedRight = getAdjustedRightToNode(node, wrapper, x + w)
      const adjustedWidth = getAdjustedWidthToNode(node, wrapper, w)
      const adjustedHeight = getAdjustedHeightToNode(node, wrapper, h)
      const wAuto = node.getStyleAttribute('size.w.auto')
      const hAuto = node.getStyleAttribute('size.h.auto')

      const base: Partial<BaseMap> = { x, y, w, h }
      const style: Partial<StyleMap> = {
        ...adjustedTop,
        ...adjustedLeft,
        ...adjustedBottom,
        ...adjustedRight,
        ...adjustedWidth,
        ...adjustedHeight,
      }
      if (wAuto === 'auto') {
        style['size.w.auto'] = 'fixed'
        style['size.w'] = w
      }
      if (hAuto === 'auto') {
        style['size.h.auto'] = 'fixed'
        style['size.h'] = h
      }

      commands.push({
        type: 'setNodeAttribute',
        params: { id: id, base: base, style: style, selector: 'default' },
      })
    }

    return commands
  }

  private getPositions = (ids: string[]): PositionMap => {
    const positions: PositionMap = {}
    ids.forEach((id) => {
      const node = this.document.getNode(id)
      if (!node) return
      positions[id] = {
        x: node.getBaseAttribute('x'),
        y: node.getBaseAttribute('y'),
      }
    })
    return positions
  }

  private setFill(id: string): void {
    const node = this.document.getNode(id)
    if (!node) return

    const parents = this.document.getAncestors(node)
    if (parents.length === 0) return

    switch (node.getBaseAttribute('type')) {
      case 'frame':
      case 'rectangle':
      case 'ellipse':
        const fills = this.progressiveFillStrategy.getProgressiveFills(parents)
        if (!fills) return
        this.commandHandler.handle([
          {
            type: 'setNodeAttribute',
            params: {
              id: id,
              style: { fills: fills },
              base: {},
              selector: 'default',
            },
          },
        ])
        break
      case 'text':
        const color = this.progressiveFillStrategy.getWhiteBlackFills(parents)
        if (!color || color.length !== 1) return
        this.commandHandler.handle([
          {
            type: 'setNodeAttribute',
            params: {
              id: id,
              style: { 'text.color': color[0] },
              base: {},
              selector: 'default',
            },
          },
        ])
        break
      default:
        return
    }
  }
}

class FillComputer {
  getProgressiveFills(parents: ReadOnlyNode[]): AttributeFill[] | null {
    const fill = this.getFirstParentFill(parents)
    if (!fill || !fill.color) return null

    const b = this.getBrightness(fill.color)
    const color = this.adjustBrightness(fill.color, b > 50 ? -8 : 8)
    return [{ type: 'color', color }]
  }

  getWhiteBlackFills(parents: ReadOnlyNode[]): AttributeFill[] | null {
    const fill = this.getFirstParentFill(parents)
    if (!fill || !fill.color) return null

    const brightness = this.getBrightness(fill.color)
    return brightness > 50 ? [createNewBlackFill()] : [createNewWhiteFill()]
  }

  private getFirstParentFill = (
    parents: ReadOnlyNode[]
  ): AttributeFill | null => {
    for (const parent of parents) {
      const fills = parent.getStyleAttribute('fills')
      if (!fills || fills.length === 0) continue
      return fills[0]
    }
    return null
  }

  private getBrightness = (color: Color): number => {
    const hsb = rgbaToHsba(color)
    return hsb.b
  }

  private adjustBrightness = (color: Color, delta: number): Color => {
    const hsb = rgbaToHsba(color)
    return hsbaToRgba({ ...hsb, b: Math.min(Math.max(hsb.b + delta, 0), 100) })
  }
}
