import { IconKey } from 'assets/iconLibrary'
import { useRef, useState } from 'react'
import { DesignColor } from 'themes'
import Icon from '../../Components/Icon/Icon/Icon'
import MenuDropdownDrawer from './MenuDropdownDrawer'

export type dropdownOption<T> = {
  value: T
  label: string
  iconKey?: IconKey
}

interface MenuDropdownProps<T> {
  options: dropdownOption<T>[]
  selected: T
  setSelected: (value: T) => void
}

export default function MenuDropdown<T>({
  options,
  selected,
  setSelected,
}: MenuDropdownProps<T>) {
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [hover, setHover] = useState(false)

  const borderColor = hover
    ? DesignColor('inputHighlight')
    : DesignColor('panelBorder')

  const selectedOption = options.find((option) => option.value === selected)

  return (
    <>
      <div
        ref={ref}
        style={{
          padding: '8px 12px',
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
          background: 'none',
          border: `1px solid ${borderColor}`,
          borderRadius: 8,
          color: DesignColor('text1'),
          fontSize: '12px',
          fontWeight: 'normal',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setOpen(!open)}
      >
        {selectedOption?.iconKey && (
          <Icon
            icon={selectedOption.iconKey}
            size={16}
            color={DesignColor('text1')}
          />
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {selectedOption?.label}
          <Icon
            icon={open ? 'DropdownArrowUp' : 'DropdownArrowDown'}
            size={10}
            color={DesignColor('text2')}
          />
        </div>
      </div>
      <MenuDropdownDrawer
        open={open}
        sourceRef={ref}
        options={options}
        close={() => setOpen(false)}
        setSelected={setSelected}
      />
    </>
  )
}
