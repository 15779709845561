import { StyleMap } from 'application/attributes'
import { ReadOnlyDocument } from 'application/document'
import { truncate } from 'application/math'
import { ReadOnlyNode } from 'application/node'

export function getAdjustedPositionModes(
  node: ReadOnlyNode
): Partial<StyleMap> {
  const topMode = node.getStyleAttribute('position.top.auto')
  const leftMode = node.getStyleAttribute('position.left.auto')
  const bottomMode = node.getStyleAttribute('position.bottom.auto')
  const rightMode = node.getStyleAttribute('position.right.auto')
  return {
    'position.top.auto': topMode,
    'position.left.auto': leftMode,
    'position.bottom.auto': bottomMode,
    'position.right.auto': rightMode,
  }
}

export function getAdjustedTopToNode(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  top: number
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentIsCanvas = parent.getBaseAttribute('type') === 'canvas'
  const parentTop = parent.getBaseAttribute('y')
  const parentHeight = parent.getBaseAttribute('h')
  const topMode = node.getStyleAttribute('position.top.auto')

  switch (topMode) {
    case 'fixed':
      if (parentIsCanvas) {
        update['position.top'] = truncate(top)
      } else {
        update['position.top'] = truncate(top - parentTop)
      }
      break
    case 'percent':
      if (parentHeight === 0 || parentIsCanvas) break
      update['position.top.percent'] = truncate(
        ((top - parentTop) / parentHeight) * 100,
        8
      )
      break
  }

  return update
}

export function getAdjustedTop(
  node: ReadOnlyNode,
  document: ReadOnlyDocument,
  top: number
): Partial<StyleMap> {
  const parent = getContainingParent(node, document)
  if (!parent) return {}

  return getAdjustedTopToNode(node, parent, top)
}

export function getFixedTop(
  node: ReadOnlyNode,
  document: ReadOnlyDocument
): Partial<StyleMap> {
  const parent = document.getParent(node)
  if (!parent) return {}

  const y = node.getBaseAttribute('y')
  const parentY = parent.getBaseAttribute('y')

  return {
    'position.top.auto': 'fixed',
    'position.top': truncate(y - parentY),
  }
}

export function getAdjustedLeftToNode(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  left: number
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentIsCanvas = parent.getBaseAttribute('type') === 'canvas'
  const parentLeft = parent.getBaseAttribute('x')
  const parentWidth = parent.getBaseAttribute('w')
  const leftMode = node.getStyleAttribute('position.left.auto')

  switch (leftMode) {
    case 'fixed':
      if (parentIsCanvas) {
        update['position.left'] = truncate(left)
      } else {
        update['position.left'] = truncate(left - parentLeft)
      }
      break
    case 'percent':
      if (parentWidth === 0) break
      update['position.left.percent'] = truncate(
        ((left - parentLeft) / parentWidth) * 100,
        8
      )
      break
  }

  return update
}

export function getAdjustedLeft(
  node: ReadOnlyNode,
  document: ReadOnlyDocument,
  left: number
): Partial<StyleMap> {
  const parent = getContainingParent(node, document)
  if (!parent) return {}

  return getAdjustedLeftToNode(node, parent, left)
}

export function getFixedLeft(
  node: ReadOnlyNode,
  document: ReadOnlyDocument
): Partial<StyleMap> {
  const parent = document.getParent(node)
  if (!parent) return {}

  const x = node.getBaseAttribute('x')
  const parentX = parent.getBaseAttribute('x')

  return {
    'position.left.auto': 'fixed',
    'position.left': truncate(x - parentX),
  }
}

export function getAdjustedBottomToNode(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  bottom: number
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentTop = parent.getBaseAttribute('y')
  const parentHeight = parent.getBaseAttribute('h')
  const bottomMode = node.getStyleAttribute('position.bottom.auto')

  switch (bottomMode) {
    case 'fixed':
      update['position.bottom'] = truncate(parentTop + parentHeight - bottom)
      break
    case 'percent':
      if (parentHeight === 0) break
      update['position.bottom.percent'] = truncate(
        100 - ((bottom - parentTop) / parentHeight) * 100,
        8
      )
      break
  }

  return update
}

export function getAdjustedBottom(
  node: ReadOnlyNode,
  document: ReadOnlyDocument,
  bottom: number
): Partial<StyleMap> {
  const parent = getContainingParent(node, document)
  if (!parent) return {}

  return getAdjustedBottomToNode(node, parent, bottom)
}

export function getAdjustedRightToNode(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  right: number
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentLeft = parent.getBaseAttribute('x')
  const parentWidth = parent.getBaseAttribute('w')
  const rightMode = node.getStyleAttribute('position.right.auto')

  switch (rightMode) {
    case 'fixed':
      update['position.right'] = truncate(parentLeft + parentWidth - right)
      break
    case 'percent':
      if (parentWidth === 0) break
      update['position.right.percent'] = truncate(
        100 - ((right - parentLeft) / parentWidth) * 100,
        8
      )
      break
  }

  return update
}

export function getAdjustedRight(
  node: ReadOnlyNode,
  document: ReadOnlyDocument,
  right: number
): Partial<StyleMap> {
  const parent = getContainingParent(node, document)
  if (!parent) return {}

  return getAdjustedRightToNode(node, parent, right)
}

export function getAdjustedWidthToNode(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  width: number,
  checkEqual: boolean = true
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentWidth = parent.getBaseAttribute('w')
  const widthMode = node.getStyleAttribute('size.w.auto')

  switch (widthMode) {
    case 'fixed':
      if (node.getStyleAttribute('size.w') === width && checkEqual) break
      update['size.w'] = truncate(width)
      break
    case 'percent':
      if (parentWidth === 0) break
      const currentPercent = node.getStyleAttribute('size.w.percent')
      const newPercent = truncate((width / parentWidth) * 100, 8)
      if (currentPercent !== newPercent || !checkEqual) {
        update['size.w.percent'] = newPercent
      }
      break
  }

  return update
}

export function getAdjustedWidth(
  node: ReadOnlyNode,
  document: ReadOnlyDocument,
  width: number,
  checkEqual: boolean = true
): Partial<StyleMap> {
  const parent = getContainingParent(node, document)
  if (!parent) return {}

  return getAdjustedWidthToNode(node, parent, width, checkEqual)
}

export function getAdjustedHeightToNode(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  height: number,
  checkEqual: boolean = true
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentHeight = parent.getBaseAttribute('h')
  const heightMode = node.getStyleAttribute('size.h.auto')

  switch (heightMode) {
    case 'fixed':
      if (node.getStyleAttribute('size.h') === height && checkEqual) break
      update['size.h'] = truncate(height)
      break
    case 'percent':
      if (parentHeight === 0) break
      const currentPercent = node.getStyleAttribute('size.h.percent')
      const newPercent = truncate((height / parentHeight) * 100, 8)
      if (currentPercent !== newPercent || !checkEqual) {
        update['size.h.percent'] = newPercent
      }
  }

  return update
}

export function getAdjustedHeight(
  node: ReadOnlyNode,
  document: ReadOnlyDocument,
  height: number,
  checkEqual: boolean = true
): Partial<StyleMap> {
  const parent = getContainingParent(node, document)
  if (!parent) return {}

  return getAdjustedHeightToNode(node, parent, height, checkEqual)
}

export function getAdjustedSizeModes(node: ReadOnlyNode): Partial<StyleMap> {
  const widthMode = node.getStyleAttribute('size.w.auto')
  const heightMode = node.getStyleAttribute('size.h.auto')
  return {
    'size.w.auto': widthMode,
    'size.h.auto': heightMode,
  }
}

export function getContainingParent(
  node: ReadOnlyNode,
  document: ReadOnlyDocument
): ReadOnlyNode | undefined {
  const positionMode = node.getStyleAttribute('position.mode')
  switch (positionMode) {
    case 'absolute':
    case 'auto':
    case 'sticky':
      return document.getParent(node)
    case 'fixed':
      const ancestors = document.getAncestors(node)
      return ancestors.find((a, i) => {
        const next = ancestors[i + 1]
        if (!next) return false
        return next.getBaseAttribute('type') === 'canvas'
      })
  }
}
