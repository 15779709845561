import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'
import { getLayoutDirection } from 'application/layout/utils'
import { ReadOnlyNode } from 'application/node'
import { NodeSizeState } from '../node/node'
import {
  computeCrossAxisFitContent,
  computeMaxContent,
  computeRatioLockedAxis,
  minMaxSize,
} from '../utils'

export class FlexCrossInitialSize {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap

  constructor(document: WriteDocument, sizeMap: NodeSizeStateMap) {
    this.document = document
    this.sizeMap = sizeMap
  }

  calculateCrossHypoSize = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size) return

    const children = node.getChildren() || []
    if (children.length === 0) return

    const direction = getLayoutDirection(node)
    const cross = direction === 'column' ? 'w' : 'h'

    this.setCrossHypoSize(node, size, children, cross)
  }

  private setCrossHypoSize = (
    node: ReadOnlyNode,
    nodeSize: NodeSizeState,
    children: string[],
    cross: 'w' | 'h'
  ): void => {
    const size = this.getCrossSize(node, nodeSize)

    for (const childId of children) {
      const child = this.document.getNode(childId)
      if (!child) continue

      const childSize = this.sizeMap.get(childId)
      if (!childSize) continue

      switch (cross) {
        case 'w':
          const wAuto = child.getStyleAttribute('size.w.auto')
          const wLockedValue = computeRatioLockedAxis(childSize, 'w')
          switch (wAuto) {
            case 'fixed':
              const w = child.getStyleAttribute('size.w')
              if (w === undefined) continue
              childSize.setHypoCrossSize(minMaxSize(w, childSize, 'w'))
              break
            case 'percent':
              if (wLockedValue !== undefined) {
                childSize.setHypoCrossSize(wLockedValue)
              } else {
                const percent = child.getStyleAttribute('size.w.percent') || 0
                childSize.setHypoCrossSize((size || 0) * (percent / 100))
              }
              break
            case 'fill':
              if (wLockedValue !== undefined) {
                childSize.setHypoCrossSize(wLockedValue)
              } else {
                childSize.setHypoCrossSize(size || 0)
              }
              break
            case 'auto':
              if (wLockedValue !== undefined) {
                childSize.setHypoCrossSize(wLockedValue)
              } else if (size !== undefined) {
                const fitContent = computeCrossAxisFitContent(
                  childId,
                  this.sizeMap,
                  this.document,
                  'w',
                  size
                )
                childSize.setHypoCrossSize(fitContent)
              } else {
                const maxContent = computeMaxContent(
                  childId,
                  this.sizeMap,
                  this.document,
                  'w'
                )
                childSize.setHypoCrossSize(maxContent)
              }
              break
          }
          break
        case 'h':
          const hAuto = child.getStyleAttribute('size.h.auto')
          const hLockedValue = computeRatioLockedAxis(childSize, 'h')
          switch (hAuto) {
            case 'fixed':
              const h = child.getStyleAttribute('size.h')
              if (h === undefined) continue
              childSize.setHypoCrossSize(minMaxSize(h, childSize, 'h'))
              break
            case 'percent':
              if (hLockedValue !== undefined) {
                childSize.setHypoCrossSize(hLockedValue)
              } else {
                const percent = child.getStyleAttribute('size.h.percent') || 0
                childSize.setHypoCrossSize((size || 0) * (percent / 100))
              }
              break
            case 'fill':
              if (hLockedValue !== undefined) {
                childSize.setHypoCrossSize(hLockedValue)
              } else {
                childSize.setHypoCrossSize(size || 0)
              }
              break
            case 'auto':
              if (hLockedValue !== undefined) {
                childSize.setHypoCrossSize(hLockedValue)
              } else if (size !== undefined) {
                const fitContent = computeCrossAxisFitContent(
                  childId,
                  this.sizeMap,
                  this.document,
                  'h',
                  size
                )
                childSize.setHypoCrossSize(fitContent)
              } else {
                const maxContent = computeMaxContent(
                  childId,
                  this.sizeMap,
                  this.document,
                  'h'
                )
                childSize.setHypoCrossSize(maxContent)
              }
              break
          }
          break
      }
    }
  }

  private getCrossSize = (
    node: ReadOnlyNode,
    size: NodeSizeState
  ): number | undefined => {
    const direction = getLayoutDirection(node)
    switch (direction) {
      case 'column':
        return size.getInnerW()
      case 'row':
      case 'wrap':
        return size.getInnerH()
    }
  }
}
