import { ReadOnlyNode } from 'application/node'
import { Context } from 'application/render'
import {
  createEllipseBorder,
  createEllipseFill,
  createEllipseShadows,
  createRectangleBlur,
  createRectangleBorder,
  createRectangleFill,
  createRectangleImage,
  createRectangleShadows,
  createRenderBounds,
  createTextFill,
} from './utils'
import { NodeRenderable } from 'editor/canvas/nodeRenderStore'
import { FontLoaderInterface } from 'application/text'
import { isDisplayNone } from 'application/attributes'

export class DocumentRenderTransformer {
  static transform(
    context: Context,
    node: ReadOnlyNode,
    parent: ReadOnlyNode | undefined,
    fontLoader: FontLoaderInterface
  ): NodeRenderable | null {
    const hidden = isDisplayNone(node)
    if (hidden) {
      return {
        key: node.getId(),
        children: [],
        renderables: { before: [], after: [] },
      }
    }

    const type = node.getBaseAttribute('type')
    switch (type) {
      case 'root':
      case 'canvas':
        return this.transformRootNode(node)
      case 'frame':
      case 'rectangle':
      case 'page':
        return this.transfromRectangleNode(context, node, parent)
      case 'ellipse':
        return this.transformEllipseNode(context, node, parent)
      case 'text':
        return this.transformTextNode(context, node, parent, fontLoader)
      case 'image':
        return this.transformImageNode(context, node, parent)
    }
  }

  private static transformRootNode(node: ReadOnlyNode): NodeRenderable {
    const key = node.getId()
    const children = node.getChildren()

    return {
      key: key,
      children: children || [],
      renderables: { before: [], after: [] },
    }
  }

  private static transfromRectangleNode(
    context: Context,
    node: ReadOnlyNode,
    parent: ReadOnlyNode | undefined
  ): NodeRenderable {
    const key = node.getId()
    const children = node.getChildren() || []
    const opacity = node.getStyleAttribute('opacity')
    const renderBounds = createRenderBounds(node)
    const fills = createRectangleFill(context, node, parent)
    const border = createRectangleBorder(context, node, parent)
    const innerShadows = createRectangleShadows(context, node, parent, 'inner')
    const outerShadows = createRectangleShadows(context, node, parent, 'outer')
    const blur = createRectangleBlur(context, node, parent)

    return {
      key: key,
      children: children,
      opacity: opacity,
      renderBounds: renderBounds,
      renderables: {
        before: [...blur, ...outerShadows, ...fills],
        after: [...innerShadows, ...border],
      },
    }
  }

  private static transformEllipseNode(
    context: Context,
    node: ReadOnlyNode,
    parent: ReadOnlyNode | undefined
  ): NodeRenderable {
    const key = node.getId()
    const children = node.getChildren()
    const renderBounds = createRenderBounds(node)
    const opacity = node.getStyleAttribute('opacity')
    const fills = createEllipseFill(context, node, parent)
    const border = createEllipseBorder(context, node, parent)
    const innerShadows = createEllipseShadows(context, node, parent, 'inner')
    const outerShadows = createEllipseShadows(context, node, parent, 'outer')

    return {
      key: key,
      children: children || [],
      opacity: opacity,
      renderBounds: renderBounds,
      renderables: {
        before: [...outerShadows, ...fills],
        after: [...innerShadows, ...border],
      },
    }
  }

  private static transformTextNode(
    context: Context,
    node: ReadOnlyNode,
    parent: ReadOnlyNode | undefined,
    fontLoader: FontLoaderInterface
  ): NodeRenderable {
    const key = node.getId()
    const fill = createTextFill(context, node, parent, fontLoader)
    const renderBounds = createRenderBounds(node)

    return {
      key: key,
      children: [],
      opacity: node.getStyleAttribute('opacity'),
      renderBounds: renderBounds,
      renderables: {
        before: [fill],
        after: [],
      },
    }
  }

  private static transformImageNode(
    context: Context,
    node: ReadOnlyNode,
    parent: ReadOnlyNode | undefined
  ): NodeRenderable {
    const key = node.getId()
    const children = node.getChildren() || []
    const opacity = node.getStyleAttribute('opacity')
    const renderBounds = createRenderBounds(node)
    const fills = createRectangleImage(context, node, parent)
    const border = createRectangleBorder(context, node, parent)
    const innerShadows = createRectangleShadows(context, node, parent, 'inner')
    const outerShadows = createRectangleShadows(context, node, parent, 'outer')

    return {
      key: key,
      children: children,
      opacity: opacity,
      renderBounds: renderBounds,
      renderables: {
        before: [...outerShadows, ...fills],
        after: [...innerShadows, ...border],
      },
    }
  }
}
