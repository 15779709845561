import { WriteDocument } from 'application/document'
import { PasteSnapshot } from '../../types'
import { Point, Rectangle, encapsulates } from 'application/shapes'
import { Node } from 'application/node'
import { PastePositionHandler } from '../paste'
import { moveTree, setAttributes } from './utils'
import {
  getAdjustedBottomToNode,
  getAdjustedLeftToNode,
  getAdjustedRightToNode,
  getAdjustedTopToNode,
} from 'application/units'

export class PasteAtOriginalOffset implements PastePositionHandler {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  paste = (snapshot: PasteSnapshot): void => {
    for (const id of snapshot.ids) {
      const offset = snapshot.offsets[id]

      const node = snapshot.nodes[id]
      if (!node) continue

      const parent = this.document.getParent(node)
      if (!parent || parent.getBaseAttribute('type') === 'canvas') continue

      const siblings = parent.getChildren()
      if (!siblings) continue

      const parentRect = this.getRectangle(parent)
      const childRect = this.getChildRectangle(offset, node, parentRect)
      const insideParent = encapsulates(parentRect, childRect)
      const shouldCenter = !insideParent && !snapshot.outsideParent[id]

      if (snapshot.canvasChild[id] || shouldCenter) {
        const centered = this.centerChild(childRect, parentRect)
        const xDelta = node.getBaseAttribute('x') - centered.x
        const yDelta = node.getBaseAttribute('y') - centered.y

        const x = centered.x
        const y = centered.y
        const w = node.getBaseAttribute('w')
        const h = node.getBaseAttribute('h')

        const adjustedTop = getAdjustedTopToNode(node, parent, y)
        const adjustedLeft = getAdjustedLeftToNode(node, parent, x)
        const adjustedRight = getAdjustedRightToNode(node, parent, x + w)
        const adjustedBottom = getAdjustedBottomToNode(node, parent, y + h)

        setAttributes(node, {
          ...adjustedTop,
          ...adjustedLeft,
          ...adjustedRight,
          ...adjustedBottom,
        })

        moveTree(node, snapshot, xDelta, yDelta)
      } else {
        const xDelta = node.getBaseAttribute('x') - childRect.x
        const yDelta = node.getBaseAttribute('y') - childRect.y

        const x = childRect.x
        const y = childRect.y
        const w = node.getBaseAttribute('w')
        const h = node.getBaseAttribute('h')

        const adjustedTop = getAdjustedTopToNode(node, parent, y)
        const adjustedLeft = getAdjustedLeftToNode(node, parent, x)
        const adjustedRight = getAdjustedRightToNode(node, parent, x + w)
        const adjustedBottom = getAdjustedBottomToNode(node, parent, y + h)

        setAttributes(node, {
          ...adjustedTop,
          ...adjustedLeft,
          ...adjustedRight,
          ...adjustedBottom,
        })

        moveTree(node, snapshot, xDelta, yDelta)
      }
    }
  }

  private getRectangle = (parent: Node): Rectangle => {
    return {
      x: parent.getBaseAttribute('x'),
      y: parent.getBaseAttribute('y'),
      w: parent.getBaseAttribute('w'),
      h: parent.getBaseAttribute('h'),
    }
  }

  private getChildRectangle = (
    offset: Point,
    node: Node,
    parentRectangle: Rectangle
  ): Rectangle => {
    return {
      x: parentRectangle.x + offset.x,
      y: parentRectangle.y + offset.y,
      w: node.getBaseAttribute('w'),
      h: node.getBaseAttribute('h'),
    }
  }

  private centerChild = (child: Rectangle, parent: Rectangle): Point => {
    return {
      x: parent.x + (parent.w - child.w) / 2,
      y: parent.y + (parent.h - child.h) / 2,
    }
  }
}
