export class NodeSizeState {
  private id: string

  private isDirty: boolean = false

  private isReplaced: boolean | undefined
  private minContentW: number | undefined
  private minContentH: number | undefined
  private maxContentW: number | undefined
  private maxContentH: number | undefined

  private minW: number | undefined
  private minH: number | undefined

  private maxW: number | undefined
  private maxH: number | undefined

  private w: number | undefined
  private h: number | undefined
  private wDeterminesRatio: boolean = false
  private hDeterminesRatio: boolean = false
  private wFlexSet: boolean = false
  private hFlexSet: boolean = false

  private innerW: number | undefined
  private innerH: number | undefined

  private paddingTop: number | undefined
  private paddingLeft: number | undefined
  private paddingBottom: number | undefined
  private paddingRight: number | undefined

  private flexBaseSize: number | undefined
  private hypoMainSize: number | undefined
  private hypoCrossSize: number | undefined

  private ratio: number | undefined

  constructor(id: string) {
    this.id = id
  }

  getId = (): string => {
    return this.id
  }

  getIsDirty = (): boolean => {
    return this.isDirty
  }

  getIsReplaced = (): boolean | undefined => {
    return this.isReplaced
  }

  getMinContentW = (): number => {
    return (
      (this.minContentW || 0) +
      (this.paddingLeft || 0) +
      (this.paddingRight || 0)
    )
  }

  getMinContentH = (): number => {
    return (
      (this.minContentH || 0) +
      (this.paddingTop || 0) +
      (this.paddingBottom || 0)
    )
  }

  getMaxContentW = (): number => {
    const maxContentW =
      (this.maxContentW || 0) +
      (this.paddingLeft || 0) +
      (this.paddingRight || 0)
    if (
      this.hDeterminesRatio &&
      !this.wDeterminesRatio &&
      this.ratio !== undefined &&
      this.w !== undefined
    ) {
      return Math.max(maxContentW, this.w)
    }
    return maxContentW
  }

  getMaxContentH = (): number => {
    const maxContentH =
      (this.maxContentH || 0) +
      (this.paddingTop || 0) +
      (this.paddingBottom || 0)
    if (
      this.wDeterminesRatio &&
      !this.hDeterminesRatio &&
      this.ratio !== undefined &&
      this.h !== undefined
    ) {
      return Math.max(maxContentH, this.h)
    }
    return maxContentH
  }

  getMinW = (): number => {
    return this.minW || 0
  }

  getMinH = (): number => {
    return this.minH || 0
  }

  getMaxW = (): number => {
    return this.maxW || Infinity
  }

  getMaxH = (): number => {
    return this.maxH || Infinity
  }

  getW = (): number | undefined => {
    return this.w
  }

  getH = (): number | undefined => {
    return this.h
  }

  getWDeterminesRatio = (): boolean => {
    return this.wDeterminesRatio
  }

  getWFlexSet = (): boolean => {
    return this.wFlexSet
  }

  getHDeterminesRatio = (): boolean => {
    return this.hDeterminesRatio
  }

  getHFlexSet = (): boolean => {
    return this.hFlexSet
  }

  getInnerW = (): number | undefined => {
    return this.innerW
  }

  getInnerH = (): number | undefined => {
    return this.innerH
  }

  getPaddingVertical = (): number => {
    return (this.paddingTop || 0) + (this.paddingBottom || 0)
  }

  getPaddingHorizontal = (): number => {
    return (this.paddingLeft || 0) + (this.paddingRight || 0)
  }

  getFlexBaseSize = (): number | undefined => {
    return this.flexBaseSize
  }

  getHypoMainSize = (): number | undefined => {
    return this.hypoMainSize
  }

  getHypoCrossSize = (): number | undefined => {
    return this.hypoCrossSize
  }

  getRatio = (): number | undefined => {
    return this.ratio
  }

  setIsDirty = (isDirty: boolean): void => {
    this.isDirty = isDirty
  }

  setIsReplaced = (isReplaced: boolean): void => {
    this.isReplaced = isReplaced
  }

  setWInitial = (w: number): void => {
    this.w = w
  }

  setHInitial = (h: number): void => {
    this.h = h
  }

  setMinContentW = (w: number): void => {
    this.minContentW = w
    this.applySizeRules()
  }

  setMinContentH = (h: number): void => {
    this.minContentH = h
    this.applySizeRules()
  }

  setMaxContentW = (w: number): void => {
    this.maxContentW = w
  }

  setMaxContentH = (h: number): void => {
    this.maxContentH = h
  }

  setMinW = (minW: number): void => {
    this.minW = minW
    this.applySizeRules()
  }

  setMinH = (minH: number): void => {
    this.minH = minH
    this.applySizeRules()
  }

  setMaxW = (maxW: number): void => {
    this.maxW = maxW
    this.applySizeRules()
  }

  setMaxH = (maxH: number): void => {
    this.maxH = maxH
    this.applySizeRules()
  }

  setW = (w: number, flex: boolean = false): void => {
    this.setWInternal(w, false, flex)
    this.applySizeRules()
  }

  setH = (h: number, flex: boolean = false): void => {
    this.setHInternal(h, false, flex)
    this.applySizeRules()
  }

  setInnerW = (innerW: number): void => {
    this.setWInternal(
      innerW + (this.paddingLeft || 0) + (this.paddingRight || 0),
      false,
      false
    )
    this.applySizeRules()
  }

  setInnerH = (innerH: number): void => {
    this.setHInternal(
      innerH + (this.paddingTop || 0) + (this.paddingBottom || 0),
      false,
      false
    )
    this.applySizeRules()
  }

  setPaddingTop = (paddingTop: number): void => {
    this.paddingTop = paddingTop
  }

  setPaddingLeft = (paddingLeft: number): void => {
    this.paddingLeft = paddingLeft
  }

  setPaddingBottom = (paddingBottom: number): void => {
    this.paddingBottom = paddingBottom
  }

  setPaddingRight = (paddingRight: number): void => {
    this.paddingRight = paddingRight
  }

  setFlexBaseSize = (flexBaseSize: number): void => {
    this.flexBaseSize = flexBaseSize
  }

  setHypoMainSize = (hypoMainSize: number): void => {
    this.hypoMainSize = hypoMainSize
  }

  setHypoCrossSize = (hypoCrossSize: number): void => {
    this.hypoCrossSize = hypoCrossSize
  }

  setRatio = (ratio: number): void => {
    this.ratio = ratio
    this.applySizeRules()
  }

  setWDeterminesRatio = (wDeterminesRatio: boolean): void => {
    this.wDeterminesRatio = wDeterminesRatio
  }

  setHDeterminesRatio = (hDeterminesRatio: boolean): void => {
    this.hDeterminesRatio = hDeterminesRatio
  }

  private setWInternal = (w: number, ratio: boolean, flex: boolean): void => {
    if (this.minContentW !== undefined && this.isReplaced && ratio) {
      this.minContentW = w
    }
    if (this.maxContentW !== undefined && ratio) {
      this.maxContentW = w
    }
    this.isDirty = this.isDirty || this.w !== w
    this.w = w
    this.wFlexSet = this.wFlexSet || flex
  }

  private setHInternal = (h: number, ratio: boolean, flex: boolean): void => {
    if (this.minContentH !== undefined && this.isReplaced && ratio) {
      this.minContentH = h
    }
    if (this.maxContentH !== undefined && ratio) {
      this.maxContentH = h
    }
    this.isDirty = this.isDirty || this.h !== h
    this.h = h
    this.hFlexSet = this.hFlexSet || flex
  }

  private applySizeRules = (): void => {
    this.applyMinMax()
    this.applyRatio()
    this.applyMinMax()
    this.updateInner()
  }

  private applyRatio = (): void => {
    if (this.ratio === undefined || this.ratio === 0) return

    if (
      this.wDeterminesRatio &&
      !this.hDeterminesRatio &&
      this.w !== undefined
    ) {
      const newH = this.w / this.ratio
      this.setHInternal(newH, true, false)
    }

    if (
      this.hDeterminesRatio &&
      !this.wDeterminesRatio &&
      this.h !== undefined
    ) {
      const newW = this.h * this.ratio
      this.setWInternal(newW, true, false)
    }
  }

  private applyMinMax = (): void => {
    if (this.w !== undefined) {
      if (this.minW !== undefined) this.w = Math.max(this.w, this.minW)
      if (this.maxW !== undefined) this.w = Math.min(this.w, this.maxW)
    }
    if (this.h !== undefined) {
      if (this.minH !== undefined) this.h = Math.max(this.h, this.minH)
      if (this.maxH !== undefined) this.h = Math.min(this.h, this.maxH)
    }
  }

  private updateInner = (): void => {
    if (this.w !== undefined) {
      this.innerW = this.w - (this.paddingLeft || 0) - (this.paddingRight || 0)
    }

    if (this.h !== undefined) {
      this.innerH = this.h - (this.paddingTop || 0) - (this.paddingBottom || 0)
    }
  }
}
