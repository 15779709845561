import { DesignColor } from 'themes'
import { IconKey } from 'assets/iconLibrary'
import { useState } from 'react'
import Icon from '../../Icon/Icon/Icon'
import useAction from 'hooks/editor/useAction'

interface TitleIconBlockProps {
  icon: IconKey

  active?: boolean
  panelActive?: boolean
  hasOverride?: boolean
  disabled?: boolean

  canDeactivate?: boolean

  onClick?: () => void
}

export default function TitleIconBlock({
  icon,
  active = false,
  panelActive = false,
  hasOverride = false,
  disabled = false,
  canDeactivate = false,
  onClick,
}: TitleIconBlockProps) {
  const action = useAction()
  const [hover, setHover] = useState(false)

  const boxShadow = getBoxShadow(hover && !action, disabled)
  const background = getBackground(active, disabled)
  const iconColor = getIconColor(
    hover && !action,
    active,
    panelActive,
    hasOverride,
    disabled
  )

  return (
    <div
      style={{
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        boxShadow,
        background,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <div
        style={{
          width: 16,
          height: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: `rotate(${canDeactivate ? 45 : 0}deg)`,
        }}
      >
        <Icon icon={icon} size={16} color={iconColor} />
      </div>
    </div>
  )
}

function getIconColor(
  hover: boolean,
  active: boolean,
  panelActive: boolean,
  overridden: boolean,
  disabled: boolean
) {
  if (disabled) return DesignColor('text1')
  if (hover) return DesignColor('inputHighlight')
  if (overridden) return DesignColor('overrideParent')
  if (active || panelActive) return DesignColor('text1')
  return DesignColor('text2')
}

function getBackground(active: boolean, disabled: boolean) {
  if (disabled) return 'none'
  if (active) return DesignColor('inputTint')
  return 'none'
}

function getBoxShadow(hover: boolean, disabled: boolean) {
  if (disabled) return 'none'
  if (hover) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}
