import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import TextBlock from '../../Text/TextBlock/TextBlock'
import IconBlock from '../../IconBlock/IconBlock/IconBlock'
import { useEffect, useRef, useState } from 'react'
import useClosePopup from 'hooks/ui/useClosePopup'
import { panelPosition } from 'components/Library/Containers/constants'
import ButtonBlock from '../../Button/ButtonBlock/ButtonBlock'
import useUploadImage, { UploadedImage } from 'hooks/editor/useUploadImage'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import ImageDisplay from '../ImageDisplay/ImageDisplay'
import { Size } from 'application/shapes'
import useActionInitiator from 'hooks/editor/useActionInitiator'

interface ImagePopupProps {
  src: string
  setSrc: (src: string, size: Size) => void

  close: () => void

  buttonRef: React.RefObject<HTMLDivElement>
}

export default function ImagePopup({
  src,
  setSrc,
  close,
  buttonRef,
}: ImagePopupProps) {
  const inputFile = useRef<HTMLInputElement>(null)
  const uploadImage = useUploadImage()
  const { openLibrary } = useActionInitiator()
  const popupRef = useRef<HTMLDivElement>(null)

  useClosePopup({
    ref: popupRef,
    close: () => {
      close()
    },
    exceptionRef: buttonRef,
    exceptionIds: ['graphic-popup'],
  })

  const [position, setPosition] = useState<panelPosition>({ top: 0, right: 0 })
  useEffect(() => {
    setPosition(getPosition(buttonRef))
  }, [buttonRef])
  if (buttonRef.current === null) return <></>

  const handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    uploadImage(
      async (uploadedImage: UploadedImage) =>
        setSrc(uploadedImage.url, {
          w: uploadedImage.size.w,
          h: uploadedImage.size.h,
        }),
      e,
      undefined
    )
  }

  const handleDropEvent = (e: React.DragEvent<HTMLDivElement>) => {
    uploadImage(
      async (uploadedImage: UploadedImage) =>
        setSrc(uploadedImage.url, {
          w: uploadedImage.size.w,
          h: uploadedImage.size.h,
        }),
      undefined,
      e
    )
  }

  return (
    <Portal>
      <PanelContainer panelRef={popupRef} position={position}>
        <PanelSection>
          <PanelRow>
            <TextBlock width={192} mode="title">
              Image
            </TextBlock>
            <IconBlock icon="X" onClick={close} />
          </PanelRow>
        </PanelSection>
        <PanelSection>
          <PanelRow>
            <input
              type="file"
              accept=".png,.webp,.jpg,.jpeg,.gif,.svg,.avif"
              ref={inputFile}
              onChange={handleChangeEvent}
              hidden
            />
            <ButtonBlock
              icon="Upload"
              text="Upload"
              onClick={() => inputFile.current?.click()}
            />
            <ButtonBlock
              width={120}
              icon="Image"
              text="Image Library"
              onClick={() => openLibrary('openImageLibrary')}
            />
          </PanelRow>
        </PanelSection>
        <ImageDisplay src={src} onDrop={handleDropEvent} />
      </PanelContainer>
    </Portal>
  )
}

function getPosition(panelRef: React.RefObject<HTMLDivElement>): panelPosition {
  if (!panelRef.current) return { top: 0, left: 0 }
  const rect = panelRef.current.getBoundingClientRect()
  if (rect.top + 328 + 64 > window.innerHeight) {
    return {
      position: 'absolute',
      bottom: 64,
      right: 248,
    }
  } else {
    return {
      position: 'absolute',
      top: rect.top - 8,
      right: 248,
    }
  }
}
