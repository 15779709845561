import ReactDOM from 'react-dom/client'
import App from './App'
import { EditorFactory } from 'editor'
import { Toaster } from 'react-hot-toast'

const editorFactory = new EditorFactory()
export let editor = editorFactory.create()
export function recreateEditor() {
  editor = editorFactory.create()
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    <Toaster position="bottom-center" />
    <App />
  </>
)
