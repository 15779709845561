import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import RepaintButton from './RepaintButton/RepaintButton'
import ToolbarDivider from 'components/Library/Components/Toolbar/ToolbarDivider/ToolbarDivider'
import ProjectNameInput from './ProjectNameInput/ProjectNameInput'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function LeftTopbar() {
  const preview = usePanelHelper(editor.getUI().getPreviewPanel())

  return (
    <PanelContainer
      position={{
        position: 'absolute',
        top: 8,
        left: 8,
      }}
      width={232}
      height={48}
      paddingH={10}
      direction="row"
      zIndex={preview.settings?.open ? 3 : 1}
    >
      <RepaintButton />
      <ToolbarDivider />
      <ProjectNameInput />
    </PanelContainer>
  )
}
