import { WriteDocument } from 'application/document'
import { PasteTargetHandler } from '../paste'
import { DocumentSelection } from 'application/selection'
import { CopySnapshot } from '../../types'
import { PasteOnOriginal } from './original'
import { PasteOnNode } from './node'
import { ReadOnlyNode } from 'application/node'
import { PasteOnCanvas } from './canvas'
import { PasteAtNodeIndex } from './nodeIndex'
import { canTypeInsertInto } from 'application/attributes'

export class PasteTargetHandlerFactory {
  private document: WriteDocument
  private documentSelection: DocumentSelection

  private pasteInOriginal: PasteOnOriginal
  private pasteOnCanvas: PasteOnCanvas

  constructor(document: WriteDocument, documentSelection: DocumentSelection) {
    this.document = document
    this.documentSelection = documentSelection

    this.pasteInOriginal = new PasteOnOriginal(this.document)
    this.pasteOnCanvas = new PasteOnCanvas(
      this.document,
      this.documentSelection
    )
  }

  createPaste = (copySnapshot: CopySnapshot): PasteTargetHandler[] => {
    const selected = this.documentSelection.getSelected()
    const ids = selected.map((n) => n.getId())

    const filteredIds = ids
      .filter((id) => this.filterNotInCopySnapshot(id, copySnapshot))
      .filter((id) => this.filterLegalContainer(id, copySnapshot))

    if (filteredIds.length === 0) {
      if (this.copiedFromOtherCanvas(copySnapshot)) {
        return [this.pasteOnCanvas]
      } else if (this.isOriginalParentValid(copySnapshot)) {
        return [this.pasteInOriginal]
      } else {
        return [this.pasteOnCanvas]
      }
    }

    return filteredIds.map((id) => new PasteOnNode(id, this.document))
  }

  createOutsidePaste = (): PasteTargetHandler[] => {
    const selected = this.documentSelection.getSelected()
    const ids = selected.map((n) => n.getId())
    if (ids.length === 0) return [this.pasteOnCanvas]

    const pages = this.getPageContainers(ids)
    if (pages.length === 0) return [this.pasteOnCanvas]

    return pages.map((c) => new PasteOnNode(c.getId(), this.document))
  }

  createReplace = (node: ReadOnlyNode): PasteTargetHandler => {
    const parent = this.document.getParent(node)
    if (!parent) return this.pasteInOriginal

    const children = parent.getChildren()
    if (!children) return this.pasteInOriginal

    const index = children.indexOf(node.getId())

    return new PasteAtNodeIndex(parent.getId(), index, this.document)
  }

  private filterNotInCopySnapshot = (
    id: string,
    copySnapshot: CopySnapshot
  ): boolean => {
    return !copySnapshot.ids.includes(id)
  }

  private filterLegalContainer = (
    id: string,
    copySnapshot: CopySnapshot
  ): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    return copySnapshot.ids.every((copyId) => {
      const copiedNode = copySnapshot.nodes[copyId]
      if (!copiedNode) return false

      return canTypeInsertInto(
        copiedNode.getBaseAttribute('type'),
        node.getBaseAttribute('type')
      )
    })
  }

  private getPageContainers = (ids: string[]): ReadOnlyNode[] => {
    const pageIds = new Set<string>()

    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue

      if (node.getBaseAttribute('type') === 'page') {
        pageIds.add(node.getId())
        continue
      }

      const ancestors = this.document.getAncestors(node)
      for (const ancestor of ancestors) {
        if (ancestor.getBaseAttribute('type') === 'page')
          pageIds.add(ancestor.getId())
      }
    }

    return Array.from(pageIds)
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
  }

  private copiedFromOtherCanvas = (copySnapshot: CopySnapshot): boolean => {
    return copySnapshot.canvasId !== this.documentSelection.getSelectedCanvas()
  }

  private isOriginalParentValid = (copySnapshot: CopySnapshot): boolean => {
    const nodes = copySnapshot.ids.map((id) => this.document.getNode(id))
    for (const node of nodes) {
      if (!node) return false

      const parentId = node.getParent()
      if (!parentId) return false

      const parent = this.document.getNode(parentId)
      if (!parent) return false
    }
    return true
  }
}
