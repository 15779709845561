import { WriteDocument } from 'application/document'
import {
  Violation,
  ViolationDynamicPositionOnCanvas,
  ViolationRemediator,
} from '../types'

export class PositionViolationRemediator implements ViolationRemediator {
  canRemediate(type: Violation['type']): boolean {
    switch (type) {
      case 'dynamic_position_on_canvas':
        return true
      default:
        return false
    }
  }

  remediate(violation: Violation, document: WriteDocument): void {
    switch (violation.type) {
      case 'dynamic_position_on_canvas':
        this.remediateDynamicPositionOnCanvas(violation, document)
        break
    }
  }

  private remediateDynamicPositionOnCanvas = (
    violation: ViolationDynamicPositionOnCanvas,
    document: WriteDocument
  ): void => {
    const node = document.getNode(violation.metadata.nodeId)
    if (node === undefined) return

    const y = node.getBaseAttribute('y')
    const x = node.getBaseAttribute('x')

    node.setStyleAttribute('position.mode', 'absolute')

    node.setStyleAttribute('position.top.auto', 'fixed')
    node.setStyleAttribute('position.top', y)
    node.setStyleAttribute('position.top.percent', undefined)

    node.setStyleAttribute('position.left.auto', 'fixed')
    node.setStyleAttribute('position.left', x)
    node.setStyleAttribute('position.left.percent', undefined)

    node.setStyleAttribute('position.bottom.auto', 'auto')
    node.setStyleAttribute('position.bottom', undefined)
    node.setStyleAttribute('position.bottom.percent', undefined)

    node.setStyleAttribute('position.right.auto', 'auto')
    node.setStyleAttribute('position.right', undefined)
    node.setStyleAttribute('position.right.percent', undefined)
  }
}
