import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'
import { getLayoutDirection } from 'application/layout/utils'
import { ReadOnlyNode } from 'application/node'
import { NodeSizeState } from '../node/node'
import { divideChildren, filterInLayoutChildren, minMaxSize } from '../utils'

export class FlexCrossSize {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap

  constructor(document: WriteDocument, sizeMap: NodeSizeStateMap) {
    this.document = document
    this.sizeMap = sizeMap
  }

  calculateCrossSize = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size) return

    const children = node.getChildren() || []
    if (children.length === 0) return

    const direction = getLayoutDirection(node)
    const cross = direction === 'column' ? 'w' : 'h'

    this.setCrossSize(node, size, children, cross)
  }

  private setCrossSize = (
    node: ReadOnlyNode,
    nodeSize: NodeSizeState,
    children: string[],
    cross: 'w' | 'h'
  ): void => {
    for (const childId of children) {
      const size = this.getCrossSize(childId, node, nodeSize)

      const child = this.document.getNode(childId)
      if (!child) continue

      const parent = this.document.getParent(child)
      if (!parent) continue

      const childSize = this.sizeMap.get(childId)
      if (!childSize) continue

      const align = child.getStyleAttribute('flex.alignSelf')

      const hypo = childSize.getHypoCrossSize()
      if (hypo === undefined) continue

      switch (cross) {
        case 'w':
          const wAuto = child.getStyleAttribute('size.w.auto')
          switch (wAuto) {
            case 'auto':
              if (size !== undefined && align === 'stretch') {
                childSize.setWDeterminesRatio(true)
                childSize.setW(size, true)
              } else {
                childSize.setW(hypo, true)
              }
              break
            case 'fixed':
            case 'percent':
            case 'fill':
              childSize.setW(hypo, true)
              break
          }
          break
        case 'h':
          const hAuto = child.getStyleAttribute('size.h.auto')
          switch (hAuto) {
            case 'auto':
              if (size !== undefined && align === 'stretch') {
                childSize.setHDeterminesRatio(true)
                childSize.setH(size, true)
              } else {
                childSize.setH(hypo, true)
              }
              break
            case 'fixed':
            case 'percent':
            case 'fill':
              childSize.setH(hypo, true)
              break
          }
          break
      }
    }
  }

  private getCrossSize = (
    childId: string,
    node: ReadOnlyNode,
    size: NodeSizeState
  ): number | undefined => {
    const direction = getLayoutDirection(node)
    switch (direction) {
      case 'column':
        return this.getCrossSizeColumn(node, size)
      case 'row':
        return this.getCrossSizeRow(node, size)
      case 'wrap':
        return this.getCrossSizeWrap(childId, node, size)
    }
  }

  private getCrossSizeColumn = (
    node: ReadOnlyNode,
    size: NodeSizeState
  ): number | undefined => {
    const innerW = size.getInnerW()
    if (innerW !== undefined) return innerW

    const children = filterInLayoutChildren(
      node.getChildren() || [],
      this.document
    )
    if (children.length === 0) return 0

    return children.reduce((acc, child) => {
      const childSize = this.sizeMap.get(child)
      if (!childSize) return acc
      const minMaxed = minMaxSize(
        childSize.getHypoCrossSize() || 0,
        childSize,
        'w'
      )
      return Math.max(acc, minMaxed)
    }, 0)
  }

  private getCrossSizeRow = (
    node: ReadOnlyNode,
    size: NodeSizeState
  ): number | undefined => {
    const innerH = size.getInnerH()
    if (innerH !== undefined) return innerH

    const children = filterInLayoutChildren(
      node.getChildren() || [],
      this.document
    )
    if (children.length === 0) return 0

    return children.reduce((acc, child) => {
      const childSize = this.sizeMap.get(child)
      if (!childSize) return acc
      const minMaxed = minMaxSize(
        childSize.getHypoCrossSize() || 0,
        childSize,
        'h'
      )
      return Math.max(acc, minMaxed)
    }, 0)
  }

  private getCrossSizeWrap = (
    childId: string,
    node: ReadOnlyNode,
    size: NodeSizeState
  ): number | undefined => {
    const w = size.getInnerW()
    if (w === undefined) return undefined

    const children = filterInLayoutChildren(
      node.getChildren() || [],
      this.document
    )
    if (children.length === 0) return 0

    const dividedChildren = divideChildren(node, this.document, this.sizeMap, w)
    const subset = dividedChildren.find((subset) => subset.includes(childId))
    if (!subset) return 0

    return subset.reduce((acc, child) => {
      const childSize = this.sizeMap.get(child)
      if (!childSize) return acc
      const minMaxed = minMaxSize(
        childSize.getHypoCrossSize() || 0,
        childSize,
        'h'
      )
      return Math.max(acc, minMaxed)
    }, 0)
  }
}
