import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'
import { getPadding } from 'application/layout/utils'

export class ContentSizeInitializer {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap

  constructor(document: WriteDocument, sizeMap: NodeSizeStateMap) {
    this.document = document
    this.sizeMap = sizeMap
  }

  initialize = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size) return

    const topPadding = getPadding(node, 'top')
    if (topPadding) size.setPaddingTop(topPadding)

    const bottomPadding = getPadding(node, 'bottom')
    if (bottomPadding) size.setPaddingBottom(bottomPadding)

    const leftPadding = getPadding(node, 'left')
    if (leftPadding) size.setPaddingLeft(leftPadding)

    const rightPadding = getPadding(node, 'right')
    if (rightPadding) size.setPaddingRight(rightPadding)
  }
}
