import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'
import { computeMaxContent, computeMinContent, minMaxSize } from '../utils'
import { ReadOnlyNode } from 'application/node'
import { NodeSizeState } from '../node/node'
import { isConstrained } from 'application/attributes'

export class BlockChildSize {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap

  constructor(document: WriteDocument, sizeMap: NodeSizeStateMap) {
    this.document = document
    this.sizeMap = sizeMap
  }

  calculate = (
    childId: string,
    w: number | undefined,
    h: number | undefined
  ): void => {
    const child = this.document.getNode(childId)
    if (!child) return

    const childSize = this.sizeMap.get(childId)
    if (!childSize) return

    this.computeWidth(w, child, childSize)
    this.computeHeight(h, child, childSize)
    this.computeConstrainedWidth(child, childSize)
    this.computeConstrainedHeight(child, childSize)
  }

  private computeWidth = (
    availableW: number | undefined,
    child: ReadOnlyNode,
    size: NodeSizeState
  ): void => {
    if (isConstrained(child, 'w')) return

    const wAuto = child.getStyleAttribute('size.w.auto')
    switch (wAuto) {
      case 'fixed':
        const fixed = child.getStyleAttribute('size.w')
        if (fixed === undefined) break
        size.setW(minMaxSize(fixed, size, 'w'))
        break
      case 'percent':
        const percent = child.getStyleAttribute('size.w.percent')
        if (percent === undefined || availableW === undefined) break
        size.setW(minMaxSize(availableW * (percent / 100), size, 'w'))
        break
      case 'fill':
        if (availableW === undefined) break
        size.setW(minMaxSize(availableW, size, 'w'))
        break
      case 'auto':
        if (availableW === undefined) break
        const w = size.getW() || Infinity
        const positionMode = child.getStyleAttribute('position.mode')
        switch (positionMode) {
          case 'auto':
          case 'sticky':
            const minContentW = computeMinContent(
              child.getId(),
              this.sizeMap,
              this.document,
              'w'
            )
            size.setW(Math.max(minContentW, Math.min(availableW, w)))
            break
          case 'absolute':
          case 'fixed':
            const maxContentW = computeMaxContent(
              child.getId(),
              this.sizeMap,
              this.document,
              'w'
            )
            size.setW(minMaxSize(Math.min(maxContentW, w), size, 'w'))
            break
        }
    }
  }

  private computeHeight = (
    availableH: number | undefined,
    child: ReadOnlyNode,
    size: NodeSizeState
  ): void => {
    if (isConstrained(child, 'h')) return

    const hAuto = child.getStyleAttribute('size.h.auto')
    switch (hAuto) {
      case 'fixed':
        const fixed = child.getStyleAttribute('size.h')
        if (fixed === undefined) break
        size.setH(fixed)
        break
      case 'percent':
        const percent = child.getStyleAttribute('size.h.percent')
        if (percent === undefined || availableH === undefined) break
        size.setH(availableH * (percent / 100))
        break
      case 'fill':
        if (availableH === undefined) break
        size.setH(availableH)
        break
    }
  }

  private computeConstrainedWidth = (
    node: ReadOnlyNode,
    size: NodeSizeState
  ): void => {
    if (!isConstrained(node, 'w')) return

    const parent = this.document.getParent(node)
    if (!parent) return

    const parentSize = this.sizeMap.get(parent.getId())
    if (!parentSize) return

    const left = this.computePosition(node, parent, parentSize, 'left')
    const right = this.computePosition(node, parent, parentSize, 'right')

    size.setW(right - left)
  }

  private computeConstrainedHeight = (
    node: ReadOnlyNode,
    size: NodeSizeState
  ): void => {
    if (!isConstrained(node, 'h')) return

    const parent = this.document.getParent(node)
    if (!parent) return

    const parentSize = this.sizeMap.get(parent.getId())
    if (!parentSize) return

    const top = this.computePosition(node, parent, parentSize, 'top')
    const bottom = this.computePosition(node, parent, parentSize, 'bottom')

    size.setH(bottom - top)
  }

  private computePosition(
    node: ReadOnlyNode,
    parent: ReadOnlyNode,
    parentSize: NodeSizeState,
    mode: 'left' | 'right' | 'top' | 'bottom'
  ): number {
    const parentX = parent.getBaseAttribute('x') || 0
    const parentY = parent.getBaseAttribute('y') || 0
    const parentH = parentSize.getH() || 0
    const parentW = parentSize.getW() || 0

    switch (mode) {
      case 'top':
        if (parent.getBaseAttribute('type') === 'canvas') return 0
        switch (node.getStyleAttribute(`position.${mode}.auto`)) {
          case 'fixed':
            const top = node.getStyleAttribute(`position.${mode}`)
            if (top === undefined) return 0
            return parentY + top
          case 'percent':
            const percent = node.getStyleAttribute(`position.${mode}.percent`)
            if (percent === undefined) return 0
            return parentY + (percent / 100) * parentH
        }
        break
      case 'bottom':
        if (parent.getBaseAttribute('type') === 'canvas')
          return node.getBaseAttribute('h')
        switch (node.getStyleAttribute(`position.${mode}.auto`)) {
          case 'fixed':
            const bottom = node.getStyleAttribute(`position.${mode}`)
            if (bottom === undefined) return 0
            return parentY + parentH - bottom
          case 'percent':
            const percent = node.getStyleAttribute(`position.${mode}.percent`)
            if (percent === undefined) return 0
            return parentY + parentH - (percent / 100) * parentH
        }
        break
      case 'left':
        if (parent.getBaseAttribute('type') === 'canvas') return 0
        switch (node.getStyleAttribute(`position.${mode}.auto`)) {
          case 'fixed':
            const left = node.getStyleAttribute(`position.${mode}`)
            if (left === undefined) return 0
            return parentX + left
          case 'percent':
            const percent = node.getStyleAttribute(`position.${mode}.percent`)
            if (percent === undefined) return 0
            return parentX + (percent / 100) * parentW
        }
        break
      case 'right':
        if (parent.getBaseAttribute('type') === 'canvas')
          return node.getBaseAttribute('w')
        switch (node.getStyleAttribute(`position.${mode}.auto`)) {
          case 'fixed':
            const right = node.getStyleAttribute(`position.${mode}`)
            if (right === undefined) return 0
            return parentX + parentW - right
          case 'percent':
            const percent = node.getStyleAttribute(`position.${mode}.percent`)
            if (percent === undefined) return 0
            return parentX + parentW - (percent / 100) * parentW
        }
        break
    }
    return 0
  }
}
