interface TimelineRowProps {
  rowRef?: React.RefObject<HTMLDivElement>
  children?: React.ReactNode
}

export default function TimelineRow({ rowRef, children }: TimelineRowProps) {
  return (
    <div
      ref={rowRef}
      id={'timelineRow'}
      className={'timelineRow'}
      style={{
        position: 'relative',
        width: '100%',
        height: 'fit-content',
        maxHeight: 400,
        padding: '8px 0px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        overflow: 'auto',
      }}
    >
      {children}
    </div>
  )
}
