import OnboardingCTA from 'components/Library/Onboarding/OnboardingCta'
import OnboardingInputField from 'components/Library/Onboarding/OnboardingInputField'
import OnboardingInputWrapper from 'components/Library/Onboarding/OnboardingInputWrapper'
import OnboardingLogo from 'components/Library/Onboarding/OnboardingLogo'
import OnboardingPanel from 'components/Library/Onboarding/OnboardingPanel'
import OnboardingTitle from 'components/Library/Onboarding/OnboardingTitle'
import OnboardingBackground from 'components/Library/Onboarding/OnboardingBackground'
import useNavigatePage from 'hooks/admin/useNavigatePage'
import useRedirectLoggedIn from 'hooks/admin/useRedirectLoggedIn'
import useUserService from 'hooks/services/useUserService'
import { useCallback, useEffect, useState } from 'react'
import useToast from 'hooks/editor/useToast'
import { useLocation } from 'react-router-dom'

export default function ResetUpdate() {
  useRedirectLoggedIn()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')
  const email = queryParams.get('email')

  const addToast = useToast()
  const userService = useUserService()
  const { toProjects, toSignUp } = useNavigatePage()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const submitHandler = useCallback(async () => {
    if (!password || !confirmPassword) {
      addToast(`You must fill in all fields`, 'error')
      return
    }

    if (password !== confirmPassword) {
      addToast(`Passwords don't match`, 'error')
      return
    }

    if (!token || !email) {
      addToast('Invalid token', 'error')
      toSignUp()
      return
    }

    await userService.updateUserPassword(token, email, password).catch(() => {})
    toProjects()
  }, [
    addToast,
    confirmPassword,
    email,
    password,
    toProjects,
    toSignUp,
    token,
    userService,
  ])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        submitHandler()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [submitHandler])

  useEffect(() => {
    if (!token || !email) toSignUp()
  }, [token, email, toSignUp])

  return (
    <OnboardingBackground>
      <OnboardingLogo />
      <OnboardingPanel>
        <OnboardingTitle>Reset Password</OnboardingTitle>
        <OnboardingInputWrapper>
          <OnboardingInputField
            label="New Password"
            icon="PasswordLock"
            placeholder="New Password"
            value={password}
            setValue={setPassword}
            password={true}
          />
          <OnboardingInputField
            label="Confirm New Password"
            icon="PasswordLock"
            placeholder="Confirm New Password"
            value={confirmPassword}
            setValue={setConfirmPassword}
            password={true}
          />
        </OnboardingInputWrapper>
        <OnboardingCTA onClick={submitHandler}>Reset Password</OnboardingCTA>
      </OnboardingPanel>
    </OnboardingBackground>
  )
}
