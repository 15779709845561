import { StyleMap, isAutolayoutChild } from 'application/attributes'
import { CommandHandler } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import {
  ReadOnlyDocumentSelection,
  computeSelectionRectangle,
} from 'application/selection'
import { Rectangle } from 'application/shapes'
import {
  getAdjustedBottom,
  getAdjustedTop,
  getAdjustedLeft,
  getAdjustedRight,
} from 'application/units/utils'

export type AlignAxis = 'h' | 'v'
export type AlignDirection = 'start' | 'center' | 'end'

export class AlignNodesAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.documentSelection = documentSelection
  }

  align = (axis: AlignAxis, direction: AlignDirection): void => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    const window = this.computeWindow()
    if (!window) return

    const positionKey = axis === 'h' ? 'x' : 'y'

    let updated = false
    for (const node of nodes) {
      const parent = this.document.getParent(node)
      if (!parent) continue

      const currentPosition = node.getBaseAttribute(positionKey)
      const newPosition = this.computePosition(axis, direction, window, node)

      if (currentPosition !== newPosition) {
        switch (axis) {
          case 'h':
            const w = node.getBaseAttribute('w')
            const hUpdate: Partial<StyleMap> = {
              ...getAdjustedLeft(node, this.document, newPosition),
              ...getAdjustedRight(node, this.document, newPosition + w),
            }
            this.setOne(node.getId(), hUpdate)
            break
          case 'v':
            const h = node.getBaseAttribute('h')
            const vUpdate: Partial<StyleMap> = {
              ...getAdjustedTop(node, this.document, newPosition),
              ...getAdjustedBottom(node, this.document, newPosition + h),
            }
            this.setOne(node.getId(), vUpdate)
            break
        }
        updated = true
      }
    }

    if (updated) this.commit()
  }

  distribute = (axis: AlignAxis): void => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    const window = this.computeWindow()
    if (!window) return

    const positionKey = axis === 'h' ? 'x' : 'y'
    const sizeKey = axis === 'h' ? 'w' : 'h'
    const windowPositionKey = axis === 'h' ? 'x' : 'y'
    const windowSizeKey = axis === 'h' ? 'w' : 'h'

    const sorted = nodes.sort((a, b) => {
      return a.getBaseAttribute(positionKey) - b.getBaseAttribute(positionKey)
    })
    const sum = sorted.reduce((acc, node) => {
      return acc + node.getBaseAttribute(sizeKey)
    }, 0)

    const totalGap = window[windowSizeKey] - sum
    let gapSize = totalGap / (sorted.length - 1)
    if (gapSize < 0) gapSize = Math.floor(gapSize)
    else gapSize = Math.ceil(gapSize)

    let updated = false
    let position = 0
    let count = 0
    for (const node of sorted) {
      const parent = this.document.getParent(node)
      if (!parent) continue

      const currentPosition = node.getBaseAttribute(positionKey)
      const newPosition = window[windowPositionKey] + position + gapSize * count

      if (currentPosition !== newPosition) {
        switch (axis) {
          case 'h':
            const w = node.getBaseAttribute('w')
            const hUpdate: Partial<StyleMap> = {
              ...getAdjustedLeft(node, this.document, newPosition),
              ...getAdjustedRight(node, this.document, newPosition + w),
            }
            this.setOne(node.getId(), hUpdate)
            break
          case 'v':
            const h = node.getBaseAttribute('h')
            const vUpdate: Partial<StyleMap> = {
              ...getAdjustedTop(node, this.document, newPosition),
              ...getAdjustedBottom(node, this.document, newPosition + h),
            }
            this.setOne(node.getId(), vUpdate)
            break
        }
        updated = true
      }

      position += node.getBaseAttribute(sizeKey)
      count++
    }

    if (updated) this.commit()
  }

  private computeWindow = (): Rectangle | null => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return null
    if (pairs.length === 1 && pairs[0][1]) {
      return computeSelectionRectangle([pairs[0][1]])
    } else {
      return computeSelectionRectangle(pairs.map((p) => p[0]))
    }
  }

  private computePosition = (
    axis: AlignAxis,
    direction: AlignDirection,
    window: Rectangle,
    node: ReadOnlyNode
  ): number => {
    const positionKey = axis === 'h' ? 'x' : 'y'
    const sizeKey = axis === 'h' ? 'w' : 'h'
    switch (direction) {
      case 'start':
        return window[positionKey]
      case 'center':
        return (
          window[positionKey] +
          window[sizeKey] / 2 -
          node.getBaseAttribute(sizeKey) / 2
        )
      case 'end':
        return (
          window[positionKey] + window[sizeKey] - node.getBaseAttribute(sizeKey)
        )
    }
  }

  private getNodes = (): ReadOnlyNode[] => {
    return this.documentSelection.getSelected().filter(this.filterNode)
  }

  private getNodesAndParents = (): [
    ReadOnlyNode,
    ReadOnlyNode | undefined
  ][] => {
    return this.documentSelection
      .getSelected()
      .filter(this.filterNode)
      .map((node) => {
        const parent = this.document.getParent(node)
        return [node, parent]
      })
  }

  private filterNode = (node: ReadOnlyNode): boolean => {
    const parent = this.document.getParent(node)
    if (!parent) return false

    return !isAutolayoutChild(node, parent)
  }

  private setOne = (nodeId: string, style: Partial<StyleMap>): void => {
    const node = this.document.getNode(nodeId)
    if (!node) return

    this.commandHandler.handle({
      type: 'setNodeAttribute',
      params: {
        id: nodeId,
        base: {},
        style: style,
      },
    })
  }

  private commit = (): void => {
    this.commandHandler.handle({
      type: 'commit',
    })
  }
}
