import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'

export class DirtyInitializer {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap

  constructor(document: WriteDocument, sizeMap: NodeSizeStateMap) {
    this.document = document
    this.sizeMap = sizeMap
  }

  initialize = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size) return

    size.setIsDirty(true)
  }
}
