import CTAButton from 'components/Library/Components/Button/CTAButton/CTAButton'
import Text from 'components/Library/Text/Text'
import persistAtom from 'components/State/persistAtom'
import useAnalytics from 'hooks/admin/useAnalytics'
import { atom, useRecoilState } from 'recoil'
import { DesignColor } from 'themes'

const playgroundMessageState = atom<boolean>(
  persistAtom({
    key: 'playgroundLoadingState',
    default: false,
    persistMode: 'session',
  })
)

export default function PlaygroundMessageScreen() {
  const { metricPlayground } = useAnalytics()
  const [accepted, setAccepted] = useRecoilState(playgroundMessageState)
  if (accepted) return <></>

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        pointerEvents: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        zIndex: 5,
      }}
    >
      <div
        style={{
          width: 360,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          padding: 32,
          gap: 8,
          alignItems: 'start',
          justifyContent: 'start',
          borderRadius: 8,
          background: DesignColor('panelBackground'),
          boxShadow: `inset 0 0 0 1px ${DesignColor('panelBorder')}`,
        }}
      >
        <Text
          styles={{ size: 16, weight: 'bold' }}
          color={DesignColor('text1')}
        >
          Repaint Playground
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            padding: `8px 0px 24px 0px`,
          }}
        >
          <Text styles={{ size: 12 }} wrap={true} color={DesignColor('text1')}>
            Welcome! This is the playground we made for Hacker news.
          </Text>
          <div style={{ display: 'flex', gap: 4 }}>
            <Text
              styles={{ size: 12, weight: 'bold' }}
              color={DesignColor('text1')}
            >
              Heads Up:
            </Text>
            <Text styles={{ size: 12 }} color={DesignColor('text1')}>
              Nothing gets saved.
            </Text>
          </div>
          <Text styles={{ size: 12 }} wrap={true} color={DesignColor('text1')}>
            If you want to make a real project, you can create an account using
            the button in the top right.
          </Text>
        </div>
        <CTAButton
          width={96}
          text={'Got It!'}
          onClick={() => {
            metricPlayground()
            setAccepted(true)
          }}
        />
      </div>
    </div>
  )
}
