import { NodeAttributesAction } from 'application/action/attributes'
import {
  AttributeAnimationEffect,
  AttributeAnimationEffectEvent,
  AttributeAnimationEffectStyleMap,
  AttributeAnimationTriggerType,
  AttributeType,
  isInteractable,
} from 'application/attributes'
import { ClientUpdateListener, Update } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocumentSelection } from 'application/selection'

export type EffectEventStyleOption =
  | 'move'
  | 'scale'
  | 'rotate'
  | 'opacity'
  | 'background'
  | 'border'
  | 'text'

export type InteractionEffectPanelSettings = {
  editing: boolean
  index: number | null
  selectedEventKey: string | null
  trigger: AttributeAnimationTriggerType | null
  effect: AttributeAnimationEffect | null
  targetOptions: string[]
}

export type InteractionEffectPanelHandlers = {
  setIndex: (index: number | null) => void
  toggleLoop: () => void
  setSelectedEvent: (key: string | null) => void
  addStartEvent: () => void
  addEvent: (time: number) => void
  deleteEvent: (key: string) => void
  duplicateEvent: (key: string) => void
  updateEvent: (value: AttributeAnimationEffectEvent) => void
  getEventStyleOptions: (key: string) => EffectEventStyleOption[]
  addEventStyle: (key: string, style: EffectEventStyleOption) => void
  removeEventStyle: (key: string, style: EffectEventStyleOption) => void
  close: () => void
}

export type InteractionEffectPanelListener = (
  settings: InteractionEffectPanelSettings
) => void

export class InteractionAnimationEffectPanel implements ClientUpdateListener {
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private attributesAction: NodeAttributesAction

  private listeners: { [key: string]: InteractionEffectPanelListener }
  private index: number | null
  private selectedEventKey: string | null

  constructor(
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    attributesAction: NodeAttributesAction
  ) {
    this.document = document
    this.selection = selection
    this.attributesAction = attributesAction

    this.listeners = {}
    this.index = null
    this.selectedEventKey = null
  }

  getTypes = (): Update['type'][] => {
    return ['node_updated', 'selection']
  }

  onUpdate = (updates: Update[]) => {
    const selected = this.getSelected()

    let selectionUpdated = false
    let nodeUpdated = false
    for (const update of updates) {
      switch (update.type) {
        case 'selection':
          selectionUpdated = true
          break
        case 'node_updated':
          if (selected.includes(update.data.id)) {
            nodeUpdated = true
          }
          break
      }
    }

    if (selectionUpdated || nodeUpdated) {
      if (selectionUpdated) this.index = null
      for (const key in this.listeners) {
        this.listeners[key](this.getSettings())
      }
    }
  }

  getSettings = (): InteractionEffectPanelSettings => {
    return {
      editing: this.index !== null,
      index: this.index,
      selectedEventKey: this.selectedEventKey,
      trigger: this.getTrigger(),
      effect: this.getEffect(),
      targetOptions: this.getTargetOptions(),
    }
  }

  getHandlers = (): InteractionEffectPanelHandlers => {
    return {
      setIndex: this.setIndex,
      toggleLoop: this.toggleLoop,
      setSelectedEvent: this.setSelectedEvent,
      addStartEvent: this.addStartEvent,
      addEvent: this.addEvent,
      deleteEvent: this.deleteEvent,
      duplicateEvent: this.duplicateEvent,
      updateEvent: this.updateEvent,
      getEventStyleOptions: this.getEventStyleOptions,
      addEventStyle: this.addEventStyle,
      removeEventStyle: this.removeEventStyle,
      close: this.close,
    }
  }

  subscribe = (key: string, listener: InteractionEffectPanelListener): void => {
    this.listeners[key] = listener
  }

  unsubscribe = (key: string): void => {
    delete this.listeners[key]
  }

  private getEffect = (): AttributeAnimationEffect | null => {
    const selected = this.getSelected()
    if (selected.length !== 1) return null

    const node = this.document.getNode(selected[0])
    if (!node) return null

    const animations = node.getBaseAttribute('animations')
    if (!animations) return null

    if (this.index === null) return null
    if (this.index < 0 || this.index >= animations.length) return null
    return animations[this.index].effect
  }

  private getTrigger = (): AttributeAnimationTriggerType | null => {
    const selected = this.getSelected()
    if (selected.length !== 1) return null

    const node = this.document.getNode(selected[0])
    if (!node) return null

    const animations = node.getBaseAttribute('animations')
    if (!animations) return null

    if (this.index === null) return null
    if (this.index < 0 || this.index >= animations.length) return null
    return animations[this.index].action.trigger
  }

  private setIndex = (index: number | null): void => {
    this.index = index
    const effect = this.getEffect()
    if (effect && effect.type === 'timeline') {
      this.selectedEventKey = this.getFirstNonStartEvent(effect)
    } else {
      this.setSelectedEvent(null)
    }
    this.notifyListeners()
  }

  private update = (
    value: AttributeAnimationEffect,
    commit: boolean = false
  ): void => {
    const selected = this.getSelected()
    if (selected.length !== 1) return

    const node = this.document.getNode(selected[0])
    if (!node) return

    const animations = node.getBaseAttribute('animations')
    if (!animations) return

    if (this.index === null) return
    if (this.index < 0 || this.index >= animations.length) return

    this.attributesAction.setBaseAttributes(
      selected,
      {
        animations: [
          ...animations.slice(0, this.index),
          { ...animations[this.index], effect: value },
          ...animations.slice(this.index + 1),
        ],
      },
      commit
    )

    this.notifyListeners()
  }

  private toggleLoop = (): void => {
    const effect = this.getEffect()
    if (!effect) return

    switch (effect.type) {
      case 'timeline':
        this.update({ ...effect, loop: !effect.loop }, true)
        break
    }
  }

  private setSelectedEvent = (key: string | null): void => {
    this.selectedEventKey = key
    this.notifyListeners()
  }

  private selectPreviousEvent = (key: string | null): void => {
    const effect = this.getEffect()
    if (!effect) return

    switch (effect.type) {
      case 'timeline':
        const events = effect.events.filter((e) => !e.start)
        if (events.length === 0) {
          this.setSelectedEvent(null)
          return
        }

        const sortedEvents = [...events].sort(
          (a, b) => a.startTime - b.startTime
        )
        const currentIndex = sortedEvents.findIndex((e) => e.key === key)
        if (currentIndex === -1) {
          this.setSelectedEvent(null)
          return
        }

        const previous = sortedEvents[currentIndex - 1]
        if (previous) this.setSelectedEvent(previous.key)
        else this.setSelectedEvent(null)
    }
  }

  private addStartEvent = (): string | undefined => {
    const effect = this.getEffect()
    if (!effect) return

    switch (effect.type) {
      case 'timeline':
        const newEvent = this.createStartEvent(effect)
        this.update({ ...effect, events: [...effect.events, newEvent] }, true)
        this.setSelectedEvent(newEvent.key)
    }
  }

  private addEvent = (time: number): string | undefined => {
    const effect = this.getEffect()
    if (!effect) return

    switch (effect.type) {
      case 'timeline':
        const newEvent = this.createEvent(effect, time)
        this.update({ ...effect, events: [...effect.events, newEvent] }, true)
        this.setSelectedEvent(newEvent.key)
    }
  }

  private deleteEvent = (key: string): void => {
    const effect = this.getEffect()
    if (!effect) return

    switch (effect.type) {
      case 'timeline':
        if (this.selectedEventKey === key) this.selectPreviousEvent(key)
        this.update(
          {
            ...effect,
            events: effect.events.filter((e) => e.key !== key),
          },
          true
        )
        break
    }
  }

  private duplicateEvent = (key: string): string | undefined => {
    const effect = this.getEffect()
    if (!effect) return

    switch (effect.type) {
      case 'timeline':
        const event = effect.events.find((e) => e.key === key)
        if (!event) return

        const newEvent = {
          ...event,
          key: this.createKey(effect),
        }
        this.update({ ...effect, events: [...effect.events, newEvent] }, true)
        this.setSelectedEvent(newEvent.key)
        return newEvent.key
    }
  }

  private updateEvent = (
    value: AttributeAnimationEffectEvent,
    commit: boolean = false
  ): void => {
    const effect = this.getEffect()
    if (!effect) return

    switch (effect.type) {
      case 'timeline':
        this.update(
          {
            ...effect,
            events: effect.events.map((e) => (e.key === value.key ? value : e)),
          },
          commit
        )
        break
    }
  }

  private getEventStyleOptions = (key: string): EffectEventStyleOption[] => {
    const effect = this.getEffect()
    if (!effect) return []

    switch (effect.type) {
      case 'timeline':
        const event = effect.events.find((e) => e.key === key)
        if (!event) return []

        const used = this.getEventStyleOptionsUsed(event.style)
        switch (event.targetType) {
          case 'self':
            const selected = this.getSelected()
            if (selected.length !== 1) return []

            const self = this.document.getNode(selected[0])
            if (!self) return []

            return this.getEventStyleOptionsByType(
              self.getBaseAttribute('type')
            ).filter((o) => !used.includes(o))
          case 'target':
            if (!event.targetId) return []

            const node = this.document.getNode(event.targetId)
            if (!node) return []

            return this.getEventStyleOptionsByType(
              node.getBaseAttribute('type')
            ).filter((o) => !used.includes(o))
        }
        break
      default:
        return []
    }
  }

  private addEventStyle = (
    key: string,
    style: EffectEventStyleOption
  ): void => {
    const effect = this.getEffect()
    if (!effect) return

    switch (effect.type) {
      case 'timeline':
        const event = effect.events.find((e) => e.key === key)
        if (!event) return

        const targetedNode = this.getTargetedNode(event)

        let updatedStyle: AttributeAnimationEffectEvent['style'] = {}
        switch (style) {
          case 'move':
            updatedStyle = {
              ...event.style,
              'transform.x.pixel': 0,
              'transform.y.pixel': 0,
              'transform.x.unit': 'px',
              'transform.y.unit': 'px',
            }
            break
          case 'scale':
            updatedStyle = { ...event.style, 'transform.scale': 1 }
            break
          case 'rotate':
            updatedStyle = { ...event.style, 'transform.rotate': 0 }
            break
          case 'opacity':
            if (targetedNode) {
              const opacity = targetedNode.getStyleAttribute('opacity')
              if (opacity !== undefined) {
                updatedStyle = { ...event.style, opacity }
              }
              break
            }
            updatedStyle = { ...event.style, opacity: 100 }
            break
          case 'background':
            if (targetedNode) {
              const fills = targetedNode.getStyleAttribute('fills')
              if (fills && fills.length > 0 && fills[0].color) {
                updatedStyle = {
                  ...event.style,
                  'background.color': fills[0].color,
                }
              }
              break
            }
            updatedStyle = {
              ...event.style,
              'background.color': { r: 0, g: 0, b: 0, a: 1 },
            }
            break
          case 'border':
            if (targetedNode) {
              const borderColor = targetedNode.getStyleAttribute('border.color')
              if (borderColor) {
                updatedStyle = {
                  ...event.style,
                  'border.color': borderColor,
                }
              }
              break
            }
            updatedStyle = {
              ...event.style,
              'border.color': { r: 0, g: 0, b: 0, a: 1 },
            }
            break
          case 'text':
            if (targetedNode) {
              const textColor = targetedNode.getStyleAttribute('text.color')
              if (textColor && textColor.color) {
                updatedStyle = {
                  ...event.style,
                  'text.color': textColor.color,
                }
              }
              break
            }
            updatedStyle = {
              ...event.style,
              'text.color': { r: 0, g: 0, b: 0, a: 1 },
            }
            break
        }
        this.updateEvent({ ...event, style: updatedStyle }, true)
        break
    }
  }

  private removeEventStyle = (
    key: string,
    style: EffectEventStyleOption
  ): void => {
    const effect = this.getEffect()
    if (!effect) return

    switch (effect.type) {
      case 'timeline':
        const event = effect.events.find((e) => e.key === key)
        if (!event) return

        let updatedStyle: AttributeAnimationEffectEvent['style'] = {}
        switch (style) {
          case 'move':
            updatedStyle = { ...event.style }
            delete updatedStyle['transform.x.pixel']
            delete updatedStyle['transform.y.pixel']
            delete updatedStyle['transform.x.percent']
            delete updatedStyle['transform.y.percent']
            delete updatedStyle['transform.x.unit']
            delete updatedStyle['transform.y.unit']
            break
          case 'scale':
            updatedStyle = { ...event.style }
            delete updatedStyle['transform.scale']
            break
          case 'rotate':
            updatedStyle = { ...event.style }
            delete updatedStyle['transform.rotate']
            break
          case 'opacity':
            updatedStyle = { ...event.style }
            delete updatedStyle['opacity']
            break
          case 'background':
            updatedStyle = { ...event.style }
            delete updatedStyle['background.color']
            break
          case 'border':
            updatedStyle = { ...event.style }
            delete updatedStyle['border.color']
            break
          case 'text':
            updatedStyle = { ...event.style }
            delete updatedStyle['text.color']
            break
        }
        this.updateEvent({ ...event, style: updatedStyle }, true)
        break
    }
  }

  private close = (): void => {
    this.index = null
    this.notifyListeners()
  }

  private getSelected = (): string[] => {
    return this.selection.getSelected().map((n) => n.getId())
  }

  private getTargetOptions = (): string[] => {
    return this.document
      .getNodes()
      .filter((n) => isInteractable(n))
      .map((n) => n.getId())
  }

  private notifyListeners = (): void => {
    for (const key in this.listeners) {
      this.listeners[key](this.getSettings())
    }
  }

  private createStartEvent = (
    effect: AttributeAnimationEffect
  ): AttributeAnimationEffectEvent => {
    return {
      key: this.createKey(effect),
      start: true,
      targetType: 'self',
      targetId: undefined,
      startTime: 0,
      duration: 0,
      easing: 'ease-in-out',
      style: {},
    }
  }

  private createEvent = (
    effect: AttributeAnimationEffect,
    time: number
  ): AttributeAnimationEffectEvent => {
    return {
      key: this.createKey(effect),
      ...this.getNewEventTarget(effect),
      start: false,
      startTime: time,
      duration: 200,
      easing: 'ease-in-out',
      style: {},
    }
  }

  private getEventStyleOptionsByType = (
    type: AttributeType
  ): EffectEventStyleOption[] => {
    switch (type) {
      case 'page':
        return ['background']
      case 'image':
        return ['move', 'scale', 'rotate', 'opacity']
      case 'text':
        return ['move', 'scale', 'rotate', 'opacity', 'text']
      case 'frame':
      case 'rectangle':
      case 'ellipse':
        return ['move', 'scale', 'rotate', 'opacity', 'background', 'border']
      default:
        return []
    }
  }

  private getEventStyleOptionsUsed = (
    styles: AttributeAnimationEffectStyleMap
  ): EffectEventStyleOption[] => {
    const options: EffectEventStyleOption[] = []
    if ('transform.x.unit' in styles) options.push('move')
    if ('transform.y.unit' in styles) options.push('move')
    if ('transform.scale' in styles) options.push('scale')
    if ('transform.rotate' in styles) options.push('rotate')
    if ('opacity' in styles) options.push('opacity')
    if ('background.color' in styles) options.push('background')
    if ('border.color' in styles) options.push('border')
    if ('text.color' in styles) options.push('text')
    return options
  }

  private createKey = (event: AttributeAnimationEffect): string => {
    switch (event.type) {
      case 'timeline':
        let index = 1
        let found = true
        while (found) {
          const curr = index
          found = event.events.some((e) => e.key === `e_${curr}`)
          if (found) index++
        }
        return `e_${index}`
      case 'keyframe':
        return 'e_1'
    }
  }

  private getNewEventTarget = (
    effect: AttributeAnimationEffect
  ): {
    targetType: 'self' | 'target'
    targetId: string | undefined
  } => {
    switch (effect.type) {
      case 'timeline':
        const firstEvent = effect.events
          .filter((e) => !e.start)
          .sort((a, b) => a.startTime - b.startTime)[0]
        if (firstEvent) {
          return {
            targetType: firstEvent.targetType,
            targetId: firstEvent.targetId,
          }
        }

        const firstStartEvent = effect.events.find((e) => e.start)
        if (firstStartEvent) {
          return {
            targetType: firstStartEvent.targetType,
            targetId: firstStartEvent.targetId,
          }
        }
        break
    }
    return { targetType: 'self', targetId: undefined }
  }

  private getFirstNonStartEvent = (
    effect: AttributeAnimationEffect
  ): string | null => {
    switch (effect.type) {
      case 'timeline':
        const filtered = effect.events.filter((e) => !e.start)
        if (filtered.length === 0) return null
        return filtered.sort((a, b) => a.startTime - b.startTime)[0].key
      case 'keyframe':
        return null
    }
  }

  private getTargetedNode = (
    event: AttributeAnimationEffectEvent
  ): ReadOnlyNode | null => {
    if (event.targetType === 'self') {
      const selected = this.getSelected()
      if (selected.length !== 1) return null
      return this.document.getNode(selected[0]) || null
    } else {
      if (!event.targetId) return null
      return this.document.getNode(event.targetId) || null
    }
  }
}
