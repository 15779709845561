import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import ResizePanelContainer from 'components/Library/Containers/ResizePanelContainer/ResizePanelContainer'
import ScrollContainer from 'components/Library/Containers/ScrollContainer/ScrollContainer'
import Canvas from './Canvas/Canvas'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import { useEffect, useRef, useState } from 'react'
import ResizePanelSection from 'components/Library/Containers/ResizePanelSection/ResizePanelSection'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import Layers from './Layers/Layers'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import Tooltip from 'components/Library/Components/Tooltip/Tooltip'
import persistAtom from 'components/State/persistAtom'
import { atom, useRecoilState } from 'recoil'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import CanvasTitle from './Canvas/CanvasTitle'

export const layersWidth = atom<number>(
  persistAtom({
    key: 'layersWidth',
    default: 232,
    persistMode: 'local',
  })
)

const canvasOpen = atom<boolean>(
  persistAtom({
    key: 'canvasOpen',
    default: false,
    persistMode: 'local',
  })
)

const canvasHeight = atom<number>(
  persistAtom({
    key: 'canvasHeight',
    default: 48,
    persistMode: 'local',
  })
)

export default function LeftSidepanel() {
  const canvasHelper = usePanelHelper(editor.getUI().getCanvasPanel())
  const layersHelper = usePanelHelper(editor.getUI().getLayersPanel())

  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useRecoilState(canvasOpen)
  const [height, setHeight] = useRecoilState(canvasHeight)
  const [width, setWidth] = useRecoilState(layersWidth)
  const [canvasCount, setCanvasCount] = useState(0)

  const { createCanvas } = canvasHelper.handlers
  const { toggleCollapsed } = layersHelper.handlers

  const handleSetHeight = (newHeight: number) => {
    if (!open) return
    setHeight(newHeight)
  }

  useEffect(() => {
    if (canvasHelper.settings === null) return
    if (
      canvasCount !== 0 &&
      canvasHelper.settings.canvases.length > canvasCount
    ) {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0
      }
    }
    setCanvasCount(canvasHelper.settings.canvases.length)
  }, [canvasCount, canvasHelper.settings])

  if (!canvasHelper.settings || !layersHelper.settings) return <></>

  const minHeight = open ? 120 : 48
  const maxHeight = open ? 400 : 48

  return (
    <ResizePanelContainer
      position={{
        position: 'absolute',
        top: 64,
        left: 8,
        zIndex: 1,
      }}
      height={'calc(100vh - 74px)'}
      width={width}
      setWidth={setWidth}
    >
      <ResizePanelSection
        height={height}
        canResize={open}
        setHeight={handleSetHeight}
        minHeight={minHeight}
        maxHeight={maxHeight}
      >
        <CanvasTitle open={open} setOpen={setOpen} addCanvas={createCanvas} />
        <ScrollContainer containerRef={scrollContainerRef}>
          <Canvas />
        </ScrollContainer>
      </ResizePanelSection>
      <PanelSection
        sectionRef={panelRef}
        bottom={true}
        width="fill"
        height="fill"
        paddingB={0}
      >
        <PanelRow width={'fill'}>
          <TextBlock width={'fill'} mode={'title'}>
            Layers
          </TextBlock>
          <Tooltip tooltipKey="CollapseLayers">
            <IconBlock
              icon="CollapseLayers"
              onClick={toggleCollapsed}
              iconRotation={layersHelper.settings.collapsed ? 180 : 0}
            />
          </Tooltip>
        </PanelRow>
        <ScrollContainer>
          <Layers containerRef={panelRef} />
        </ScrollContainer>
      </PanelSection>
    </ResizePanelContainer>
  )
}
