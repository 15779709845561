import { WriteDocument } from 'application/document'
import { PositionNodeMap } from '../types'
import { getPositionNode } from '../utils'

export class CanvasPositionCalculator {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  calculate = (id: string, positionMap: PositionNodeMap): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const parent = this.document.getParent(node)
    if (!parent || parent.getBaseAttribute('type') !== 'canvas') return

    const position = getPositionNode(id, positionMap)
    if (!position) return

    const positionY = node.getStyleAttribute('position.top')
    const positionX = node.getStyleAttribute('position.left')
    const y = node.getBaseAttribute('y')
    const x = node.getBaseAttribute('x')

    position.y = positionY !== undefined ? positionY : y
    position.x = positionX !== undefined ? positionX : x
  }
}
