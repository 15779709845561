import { WSCommandDocumentUpdate, isHeartbeatAck } from 'application/service'
import { WebsocketService } from 'application/service/websocket'

type ConnectListener = () => void
type DisconnectListener = () => void
type ErrorListener = () => void

const isDevMode = process.env.REACT_APP_DEV_MODE === 'true'
const interval = isDevMode ? 10_000 : 1_000
const timeout = isDevMode ? 60_000 : 5_000

export class EditorWebsocketService {
  private service: WebsocketService

  private connectListeners: { [key: string]: ConnectListener }
  private disconnectListeners: { [key: string]: DisconnectListener }
  private errorListeners: { [key: string]: ErrorListener }
  private heartbeatInterval: NodeJS.Timeout | null
  private lastHeartbeatAck: number | null
  private failedMessages: number

  constructor(service: WebsocketService) {
    this.service = service
    this.addListeners()

    this.connectListeners = {}
    this.disconnectListeners = {}
    this.errorListeners = {}
    this.heartbeatInterval = null
    this.lastHeartbeatAck = null
    this.failedMessages = 0
  }

  open = (): void => {
    if (this.service.isOpen()) return
    this.service.open()
  }

  close = (): void => {
    if (!this.service.isOpen()) return
    this.service.close()
  }

  subscribeConnect = (key: string, listener: DisconnectListener): void => {
    this.connectListeners[key] = listener
  }

  unsubscribeConnect = (key: string): void => {
    delete this.connectListeners[key]
  }

  subscribeDisconnect = (key: string, listener: DisconnectListener): void => {
    this.disconnectListeners[key] = listener
  }

  unsubscribeDisconnect = (key: string): void => {
    delete this.disconnectListeners[key]
  }

  subscribeError = (key: string, listener: ErrorListener): void => {
    this.errorListeners[key] = listener
  }

  unsubscribeError = (key: string): void => {
    delete this.errorListeners[key]
  }

  sendDocumentUpdate = (command: WSCommandDocumentUpdate): void => {
    const result = this.service.sendCommand(command)
    if (!result) {
      this.failedMessages++
      this.failedMessageCheck()
    } else {
      this.failedMessages = 0
    }
  }

  private addListeners = (): void => {
    this.service.addConnectFailedListener('ws_service', this.onConnectError)
    this.service.addOpenListener('ws_service', this.onOpen)
    this.service.addCloseListener('ws_service', this.onClose)
    this.service.addMessageListener('ws_service', this.onMessage)
  }

  private sendHeartbeat = (): void => {
    const result = this.service.sendCommand({
      type: 'heartbeat',
      token: '',
      data: {
        timestamp: Date.now(),
      },
    })
    if (!result) {
      this.failedMessages++
      this.failedMessageCheck()
    } else {
      this.failedMessages = 0
    }
  }

  private onConnectError = (): void => {
    this.stopHeartbeat()
    this.notifyError()
  }

  private onOpen = (): void => {
    this.startHeartbeat()
    this.notifyConnect()
  }

  private onClose = (): void => {
    this.stopHeartbeat()
    this.notifyDisconnect()
  }

  private onMessage = (data?: object): void => {
    if (!data) return
    const isHeartbeat = isHeartbeatAck(data)
    if (isHeartbeat) {
      console.debug('[WS] Heartbeat received')
      this.lastHeartbeatAck = Date.now()
    }
  }

  private startHeartbeat = (): void => {
    this.lastHeartbeatAck = Date.now()
    this.heartbeatRoutine()
  }

  private stopHeartbeat = (): void => {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval)
    }
  }

  private heartbeatRoutine = (): void => {
    if (!this.lastHeartbeatAck) return
    if (Date.now() - this.lastHeartbeatAck > timeout) {
      this.close()
      return
    }
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval)
    }
    this.sendHeartbeat()
    this.heartbeatInterval = setInterval(this.heartbeatRoutine, interval)
  }

  private failedMessageCheck = (): void => {
    if (this.failedMessages >= 3) {
      this.close()
      this.notifyError()
    }
  }

  private notifyConnect = (): void => {
    for (const key in this.connectListeners) {
      this.connectListeners[key]()
    }
  }

  private notifyDisconnect = (): void => {
    for (const key in this.disconnectListeners) {
      this.disconnectListeners[key]()
    }
  }

  private notifyError = (): void => {
    for (const key in this.errorListeners) {
      this.errorListeners[key]()
    }
  }
}
