import { LayoutDependencyGraph, LayoutDependencyNode } from '../types'

export function hasDependency(
  dependent: LayoutDependencyNode,
  dependency: LayoutDependencyNode
): boolean {
  return dependent.dependencies.some((d) => d.id === dependency.id)
}

export function getNonDependentNodes(
  graph: LayoutDependencyGraph
): LayoutDependencyNode[] {
  return Object.values(graph).filter((n) => n.dependentOn.length === 0)
}

export function removeNode(
  node: LayoutDependencyNode,
  graph: LayoutDependencyGraph
): void {
  for (const dependent of node.dependentOn) {
    dependent.dependencies = dependent.dependencies.filter((n) => n !== node)
  }
  for (const dependency of node.dependencies) {
    dependency.dependentOn = dependency.dependentOn.filter((n) => n !== node)
  }
  delete graph[node.id]
}

export function cloneGraph(
  graph: LayoutDependencyGraph
): LayoutDependencyGraph {
  const newGraph: LayoutDependencyGraph = {}
  for (const id in graph) {
    const node = graph[id]
    newGraph[id] = {
      id: node.id,
      dependentOn: [...node.dependentOn],
      dependencies: [...node.dependencies],
    }
  }
  return newGraph
}
