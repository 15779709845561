import { DocumentSelection } from 'application/selection'
import { CopySnapshot, PasteSnapshot } from '../types'
import { PasteSnapshotGenerator } from './generator'
import { ClientDocumentHelper } from 'application/client/document/helper'

export interface PasteTargetHandler {
  paste(snapshot: PasteSnapshot): void
}

export interface PastePositionHandler {
  paste(snapshot: PasteSnapshot): void
}

export class Paste {
  private documentHelper: ClientDocumentHelper
  private documentSelection: DocumentSelection
  private snapshotGenerator: PasteSnapshotGenerator

  constructor(
    documentHelper: ClientDocumentHelper,
    documentSelection: DocumentSelection,
    snapshotGenerator: PasteSnapshotGenerator
  ) {
    this.documentHelper = documentHelper
    this.documentSelection = documentSelection
    this.snapshotGenerator = snapshotGenerator
  }

  paste = (
    snapshot: CopySnapshot,
    target: PasteTargetHandler,
    position: PastePositionHandler
  ): void => {
    const pasteSnapshot = this.snapshotGenerator.generate(snapshot)

    for (const node of Object.values(pasteSnapshot.nodes)) {
      this.documentHelper.addNode(node, false)
    }

    target.paste(pasteSnapshot)
    position.paste(pasteSnapshot)

    this.documentSelection.select(pasteSnapshot.ids)
  }
}
