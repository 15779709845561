import { AttributeTransitionTiming } from 'application/attributes'
import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import TitleIconBlock from 'components/Library/Components/IconBlock/TitleIconBlock/TitleIconBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function TransitionSettings() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getTransitionPanel()
  )
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings) return <></>

  const { duration, timing, mode } = settings
  const {
    setTiming,
    setDuration,
    slideDuration,
    addTransition,
    removeTransition,
    clearMixed,
  } = handlers
  const { hasOverride, sectionHasOverride } = overrides.handlers
  if (mode === 'disabled') return <></>

  const handleAdd = () => {
    if (mode === 'mixed') clearMixed()
    else if (mode === 'add') addTransition()
    else if (mode === 'remove') removeTransition()
  }

  return (
    <PanelSection>
      <PanelRow>
        <OverrideReset section={'transition'}>
          <TitleButtonBlock
            title="Transition"
            active={mode === 'remove' || mode === 'mixed'}
            hasOverride={sectionHasOverride('transition')}
            onClick={handleAdd}
          />
        </OverrideReset>
        <TitleIconBlock
          icon={'Plus'}
          panelActive={mode === 'remove'}
          canDeactivate={mode === 'remove'}
          onClick={handleAdd}
        />
      </PanelRow>
      {mode === 'mixed' && (
        <PanelRow>
          <TextBlock width={216} mode={'label2'}>
            {'Click + to clear mixed transitions'}
          </TextBlock>
        </PanelRow>
      )}
      {mode === 'remove' && (
        <PanelRow>
          <OverrideReset keys={['transition.duration']}>
            <NumberBlock
              icon={'Duration'}
              tooltipKey={'TransitionDuration'}
              value={duration}
              setValue={setDuration}
              increment={(v) => slideDuration(v)}
              decrement={(v) => slideDuration(-v)}
              min={0}
              max={20}
              hasOverride={hasOverride(['transition.duration'])}
              unit={'s'}
              step={0.1}
            />
          </OverrideReset>
          <OverrideReset keys={['transition.timing']}>
            <Dropdown
              width={96}
              tooltipKey={'TransitionTimingMode'}
              mixed={timing === 'Mixed'}
              selected={timing}
              select={(v) => {
                if (v === 'Mixed') return
                setTiming(v)
              }}
              options={timingOptions}
              commit={true}
              hasOverride={hasOverride(['transition.timing'])}
            />
          </OverrideReset>
        </PanelRow>
      )}
    </PanelSection>
  )
}

const timingOptions: dropdownOption<AttributeTransitionTiming>[] = [
  {
    value: 'linear',
    text: 'Linear',
  },
  {
    value: 'ease',
    text: 'Ease',
  },
  {
    value: 'ease-in',
    text: 'Ease In',
  },
  {
    value: 'ease-out',
    text: 'Ease Out',
  },
  {
    value: 'ease-in-out',
    text: 'Ease In & Out',
  },
]
