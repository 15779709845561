import { WriteDocument } from 'application/document'
import { LayoutDependencyGraphFactory, LayoutEngineCalculator } from '../types'
import { BoundsCalculator } from './bounds'
import { getNonDependentNodes, removeNode } from '../dependency/utils'
import { RenderBoxCalculator } from './render'

export class BoxLayoutEngine implements LayoutEngineCalculator {
  private document: WriteDocument
  private topDownFactory: LayoutDependencyGraphFactory
  private bounds: BoundsCalculator
  private render: RenderBoxCalculator

  constructor(
    document: WriteDocument,
    topDownFactory: LayoutDependencyGraphFactory,
    box: BoundsCalculator,
    render: RenderBoxCalculator
  ) {
    this.document = document
    this.topDownFactory = topDownFactory
    this.bounds = box
    this.render = render
  }

  layout(nodes: Set<string>): void {
    const graph = this.topDownFactory.create(nodes)
    let terminalNodes = getNonDependentNodes(graph)
    while (terminalNodes.length > 0) {
      for (const terminalNode of terminalNodes) {
        this.computeBounds(terminalNode.id)
        removeNode(terminalNode, graph)
      }
      terminalNodes = getNonDependentNodes(graph)
    }
  }

  private computeBounds(id: string): void {
    const node = this.document.getNode(id)
    if (!node) return

    this.bounds.calculate(id)
    this.render.calculate(id)
  }
}
