import Text from 'components/Library/Text/Text'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'
import { DesignColor } from 'themes'
import Icon from '../../Icon/Icon/Icon'

interface TimelineTimeButtonProps {
  buttonRef?: React.RefObject<HTMLDivElement>

  start: boolean
  end: boolean
  time: number

  onClick: () => void
}

export default function TimelineTimeButton({
  buttonRef,
  start,
  end,
  time,
  onClick,
}: TimelineTimeButtonProps) {
  const blockRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered<boolean>({
    ref: buttonRef || blockRef,
  })

  return (
    <div
      ref={buttonRef || blockRef}
      className={'timelineTimeButton'}
      style={{
        width: 48,
        height: 24,
        padding: '0px 8px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        gap: 4,
      }}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {!hovered && (
        <Text color={DesignColor(hovered ? 'inputHighlight' : 'text2')}>
          {start ? 'Init' : end ? 'End' : formatTime(time)}
        </Text>
      )}
      {hovered && (
        <Icon icon={'Plus'} size={12} color={DesignColor('inputHighlight')} />
      )}
      <div
        style={{
          height: 4,
          width: 4,
          borderRadius: '50%',
          backgroundColor: DesignColor(hovered ? 'inputHighlight' : 'text1'),
          zIndex: 1,
        }}
      />
    </div>
  )
}

function formatTime(time: number) {
  return (time / 1000).toFixed(1)
}
