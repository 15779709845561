import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'
import { ReadOnlyNode } from 'application/node'
import { NodeSizeState } from '../node/node'
import { getLayoutDirection, getLayoutMode } from 'application/layout/utils'
import { BlockChildSize } from './blockChild'
import { FlexMainSize } from './flexMain'
import { FlexCrossSize } from './flexCross'
import { TextSizeCalculator } from '../types'
import { TextAutoSize } from './textAuto'
import { isAbsolutePositionMode } from 'application/attributes'
import { FlexMainInitialSize } from './flexMainInitial'
import { FlexCrossInitialSize } from './flexCrossInitial'
import { BlockChildAutoSize } from './blockChildAuto'
import { computeMaxContent } from '../utils'

export class LayoutCalculator {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap
  private blockChildSize: BlockChildSize
  private blockChildAutoSize: BlockChildAutoSize
  private flexMainSize: FlexMainSize
  private flexMainInitialSize: FlexMainInitialSize
  private flexCrossInitialSize: FlexCrossInitialSize
  private flexCrossSize: FlexCrossSize
  private textAutoSize: TextAutoSize

  constructor(
    document: WriteDocument,
    textSize: TextSizeCalculator,
    sizeMap: NodeSizeStateMap
  ) {
    this.document = document
    this.sizeMap = sizeMap
    this.blockChildSize = new BlockChildSize(document, sizeMap)
    this.blockChildAutoSize = new BlockChildAutoSize(document, sizeMap)
    this.flexMainSize = new FlexMainSize(document, sizeMap)
    this.flexMainInitialSize = new FlexMainInitialSize(document, sizeMap)
    this.flexCrossInitialSize = new FlexCrossInitialSize(document, sizeMap)
    this.flexCrossSize = new FlexCrossSize(document, sizeMap)
    this.textAutoSize = new TextAutoSize(document, sizeMap, textSize)
  }

  calculate = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size || !size.getIsDirty()) return

    const children = node.getChildren() || []
    const parent = this.document.getParent(node)
    if (!parent || parent.getBaseAttribute('type') === 'root') return

    const parentType = parent.getBaseAttribute('type')
    if (parentType === 'root') return

    if (parentType === 'canvas') {
      this.sizeCanvasChild(node, size)
    }

    const mode = getLayoutMode(node)
    switch (mode) {
      case 'block':
        this.sizeBlockChildren(children, size)
        break
      case 'flex':
        this.sizeFlexChildren(id, children)
        break
      case 'none':
        break
    }

    this.textAutoSize.calculate(id)

    if (parentType === 'canvas') {
      this.blockChildAutoSize.calculate(id)
    }

    this.sizeAbsoluteChildren(children, size)

    if (parentType === 'canvas') {
      this.sizeFixedChildren(id, size)
    }

    size.setIsDirty(false)
  }

  private sizeBlockChildren = (
    children: string[],
    size: NodeSizeState
  ): void => {
    const innerW = size.getInnerW()
    const innerH = size.getInnerH()

    const nonAbsolute = children.filter((childId) => {
      const child = this.document.getNode(childId)
      if (!child) return false
      return !isAbsolutePositionMode(child)
    })

    for (const childId of nonAbsolute) {
      this.blockChildSize.calculate(childId, innerW, innerH)
    }

    this.calculateChildren(nonAbsolute)

    for (const childId of nonAbsolute) {
      this.blockChildAutoSize.calculate(childId)
    }
  }

  private sizeAbsoluteChildren = (
    children: string[],
    size: NodeSizeState
  ): void => {
    const w = size.getW()
    const h = size.getH()

    const absolute = children.filter((childId) => {
      const child = this.document.getNode(childId)
      if (!child) return false
      const positionMode = child.getStyleAttribute('position.mode')
      return positionMode === 'absolute'
    })

    for (const childId of absolute) {
      this.blockChildSize.calculate(childId, w, h)
    }

    this.calculateChildren(absolute)

    for (const childId of absolute) {
      this.blockChildAutoSize.calculate(childId)
    }
  }

  private sizeFixedChildren = (id: string, size: NodeSizeState): void => {
    const w = size.getW()
    const h = size.getH()

    const descendants = this.getAllFixedDescendants(id)
    for (const childId of descendants) {
      this.blockChildSize.calculate(childId, w, h)
    }

    this.calculateChildren(descendants)

    for (const childId of descendants) {
      this.blockChildAutoSize.calculate(childId)
    }
  }

  private sizeFlexChildren = (id: string, children: string[]): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const direction = getLayoutDirection(node)
    if (direction === 'column') {
      this.flexCrossInitialSize.calculateCrossHypoSize(id)
      this.flexCrossSize.calculateCrossSize(id)
      this.calculateChildren(children)
    }

    this.flexMainInitialSize.calculateMainBaseSize(id)
    this.flexMainInitialSize.calculateMainHypoSize(id)
    this.flexMainSize.calculateMainSize(id)
    this.calculateChildren(children)

    this.flexCrossInitialSize.calculateCrossHypoSize(id)
    this.flexCrossSize.calculateCrossSize(id)
    this.calculateChildren(children)
  }

  private calculateChildren = (children: string[]): void => {
    for (const childId of children) {
      this.calculate(childId)
    }
  }

  private sizeCanvasChild(node: ReadOnlyNode, size: NodeSizeState): void {
    const wAuto = node.getStyleAttribute('size.w.auto')
    switch (wAuto) {
      case 'fixed':
        const w = node.getStyleAttribute('size.w')
        if (w !== undefined) size.setW(w)
        break
      case 'auto':
        const maxW = computeMaxContent(
          node.getId(),
          this.sizeMap,
          this.document,
          'w'
        )
        size.setW(maxW)
    }

    const hAuto = node.getStyleAttribute('size.h.auto')
    switch (hAuto) {
      case 'fixed':
        const h = node.getStyleAttribute('size.h')
        if (h !== undefined) size.setH(h)
        break
    }
  }

  private getAllFixedDescendants = (id: string): string[] => {
    const node = this.document.getNode(id)
    if (!node) return []

    return this.document
      .getDescendants(node)
      .filter((d) => d.getStyleAttribute('position.mode') === 'fixed')
      .map((descendant) => descendant.getId())
  }
}
