import ToolbarCTAButtonLight from 'components/Library/Components/Toolbar/ToolbarCTAButtonLight/ToolbarCTAButtonLight'
import useAnalytics from 'hooks/admin/useAnalytics'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function PreviewButton() {
  const { metricPreview } = useAnalytics()
  const preview = usePanelHelper(editor.getUI().getPreviewPanel())
  if (!preview.settings) return <></>
  const { open } = preview.settings
  const { setOpen } = preview.handlers

  return (
    <ToolbarCTAButtonLight
      text="Preview"
      onClick={() => {
        metricPreview()
        setOpen(!open)
      }}
    />
  )
}
