import { WriteDocument } from 'application/document'
import { BottomUpDependencyGraphFactory } from '../dependency/up'
import { SizeLayoutEngine } from './size'
import { IntrinsicSizeInitializer } from './initialize/intrinsic'
import { NodeSizeStateMap } from './node/map'
import { NodeSizeStateFactory } from './node/generator'
import { AspectRatioInitializer } from './initialize/ratio'
import { ContentSizeInitializer } from './initialize/content'
import { LayoutCalculator } from './layout/layout'
import { MinMaxSizeInitializer } from './initialize/minMax'
import { FixedSizeInitializer } from './initialize/fixed'
import { DirtyInitializer } from './initialize/dirty'
import { TextSizeCalculator } from './types'

export class SizeLayoutEngineFactory {
  create = (
    document: WriteDocument,
    textSize: TextSizeCalculator
  ): SizeLayoutEngine => {
    const bottomUpFactory = new BottomUpDependencyGraphFactory(document)

    const sizeMapFactory = new NodeSizeStateFactory(document)
    const sizeMap = new NodeSizeStateMap(sizeMapFactory)

    const intrinsic = new IntrinsicSizeInitializer(document, textSize, sizeMap)
    const aspectRatio = new AspectRatioInitializer(document, sizeMap)
    const content = new ContentSizeInitializer(document, sizeMap)
    const minMax = new MinMaxSizeInitializer(document, sizeMap)
    const fixed = new FixedSizeInitializer(document, sizeMap)
    const dirty = new DirtyInitializer(document, sizeMap)

    const layout = new LayoutCalculator(document, textSize, sizeMap)

    return new SizeLayoutEngine(
      document,
      sizeMap,
      bottomUpFactory,
      intrinsic,
      aspectRatio,
      content,
      minMax,
      fixed,
      dirty,
      layout
    )
  }
}
