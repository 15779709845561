import { ReadOnlyNode } from 'application/node'
import { RelativeOffset, RelativeOffsetMap } from './types'

export function getRelativeOffsetMap(): RelativeOffsetMap {
  return {}
}

export function getRelativeOffset(
  id: string,
  relativeOffsetMap: RelativeOffsetMap
): RelativeOffset {
  if (!relativeOffsetMap[id]) {
    relativeOffsetMap[id] = { x: 0, y: 0 }
  }
  return relativeOffsetMap[id]
}

export function getLeft(
  node: ReadOnlyNode,
  parent: ReadOnlyNode
): number | null {
  const left = node.getStyleAttribute('position.left.auto')
  switch (left) {
    case 'auto':
    case undefined:
      return null
    case 'fixed':
      const px = node.getStyleAttribute('position.left')
      return px || 0
    case 'percent':
      const parentWidth = parent.getBaseAttribute('w')
      const percent = node.getStyleAttribute('position.left.percent')
      if (percent === undefined) return 0
      return (percent / 100) * parentWidth
  }
}

export function getTop(
  node: ReadOnlyNode,
  parent: ReadOnlyNode
): number | null {
  const top = node.getStyleAttribute('position.top.auto')
  switch (top) {
    case 'auto':
    case undefined:
      return null
    case 'fixed':
      const px = node.getStyleAttribute('position.top')
      return px || 0
    case 'percent':
      const parentHeight = parent.getBaseAttribute('h')
      const percent = node.getStyleAttribute('position.top.percent')
      if (percent === undefined) return 0
      return (percent / 100) * parentHeight
  }
}

export function getRight(
  node: ReadOnlyNode,
  parent: ReadOnlyNode
): number | null {
  const right = node.getStyleAttribute('position.right.auto')
  switch (right) {
    case 'auto':
    case undefined:
      return null
    case 'fixed':
      const px = node.getStyleAttribute('position.right')
      return px || 0
    case 'percent':
      const parentWidth = parent.getBaseAttribute('w')
      const percent = node.getStyleAttribute('position.right.percent')
      if (percent === undefined) return 0
      return ((100 - percent) / 100) * parentWidth
  }
}

export function getBottom(
  node: ReadOnlyNode,
  parent: ReadOnlyNode
): number | null {
  const bottom = node.getStyleAttribute('position.bottom.auto')
  switch (bottom) {
    case 'auto':
    case undefined:
      return null
    case 'fixed':
      const px = node.getStyleAttribute('position.bottom')
      return px || 0
    case 'percent':
      const parentHeight = parent.getBaseAttribute('h')
      const percent = node.getStyleAttribute('position.bottom.percent')
      if (percent === undefined) return 0
      return ((100 - percent) / 100) * parentHeight
  }
}
