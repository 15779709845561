import { WriteDocument } from 'application/document'
import { NodeSizeStateMap } from '../node/map'
import { NodeSizeState } from '../node/node'

export class MinMaxSizeInitializer {
  private document: WriteDocument
  private sizeMap: NodeSizeStateMap

  constructor(document: WriteDocument, sizeMap: NodeSizeStateMap) {
    this.document = document
    this.sizeMap = sizeMap
  }

  initialize = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const size = this.sizeMap.get(id)
    if (!size) return

    this.initializeMinMax(id, size)
  }

  private initializeMinMax = (id: string, size: NodeSizeState): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const minWMode = node.getStyleAttribute('size.w.min.mode')
    const minHMode = node.getStyleAttribute('size.h.min.mode')
    const maxWMode = node.getStyleAttribute('size.w.max.mode')
    const maxHMode = node.getStyleAttribute('size.h.max.mode')
    const minW = node.getStyleAttribute('size.w.min')
    const minH = node.getStyleAttribute('size.h.min')
    const maxW = node.getStyleAttribute('size.w.max')
    const maxH = node.getStyleAttribute('size.h.max')

    if (minWMode === 'fixed' && minW !== undefined) size.setMinW(minW)
    if (minHMode === 'fixed' && minH !== undefined) size.setMinH(minH)
    if (maxWMode === 'fixed' && maxW !== undefined) size.setMaxW(maxW)
    if (maxHMode === 'fixed' && maxH !== undefined) size.setMaxH(maxH)
  }
}
