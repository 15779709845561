import { DocumentSelection } from 'application/selection'
import { Command, CommandHandler } from './command'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection/types'
import { UpdateHandler } from './update/update'
import { Update } from './update/types'
import { ClientUpdateListener } from './types'
import { IdGenerator } from 'application/ids'

type ClientUpdateListenerMap = {
  [key: string]: ClientUpdateListener
}

interface LayoutEngine {
  layout(): void
}

export class Client {
  private readOnlyDocument: ReadOnlyDocument
  private idGenerator: IdGenerator
  private selection: DocumentSelection
  private updateHandler: UpdateHandler
  private layoutEngine: LayoutEngine

  private commandHandlers: CommandHandler[]
  private updateListeners: ClientUpdateListenerMap

  constructor(
    readOnlyDocument: ReadOnlyDocument,
    idGenerator: IdGenerator,
    selection: DocumentSelection,
    updateHandler: UpdateHandler,
    layoutEngine: LayoutEngine
  ) {
    this.readOnlyDocument = readOnlyDocument
    this.idGenerator = idGenerator
    this.selection = selection
    this.updateHandler = updateHandler
    this.layoutEngine = layoutEngine

    this.commandHandlers = []
    this.updateListeners = {}
  }

  registerHandler = (handler: CommandHandler): void => {
    this.commandHandlers.push(handler)
  }

  handle = (command: Command | Command[]): void => {
    if (Array.isArray(command)) {
      for (const c of command) {
        this.handleCommand(c)
      }
    } else {
      this.handleCommand(command)
    }
    this.layoutEngine.layout()
    this.publishUpdates()
  }

  getDocument = (): ReadOnlyDocument => {
    return this.readOnlyDocument
  }

  getIdGenerator = (): IdGenerator => {
    return this.idGenerator
  }

  getSelection = (): ReadOnlyDocumentSelection => {
    return this.selection
  }

  subscribe = (key: string, listener: ClientUpdateListener): void => {
    this.updateListeners[key] = listener
  }

  unsubscribe = (key: string): void => {
    delete this.updateListeners[key]
  }

  private handleCommand = (command: Command): void => {
    for (const handler of this.commandHandlers) {
      handler.handle(command)
    }
  }

  private publishUpdates = (): void => {
    const updates = this.updateHandler.get()
    const updatesByType: { [key: string]: Update[] } = {}
    for (const update of updates) {
      if (!updatesByType[update.type]) {
        updatesByType[update.type] = []
      }
      updatesByType[update.type].push(update)
    }

    for (const listener of Object.values(this.updateListeners)) {
      const types = listener.getTypes()
      const filteredUpdates = types.reduce((acc: Update[], type) => {
        if (updatesByType[type]) {
          return acc.concat(updatesByType[type])
        }
        return acc
      }, [])
      listener.onUpdate(filteredUpdates)
    }

    this.updateHandler.clear()
  }
}
