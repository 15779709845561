import { StyleMap } from 'application/attributes'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useState } from 'react'
import ClipBlock from './ClipBlock/ClipBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'

export default function RoundingRow() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getRoundingPanel()
  )
  const clip = usePanelHelper(editor.getUI().getClipContentPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  const [custom, setCustom] = useState(settings?.mode === 'custom' || false)
  const hasClip = clip.settings && clip.settings.attributes
  if (!settings || !settings.attributes) {
    if (hasClip) return <PanelRow>{<ClipBlock />}</PanelRow>
    return <></>
  }

  const { setRounding, slideRounding, clearCustom } = handlers
  const { hasOverride } = overrides.handlers
  const {
    'rounding.topLeft': topLeft,
    'rounding.topRight': topRight,
    'rounding.bottomRight': bottomRight,
    'rounding.bottomLeft': bottomLeft,
  } = settings.attributes
  const { mode } = settings
  const split = mode === 'custom' || custom

  return (
    <>
      <PanelRow>
        {hasClip && <ClipBlock />}
        <OverrideReset keys={getAllKeys()}>
          <NumberBlock
            icon={'RadiusTopLeft'}
            tooltipKey={split ? 'TopLeftRounding' : 'Rounding'}
            value={split ? 'Mixed' : topLeft}
            setValue={(v) => setRounding(v, 'all')}
            increment={(v) => slideRounding(v, 'all')}
            decrement={(v) => slideRounding(-v, 'all')}
            min={0}
            step={2}
            presets={presets}
            hasOverride={hasOverride(getAllKeys())}
            disabled={split}
          />
        </OverrideReset>
        {!hasClip && <PanelBlock />}
        <IconBlock
          icon={'RadiusSplit'}
          tooltipKey={'SplitRounding'}
          tooltipDirection={'left'}
          selected={split}
          onClick={() => {
            if (split) clearCustom()
            setCustom(!custom)
          }}
        />
      </PanelRow>
      {split && (
        <>
          <PanelRow>
            <NumberBlock
              icon={'RadiusTopLeft'}
              tooltipKey={'TopLeftRounding'}
              value={topLeft}
              setValue={(v) => setRounding(v, 'topLeft')}
              increment={(v) => slideRounding(v, 'topLeft')}
              decrement={(v) => slideRounding(-v, 'topLeft')}
              min={0}
              step={2}
              presets={presets}
              hasOverride={hasOverride(['rounding.topLeft'])}
            />
            <OverrideReset keys={['rounding.topRight']}>
              <NumberBlock
                icon={'RadiusTopRight'}
                tooltipKey={'TopRightRounding'}
                value={topRight}
                setValue={(v) => setRounding(v, 'topRight')}
                increment={(v) => slideRounding(v, 'topRight')}
                decrement={(v) => slideRounding(-v, 'topRight')}
                min={0}
                step={2}
                presets={presets}
                hasOverride={hasOverride(['rounding.topRight'])}
              />
            </OverrideReset>
          </PanelRow>
          <PanelRow>
            <OverrideReset keys={['rounding.bottomLeft']}>
              <NumberBlock
                icon={'RadiusBottomLeft'}
                tooltipKey={'BottomLeftRounding'}
                value={bottomLeft}
                setValue={(v) => setRounding(v, 'bottomLeft')}
                increment={(v) => slideRounding(v, 'bottomLeft')}
                decrement={(v) => slideRounding(-v, 'bottomLeft')}
                min={0}
                step={2}
                presets={presets}
                hasOverride={hasOverride(['rounding.bottomLeft'])}
              />
            </OverrideReset>
            <OverrideReset keys={['rounding.bottomRight']}>
              <NumberBlock
                icon={'RadiusBottomRight'}
                tooltipKey={'BottomRightRounding'}
                value={bottomRight}
                setValue={(v) => setRounding(v, 'bottomRight')}
                increment={(v) => slideRounding(v, 'bottomRight')}
                decrement={(v) => slideRounding(-v, 'bottomRight')}
                min={0}
                step={2}
                presets={presets}
                hasOverride={hasOverride(['rounding.bottomRight'])}
              />
            </OverrideReset>
          </PanelRow>
        </>
      )}
    </>
  )
}

const presets = [0, 4, 8, 12, 16, 20, 24, 28, 32, 40, 48, 64, 128, 256]

function getAllKeys(): (keyof StyleMap)[] {
  return [
    'rounding.topLeft',
    'rounding.topRight',
    'rounding.bottomRight',
    'rounding.bottomLeft',
  ]
}
