import ReconnectingGraphic from './ReconnectingGraphic/ReconnectingGraphic'

export default function ReconnectingScreen() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        pointerEvents: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        zIndex: 5,
      }}
    >
      <ReconnectingGraphic />
    </div>
  )
}
