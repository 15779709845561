import { editor } from 'index'
import { useCallback, useEffect } from 'react'

export default function useCreatePlayground() {
  const { setPlayground } = editor.getUI().getProjectService()

  const handleCreatePlayground = useCallback(async () => {
    await setPlayground()
  }, [setPlayground])

  useEffect(() => {
    handleCreatePlayground()
  }, [handleCreatePlayground])
}
