import { BaseMap } from 'application/attributes'
import { Command, SetNodeAttribute } from 'application/client'
import { ReadOnlyDocumentSelection } from 'application/selection'
import _ from 'lodash'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class AttributesAction {
  private commandHandler: CommandHandler
  private selection: ReadOnlyDocumentSelection

  constructor(
    commandHandler: CommandHandler,
    selection: ReadOnlyDocumentSelection
  ) {
    this.commandHandler = commandHandler
    this.selection = selection
  }

  toggleLocked = (): void => {
    const selected = this.selection.getSelected()
    if (selected.length === 0) return

    const locked = this.allEqualValueBase('locked', true)
    const commands = this.buildBaseCommands('locked', !locked)
    this.commandHandler.handle(commands)
    this.commandHandler.handle([{ type: 'commit' }])
  }

  private allEqualValueBase = (
    key: keyof BaseMap,
    value: BaseMap[keyof BaseMap]
  ): boolean => {
    const selected = this.selection.getSelected()
    return selected.every((n) => _.isEqual(n.getBaseAttribute(key), value))
  }

  private buildBaseCommands = (
    key: keyof BaseMap,
    value: BaseMap[keyof BaseMap]
  ): SetNodeAttribute[] => {
    const selected = this.selection.getSelected()
    return selected.map((node) => {
      return {
        type: 'setNodeAttribute',
        params: {
          id: node.getId(),
          base: { [key]: value },
          style: {},
        },
      }
    })
  }
}
