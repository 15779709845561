import { WriteDocument } from 'application/document'
import { NodeSizeState } from './node'

export class NodeSizeStateFactory {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  create = (id: string): NodeSizeState | null => {
    const node = this.document.getNode(id)
    if (!node) throw new Error(`Node with id ${id} not found`)

    return new NodeSizeState(node.getId())
  }
}
