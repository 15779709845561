import { LayoutEngineCalculator, LayoutEngineListener } from './types'

export class LayoutEngine {
  private calculators: LayoutEngineCalculator[]
  private listener: LayoutEngineListener

  constructor(
    calculators: LayoutEngineCalculator[],
    listener: LayoutEngineListener
  ) {
    this.calculators = calculators
    this.listener = listener
  }

  computeLayout = (nodes: Set<string>): void => {
    if (this.listener.onStartLayout) {
      this.listener.onStartLayout()
    }

    for (const calculator of this.calculators) {
      calculator.layout(nodes)
    }

    if (this.listener.onFinishLayout) {
      this.listener.onFinishLayout()
    }
  }
}
