import { clamp } from 'lodash'
import { ZOOM_MAX, ZOOM_MIN } from './consts'
import { Point, Rectangle } from 'application/shapes'
import {
  Camera,
  MouseDevice,
  PanCameraMouseAction,
  ZoomCameraAction,
  PanCameraWheelAction,
} from './types'
import { BrowserType } from 'application/browser'

export class CameraCalculation {
  static panCameraMouse = (action: PanCameraMouseAction) => {
    const { camera, mode, type, e } = action

    const multiplier = this.getPanMultiplier(e, type)
    let dx = -action.e.movementX * multiplier
    let dy = action.e.movementY * multiplier

    switch (mode) {
      case 'horizontal':
        dx *= 5
        dy = 0
        break
      case 'vertical':
        dy *= 5
        dx = 0
        break
      case 'drag':
        dx = e.movementX
        dy = -e.movementY
        break
    }

    return this.panCamera(camera, dx, dy)
  }

  static panCameraWheel = (action: PanCameraWheelAction): Camera => {
    const { camera, mode, type, e } = action

    const multiplier = this.getPanMultiplier(e, type)
    let dx = -e.deltaX * multiplier
    let dy = e.deltaY * multiplier

    switch (mode) {
      case 'horizontal':
        dy = 0
        break
      case 'vertical':
        dx = 0
        break
      case 'drag':
        dx = -e.movementX
        dy = -e.movementY
        break
    }

    return this.panCamera(camera, dx, dy)
  }

  static zoomCamera = (action: ZoomCameraAction): Camera => {
    const { camera, e, type, canvasRect } = action

    const multiplier = this.getPanMultiplier(e, type)
    const delta = e.deltaY * multiplier
    const zoomDivisor = this.getZoomDivisor(type)

    const dz = delta / zoomDivisor
    const point = {
      x: e.clientX - canvasRect.x,
      y: e.clientY - canvasRect.y,
    }

    return this.zoomCameraHelper(camera, point, dz)
  }

  static getMouseDevice = (e: WheelEvent | MouseEvent): MouseDevice => {
    if (e instanceof WheelEvent) {
      return this.getMouseDeviceFromWheelEvent(e)
    }
    return 'mouse'
  }

  static centerCameraOnRectangle = (
    rectangle: Rectangle,
    canvasRectangle: Rectangle
  ): Camera => {
    const paddedRectangle = {
      x: rectangle.x - 200,
      y: rectangle.y - 200,
      w: rectangle.w + 400,
      h: rectangle.h + 400,
    }
    const z = Math.min(
      (canvasRectangle.w - 550) / paddedRectangle.w,
      (canvasRectangle.h - 200) / paddedRectangle.h
    )
    const x =
      paddedRectangle.x + paddedRectangle.w / 2 - canvasRectangle.w / z / 2
    const y =
      paddedRectangle.y + paddedRectangle.h / 2 - canvasRectangle.h / z / 2
    return {
      x: x,
      y: -y,
      z: z,
    }
  }

  static moveCameraOnRectangle = (
    rectangle: Rectangle,
    canvasRectangle: Rectangle,
    camera: Camera
  ): Camera => {
    const paddedRectangle = {
      x: rectangle.x - 200,
      y: rectangle.y - 200,
      w: rectangle.w + 400,
      h: rectangle.h + 400,
    }
    const z = Math.min(
      Math.min(
        canvasRectangle.w / paddedRectangle.w,
        canvasRectangle.h / paddedRectangle.h
      ),
      camera.z
    )
    const x =
      paddedRectangle.x + paddedRectangle.w / 2 - canvasRectangle.w / z / 2
    const y =
      paddedRectangle.y + paddedRectangle.h / 2 - canvasRectangle.h / z / 2
    return {
      x: x,
      y: -y,
      z: z,
    }
  }

  static isRectangleInView = (
    rectangle: Rectangle,
    viewableRectangle: Rectangle
  ): boolean => {
    return (
      rectangle.x >= viewableRectangle.x &&
      rectangle.x + rectangle.w <= viewableRectangle.x + viewableRectangle.w &&
      rectangle.y >= viewableRectangle.y &&
      rectangle.y + rectangle.h <= viewableRectangle.y + viewableRectangle.h
    )
  }

  private static getMouseDeviceFromWheelEvent = (
    e: WheelEvent
  ): MouseDevice => {
    return Math.abs(e.deltaY) > 4 ? 'mouse' : 'touchpad'
  }

  static isTouchpad = (e: WheelEvent): boolean => {
    return Math.abs(e.deltaY) < 15
  }

  private static getPanMultiplier = (
    e: WheelEvent | MouseEvent,
    mouseDevice: MouseDevice
  ): number => {
    if (e instanceof WheelEvent) {
      return this.getPanMultiplierFromWheelEvent(mouseDevice)
    } else if (e instanceof MouseEvent) {
      return this.getPanMultiplierFromMouseEvent(mouseDevice)
    }
    return 1
  }

  private static getPanMultiplierFromMouseEvent = (
    mouseDevice: MouseDevice
  ): number => {
    switch (mouseDevice) {
      case 'mouse':
        return 0.5
      case 'touchpad':
        return 2.25
    }
  }

  private static getPanMultiplierFromWheelEvent = (
    mouseDevice: MouseDevice
  ): number => {
    switch (mouseDevice) {
      case 'mouse':
        return 1.0
      case 'touchpad':
        return 2.25
    }
  }

  private static getZoomDivisor = (mouseDevice: MouseDevice): number => {
    const browser = this.getBrowser()
    switch (browser) {
      case 'chrome':
        return mouseDevice === 'mouse' ? 800 : 120
      case 'firefox':
        return mouseDevice === 'mouse' ? 250 : 120
      case 'edge':
        return mouseDevice === 'mouse' ? 250 : 120
      case 'safari':
        return mouseDevice === 'mouse' ? 800 : 240
      default:
        return mouseDevice === 'mouse' ? 1000 : 120
    }
  }

  private static getBrowser = (): BrowserType => {
    const userAgent = navigator.userAgent
    if (/Edg/.test(userAgent)) return 'edge'
    if (/Chrome/.test(userAgent)) return 'chrome'
    if (/Firefox/.test(userAgent)) return 'firefox'
    if (/Safari/.test(userAgent)) return 'safari'
    return 'other'
  }

  private static panCamera = (
    camera: Camera,
    dx: number,
    dy: number
  ): Camera => {
    return {
      x: camera.x - dx / camera.z,
      y: camera.y - dy / camera.z,
      z: camera.z,
    }
  }

  private static zoomCameraHelper = (
    camera: Camera,
    point: Point,
    dz: number
  ): Camera => {
    const zoom = clamp(camera.z - dz * camera.z, ZOOM_MIN, ZOOM_MAX)

    const p1 = this.screenToCanvas(point, camera)
    const p2 = this.screenToCanvas(point, { ...camera, z: zoom })

    return {
      x: camera.x - (p2.x - p1.x),
      y: camera.y + (p2.y - p1.y),
      z: zoom,
    }
  }

  private static screenToCanvas = (point: Point, camera: Camera): Point => {
    return {
      x: point.x / camera.z + camera.x,
      y: point.y / camera.z - camera.y,
    }
  }
}
