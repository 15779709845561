import posthog from 'posthog-js'
import { useEffect } from 'react'

const {
  REACT_APP_PUBLIC_POSTHOG_KEY,
  REACT_APP_PUBLIC_POSTHOG_HOST,
  REACT_APP_POSTHOG_ENV,
} = process.env

export default function usePosthogInit() {
  useEffect(() => {
    if (REACT_APP_POSTHOG_ENV === 'none') return
    posthog.init(REACT_APP_PUBLIC_POSTHOG_KEY || '', {
      api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
    })
  }, [])
}
