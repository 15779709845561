import { WriteDocument } from 'application/document'
import { PositionNodeMap } from '../types'
import { getPositionNode } from '../utils'
import { isAbsolutePositionMode } from 'application/attributes'
import { getPadding } from 'application/layout/utils'

export class BlockPositionCalculator {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  calculate = (id: string, positionMap: PositionNodeMap): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const children = node.getChildren()
    if (!children) return

    const position = getPositionNode(id, positionMap)
    if (!position) return

    let x = position.x + getPadding(node, 'left')
    let y = position.y + getPadding(node, 'top')

    for (const childId of children) {
      const child = this.document.getNode(childId)
      if (!child) continue

      const childPosition = getPositionNode(childId, positionMap)
      if (!childPosition) continue

      childPosition.x = x
      childPosition.y = y

      if (!isAbsolutePositionMode(child)) {
        y += child.getBaseAttribute('h')
      }
    }
  }
}
