import { PasteSnapshot } from '../../types'
import { PasteTargetHandler } from '../paste'
import { WriteDocument } from 'application/document'
import { isAbsolutePositionMode } from 'application/attributes'

export class PasteOnNode implements PasteTargetHandler {
  private nodeId: string
  private document: WriteDocument

  constructor(nodeId: string, document: WriteDocument) {
    this.nodeId = nodeId
    this.document = document
  }

  paste = (snapshot: PasteSnapshot): void => {
    for (const id of snapshot.ids) {
      this.addChild(id, snapshot)
    }
  }

  private addChild = (id: string, snapshot: PasteSnapshot): void => {
    const node = snapshot.nodes[id]
    if (!node) return

    const parent = this.document.getNode(this.nodeId)
    if (!parent) return

    node.setParent(this.nodeId)

    if (parent.getStyleAttribute('autolayout.mode') === 'flex') {
      if (isAbsolutePositionMode(node)) {
        parent.addChild(id, 0)
      } else {
        parent.addChild(id, undefined)
      }
    } else {
      parent.addChild(id, snapshot.indexes[id])
    }
  }
}
