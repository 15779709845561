import { MultiselectStyleMap } from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'

type OpacityPanelKeys = 'opacity'

type OpacityPanelAttributes = Pick<MultiselectStyleMap, OpacityPanelKeys> | null

export interface OpacityPanelState {
  attributes: OpacityPanelAttributes
}

export interface OpacityPanelHandlers {
  setOpacity: (value: number) => void
  slideOpacity: (value: number) => void
}

export class OpacityPanel extends StyleAttributePanel<
  OpacityPanelState,
  OpacityPanelHandlers,
  OpacityPanelKeys
> {
  getSettings(): OpacityPanelState {
    return {
      attributes: this.attributes,
    }
  }

  getHandlers(): OpacityPanelHandlers {
    return {
      setOpacity: this.setOpacity,
      slideOpacity: this.slideOpacity,
    }
  }

  private setOpacity = (value: number): void => {
    this.setMulti({ opacity: value })
  }

  private slideOpacity = (value: number): void => {
    this.slideMulti('opacity', value)
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 100
  }
}
