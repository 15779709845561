import { DesignColor } from 'themes'
import { useEffect } from 'react'

interface LayerRowProps {
  layerId: string
  level: number

  right: boolean

  selected: boolean
  selectedParent: boolean
  selectedAbove: boolean
  selectedBelow: boolean

  hovered: boolean
  renaming: boolean
  lineInside: boolean

  onMouseDown: (e: React.MouseEvent) => void
  onMouseEnter: () => void

  children?: React.ReactNode

  containerRef: React.RefObject<HTMLDivElement>
}

export default function LayerRow({
  layerId,
  level,
  right,
  children,
  selected,
  selectedParent,
  selectedAbove,
  selectedBelow,
  hovered,
  renaming,
  lineInside,
  onMouseDown,
  onMouseEnter,
  containerRef,
}: LayerRowProps) {
  const backgroundColor = getBackgroundColor(selected, selectedParent, renaming)
  const boxShadow = getBoxShadow(
    selected,
    selectedAbove,
    selectedBelow,
    hovered,
    lineInside
  )

  useEffect(() => {
    if (selected) {
      const layerRow = document.getElementById(`layer-row-${layerId}`)
      if (layerRow && isOutOfView(layerRow, containerRef)) {
        layerRow.scrollIntoView({
          behavior: 'instant',
          block: 'center',
          inline: 'center',
        })
      }
    }
  }, [selected, layerId, containerRef])

  return (
    <div
      key={layerId}
      id={`layer-row-${layerId}`}
      className={`layer-row`}
      data-layer-id={layerId}
      data-layer-level={level}
      style={{
        position: 'relative',
        width: '100%',
        height: 32,
        padding: `4px ${right ? 8 : 0}px 4px ${level * 16}px`,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        backgroundColor,
        boxShadow,
      }}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </div>
  )
}

function getBackgroundColor(
  selected: boolean,
  selectedParent: boolean,
  renaming: boolean
): string {
  if (renaming) return 'transparent'
  if (selected) return DesignColor('inputHighlightTint1')
  if (selectedParent) return DesignColor('inputHighlightTint2')
  return 'transparent'
}

function getBoxShadow(
  selected: boolean,
  selectedAbove: boolean,
  selectedBelow: boolean,
  hovered: boolean,
  lineInside: boolean
): string {
  if (!selected && !hovered && !lineInside) return 'none'
  const shadows: string[] = [
    `inset 1px 0px 0px 0px ${DesignColor('inputHighlight')}`,
    `inset -1px 0px 0px 0px ${DesignColor('inputHighlight')}`,
  ]
  if (!selectedAbove) {
    shadows.push(`inset 0 1px 0 0 ${DesignColor('inputHighlight')}`)
  }
  if (!selectedBelow) {
    shadows.push(`inset 0 -1px 0 0 ${DesignColor('inputHighlight')}`)
  }
  return shadows.join(', ')
}

function isOutOfView(
  rowElement: HTMLElement,
  containerRef: React.RefObject<HTMLDivElement>
) {
  const container = containerRef.current
  if (!container) return false
  const rowBox = rowElement.getBoundingClientRect()
  const containerBox = container.getBoundingClientRect()
  return rowBox.top < containerBox.top || rowBox.top > containerBox.bottom
}
