import { useRef, useState } from 'react'
import ColorTab from './Tabs/ColorTab'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import { panelPosition } from 'components/Library/Containers/constants'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import TabsBlock from '../../Tabs/TabsBlock'
import IconBlock from '../../IconBlock/IconBlock/IconBlock'
import useClosePopup from 'hooks/ui/useClosePopup'
import useCommit from 'hooks/editor/useCommit'
import MultistepGradientTab from './Tabs/MultistepGradientTab'
import {
  AttributeFill,
  AttributeFillType,
  AttributeGradientType,
  createNewGradientFill,
  createNewImageFill,
} from 'application/attributes'
import ImageTab from './Tabs/ImageTab'

interface FillPopupProps {
  fill: AttributeFill
  updateFill: (fill: AttributeFill) => void

  modes: AttributeFillType[]
  gradientOptions?: AttributeGradientType[]

  setOpen: (open: boolean) => void

  exceptionRef: React.RefObject<HTMLDivElement>

  popupTop?: number
  popupRight?: number
  ignorePopup?: boolean
}

export default function FillPopup(props: FillPopupProps) {
  const commitUpdate = useCommit()
  const popupRef = useRef<HTMLDivElement>(null)

  const { fill, modes, setOpen, exceptionRef } = props
  const [tab, setTab] = useState<AttributeFillType>(fill.type)

  useClosePopup({
    ref: popupRef,
    close: () => setOpen(false),
    exceptionRef: exceptionRef,
    exceptionIds: ['image-library'],
    ignorePopup: props.ignorePopup,
  })

  const content = getContent(props)
  const position = getPopupPosition(exceptionRef, tab)

  const handleSetTab = (newTab: string) => {
    const mode = tabs.find((tab) => tab.label === newTab)?.type
    if (!mode) return
    handleModeUpdate(mode, fill, props.updateFill)
    commitUpdate()
    setTab(mode)
  }

  return (
    <Portal>
      <PanelContainer
        id={'fill-popup'}
        popup={true}
        panelRef={popupRef}
        position={position}
      >
        <PanelSection>
          <PanelRow>
            <TabsBlock
              tabs={getTabs(modes).map((tab) => tab.label)}
              selected={getTabs(modes).find((t) => t.type === tab)!.label}
              select={handleSetTab}
            />
            <IconBlock icon="X" onClick={() => setOpen(false)} />
          </PanelRow>
        </PanelSection>
        {content}
      </PanelContainer>
    </Portal>
  )
}

function getContent(props: FillPopupProps) {
  switch (props.fill.type) {
    case 'color':
      return <ColorTab fill={props.fill} updateFill={props.updateFill} />
    case 'gradient':
      return (
        <MultistepGradientTab fill={props.fill} updateFill={props.updateFill} />
      )
    case 'image':
      return <ImageTab fill={props.fill} updateFill={props.updateFill} />
  }
}

function getPopupPosition(
  exceptionRef: React.RefObject<HTMLDivElement>,
  tab: AttributeFillType
): panelPosition {
  if (!exceptionRef.current) return { top: 0, left: 0 }
  const rect = exceptionRef.current.getBoundingClientRect()
  const height = getHeight(tab)
  if (rect.top + height + 64 > window.innerHeight) {
    return {
      position: 'absolute',
      bottom: 64,
      right: 248,
    }
  } else {
    return {
      position: 'absolute',
      top: rect.top - 8,
      right: 248,
    }
  }
}

function getHeight(tab: AttributeFillType): number {
  switch (tab) {
    case 'color':
      return 417
    case 'gradient':
      return 240
    case 'image':
      return 328
  }
}

function handleModeUpdate(
  mode: AttributeFillType,
  fill: AttributeFill,
  updateFill: (fill: AttributeFill) => void
) {
  if (mode === fill.type) return
  switch (mode) {
    case 'color':
      updateFill({
        ...fill,
        type: mode,
        gradient: undefined,
        image: undefined,
      })
      return
    case 'gradient':
      updateFill({
        ...fill,
        type: mode,
        image: undefined,
        gradient: createNewGradientFill(fill.color),
      })
      return
    case 'image':
      updateFill({
        ...fill,
        type: mode,
        gradient: undefined,
        image: createNewImageFill(),
      })
      return
  }
}

function getTabs(
  modes: AttributeFillType[]
): { type: AttributeFillType; label: string }[] {
  return tabs.filter((tab) => modes.includes(tab.type))
}

const tabs: { type: AttributeFillType; label: string }[] = [
  { type: 'color', label: 'Color' },
  { type: 'gradient', label: 'Gradient' },
  { type: 'image', label: 'Image' },
]
