import { WriteDocument } from 'application/document'
import { PositionNodeMap } from '../types'
import { TopDownDependencyGraphFactory } from 'application/layout/dependency/down'
import { LayoutDependencyGraphFactory } from 'application/layout/types'
import {
  getNonDependentNodes,
  removeNode,
} from 'application/layout/dependency/utils'
import { getRelativeOffsetMap } from './utils'
import { RelativeOffsetMap } from './types'
import { RelativePositionCalculator } from './relative'
import { AbsolutePositionCalculator } from './absolute'
import { FixedPositionCalculator } from './fixed'
import { StickyPositionCalculator } from './sticky'

export class OffsetPositionCalculator {
  private document: WriteDocument
  private topDownFactory: LayoutDependencyGraphFactory
  private relative: RelativePositionCalculator
  private absolute: AbsolutePositionCalculator
  private fixed: FixedPositionCalculator
  private sticky: StickyPositionCalculator

  constructor(
    document: WriteDocument,
    topDownFactory: TopDownDependencyGraphFactory,
    relative: RelativePositionCalculator,
    absolute: AbsolutePositionCalculator,
    fixed: FixedPositionCalculator,
    sticky: StickyPositionCalculator
  ) {
    this.document = document
    this.topDownFactory = topDownFactory
    this.relative = relative
    this.absolute = absolute
    this.fixed = fixed
    this.sticky = sticky
  }

  calculate = (ids: Set<string>, positionMap: PositionNodeMap): void => {
    const offsetMap = getRelativeOffsetMap()
    const graph = this.topDownFactory.create(ids)
    let terminalNodes = getNonDependentNodes(graph)
    while (terminalNodes.length > 0) {
      for (const terminalNode of terminalNodes) {
        this.computeOffsetPosition(terminalNode.id, positionMap, offsetMap)
        removeNode(terminalNode, graph)
      }
      terminalNodes = getNonDependentNodes(graph)
    }
  }

  private computeOffsetPosition = (
    id: string,
    positionMap: PositionNodeMap,
    offsetMap: RelativeOffsetMap
  ): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const parent = this.document.getParent(node)
    if (!parent || parent.getBaseAttribute('type') === 'canvas') return

    const positionMode = node.getStyleAttribute('position.mode')
    switch (positionMode) {
      case 'auto':
        this.relative.calculate(id, positionMap, offsetMap)
        break
      case 'absolute':
        this.absolute.calculate(id, positionMap, offsetMap)
        break
      case 'fixed':
        this.fixed.calculate(id, positionMap, offsetMap)
        break
      case 'sticky':
        this.sticky.calculate(id, positionMap, offsetMap)
        break
    }
  }
}
