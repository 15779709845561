import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PreviewButton from './PreviewButton/PreviewButton'
import ToolbarDivider from 'components/Library/Components/Toolbar/ToolbarDivider/ToolbarDivider'
import PublishButton from './PublishButton/PublishButton'
import SettingsButton from './SettingsButton/SettingsButton'
import useIsPlayground from 'hooks/playground/useIsPlayground'
import SignupButton from './SignupButton/SignupButton'

export default function RightTopbar() {
  const isPlayground = useIsPlayground()

  return (
    <PanelContainer
      position={{
        position: 'absolute',
        top: 8,
        right: 8,
      }}
      width={232}
      height={48}
      paddingH={10}
      gap={8}
      direction="row"
    >
      <SettingsButton disabled={isPlayground} />
      <ToolbarDivider />
      <PreviewButton />
      {isPlayground ? <SignupButton /> : <PublishButton />}
    </PanelContainer>
  )
}
