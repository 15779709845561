import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import { AttributeSizeRatioMode } from 'application/attributes'
import { PanelAspectRatioType } from 'editor/ui/sizeRatioPanel'

export default function OpacityRow() {
  const opacityPanel = usePanelHelper(editor.getUI().getOpacityPanel())
  const ratioPanel = usePanelHelper(editor.getUI().getSizeRatioPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!opacityPanel.settings || !opacityPanel.settings.attributes) return <></>
  if (!ratioPanel.settings || !ratioPanel.settings.attributes) return <></>
  if (!overrides.settings) return <></>

  const { setOpacity, slideOpacity } = opacityPanel.handlers
  const { setRatio, setRatioMode } = ratioPanel.handlers
  const { hasOverride } = overrides.handlers
  const { opacity } = opacityPanel.settings.attributes
  const { ratioType, ratioWarning } = ratioPanel.settings
  const { 'size.ratio': ratio, 'size.ratio.mode': mode } =
    ratioPanel.settings.attributes

  return (
    <>
      <PanelRow>
        <OverrideReset keys={['opacity']}>
          <NumberBlock
            icon="Opacity"
            tooltipKey="Opacity"
            value={opacity}
            setValue={setOpacity}
            increment={(v) => slideOpacity(v)}
            decrement={(v) => slideOpacity(-v)}
            min={0}
            max={100}
            unit="%"
            presets={opacityPresets}
            hasOverride={hasOverride(['opacity'])}
          />
        </OverrideReset>
        <OverrideReset keys={['size.ratio', 'size.ratio.mode']}>
          <Dropdown
            tooltipKey={'AspectRatio'}
            tooltipDirection={ratioWarning ? 'left' : undefined}
            warning={ratioWarning}
            options={getSizeRatioOptions(ratioType)}
            selected={getSelected(ratio, mode)}
            hasIcon={true}
            defaultIcon={'AspectRatio'}
            mixedIcon={'AspectRatio'}
            mixed={mode === 'Mixed' || ratio === 'Mixed'}
            select={(v) => {
              if (v === 'Mixed') return
              if (v === 'custom') {
                setRatioMode('custom')
                setRatio(1)
              } else if (v === 'auto') {
                setRatioMode('auto')
              } else {
                setRatioMode('fixed')
                setRatio(v)
              }
            }}
            empty={ratioType === 'none' && mode === 'auto'}
            hasOverride={hasOverride(['size.ratio', 'size.ratio.mode'])}
            commit={true}
          />
        </OverrideReset>
      </PanelRow>
      {mode === 'custom' && (
        <PanelRow>
          <PanelBlock />
          <OverrideReset keys={['size.ratio']}>
            <NumberBlock
              icon={'AspectRatio'}
              tooltipKey={'AspectRatioCustom'}
              value={ratio}
              setValue={setRatio}
              hasOverride={hasOverride(['size.ratio'])}
              decimals={2}
            />
          </OverrideReset>
        </PanelRow>
      )}
    </>
  )
}

const opacityPresets = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0]

type sizeRatioOption = 'auto' | 'custom' | number

function getSizeRatioOptions(
  type: PanelAspectRatioType
): dropdownOption<sizeRatioOption>[] {
  return [
    { value: 'auto', text: type === 'none' ? 'Ratio' : 'Default' },
    { value: 1 / 1, text: '1:1' },
    { value: 2 / 1, text: '2:1' },
    { value: 2.39 / 1, text: '2.39:1' },
    { value: 3 / 2, text: '3:2' },
    { value: 2 / 3, text: '2:3' },
    { value: 4 / 3, text: '4:3' },
    { value: 16 / 9, text: '16:9' },
    { value: 'custom', text: 'Custom' },
  ]
}

function getSelected(
  value: 'Mixed' | number | undefined,
  mode: 'Mixed' | AttributeSizeRatioMode | undefined
): 'Mixed' | sizeRatioOption {
  if (mode === 'auto') return 'auto'
  if (mode === 'custom') return 'custom'
  if (value === undefined) return 'Mixed'
  if (value === 'Mixed') return 'Mixed'
  return value
}
