import { TemplateInfo } from 'application/service'
import { panelShadow } from 'components/Library/Containers/constants'
import Text from 'components/Library/Text/Text'
import { DesignColor } from 'themes'
import Icon from '../Icon/Icon/Icon'
import { useRef } from 'react'
import useHovered from 'hooks/ui/useHovered'

interface TemplateCardProps {
  template: TemplateInfo

  select(template: TemplateInfo): void
  copy(template: TemplateInfo): void
}

export default function TemplateCard({
  template,
  select,
  copy,
}: TemplateCardProps) {
  const cardRef = useRef<HTMLDivElement>(null)
  const iconRef = useRef<HTMLDivElement>(null)

  const cardHovered = useHovered<boolean>({ ref: cardRef })
  const iconHovered = useHovered<boolean>({ ref: iconRef })

  const boxShadow = getBoxShadow(cardHovered.hovered || false)

  return (
    <div
      ref={cardRef}
      style={{
        width: '100%',
        height: 'fit-content',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px 16px',
        boxSizing: 'border-box',
        boxShadow: boxShadow,
        backgroundColor: DesignColor('panelBackground'),
        borderRadius: 8,
        cursor: 'pointer',
      }}
      onClick={() => select(template)}
      onMouseEnter={() => cardHovered.setHovered(true)}
      onMouseLeave={() => cardHovered.setHovered(false)}
    >
      <div
        style={{
          width: '100%',
          height: 'fit-content',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: DesignColor('canvasBackground'),
          boxShadow: `inset 0px 0px 4px 0px rgba(0, 0, 0, 0.25)`,
        }}
      >
        <img
          src={template.previewUrl}
          style={{
            width: '100%',
            height: 'auto',
            aspectRatio: '4 / 3',
            objectFit: 'contain',
          }}
          alt={template.name}
        />
      </div>
      <div
        style={{
          width: '100%',
          height: 'fit-content',
          padding: '16px 0 0 0',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          justifyContent: 'space-between',
        }}
      >
        <Text styles={{ size: 14, weight: 'medium' }}>{template.name}</Text>
        <div
          ref={iconRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={(e) => {
            e.stopPropagation()
            copy(template)
          }}
          onMouseEnter={() => iconHovered.setHovered(true)}
          onMouseLeave={() => iconHovered.setHovered(false)}
        >
          <Icon
            icon="Copy"
            size={20}
            color={
              iconHovered.hovered
                ? DesignColor('inputHighlight')
                : DesignColor('text1')
            }
          />
        </div>
      </div>
    </div>
  )
}

function getBoxShadow(hovered: boolean) {
  return [
    panelShadow,
    `inset 0px 0px 0px 1px ${
      hovered ? DesignColor('inputHighlight') : DesignColor('panelBorder')
    }`,
  ].join(', ')
}
