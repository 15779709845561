import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import TextInputBlock from 'components/Library/Components/Text/TextInputBlock/TextInputBlock'
import TextRow from 'components/Library/Components/Text/TextRow/TextRow'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { useEffect, useState } from 'react'
import DNSGrid from './DNSGrid'
import useToast from 'hooks/editor/useToast'

interface DomainSettingsProps {
  defaultDomain: string

  validated: boolean
  premium: boolean
  premiumDomain?: string
  setPremiumDomain: (domain: string) => void

  ip1: string
  ip2: string
  fileserver: string

  close: () => void
}

export default function DomainSettings({
  defaultDomain,
  validated,
  premium,
  premiumDomain,
  setPremiumDomain,
  ip1,
  ip2,
  fileserver,
  close,
}: DomainSettingsProps) {
  const toast = useToast()

  const [localDomain, setLocalDomain] = useState<string>('')

  useEffect(() => {
    setLocalDomain('')
  }, [premiumDomain])

  return (
    <>
      <PanelSection width={'fill'}>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={'fill'} mode="title">
            Default Domain
          </TextBlock>
          <IconBlock icon="X" onClick={close} />
        </PanelRow>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={72} mode="label2">
            Default:
          </TextBlock>
          <TextBlock width={'fill'} mode="label1">
            {defaultDomain}
          </TextBlock>
          <IconBlock
            icon="Copy"
            tooltipKey="CopyDomain"
            tooltipDirection="left"
            onClick={() => {
              navigator.clipboard.writeText(defaultDomain)
              toast('Domain copied to clipboard', 'info')
            }}
          />
        </PanelRow>
      </PanelSection>
      <PanelSection width={'fill'}>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={'fill'} mode="title">
            Custom Domain
          </TextBlock>
        </PanelRow>
        {!premium && (
          <PanelRow width={'fill'} paddingL={8} paddingR={16}>
            <TextBlock width={'fill'} mode="label2">
              Upgrade to a premium plan to connect a custom domain.
            </TextBlock>
          </PanelRow>
        )}
        {premium && !premiumDomain && (
          <>
            <PanelRow width={'fill'} paddingL={8} paddingR={16}>
              <TextBlock width={'fill'} mode="label2">
                Connect your domain from an outside domain name service.
              </TextBlock>
            </PanelRow>
            <PanelRow width={'fill'} paddingL={8} paddingR={16}>
              <TextBlock width={96} mode="label2">
                Domain:
              </TextBlock>
              <TextInputBlock
                width={'fill'}
                value={localDomain}
                setValue={setLocalDomain}
                placeholder="mydomain.com"
              />
              <IconBlock
                icon="Plus"
                onClick={() => setPremiumDomain(formatDomain(localDomain))}
              />
            </PanelRow>
          </>
        )}
        {premium && premiumDomain && (
          <>
            <PanelRow width={'fill'} paddingL={8} paddingR={16}>
              <TextBlock width={96} mode="label2">
                Domain:
              </TextBlock>
              <TextBlock width={'fill'} mode={'label1'}>
                {premiumDomain}
              </TextBlock>
              <IconBlock icon="X" onClick={() => setPremiumDomain('')} />
            </PanelRow>
            {!validated && (
              <>
                <TextRow width={'fill'} mode={'label2'}>
                  Now you must setup your DNS records in your domain provider.
                  Domain changes take anywhere between a few minutes and 48
                  hours. After updating your DNS records, please publish your
                  site again to apply the changes. Once the records are set up
                  correctly and you've published, this screen will automatically
                  update.
                </TextRow>
                <DNSGrid ip1={ip1} ip2={ip2} fileserver={fileserver} />
              </>
            )}
          </>
        )}
      </PanelSection>
    </>
  )
}

function formatDomain(domain: string) {
  let updated = domain.replace(/(^\w+:|^)\/\//, '')
  updated = updated.replace('www.', '')
  return updated
}
