import { WriteDocument } from 'application/document'
import { RuleValidator, Violation } from '../types'

export class PositionRulesValidator implements RuleValidator {
  private validators: ((
    nodeId: string,
    document: WriteDocument
  ) => Violation[])[]

  constructor() {
    this.validators = [this.checkDynamicPositionOnCanvas]
  }

  validate(nodeId: string, document: WriteDocument): Violation[] {
    const violations: Violation[] = []

    for (const validator of this.validators) {
      const nodeViolations = validator(nodeId, document)
      violations.push(...nodeViolations)
    }

    return violations
  }

  private checkDynamicPositionOnCanvas = (
    nodeId: string,
    document: WriteDocument
  ): Violation[] => {
    const node = document.getNode(nodeId)
    if (node === undefined) return []

    const parent = document.getParent(node)
    if (parent === undefined) return []
    if (parent.getBaseAttribute('type') !== 'canvas') return []

    if (
      node.getStyleAttribute('position.mode') !== 'absolute' ||
      node.getStyleAttribute('position.top.auto') !== 'fixed' ||
      node.getStyleAttribute('position.left.auto') !== 'fixed' ||
      node.getStyleAttribute('position.right.auto') !== 'auto' ||
      node.getStyleAttribute('position.bottom.auto') !== 'auto'
    ) {
      return [
        {
          type: 'dynamic_position_on_canvas',
          metadata: {
            nodeId: node.getId(),
          },
        },
      ]
    }

    return []
  }
}
