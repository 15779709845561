import { panelShadow } from 'components/Library/Containers/constants'
import MenuButton from 'components/Library/Menus/MenuButton'
import { DesignColor } from 'themes'

interface ProjectGridEmptyProps {
  createProject: () => void
}

export default function ProjectGridEmpty({
  createProject,
}: ProjectGridEmptyProps) {
  return (
    <div
      style={{
        height: '400px',
        margin: '0px 24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
        background: DesignColor('panelBackground'),
        boxShadow: [
          panelShadow,
          `inset 0 0 0 1px ${DesignColor('panelBorder')}`,
        ].join(', '),
        borderRadius: 8,
      }}
    >
      <h2>
        <span
          style={{
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '150%',
            color: DesignColor('text1'),
          }}
        >
          Welcome to Repaint
        </span>{' '}
        <br />
        <span
          style={{
            fontSize: '16px',
            fontWeight: 400,
            color: DesignColor('text1'),
          }}
        >
          Create a project to get started
        </span>
      </h2>
      <MenuButton
        iconKey="Plus"
        label="Create Project"
        onClick={createProject}
      />
    </div>
  )
}
