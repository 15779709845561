import { WriteDocument } from 'application/document'
import { BasePositionCalculator } from './layout/layout'
import { PositionLayoutEngine } from './position'
import { TopDownDependencyGraphFactory } from '../dependency/down'
import { CanvasPositionCalculator } from './layout/canvas'
import { FlexPositionCalculator } from './layout/flex'
import { BlockPositionCalculator } from './layout/block'
import { OffsetPositionCalculator } from './offset/offset'
import { AbsolutePositionCalculator } from './offset/absolute'
import { RelativePositionCalculator } from './offset/relative'
import { FixedPositionCalculator } from './offset/fixed'
import { StickyPositionCalculator } from './offset/sticky'

export class PositionLayoutEngineFactory {
  create = (document: WriteDocument): PositionLayoutEngine => {
    const topDownFactory = new TopDownDependencyGraphFactory(document)

    const canvas = new CanvasPositionCalculator(document)
    const flex = new FlexPositionCalculator(document)
    const block = new BlockPositionCalculator(document)
    const base = new BasePositionCalculator(
      document,
      topDownFactory,
      canvas,
      flex,
      block
    )

    const relative = new RelativePositionCalculator(document)
    const absolute = new AbsolutePositionCalculator(document)
    const fixed = new FixedPositionCalculator(document)
    const sticky = new StickyPositionCalculator(document)
    const offset = new OffsetPositionCalculator(
      document,
      topDownFactory,
      relative,
      absolute,
      fixed,
      sticky
    )

    return new PositionLayoutEngine(document, base, offset)
  }
}
