import { WriteDocument } from 'application/document'
import {
  Violation,
  ViolationDynamicSizeOnCanvas,
  ViolationRemediator,
} from '../types'

export class SizeViolationRemediator implements ViolationRemediator {
  canRemediate(type: Violation['type']): boolean {
    switch (type) {
      case 'dynamic_size_on_canvas':
        return true
      default:
        return false
    }
  }

  remediate(violation: Violation, document: WriteDocument): void {
    switch (violation.type) {
      case 'dynamic_size_on_canvas':
        this.remediateDynamicSizeOnCanvas(violation, document)
        break
    }
  }

  private remediateDynamicSizeOnCanvas = (
    violation: ViolationDynamicSizeOnCanvas,
    document: WriteDocument
  ): void => {
    const node = document.getNode(violation.metadata.nodeId)
    if (node === undefined) return

    const type = node.getBaseAttribute('type')
    const h = node.getBaseAttribute('h')
    const w = node.getBaseAttribute('w')

    if (
      node.getStyleAttribute('size.w.auto') === 'percent' ||
      node.getStyleAttribute('size.w.auto') === 'fill' ||
      (node.getStyleAttribute('size.w.auto') === 'auto' &&
        !['image', 'text'].includes(type))
    ) {
      node.setStyleAttribute('size.w', w)
      node.setStyleAttribute('size.w.auto', 'fixed')
    }

    if (
      node.getStyleAttribute('size.h.auto') === 'percent' ||
      node.getStyleAttribute('size.h.auto') === 'fill'
    ) {
      node.setStyleAttribute('size.h', h)
      node.setStyleAttribute('size.h.auto', 'fixed')
    }
  }
}
