import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import SizeInput from './SizeInput'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'

export default function SizeRow() {
  const sizeRow = usePanelHelper(editor.getUI().getSizePanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())

  if (!sizeRow.settings) return <></>
  if (!sizeRow.settings.attributes) return <></>

  const {
    setSize,
    slideSize,
    setSizeAuto,
    addMinMax,
    removeMinMax,
    setMinMax,
    slideMinMax,
  } = sizeRow.handlers
  const { hasOverride } = overrides.handlers

  const {
    attributes,
    minWMode,
    minHMode,
    maxWMode,
    maxHMode,
    widthDisplay,
    heightDisplay,
    widthOptions,
    heightOptions,
    widthAutoOptions,
    heightAutoOptions,
  } = sizeRow.settings

  const {
    'size.w': width,
    'size.h': height,
    'size.w.percent': widthPercent,
    'size.h.percent': heightPercent,
    'size.w.auto': widthAuto,
    'size.h.auto': heightAuto,
    'size.w.min': minWidth,
    'size.h.min': minHeight,
    'size.w.max': maxWidth,
    'size.h.max': maxHeight,
  } = attributes

  const hasMinWidth = minWMode === 'fixed' || minWMode === 'Mixed'
  const hasMinHeight = minHMode === 'fixed' || minHMode === 'Mixed'
  const hasMaxWidth = maxWMode === 'fixed' || maxWMode === 'Mixed'
  const hasMaxHeight = maxHMode === 'fixed' || maxHMode === 'Mixed'

  return (
    <>
      <PanelRow>
        <OverrideReset keys={['size.w', 'size.w.auto', 'size.w.percent']}>
          <SizeInput
            icon={'Width'}
            tooltipKey={'Width'}
            mode={widthAuto}
            autoOptions={widthAutoOptions}
            setAuto={(v) => setSizeAuto(v, 'w')}
            value={width === undefined ? 'Mixed' : width}
            valueDisplay={widthDisplay}
            valuePercent={widthPercent}
            setValue={(v) => setSize(v, 'w')}
            increment={(v) => slideSize(v, 'w')}
            decrement={(v) => slideSize(-v, 'w')}
            canAddMin={widthOptions.includes('min')}
            canAddMax={widthOptions.includes('max')}
            addMin={() => addMinMax('min', 'w')}
            addMax={() => addMinMax('max', 'w')}
            min={0}
            max={50_000}
            hasOverride={hasOverride([
              'size.w',
              'size.w.auto',
              'size.w.percent',
            ])}
          />
        </OverrideReset>
        <OverrideReset keys={['size.h', 'size.h.auto', 'size.h.percent']}>
          <SizeInput
            icon={'Height'}
            tooltipKey={'Height'}
            mode={heightAuto}
            autoOptions={heightAutoOptions}
            setAuto={(v) => setSizeAuto(v, 'h')}
            value={height === undefined ? 'Mixed' : height}
            valuePercent={heightPercent}
            valueDisplay={heightDisplay}
            setValue={(v) => setSize(v, 'h')}
            increment={(v) => slideSize(v, 'h')}
            decrement={(v) => slideSize(-v, 'h')}
            canAddMin={heightOptions.includes('min')}
            canAddMax={heightOptions.includes('max')}
            addMin={() => addMinMax('min', 'h')}
            addMax={() => addMinMax('max', 'h')}
            min={0}
            max={50_000}
            hasOverride={hasOverride([
              'size.h',
              'size.h.auto',
              'size.h.percent',
            ])}
          />
        </OverrideReset>
      </PanelRow>
      {(hasMaxWidth || hasMaxHeight) && (
        <PanelRow>
          {hasMaxWidth ? (
            <OverrideReset keys={['size.w.max', 'size.w.max.mode']}>
              <NumberBlock
                icon={'MaxWidth'}
                tooltipKey={'MaxWidth'}
                value={maxWidth}
                setValue={(v) => setMinMax(v, 'max', 'w')}
                increment={(v) => slideMinMax(v, 'max', 'w')}
                decrement={(v) => slideMinMax(-v, 'max', 'w')}
                min={0}
                max={50_000}
                onDelete={() => removeMinMax('max', 'w')}
                hasOverride={hasOverride(['size.w.max', 'size.w.max.mode'])}
              />
            </OverrideReset>
          ) : (
            <PanelBlock />
          )}
          {hasMaxHeight && (
            <OverrideReset keys={['size.h.max', 'size.h.max.mode']}>
              <NumberBlock
                icon={'MaxHeight'}
                tooltipKey={'MaxHeight'}
                value={maxHeight}
                setValue={(v) => setMinMax(v, 'max', 'h')}
                increment={(v) => slideMinMax(v, 'max', 'h')}
                decrement={(v) => slideMinMax(-v, 'max', 'h')}
                min={0}
                max={50_000}
                onDelete={() => removeMinMax('max', 'h')}
                hasOverride={hasOverride(['size.h.max', 'size.h.max.mode'])}
              />
            </OverrideReset>
          )}
        </PanelRow>
      )}
      {(hasMinWidth || hasMinHeight) && (
        <PanelRow>
          {hasMinWidth ? (
            <OverrideReset keys={['size.w.min', 'size.w.min.mode']}>
              <NumberBlock
                icon={'MinWidth'}
                tooltipKey={'MinWidth'}
                value={minWidth}
                setValue={(v) => setMinMax(v, 'min', 'w')}
                increment={(v) => slideMinMax(v, 'min', 'w')}
                decrement={(v) => slideMinMax(-v, 'min', 'w')}
                min={0}
                max={50_000}
                onDelete={() => removeMinMax('min', 'w')}
                hasOverride={hasOverride(['size.w.min', 'size.w.min.mode'])}
              />
            </OverrideReset>
          ) : (
            <PanelBlock />
          )}
          {hasMinHeight && (
            <OverrideReset keys={['size.h.min', 'size.h.min.mode']}>
              <NumberBlock
                icon={'MinHeight'}
                tooltipKey={'MinHeight'}
                value={minHeight}
                setValue={(v) => setMinMax(v, 'min', 'h')}
                increment={(v) => slideMinMax(v, 'min', 'h')}
                decrement={(v) => slideMinMax(-v, 'min', 'h')}
                min={0}
                max={50_000}
                onDelete={() => removeMinMax('min', 'h')}
                hasOverride={hasOverride(['size.h.min', 'size.h.min.mode'])}
              />
            </OverrideReset>
          )}
        </PanelRow>
      )}
    </>
  )
}
