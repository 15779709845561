import { ReadOnlyNode } from 'application/node'
import { SizeNode } from './types'
import { ReadOnlyDocument, WriteDocument } from 'application/document'
import {
  isAbsolutePositionMode,
  isAutolayoutChild,
  isDisplayNone,
} from 'application/attributes'
import {
  getGap,
  getLayoutDirection,
  getLayoutMode,
  getPadding,
  isLayoutWrap,
} from '../utils'
import { NodeSizeStateMap } from './node/map'
import { NodeSizeState } from './node/node'
import { truncate } from 'application/math'

export function filterInLayoutChildren(
  children: string[],
  document: ReadOnlyDocument
): string[] {
  return children.filter((childId) => {
    const child = document.getNode(childId)
    if (!child) return false
    if (isAbsolutePositionMode(child)) return false
    if (isDisplayNone(child)) return false
    return true
  })
}

export function divideChildren(
  node: ReadOnlyNode,
  document: ReadOnlyDocument,
  sizeNodeMap: NodeSizeStateMap,
  max: number
): string[][] {
  const children = node.getChildren() || []
  const filtered = filterInLayoutChildren(children, document)
  if (!isLayoutWrap(node)) return [filtered]

  const gap = getGap(node)

  const divided: string[][] = []
  let current: string[] = []

  let total = 0
  for (const childId of filtered) {
    const childSize = sizeNodeMap.get(childId)
    if (!childSize) continue

    const axisSize = childSize.getHypoMainSize() || 0
    total += axisSize

    if (total > max || max <= 0) {
      divided.push(current)
      current = []
      total = axisSize + gap
    } else {
      total += gap
    }

    current.push(childId)
  }

  if (current.length > 0) {
    divided.push(current)
  }

  if (divided.length > 0 && divided[0].length === 0) {
    divided.shift()
  }

  return divided
}

export function getAvailableSpace(
  node: ReadOnlyNode,
  document: ReadOnlyDocument,
  size: SizeNode
): number {
  const direction = getLayoutDirection(node)

  const children = node.getChildren()
  if (!children) return 0

  const gap = getGap(node)
  const autoChildren = children.filter((childId) => {
    const child = document.getNode(childId)
    if (!child) return false
    return isAutolayoutChild(child, node) && !isDisplayNone(child)
  })
  const isWrapped = isLayoutWrap(node)
  const totalGap = isWrapped ? 0 : (gap || 0) * (autoChildren.length - 1)

  switch (direction) {
    case 'row':
    case 'wrap':
      const left = getPadding(node, 'left')
      const right = getPadding(node, 'right')
      return size.w - totalGap - left - right
    case 'column':
      const top = getPadding(node, 'top')
      const bottom = getPadding(node, 'bottom')
      return size.h - totalGap - top - bottom
  }
}

export function computeMinContent(
  id: string,
  sizeMap: NodeSizeStateMap,
  document: WriteDocument,
  mode: 'w' | 'h',
  overflow: boolean = false,
  child: boolean = false
): number {
  const node = document.getNode(id)
  if (!node) return 0

  const size = sizeMap.get(id)
  if (!size) return 0

  let minContent = 0
  switch (mode) {
    case 'w':
      const minContentW = size.getMinContentW()
      const wAuto = node.getStyleAttribute('size.w.auto')
      switch (wAuto) {
        case 'fixed':
          const fixedW = node.getStyleAttribute('size.w')
          if (child && fixedW !== undefined) return fixedW
          break
        case 'percent':
        case 'fill':
          if (child) return 0
          break
      }

      minContent = minContentW
      break
    case 'h':
      const minContentH = size.getMinContentH()
      const hAuto = node.getStyleAttribute('size.h.auto')
      switch (hAuto) {
        case 'fixed':
          const fixedH = node.getStyleAttribute('size.h')
          if (child && fixedH !== undefined) return fixedH
          break
        case 'percent':
        case 'fill':
          if (child) return 0
          break
      }

      minContent = minContentH
      break
  }

  if (node.getStyleAttribute('clip') && !overflow) return minContent

  const children = filterInLayoutChildren(node.getChildren() || [], document)
  if (children.length === 0) return minContent

  const totalGap = computeTotalGap(node, document, mode)
  const layoutDirection = getLayoutDirection(node)
  switch (mode) {
    case 'w':
      switch (layoutDirection) {
        case 'row':
        case 'wrap':
          minContent +=
            totalGap +
            children.reduce((sum, childId) => {
              const childSize = sizeMap.get(childId)
              if (!childSize) return sum

              const childMinContent = minMaxSize(
                computeMinContent(childId, sizeMap, document, 'w', true, true),
                childSize,
                'w'
              )

              return sum + childMinContent
            }, 0)
          break
        case 'column':
          minContent += children.reduce((max, childId) => {
            const childSize = sizeMap.get(childId)
            if (!childSize) return max

            const childMinContent = minMaxSize(
              computeMinContent(childId, sizeMap, document, 'w', true, true),
              childSize,
              'w'
            )

            return Math.max(max, childMinContent)
          }, 0)
          break
      }
      break
    case 'h':
      switch (layoutDirection) {
        case 'column':
          minContent +=
            totalGap +
            children.reduce((sum, childId) => {
              const childSize = sizeMap.get(childId)
              if (!childSize) return sum

              const childMinContent = minMaxSize(
                computeMinContent(childId, sizeMap, document, 'h', true, true),
                childSize,
                'h'
              )

              return sum + childMinContent
            }, 0)
          break
        case 'row':
        case 'wrap':
          minContent += children.reduce((max, childId) => {
            const childSize = sizeMap.get(childId)
            if (!childSize) return max

            const childMinContent = minMaxSize(
              computeMinContent(childId, sizeMap, document, 'h', true, true),
              childSize,
              'h'
            )

            return Math.max(max, childMinContent)
          }, 0)
          break
      }
      break
  }

  return minContent
}

export function computeMaxContent(
  id: string,
  sizeMap: NodeSizeStateMap,
  document: WriteDocument,
  mode: 'w' | 'h'
): number {
  const node = document.getNode(id)
  if (!node) return 0

  const parent = document.getParent(node)
  if (!parent) return 0

  const parentMode = getLayoutMode(parent)
  const parentDirection = getLayoutDirection(parent)

  const size = sizeMap.get(id)
  if (!size) return 0

  let maxContent = 0
  switch (mode) {
    case 'w':
      const maxContentW = size.getMaxContentW()
      maxContent = maxContentW

      const wAuto = node.getStyleAttribute('size.w.auto')
      switch (wAuto) {
        case 'fixed':
          const fixedW = node.getStyleAttribute('size.w')
          if (fixedW !== undefined) return fixedW
          break
      }

      if (parentMode === 'flex' && parentDirection === 'row') {
        const basis = node.getStyleAttribute('flex.basis')
        const clip = node.getStyleAttribute('clip')
        switch (basis) {
          case 'none':
            if (clip) return maxContent
        }
      }
      break
    case 'h':
      const maxContentH = size.getMaxContentH()
      maxContent = maxContentH

      const hAuto = node.getStyleAttribute('size.h.auto')
      switch (hAuto) {
        case 'fixed':
          const fixedH = node.getStyleAttribute('size.h')
          if (fixedH !== undefined) return fixedH
          break
      }

      if (parentMode === 'flex' && parentDirection === 'column') {
        const basis = node.getStyleAttribute('flex.basis')
        const clip = node.getStyleAttribute('clip')
        switch (basis) {
          case 'none':
            if (clip) return maxContent
        }
      }

      break
  }

  const children = filterInLayoutChildren(node.getChildren() || [], document)
  if (children.length === 0) return maxContent

  const totalGap = computeTotalGap(node, document, mode)
  maxContent += totalGap

  const layoutDirection = getLayoutDirection(node)
  switch (mode) {
    case 'w':
      switch (layoutDirection) {
        case 'row':
        case 'wrap':
          maxContent += children.reduce((sum, childId) => {
            const childSize = sizeMap.get(childId)
            if (!childSize) return sum

            const childMaxContent = computeMaxContent(
              childId,
              sizeMap,
              document,
              'w'
            )

            return sum + minMaxSize(childMaxContent, childSize, 'w')
          }, 0)
          break
        case 'column':
          maxContent += children.reduce((max, childId) => {
            const childSize = sizeMap.get(childId)
            if (!childSize) return max

            const childMaxContent = computeMaxContent(
              childId,
              sizeMap,
              document,
              'w'
            )

            return Math.max(max, minMaxSize(childMaxContent, childSize, 'w'))
          }, 0)
          break
      }
      break
    case 'h':
      switch (layoutDirection) {
        case 'column':
        case 'wrap':
          maxContent += children.reduce((sum, childId) => {
            const childSize = sizeMap.get(childId)
            if (!childSize) return sum

            const childMaxContent = computeMaxContent(
              childId,
              sizeMap,
              document,
              'h'
            )

            return sum + minMaxSize(childMaxContent, childSize, 'h')
          }, 0)
          break
        case 'row':
          maxContent += children.reduce((max, childId) => {
            const childSize = sizeMap.get(childId)
            if (!childSize) return max

            const childMaxContent = computeMaxContent(
              childId,
              sizeMap,
              document,
              'h'
            )

            return Math.max(max, minMaxSize(childMaxContent, childSize, 'h'))
          }, 0)
          break
      }
      break
  }

  return maxContent
}

export function computeCrossAxisMinContent(
  id: string,
  sizeMap: NodeSizeStateMap,
  document: WriteDocument,
  mode: 'w' | 'h',
  overflow: boolean = false
): number {
  const node = document.getNode(id)
  if (!node) return 0

  const size = sizeMap.get(id)
  if (!size) return 0

  let minContent = 0
  switch (mode) {
    case 'w':
      const minW = size.getMinW()
      const wAuto = node.getStyleAttribute('size.w.auto')
      switch (wAuto) {
        case 'fixed':
          const fixed = node.getStyleAttribute('size.w')
          if (fixed !== undefined) return Math.max(fixed, minW || 0)
          break
        case 'auto':
          minContent = size.getMinContentW()
      }
      break
    case 'h':
      const minH = size.getMinH()
      const hAuto = node.getStyleAttribute('size.h.auto')
      switch (hAuto) {
        case 'fixed':
          const fixed = node.getStyleAttribute('size.h')
          if (fixed !== undefined) return Math.max(fixed, minH || 0)
          break
        case 'auto':
          minContent = size.getMinContentH()
      }
      break
  }

  if (node.getStyleAttribute('clip') && !overflow) return minContent

  const children = filterInLayoutChildren(node.getChildren() || [], document)
  if (children.length === 0) return minContent

  const totalGap = computeTotalGap(node, document, mode)
  const layoutDirection = getLayoutDirection(node)
  switch (mode) {
    case 'w':
      switch (layoutDirection) {
        case 'row':
        case 'wrap':
          minContent +=
            totalGap +
            children.reduce((sum, childId) => {
              const childSize = sizeMap.get(childId)
              if (!childSize) return sum

              const childMinContent = minMaxSize(
                computeCrossAxisMinContent(
                  childId,
                  sizeMap,
                  document,
                  'w',
                  true
                ),
                childSize,
                'w'
              )

              return sum + childMinContent
            }, 0)
          break
        case 'column':
          minContent += children.reduce((max, childId) => {
            const childSize = sizeMap.get(childId)
            if (!childSize) return max

            const childMinContent = minMaxSize(
              computeCrossAxisMinContent(childId, sizeMap, document, 'w', true),
              childSize,
              'w'
            )

            return Math.max(max, childMinContent)
          }, 0)
          break
      }
      break
    case 'h':
      switch (layoutDirection) {
        case 'column':
          minContent +=
            totalGap +
            children.reduce((sum, childId) => {
              const childSize = sizeMap.get(childId)
              if (!childSize) return sum

              const childMinContent = minMaxSize(
                computeCrossAxisMinContent(
                  childId,
                  sizeMap,
                  document,
                  'h',
                  true
                ),
                childSize,
                'h'
              )

              return sum + childMinContent
            }, 0)
          break
        case 'row':
        case 'wrap':
          minContent += children.reduce((max, childId) => {
            const childSize = sizeMap.get(childId)
            if (!childSize) return max

            const childMinContent = minMaxSize(
              computeCrossAxisMinContent(childId, sizeMap, document, 'h', true),
              childSize,
              'h'
            )

            return Math.max(max, childMinContent)
          }, 0)
          break
      }
      break
  }

  switch (mode) {
    case 'w':
      const minW = size.getMinW()
      return Math.max(minW || 0, minContent)
    case 'h':
      const minH = size.getMinH()
      return Math.max(minH || 0, minContent)
  }
}

export function computeCrossAxisFitContent(
  id: string,
  sizeMap: NodeSizeStateMap,
  document: WriteDocument,
  mode: 'w' | 'h',
  bounds: number
): number {
  const min = computeCrossAxisMinContent(id, sizeMap, document, mode)
  const max = computeMaxContent(id, sizeMap, document, mode)
  return Math.max(min, Math.min(max, bounds))
}

export function sumChildrenFlexBase(
  node: ReadOnlyNode,
  sizeMap: NodeSizeStateMap,
  document: WriteDocument,
  mode: 'w' | 'h'
): number {
  const children = filterInLayoutChildren(node.getChildren() || [], document)
  if (children.length === 0) return 0

  const baseSizeSum = children.reduce((sum, childId) => {
    const childSize = sizeMap.get(childId)
    if (!childSize) return sum

    const baseSize = childSize.getFlexBaseSize()
    if (baseSize === undefined) return sum

    return sum + baseSize
  }, 0)

  const totalGap = computeTotalGap(node, document, mode)

  return baseSizeSum + totalGap
}

function computeTotalGap(
  node: ReadOnlyNode,
  document: ReadOnlyDocument,
  mode: 'w' | 'h'
): number {
  const children = node.getChildren()
  if (!children) return 0

  const layoutMode = getLayoutMode(node)
  if (layoutMode !== 'flex') return 0

  const gap = getGap(node)
  const filtered = filterInLayoutChildren(children, document)

  switch (mode) {
    case 'w':
      switch (getLayoutDirection(node)) {
        case 'row':
          return (gap || 0) * (filtered.length - 1)
        case 'column':
        case 'wrap':
          return 0
      }
      break
    case 'h':
      switch (getLayoutDirection(node)) {
        case 'column':
          return (gap || 0) * (filtered.length - 1)
        case 'row':
        case 'wrap':
          return 0
      }
      break
  }
}
export function minMaxSize(
  size: number,
  sizeState: NodeSizeState,
  axis: 'w' | 'h'
): number {
  switch (axis) {
    case 'w':
      const minW = sizeState.getMinW() || 0
      const maxW = sizeState.getMaxW()
      return Math.min(maxW, Math.max(minW, size))
    case 'h':
      const minH = sizeState.getMinH() || 0
      const maxH = sizeState.getMaxH()
      return Math.min(maxH, Math.max(minH, size))
  }
}

export function computeRatioLockedAxis(
  size: NodeSizeState,
  axis: 'w' | 'h'
): number | undefined {
  const ratio = size.getRatio()
  switch (axis) {
    case 'w':
      const hSet = size.getHDeterminesRatio() && !size.getWDeterminesRatio()
      const h = size.getH()
      if (!hSet || h === undefined || ratio === undefined) return undefined
      return h * ratio
    case 'h':
      const wSet = size.getWDeterminesRatio() && !size.getHDeterminesRatio()
      const w = size.getW()
      if (!wSet || w === undefined || ratio === undefined) return undefined
      return w / ratio
  }
}

export function computeMinPageHeight(size: NodeSizeState): number {
  const w = size.getW()
  if (w === undefined) return 0
  if (w > 1201) return truncate((w / 16) * 9)
  if (w > 768) return truncate((w / 3) * 4)
  return truncate((w / 9) * 16)
}
