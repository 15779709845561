import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

export const Portal = ({ children }: { children: React.ReactNode }) => {
  const portalRoot = document.getElementById('portal-root') || document.body
  return ReactDOM.createPortal(children, portalRoot)
}

export default function PortalRoot() {
  return <PortalRootStyle id="portal-root" />
}

const PortalRootStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
`
