import { NodeSelectionAction } from 'application/action'
import { NodeWrapAction } from 'application/action/wrap'
import {
  BaseMap,
  SelectorName,
  StyleMap,
  canTypeInsertInto,
  createAutolayoutAttributes,
  isContainerType,
} from 'application/attributes'
import { Command } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { Rectangle } from 'application/shapes'
import _ from 'lodash'

interface CommandHandler {
  handle: (command: Command | Command[]) => void
}

export class AddAutolayoutAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private selectionAction: NodeSelectionAction
  private wrap: NodeWrapAction

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    selectionAction: NodeSelectionAction,
    wrap: NodeWrapAction
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.selection = selection
    this.selectionAction = selectionAction
    this.wrap = wrap
  }

  addAutolayout = (): void => {
    const selected = this.selection.getSelected()
    if (selected.length === 0) return

    if (this.getShouldAdd(selected)) {
      this.addAutolayoutToContainer(selected[0], 'default')
    } else {
      this.wrapSelected(selected)
    }
  }

  private wrapSelected = (selected: ReadOnlyNode[]): void => {
    const filtered = selected.filter((n) => {
      const type = n.getBaseAttribute('type')
      return canTypeInsertInto(type, 'frame')
    })

    const filteredIds = filtered.map((n) => n.getId())
    const originalRects = this.getOriginalRectangles(filteredIds)
    const originalAttrs = this.getOriginalAttributes(filteredIds)

    const wrapperId = this.wrap.wrap(filteredIds, 'frame')
    if (!wrapperId) return

    const wrapper = this.document.getNode(wrapperId)
    if (!wrapper) return

    const newAttributes = createAutolayoutAttributes(
      wrapper,
      Object.values(originalAttrs),
      originalRects,
      true
    )

    const mode = newAttributes['autolayout.direction'] === 'row' ? 'x' : 'y'
    this.sortChildren(wrapper, mode)

    const setWrapperAttributes = this.buildSetAttributesCommand(
      wrapperId,
      {},
      { fills: [], ...newAttributes },
      'default'
    )
    this.commandHandler.handle(setWrapperAttributes)

    const setNodeAttributes = filteredIds.map((id) => {
      return this.buildSetAttributesCommand(
        id,
        {},
        {
          'position.mode': 'auto',
          'position.top.auto': 'auto',
          'position.left.auto': 'auto',
          'position.bottom.auto': 'auto',
          'position.right.auto': 'auto',
          'size.w': originalAttrs[id]['size.w'],
          'size.h': originalAttrs[id]['size.h'],
          'size.w.auto': originalAttrs[id]['size.w.auto'],
          'size.h.auto': originalAttrs[id]['size.h.auto'],
          'size.w.percent': originalAttrs[id]['size.w.percent'],
          'size.h.percent': originalAttrs[id]['size.h.percent'],
        },
        'default'
      )
    })
    this.commandHandler.handle(setNodeAttributes)

    this.selectionAction.selectNodes([wrapperId], true)
  }

  private addAutolayoutToContainer = (
    node: ReadOnlyNode,
    selector: SelectorName | undefined
  ): void => {
    const children = this.getChildren(node).filter(
      (n) => n.getStyleAttribute('position.mode') === 'absolute'
    )
    const childrenIds = children.map((n) => n.getId())
    const childRects = this.getOriginalRectangles(childrenIds)

    const addedAttributes = createAutolayoutAttributes(node, [], childRects)
    const mode = addedAttributes['autolayout.direction'] === 'row' ? 'x' : 'y'
    this.sortChildren(node, mode)

    const setAttributes = this.buildSetAttributesCommand(
      node.getId(),
      {},
      { ...addedAttributes },
      selector
    )
    this.commandHandler.handle(setAttributes)

    const commands = this.buildPositionModeCommands(childrenIds)
    this.commandHandler.handle(commands)
  }

  private sortChildren = (node: ReadOnlyNode, mode: 'x' | 'y'): void => {
    const children = node.getChildren()
    if (!children) return

    const sorted = children.slice().sort((a, b) => {
      const aNode = this.document.getNode(a)
      const bNode = this.document.getNode(b)
      if (!aNode || !bNode) return 0

      const aPos = aNode.getBaseAttribute(mode)
      const bPos = bNode.getBaseAttribute(mode)

      return aPos - bPos
    })

    this.commandHandler.handle({
      type: 'setChildOrder',
      params: { parentId: node.getId(), children: sorted },
    })
  }

  private buildSetAttributesCommand = (
    id: string,
    base: Partial<BaseMap>,
    style: Partial<StyleMap>,
    selector: SelectorName | undefined
  ): Command => {
    return {
      type: 'setNodeAttribute',
      params: { id, base, style, selector },
    }
  }

  private buildPositionModeCommands = (ids: string[]): Command[] => {
    return ids
      .filter((id) => {
        const node = this.document.getNode(id)
        if (!node) return false
        return node.getStyleAttribute('position.mode') === 'absolute'
      })
      .map((id) => {
        return this.buildSetAttributesCommand(
          id,
          {},
          {
            'position.mode': 'auto',
            'position.top.auto': 'auto',
            'position.left.auto': 'auto',
            'position.bottom.auto': 'auto',
            'position.right.auto': 'auto',
          },
          'default'
        )
      })
  }

  private getChildren = (node: ReadOnlyNode): ReadOnlyNode[] => {
    const children = node.getChildren() || []
    return children
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
  }

  private getOriginalAttributes = (
    ids: string[]
  ): { [id: string]: StyleMap } => {
    const attributes: { [id: string]: StyleMap } = {}
    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue
      attributes[id] = _.cloneDeep(node.getStyleAttributes())
    }
    return attributes
  }

  private getOriginalRectangles = (ids: string[]): Rectangle[] => {
    const rectangles: Rectangle[] = []
    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue
      rectangles.push({
        x: node.getBaseAttribute('x'),
        y: node.getBaseAttribute('y'),
        w: node.getBaseAttribute('w'),
        h: node.getBaseAttribute('h'),
      })
    }
    return rectangles
  }

  private getShouldAdd = (nodes: ReadOnlyNode[]): boolean => {
    return (
      nodes.length === 1 &&
      nodes[0].getStyleAttribute('autolayout.mode') !== 'flex' &&
      isContainerType(nodes[0].getBaseAttribute('type'))
    )
  }
}
