import useAction from 'hooks/editor/useAction'
import useActionInitiator from 'hooks/editor/useActionInitiator'
import { useEffect } from 'react'
import { Portal } from '../../PortalRoot/PortalRoot'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import FullscreenDimContainer from 'components/Library/Containers/FullscreenDimContainer/FullscreenDimContainer'
import PanelColumn from 'components/Library/Containers/PanelColumn/PanelColumn'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import TemplatesTitle from 'components/Library/Components/Templates/TemplatesTitle'
import TemplateTab from 'components/Library/Components/Templates/TemplatesTab'
import TemplateDivider from 'components/Library/Components/Templates/TemplatesDivider'
import TemplatesSearch from 'components/Library/Components/Templates/TemplatesSearch'
import TemplatesNotFound from 'components/Library/Components/Templates/TemplatesNotFound'
import TemplatesDisplay from 'components/Library/Components/Templates/TemplatesDisplay'
import { DesignColor } from 'themes'
import useToast from 'hooks/editor/useToast'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import useAnalytics from 'hooks/admin/useAnalytics'
import { IconKey } from 'assets/iconLibrary'

export default function TemplateLibrary() {
  const { metricTemplate } = useAnalytics()
  const toast = useToast()

  const { closeLibrary } = useActionInitiator()
  const action = useAction()

  const { settings, handlers } = usePanelHelper(
    editor.getUI().getTemplatesPanel()
  )

  const { setQuery, setScroll, setTab, select, copy, load, close } = handlers

  useEffect(() => {
    load()
    return () => close()
  }, [load, close])

  if (!settings || action !== 'openTemplateLibrary') return <></>

  const { tab, scroll, query, templates } = settings

  return (
    <Portal>
      <FullscreenDimContainer>
        <PanelContainer
          id={'template-library'}
          position={{
            position: 'absolute',
            top: 48,
            left: 48,
          }}
          width={'calc(100% - 96px)'}
          height={'calc(100% - 96px)'}
          direction={'row'}
          overflow={'hidden'}
        >
          <PanelColumn>
            <TemplatesTitle />
            <PanelSection bottom={true}>
              {tabs.map((t, index) => {
                if (t.value === 'divider') {
                  return <TemplateDivider key={index} />
                } else {
                  return (
                    <TemplateTab
                      key={t.value}
                      icon={t.icon}
                      display={t.label}
                      select={() => setTab(t.value)}
                      selected={tab === t.value}
                    />
                  )
                }
              })}
            </PanelSection>
          </PanelColumn>
          <PanelColumn
            width={'fill'}
            background={DesignColor('canvasBackground')}
          >
            <TemplatesSearch
              value={query}
              setValue={setQuery}
              close={closeLibrary}
            />
            {templates.length > 0 ? (
              <TemplatesDisplay
                templates={templates}
                scroll={scroll}
                setScroll={setScroll}
                select={async (t) => {
                  await select(t)
                  metricTemplate()
                  closeLibrary()
                  close()
                }}
                copy={async (t) => {
                  await copy(t)
                  toast(`Template ${t.name} copied!`, 'info')
                  closeLibrary()
                }}
              />
            ) : (
              <TemplatesNotFound clearSearch={() => setQuery('')} />
            )}
          </PanelColumn>
        </PanelContainer>
      </FullscreenDimContainer>
    </Portal>
  )
}

const tabs: {
  value: string
  label: string
  icon: IconKey
}[] = [
  {
    value: 'navigation',
    label: 'Navigation',
    icon: 'Navigation',
  },
  {
    value: 'sections',
    label: 'Sections',
    icon: 'Section',
  },
]
