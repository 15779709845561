import {
  AttributeAnimationEffectEvent,
  AttributeAnimationEffectTimeline,
} from 'application/attributes'
import ButtonBlock from 'components/Library/Components/Button/ButtonBlock/ButtonBlock'
import TimelineEvent from 'components/Library/Components/Button/TimelineEvent/TimelineEvent'
import TimelineTimeButton from 'components/Library/Components/Button/TimelineTimeButton/TimelineTimeButton'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import TimelineEffectBlock from 'components/Library/Containers/TimelineEffectsBlock/TimelineEffectBlock'
import TimelineRow from 'components/Library/Containers/TimelineRow/TimelineRow'
import { useEffect, useRef } from 'react'
import TimelineLine from '../TimelineLine/TimelineLine'

interface TimelineProps {
  effect: AttributeAnimationEffectTimeline

  selected: string | null
  setSelected: (selected: string | null) => void

  addStartEvent: () => void
  addEvent: (time: number) => void

  deleteEvent: (key: string) => void
  duplicateEvent: (key: string) => void
}

export default function Timeline({
  effect,
  selected,
  setSelected,
  addStartEvent,
  addEvent,
  deleteEvent,
  duplicateEvent,
}: TimelineProps) {
  const startRef = useRef<HTMLDivElement>(null)
  const endRef = useRef<HTMLDivElement>(null)
  const timelineRef = useRef<HTMLDivElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)
  const addButtonRef = useRef<HTMLDivElement>(null)
  const startEffects = getStartEffects(effect)
  const effectBundles = getEffectBundles(effect)
  const lastStartTime = getLastStartTime(effect)
  const endTime = getEndTime(effect)

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      handleClickOutside(e, panelRef, addButtonRef, setSelected)
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      handleDeleteKey(e, selected, deleteEvent)
      handleDuplicateKey(e, selected, duplicateEvent)
    }

    window.addEventListener('click', handleClick)
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('click', handleClick)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [setSelected, selected, deleteEvent, duplicateEvent])

  return (
    <PanelSection sectionRef={panelRef} maxHeight={400}>
      <PanelRow>
        <TextBlock width={192} mode={'title'}>
          Timeline
        </TextBlock>
        <IconBlock
          buttonRef={addButtonRef}
          icon={'Plus'}
          onClick={() => addEvent(lastStartTime)}
        />
      </PanelRow>
      <TimelineRow rowRef={timelineRef}>
        <TimelineLine
          key={effect.events.length}
          panelRef={timelineRef}
          startRef={startRef}
          endRef={endRef}
        />
        <PanelRow height={'hug'} align={'start'}>
          <TimelineTimeButton
            buttonRef={startRef}
            time={0}
            start={true}
            end={false}
            onClick={addStartEvent}
          />
          {startEffects.length > 0 && (
            <TimelineEffectBlock>
              {startEffects.map((e) => (
                <TimelineEvent
                  key={e.key}
                  eventId={e.key}
                  self={e.targetType === 'self'}
                  id={e.targetType !== 'self' ? e.targetId : undefined}
                  selected={selected === e.key}
                  select={() => setSelected(e.key)}
                />
              ))}
            </TimelineEffectBlock>
          )}
          {startEffects.length === 0 && (
            <ButtonBlock
              width={168}
              text={'No starting values'}
              dim={true}
              onClick={addStartEvent}
            />
          )}
        </PanelRow>
        {effectBundles.map((bundle, i) => (
          <PanelRow key={i} height={'hug'} align={'start'}>
            <TimelineTimeButton
              time={bundle.time}
              start={false}
              end={false}
              onClick={() => addEvent(bundle.time)}
            />
            <TimelineEffectBlock>
              {bundle.events.map((e) => (
                <TimelineEvent
                  key={e.key}
                  eventId={e.key}
                  self={e.targetType === 'self'}
                  id={e.targetType !== 'self' ? e.targetId : undefined}
                  selected={selected === e.key}
                  select={() => setSelected(e.key)}
                />
              ))}
            </TimelineEffectBlock>
          </PanelRow>
        ))}
        <PanelRow>
          <TimelineTimeButton
            buttonRef={endRef}
            time={endTime}
            start={false}
            end={effectBundles.length > 0}
            onClick={() => addEvent(endTime)}
          />
        </PanelRow>
      </TimelineRow>
    </PanelSection>
  )
}

function getStartEffects(effect: AttributeAnimationEffectTimeline) {
  return effect.events.filter((e) => e.start)
}

type EffectBundle = {
  time: number
  events: AttributeAnimationEffectEvent[]
}

function getEffectBundles(
  effect: AttributeAnimationEffectTimeline
): EffectBundle[] {
  const bundleMap: { [key: number]: AttributeAnimationEffectEvent[] } = {}

  for (const event of effect.events) {
    if (event.start) continue

    const time = event.startTime
    if (!bundleMap[time]) bundleMap[time] = []
    bundleMap[time].push(event)
  }

  return Object.keys(bundleMap).map((key) => ({
    time: parseInt(key),
    events: bundleMap[parseInt(key)],
  }))
}

function getLastStartTime(effect: AttributeAnimationEffectTimeline) {
  return Math.max(...effect.events.map((e) => e.startTime), 0)
}

function getEndTime(effect: AttributeAnimationEffectTimeline) {
  return Math.max(...effect.events.map((e) => e.startTime + e.duration), 0)
}

function handleClickOutside(
  e: MouseEvent,
  panelRef: React.RefObject<HTMLDivElement>,
  addButtonRef: React.RefObject<HTMLDivElement>,
  setSelected: (selected: string | null) => void
): void {
  if (!panelRef.current) return
  if (!panelRef.current.contains(e.target as Node)) return
  if (!addButtonRef.current) return
  if (addButtonRef.current.contains(e.target as Node)) return
  if (!e.target) return
  const target = e.target as HTMLElement
  const timelineEvent = target.closest('.timelineEvent')
  const timelineTimeButton = target.closest('.timelineTimeButton')
  if (timelineEvent || timelineTimeButton) return
  setSelected(null)
}

function handleDeleteKey(
  e: KeyboardEvent,
  selected: string | null,
  deleteEvent: (key: string) => void
): void {
  if (e.key !== 'Delete' && e.key !== 'Backspace') return
  if (!selected) return
  e.preventDefault()
  deleteEvent(selected)
}

function handleDuplicateKey(
  e: KeyboardEvent,
  selected: string | null,
  duplicateEvent: (key: string) => void
): void {
  if (e.key !== 'd') return
  if (!e.ctrlKey && !e.metaKey) return
  if (!selected) return
  e.preventDefault()
  duplicateEvent(selected)
}
