import { MultiselectStyleMap } from 'application/attributes'
import { truncate } from 'application/math'
import { ReadOnlyNode } from 'application/node'
import { AttributePanel } from './attributePanel'

type PositionPanelKeys =
  | 'position.top'
  | 'position.left'
  | 'position.right'
  | 'position.bottom'
  | 'position.top.auto'
  | 'position.left.auto'
  | 'position.right.auto'
  | 'position.bottom.auto'
  | 'position.top.percent'
  | 'position.left.percent'
  | 'position.right.percent'
  | 'position.bottom.percent'
  | 'position.mode'

export type PositionPanelAttributes = Pick<
  MultiselectStyleMap,
  PositionPanelKeys
> | null

export type PositionPanelSide = 'top' | 'left' | 'bottom' | 'right'

export interface PositionPanelState {
  leftDisplay: 'Mixed' | number
  topDisplay: 'Mixed' | number
  rightDisplay: 'Mixed' | number
  bottomDisplay: 'Mixed' | number
  attributes: PositionPanelAttributes
  displayMode: 'Mixed' | 'canvas' | 'css'
}

export interface PositionPanelHandlers {
  setPosition: (value: number, side: PositionPanelSide) => void
  slidePosition: (value: number, side: PositionPanelSide) => void
  setPositionUnit: (
    unit: 'percent' | 'fixed' | 'auto',
    side: PositionPanelSide
  ) => void
  collapsePosition: () => void
}

export class PositionPanel extends AttributePanel<
  PositionPanelState,
  PositionPanelHandlers,
  PositionPanelKeys,
  never
> {
  getSettings(): PositionPanelState {
    return {
      leftDisplay: this.getDisplayValue('left'),
      topDisplay: this.getDisplayValue('top'),
      rightDisplay: this.getDisplayValue('right'),
      bottomDisplay: this.getDisplayValue('bottom'),
      attributes: this.styleAttributes,
      displayMode: this.getPositionDisplayMode(),
    }
  }

  getHandlers(): PositionPanelHandlers {
    return {
      setPositionUnit: this.setPositionUnit,
      setPosition: this.setPosition,
      slidePosition: this.slidePosition,
      collapsePosition: this.collapsePosition,
    }
  }

  private getDisplayValue(
    side: 'top' | 'left' | 'bottom' | 'right'
  ): 'Mixed' | number {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return 'Mixed'

    const values = pairs.map(([n, p]) => {
      if (!p) return undefined
      return this.computePositionPixels(n, p, side)
    })

    const firstValue = values[0]
    for (let i = 1; i < values.length; i++) {
      if (values[i] !== firstValue) return 'Mixed'
    }

    return firstValue === undefined ? 'Mixed' : firstValue
  }

  private getPositionDisplayMode = (): 'Mixed' | 'canvas' | 'css' => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return 'Mixed'

    const modes = pairs.map(([_, parent]) => this.getNodeDisplayMode(parent))
    const first = modes[0]
    if (modes.every((m) => m === first)) return first
    return 'Mixed'
  }

  private setPosition = (value: number, side: PositionPanelSide): void => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    for (const node of nodes) {
      const mode = node.getStyleAttribute(`position.${side}.auto`)
      switch (mode) {
        case 'percent':
          this.setOne(node.getId(), {}, { [`position.${side}.percent`]: value })
          break
        case 'fixed':
          this.setOne(node.getId(), {}, { [`position.${side}`]: value })
          break
        case 'auto':
        case undefined:
          this.setOne(
            node.getId(),
            {},
            {
              [`position.${side}`]: value,
              [`position.${side}.auto`]: 'fixed',
            }
          )
      }
      break
    }
  }

  private setPositionUnit = (
    unit: 'percent' | 'fixed' | 'auto',
    side: PositionPanelSide
  ): void => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return

    for (const p of pairs) {
      const node = p[0]
      const parent = p[1]
      if (!parent) continue

      const displayMode = this.getNodeDisplayMode(parent)
      switch (displayMode) {
        case 'css':
          const opposite = this.isOppositeActive(side, node)
          const axis = side === 'top' || side === 'bottom' ? 'h' : 'w'
          if (opposite) {
            this.setOne(node.getId(), {}, { [`size.${axis}.auto`]: 'auto' })
          }

          switch (unit) {
            case 'fixed':
              const value = this.computePositionPixels(node, parent, side)
              this.setOne(
                node.getId(),
                {},
                {
                  [`position.${side}`]: value,
                  [`position.${side}.percent`]: undefined,
                  [`position.${side}.auto`]: 'fixed',
                }
              )
              break
            case 'percent':
              const percent = this.computePositionPercent(node, parent, side)
              this.setOne(
                node.getId(),
                {},
                {
                  [`position.${side}`]: undefined,
                  [`position.${side}.percent`]: percent,
                  [`position.${side}.auto`]: 'percent',
                }
              )
              break
            case 'auto':
              this.setOne(
                node.getId(),
                {},
                {
                  [`position.${side}`]: undefined,
                  [`position.${side}.percent`]: undefined,
                  [`position.${side}.auto`]: 'auto',
                }
              )
              break
          }
      }
    }

    this.commit()
  }

  private slidePosition = (value: number, side: PositionPanelSide): void => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return

    for (const [node, parent] of pairs) {
      const displayMode = this.getNodeDisplayMode(parent)
      switch (displayMode) {
        case 'canvas':
          switch (side) {
            case 'top':
              this.setOne(
                node.getId(),
                { y: node.getBaseAttribute('y') + value },
                {}
              )
              break
            case 'left':
              this.setOne(
                node.getId(),
                { x: node.getBaseAttribute('x') + value },
                {}
              )
              break
          }
          break
        case 'css':
          const mode = node.getStyleAttribute(`position.${side}.auto`)
          switch (mode) {
            case 'percent':
              const percent =
                node.getStyleAttribute(`position.${side}.percent`) || 0
              this.setOne(
                node.getId(),
                {},
                {
                  [`position.${side}.percent`]: percent + value,
                }
              )
              break
            case 'fixed':
              const fixed = node.getStyleAttribute(`position.${side}`) || 0
              this.setOne(
                node.getId(),
                {},
                {
                  [`position.${side}`]: fixed + value,
                }
              )
              break
          }
      }
    }
  }

  private collapsePosition = (): void => {
    for (const pairs of this.getNodesAndParents()) {
      const node = pairs[0]
      const parent = pairs[1]
      if (!parent) continue

      const displayMode = this.getNodeDisplayMode(parent)
      switch (displayMode) {
        case 'css':
          let topMode = node.getStyleAttribute('position.top.auto')
          let leftMode = node.getStyleAttribute('position.left.auto')
          let top = node.getStyleAttribute('position.top')
          let left = node.getStyleAttribute('position.left')
          let topPercent = node.getStyleAttribute('position.top.percent')
          let leftPercent = node.getStyleAttribute('position.left.percent')

          if (topMode === 'percent') {
            topPercent = this.computePositionPercent(node, parent, 'top')
          } else {
            topMode = 'fixed'
            top = this.computePositionPixels(node, parent, 'top')
          }

          if (leftMode === 'percent') {
            leftPercent = this.computePositionPercent(node, parent, 'left')
          } else {
            leftMode = 'fixed'
            left = this.computePositionPixels(node, parent, 'left')
          }

          this.setOne(
            node.getId(),
            {},
            {
              'position.top': top,
              'position.left': left,
              'position.top.percent': topPercent,
              'position.left.percent': leftPercent,
              'position.top.auto': topMode,
              'position.left.auto': leftMode,
              'position.bottom': undefined,
              'position.right': undefined,
              'position.bottom.percent': undefined,
              'position.right.percent': undefined,
              'position.bottom.auto': 'auto',
              'position.right.auto': 'auto',
            }
          )
          break
      }
    }

    this.commit()
  }

  private computePositionPixels = (
    node: ReadOnlyNode,
    parent: ReadOnlyNode,
    mode: 'top' | 'left' | 'bottom' | 'right'
  ): number => {
    switch (mode) {
      case 'top':
        if (parent.getBaseAttribute('type') === 'canvas')
          return node.getBaseAttribute('y')
        return truncate(
          node.getBaseAttribute('y') - parent.getBaseAttribute('y')
        )
      case 'left':
        if (parent.getBaseAttribute('type') === 'canvas')
          return node.getBaseAttribute('x')
        return truncate(
          node.getBaseAttribute('x') - parent.getBaseAttribute('x')
        )
      case 'bottom':
        if (parent.getBaseAttribute('type') === 'canvas') return 0
        return truncate(
          parent.getBaseAttribute('y') +
            parent.getBaseAttribute('h') -
            node.getBaseAttribute('y') -
            node.getBaseAttribute('h')
        )
      case 'right':
        if (parent.getBaseAttribute('type') === 'canvas') return 0
        return truncate(
          parent.getBaseAttribute('x') +
            parent.getBaseAttribute('w') -
            node.getBaseAttribute('x') -
            node.getBaseAttribute('w')
        )
    }
  }

  private computePositionPercent = (
    node: ReadOnlyNode,
    parent: ReadOnlyNode,
    mode: 'top' | 'left' | 'bottom' | 'right'
  ): number => {
    if (node.getBaseAttribute('type') === 'canvas') return 0
    const parentSize = ['top', 'bottom'].includes(mode)
      ? parent.getBaseAttribute('h')
      : parent.getBaseAttribute('w')
    return truncate(
      (this.computePositionPixels(node, parent, mode) / parentSize) * 100,
      1
    )
  }

  private isOppositeActive = (
    side: PositionPanelSide,
    node: ReadOnlyNode
  ): boolean => {
    switch (side) {
      case 'top':
        return node.getStyleAttribute('position.bottom.auto') !== 'auto'
      case 'left':
        return node.getStyleAttribute('position.right.auto') !== 'auto'
      case 'bottom':
        return node.getStyleAttribute('position.top.auto') !== 'auto'
      case 'right':
        return node.getStyleAttribute('position.left.auto') !== 'auto'
    }
  }

  private getNodeDisplayMode = (
    parent: ReadOnlyNode | null
  ): 'canvas' | 'css' => {
    if (!parent) return 'canvas'
    if (parent.getBaseAttribute('type') === 'canvas') return 'canvas'
    return 'css'
  }
}
