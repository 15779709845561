import { PositionNode, PositionNodeMap } from './types'

export function getPositionNodeMap(): PositionNodeMap {
  return {}
}

export function getPositionNode(
  id: string,
  positionNodeMap: PositionNodeMap
): PositionNode {
  if (!positionNodeMap[id]) {
    positionNodeMap[id] = {
      id: id,
      x: 0,
      y: 0,
    }
  }
  return positionNodeMap[id]
}
