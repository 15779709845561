import { IconKey } from 'assets/iconLibrary'
import Text from 'components/Library/Text/Text'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'
import { DesignColor } from 'themes'
import Icon from '../Icon/Icon/Icon'

interface TemplateTabProps {
  icon: IconKey
  display: string

  select: () => void
  selected: boolean
}

export default function TemplateTab({
  icon,
  display,
  select,
  selected,
}: TemplateTabProps) {
  const tabRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered<boolean>({ ref: tabRef })

  const iconColor = selected ? DesignColor('text1') : DesignColor('text2')
  const boxShadow = getBoxShadow(hovered || false)

  return (
    <div
      style={{
        width: '100%',
        padding: '4px 16px',
        display: 'flex',
      }}
    >
      <div
        ref={tabRef}
        style={{
          width: 200,
          height: 40,
          padding: '12px 16px',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          gap: 8,
          cursor: 'pointer',
          background: selected
            ? DesignColor('inputHighlightTint2')
            : 'transparent',
          boxShadow: boxShadow,
          borderRadius: 4,
        }}
        onClick={select}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Icon size={16} icon={icon} color={iconColor} />
        <Text
          styles={{ cursor: 'pointer', size: 12 }}
          color={DesignColor('text1')}
        >
          {display}
        </Text>
      </div>
    </div>
  )
}

function getBoxShadow(hover: boolean) {
  if (hover) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}
