import { WriteDocument } from 'application/document'
import { LayoutEngine } from './engine'
import { SizeLayoutEngineFactory } from './size/sizeFactory'
import { LayoutEngineListener } from './types'
import { PositionLayoutEngineFactory } from './position/positionFactory'
import { BoxLayoutEngineFactory } from './box/boxFactory'
import { TextSizeCalculator } from './size/types'

export class LayoutEngineFactory {
  create(
    document: WriteDocument,
    textSize: TextSizeCalculator,
    listener: LayoutEngineListener
  ) {
    const sizeLayoutEngineFactory = new SizeLayoutEngineFactory()
    const positionLayoutEngineFactory = new PositionLayoutEngineFactory()
    const boxLayoutEngineFactory = new BoxLayoutEngineFactory()

    const sizeCalculator = sizeLayoutEngineFactory.create(document, textSize)
    const positionCalculator = positionLayoutEngineFactory.create(document)
    const boxCalculator = boxLayoutEngineFactory.create(document)

    return new LayoutEngine(
      [sizeCalculator, positionCalculator, boxCalculator],
      listener
    )
  }
}
