import { Resizable } from 're-resizable'
import { panelBorderFull, panelPosition, panelShadow } from '../constants'
import { DesignColor } from 'themes'

interface ResizePanelContainerProps {
  panelRef?: React.RefObject<HTMLDivElement>

  position?: panelPosition

  height?: number | string
  paddingH?: 0 | 10
  direction?: 'column' | 'row'

  width?: number
  setWidth?: (width: number) => void

  popup?: boolean

  children?: React.ReactNode
}

export default function ResizePanelContainer({
  panelRef,
  position,
  paddingH = 0,
  direction = 'column',
  width = 232,
  setWidth,
  height = 400,
  popup,
  children,
}: ResizePanelContainerProps) {
  return (
    <ResizeableContainer
      position={position}
      width={width}
      setWidth={setWidth}
      height={height}
    >
      <div
        className={popup ? 'popup' : undefined}
        ref={panelRef}
        style={{
          width: '100%',
          height: height,
          padding: `0 ${paddingH}px`,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: direction,
          alignItems: 'center',
          justifyContent: 'start',
          boxShadow: [panelShadow, panelBorderFull].join(', '),
          background: DesignColor('panelBackground'),
          borderRadius: 8,
        }}
      >
        {children}
      </div>
    </ResizeableContainer>
  )
}

function ResizeableContainer({
  position = {},
  width,
  setWidth,
  height,
  children,
}: {
  position?: panelPosition
  width: number
  setWidth?: (width: number) => void
  height: number | string
  children?: React.ReactNode
}) {
  return (
    <Resizable
      style={{
        ...position,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        borderRadius: 4,
        pointerEvents: 'auto',
      }}
      defaultSize={{
        width,
        height,
      }}
      onResizeStop={(_, __, ___, d) => {
        setWidth && setWidth(width + d.width)
      }}
      minWidth={232}
      maxWidth={400}
      handleClasses={{
        right: 'resize-handle',
      }}
      handleStyles={{
        right: {
          width: '10px',
          height: '100%',
          position: 'absolute',
          cursor: 'col-resize',
        },
        top: { display: 'none' },
        bottom: { display: 'none' },
        left: { display: 'none' },
      }}
    >
      {children}
    </Resizable>
  )
}
