import { Point, Rectangle } from 'application/shapes'
import { PastePositionHandler } from '../paste'
import { PasteSnapshot } from '../../types'
import { computeSelectionRectangle } from 'application/selection'
import { moveTree, setAttributes } from './utils'
import {
  getAdjustedBottomToNode,
  getAdjustedLeftToNode,
  getAdjustedRightToNode,
  getAdjustedTopToNode,
} from 'application/units'
import { WriteDocument } from 'application/document'

export class PasteAtPoint implements PastePositionHandler {
  private point: Point
  private document: WriteDocument

  constructor(document: WriteDocument, point: Point) {
    this.document = document
    this.point = point
  }

  paste = (snapshot: PasteSnapshot): void => {
    const window = this.getWindow(snapshot)
    if (!window) return

    for (const id of snapshot.ids) {
      const offset = this.getOffset(id, window, snapshot)

      const node = snapshot.nodes[id]
      if (!node) continue

      const parent = this.document.getParent(node)
      if (!parent) continue

      const xDelta = node.getBaseAttribute('x') - this.point.x - offset.x
      const yDelta = node.getBaseAttribute('y') - this.point.y - offset.y

      const x = this.point.x + offset.x
      const y = this.point.y + offset.y
      const w = node.getBaseAttribute('w')
      const h = node.getBaseAttribute('h')

      const adjustedTop = getAdjustedTopToNode(node, parent, y)
      const adjustedLeft = getAdjustedLeftToNode(node, parent, x)
      const adjustedRight = getAdjustedRightToNode(node, parent, x + w)
      const adjustedBottom = getAdjustedBottomToNode(node, parent, y + h)

      setAttributes(node, {
        ...adjustedTop,
        ...adjustedLeft,
        ...adjustedRight,
        ...adjustedBottom,
      })

      moveTree(node, snapshot, xDelta, yDelta)
    }
  }

  private getOffset = (
    id: string,
    window: Rectangle,
    snapshot: PasteSnapshot
  ): Point => {
    const node = snapshot.nodes[id]
    if (!node) return { x: 0, y: 0 }

    return {
      x: node.getBaseAttribute('x') - window.x,
      y: node.getBaseAttribute('y') - window.y,
    }
  }

  private getWindow = (snapshot: PasteSnapshot): Rectangle | null => {
    const nodes = snapshot.ids.map((id) => snapshot.nodes[id])
    return computeSelectionRectangle(nodes)
  }
}
