import { DesignColor } from 'themes'
import Icon from '../Icon/Icon/Icon'
import { IconKey } from 'assets/iconLibrary'
import { AttributeType } from 'application/attributes'

interface LayerIconProps {
  type: AttributeType

  depth: number

  arrow: boolean
  open: boolean
  toggleOpen: () => void

  selected: boolean
  parentSelected: boolean

  hidden: boolean
}

export default function LayerIcon({
  type,
  depth,
  arrow,
  open,
  toggleOpen,
  selected,
  parentSelected,
  hidden,
}: LayerIconProps) {
  return (
    <div
      style={{
        position: 'relative',
        width: 36,
        height: 24,
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        flexShrink: 0,
        cursor: arrow ? 'pointer' : 'default',
      }}
      onClick={(e) => {
        e.stopPropagation()
        toggleOpen()
      }}
    >
      {arrow && !hidden && (
        <RotationContainer rotation={open ? 90 : 0}>
          <Icon
            icon={'DropdownArrowRight'}
            size={10}
            color={DesignColor('text2')}
          />
        </RotationContainer>
      )}
      <div
        style={{
          width: 24,
          height: 24,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icon
          icon={getIcon(type)}
          size={16}
          color={getIconColor(depth, hidden, selected || parentSelected)}
        />
      </div>
    </div>
  )
}

function RotationContainer({
  children,
  rotation,
}: {
  children: React.ReactNode
  rotation: number
}) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 7,
        left: 3,
        width: 10,
        height: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        transform: `rotate(${rotation}deg)`,
      }}
    >
      {children}
    </div>
  )
}

function getIcon(type: AttributeType): IconKey {
  switch (type) {
    case 'page':
      return 'Page'
    case 'frame':
      return 'Frame'
    case 'rectangle':
      return 'Rectangle'
    case 'ellipse':
      return 'Ellipse'
    case 'text':
      return 'Text'
    case 'image':
      return 'Image'
    default:
      return 'Frame'
  }
}

function getIconColor(
  layer: number,
  hidden: boolean,
  selected: boolean
): string {
  if (hidden) return DesignColor('text3')
  return DesignColor(layer === 0 || selected ? 'text1' : 'text2')
}
