import { DesignColor } from 'themes'
import Icon from '../Icon/Icon/Icon'
import { IconKey } from 'assets/iconLibrary'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'

interface LayerLockBlockProps {
  parentLocked: boolean
  locked: boolean
  setLocked: (locked: boolean) => void

  hidden: boolean
}

export default function LayerLockBlock({
  parentLocked,
  locked,
  setLocked,
  hidden,
}: LayerLockBlockProps) {
  const buttonRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered<boolean>({ ref: buttonRef })

  const icon = getIcon(locked, parentLocked)
  if (!icon || (!locked && !parentLocked)) return <></>

  return (
    <div
      ref={buttonRef}
      style={{
        width: 24,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={() => setLocked(!locked)}
    >
      {icon && (
        <Icon
          icon={icon}
          size={16}
          color={getColor(hovered || false, hidden)}
        />
      )}
    </div>
  )
}

function getIcon(locked: boolean, parentLocked: boolean): IconKey | null {
  if (locked) return 'LockClosed'
  if (parentLocked) return 'LockChild'
  return null
}

function getColor(hovered: boolean, hidden: boolean): string {
  if (hidden) return DesignColor('text3')
  if (hovered) return DesignColor('inputHighlight')
  return DesignColor('text1')
}
