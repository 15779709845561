import {
  AttributeType,
  BaseMap,
  PLACEHOLDER_IMAGE,
  StyleMap,
} from 'application/attributes'
import { Node } from './node'
import { NewNodeSettings, NodeUpdateListener } from './types'
import { ProjectDocumentNode } from 'application/service'

const nodesWithChildren = ['root', 'canvas', 'page', 'frame']

export class NodeFactory {
  private listener: NodeUpdateListener

  constructor(listener: NodeUpdateListener) {
    this.listener = listener
  }

  createNode(settings: NewNodeSettings): Node | null {
    if (!settings.base.type) return null
    const hasChildren = nodesWithChildren.includes(settings.base.type)

    return new Node(
      settings.id,
      undefined,
      hasChildren ? [] : undefined,
      {
        ...this.getDefaultBase(settings.base.type),
        ...this.addTextContent(settings.base.type),
        ...settings.base,
      },
      {
        name: 'default',
        styles: {
          ...this.getDefaultStyle(),
          ...this.addRounding(settings.base.type),
          ...this.addClip(settings.base.type),
          ...this.addImage(settings.base.type),
          ...this.addAutoHeight(settings.base.type),
          ...this.addFlex(settings.base.type),
          ...settings.style,
        },
      },
      [],
      'default',
      'none',
      this.listener
    )
  }

  createLoadedNode(node: ProjectDocumentNode): Node {
    return new Node(
      node.id,
      node.parent,
      node.children,
      node.baseAttributes,
      node.defaultSelector,
      node.selectors,
      node.activeBreakpoint,
      node.activePseudo,
      this.listener
    )
  }

  private getDefaultBase(type: AttributeType): BaseMap {
    return {
      type: type,
      name: 'Node',
      nameOverridden: false,
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      'boundingBox.h': 0,
      'boundingBox.w': 0,
      'boundingBox.x': 0,
      'boundingBox.y': 0,
    }
  }

  private addTextContent(type: AttributeType): Partial<BaseMap> {
    switch (type) {
      case 'text':
        return { 'text.content': 'Text' }
      default:
        return {}
    }
  }

  private getDefaultStyle(): StyleMap {
    return {
      opacity: 100,
      'position.top': 0,
      'position.left': 0,
      'position.top.auto': 'fixed',
      'position.left.auto': 'fixed',
      'position.bottom.auto': 'auto',
      'position.right.auto': 'auto',
      'position.mode': 'absolute',
      'autolayout.mode': 'block',
      'size.w': 0,
      'size.h': 0,
      'size.w.auto': 'fixed',
      'size.h.auto': 'fixed',
      'size.ratio.mode': 'auto',
      'flex.grow': 0,
      'flex.shrink': 0,
      'flex.basis': 'auto',
      'flex.alignSelf': 'auto',
    }
  }

  private addClip(type: AttributeType): Partial<StyleMap> {
    switch (type) {
      case 'frame':
      case 'page':
        return { clip: true }
      default:
        return {}
    }
  }

  private addRounding(type: AttributeType): Partial<StyleMap> {
    switch (type) {
      case 'frame':
      case 'rectangle':
      case 'image':
        return {
          'rounding.topLeft': 0,
          'rounding.topRight': 0,
          'rounding.bottomRight': 0,
          'rounding.bottomLeft': 0,
        }
      default:
        return {}
    }
  }

  private addImage(type: AttributeType): Partial<StyleMap> {
    switch (type) {
      case 'image':
        return {
          'image.src': PLACEHOLDER_IMAGE,
          'image.resize': 'fill',
          'image.originalSize.w': 800,
          'image.originalSize.h': 800,
        }
      default:
        return {}
    }
  }

  private addAutoHeight(type: AttributeType): Partial<StyleMap> {
    switch (type) {
      case 'page':
        return { 'size.h.auto': 'auto' }
      default:
        return {}
    }
  }

  private addFlex(type: AttributeType): Partial<StyleMap> {
    switch (type) {
      case 'page':
        return {
          'autolayout.mode': 'flex',
          'autolayout.direction': 'column',
          'autolayout.align.main': 'start',
          'autolayout.align.counter': 'start',
          'autolayout.gap': 0,
        }
      default:
        return {}
    }
  }
}
