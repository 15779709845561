import { getOptionHotkeyPrefix } from 'components/Pages/Editor/RightClickMenu/Elements/ElementsRightClickMenu'

export type tooltipDirection = 'left' | 'right' | 'center'
export type tooltipKey =
  | 'LightMode'
  | 'DarkMode'
  | 'MainMenu'
  | 'WebsiteSettings'
  | 'TutorialTemplates'
  | 'AddTemplates'
  | 'Page'
  | 'Frame'
  | 'Rectangle'
  | 'Ellipse'
  | 'Text'
  | 'Image'
  | 'Graphics'
  | 'Hand'
  | 'AddCanvas'
  | 'CollapseLayers'
  | 'AlignLeft'
  | 'AlignCenter'
  | 'AlignRight'
  | 'AlignTop'
  | 'AlignMiddle'
  | 'AlignBottom'
  | 'DistributeHorizontal'
  | 'DistributeVertical'
  | 'PositionMode'
  | 'PositionTop'
  | 'PositionLeft'
  | 'PositionBottom'
  | 'PositionRight'
  | 'PositionSplit'
  | 'StickyGap'
  | 'AspectRatio'
  | 'AspectRatioCustom'
  | 'Width'
  | 'Height'
  | 'MaxWidth'
  | 'MaxHeight'
  | 'MinWidth'
  | 'MinHeight'
  | 'FlexSize'
  | 'AlignSelf'
  | 'Opacity'
  | 'ClipContent'
  | 'Visibility'
  | 'Rounding'
  | 'TopLeftRounding'
  | 'TopRightRounding'
  | 'BottomLeftRounding'
  | 'BottomRightRounding'
  | 'SplitRounding'
  | 'FontFamily'
  | 'FontWeight'
  | 'FontSize'
  | 'FontAlignLeft'
  | 'FontAlignCenter'
  | 'FontAlignRight'
  | 'FontHugBoth'
  | 'FontHugHeight'
  | 'FontFixedSize'
  | 'FontLineSpacing'
  | 'FontLetterSpacing'
  | 'FontMissing'
  | 'UploadImage'
  | 'ImageSizeMode'
  | 'ImageToOriginalSize'
  | 'BorderWidth'
  | 'BorderMode'
  | 'BorderWidthTop'
  | 'BorderWidthRight'
  | 'BorderWidthBottom'
  | 'BorderWidthLeft'
  | 'DisplayBlock'
  | 'DisplayFlex'
  | 'DisplayHidden'
  | 'FlexRow'
  | 'FlexColumn'
  | 'FlexRowWrap'
  | 'FlexGap'
  | 'FlexAlignment'
  | 'FlexSpaceBetween'
  | 'PaddingLeft'
  | 'PaddingRight'
  | 'PaddingTop'
  | 'PaddingBottom'
  | 'PaddingVertical'
  | 'PaddingHorizontal'
  | 'PaddingSplit'
  | 'ShadowSettings'
  | 'ShadowX'
  | 'ShadowY'
  | 'ShadowBlur'
  | 'ShadowSpread'
  | 'BackgroundBlurRadius'
  | 'HorizontalConstraint'
  | 'VerticalConstraint'
  | 'TransitionDuration'
  | 'TransitionTimingMode'
  | 'CursorMode'
  | 'ResetOverrides'
  | 'PreviewDesktop'
  | 'PreviewTablet'
  | 'PreviewLandscape'
  | 'PreviewMobile'
  | 'PreviewWidth'
  | 'PreviewHeight'
  | 'PreviewFullscreen'
  | 'Zoom'
  | 'CopyDomain'
  | 'VisitSite'
  | 'BreakpointDefault'
  | 'BreakpointTablet'
  | 'BreakpointLandscape'
  | 'BreakpointMobile'
  | 'StateDefault'
  | 'StateHover'
  | 'ContainerSize'
  | 'ContainerDesktop'
  | 'ContainerTablet'
  | 'ContainerLandscape'
  | 'ContainerMobile'
  | 'InteractionsLink'
  | 'InteractionsLinkModePage'
  | 'InteractionsLinkModeURL'
  | 'InteractionsLinkModeScroll'
  | 'InteractionsLinkModeEmail'
  | 'InteractionsLinkPageTarget'
  | 'InteractionsLinkURL'
  | 'InteractionsLinkScrollTarget'
  | 'InteractionsLinkScrollTop'
  | 'InteractionsLinkScrollCenter'
  | 'InteractionsLinkScrollBottom'
  | 'InteractionsShowHide'
  | 'AnimationStartTime'
  | 'AnimationDuration'
  | 'AnimationEasing'
  | 'AnimationTransformX'
  | 'AnimationTransformY'
  | 'AnimationScale'
  | 'AnimationRotation'
  | 'AnimationOpacity'

type tooltipMap = {
  [key in tooltipKey]: {
    text: string
    subtext?: string
  }
}

export const TooltipMap: tooltipMap = {
  // Menus
  LightMode: { text: 'Light Mode' },
  DarkMode: { text: 'Dark Mode' },

  // Top Left Toolbar
  MainMenu: { text: 'Main Menu' },

  // Tutorial
  TutorialTemplates: { text: 'Click this button to access templates' },

  // Tools
  AddTemplates: { text: 'Add Templates', subtext: 'A' },
  Page: { text: 'Page', subtext: 'P' },
  Frame: { text: 'Frame', subtext: 'F' },
  Rectangle: { text: 'Rectangle', subtext: 'R' },
  Ellipse: { text: 'Ellipse', subtext: 'E' },
  Text: { text: 'Text', subtext: 'T' },
  Image: { text: 'Image', subtext: 'I' },
  Graphics: { text: 'Graphics', subtext: 'G' },
  Hand: { text: 'Hand', subtext: 'Space' },

  // Top Right Toolbar
  WebsiteSettings: { text: 'Website Settings' },

  // Left Sidepanel
  AddCanvas: { text: 'New Canvas' },
  CollapseLayers: {
    text: 'Open / Close Layers',
    subtext: getOptionHotkeyPrefix() + 'L',
  },

  // Alignment Settings Panel
  AlignLeft: { text: 'Align Left' },
  AlignCenter: { text: 'Align Center' },
  AlignRight: { text: 'Align Right' },
  AlignTop: { text: 'Align Top' },
  AlignMiddle: { text: 'Align Middle' },
  AlignBottom: { text: 'Align Bottom' },
  DistributeHorizontal: { text: 'Distribute Horizontal Space' },
  DistributeVertical: { text: 'Distribute Vertical Space' },

  // General Settings Panels
  PositionMode: { text: 'Position Mode' },
  PositionTop: { text: 'Top' },
  PositionLeft: { text: 'Left' },
  PositionBottom: { text: 'Bottom' },
  PositionRight: { text: 'Right' },
  PositionSplit: { text: 'Split Position' },
  StickyGap: { text: 'Sticky Gap' },
  AspectRatio: { text: 'Aspect Ratio' },
  AspectRatioCustom: { text: 'Custom Aspect Ratio' },
  Width: { text: 'Width' },
  Height: { text: 'Height' },
  MaxWidth: { text: 'Max Width' },
  MaxHeight: { text: 'Max Height' },
  MinWidth: { text: 'Min Width' },
  MinHeight: { text: 'Min Height' },
  FlexSize: { text: 'Flex Size' },
  AlignSelf: { text: 'Align Self' },
  Opacity: { text: 'Opacity' },
  ClipContent: { text: 'Clip Content' },
  Visibility: { text: 'Toggle Visibility' },
  Rounding: { text: 'Rounding' },
  TopLeftRounding: { text: 'Top Left Rounding' },
  TopRightRounding: { text: 'Top Right Rounding' },
  BottomLeftRounding: { text: 'Bottom Left Rounding' },
  BottomRightRounding: { text: 'Bottom Right Rounding' },
  SplitRounding: { text: 'Split Rounding' },

  // Text Settings Panel
  FontFamily: { text: 'Font Family' },
  FontWeight: { text: 'Font Weight' },
  FontSize: { text: 'Font Size' },
  FontAlignLeft: { text: 'Align Left' },
  FontAlignCenter: { text: 'Align Center' },
  FontAlignRight: { text: 'Align Right' },
  FontHugBoth: { text: 'Hug Text' },
  FontHugHeight: { text: 'Hug Height' },
  FontFixedSize: { text: 'Fixed Size' },
  FontLineSpacing: { text: 'Line Spacing' },
  FontLetterSpacing: { text: 'Letter Spacing' },
  FontMissing: { text: 'Font Missing or Renamed' },

  // Image Settings Panel
  UploadImage: { text: 'Upload Image' },
  ImageSizeMode: { text: 'Image Size Mode' },
  ImageToOriginalSize: { text: 'Original Size' },

  // Border Settings Panel
  BorderWidth: { text: 'Border Width' },
  BorderMode: { text: 'Border Mode' },
  BorderWidthTop: { text: 'Top Border Width' },
  BorderWidthRight: { text: 'Right Border Width' },
  BorderWidthBottom: { text: 'Bottom Border Width' },
  BorderWidthLeft: { text: 'Left Border Width' },

  // Layout Settings Panel
  DisplayBlock: { text: 'Block' },
  DisplayFlex: { text: 'Flex' },
  DisplayHidden: { text: 'Hidden' },
  FlexRow: { text: 'Row' },
  FlexColumn: { text: 'Column' },
  FlexRowWrap: { text: 'Row Wrap' },
  FlexGap: { text: 'Gap' },
  FlexAlignment: { text: 'Alignment' },
  FlexSpaceBetween: { text: 'Space Between' },

  // Padding Settings Panel
  PaddingLeft: { text: 'Left Padding' },
  PaddingRight: { text: 'Right Padding' },
  PaddingTop: { text: 'Top Padding' },
  PaddingBottom: { text: 'Bottom Padding' },
  PaddingVertical: { text: 'Vertical Padding' },
  PaddingHorizontal: { text: 'Horizontal Padding' },
  PaddingSplit: { text: 'Split Padding' },

  // Shadow Settings Panel
  ShadowSettings: { text: 'Shadow Settings' },
  ShadowX: { text: 'X Offset' },
  ShadowY: { text: 'Y Offset' },
  ShadowBlur: { text: 'Blur' },
  ShadowSpread: { text: 'Spread' },

  // Background Blur Panel
  BackgroundBlurRadius: { text: 'Blur Radius' },

  // Responsive Settings Panel
  HorizontalConstraint: { text: 'Horizontal Constraint' },
  VerticalConstraint: { text: 'Vertical Constraint' },

  // Transition Settings Panel
  TransitionDuration: { text: 'Transition Duration' },
  TransitionTimingMode: { text: 'Transition Timing Mode' },

  // Cursor Settings Panel
  CursorMode: { text: 'Cursor Mode' },

  // Interactions Settings Panel
  InteractionsLink: { text: `Link to Page or External Link` },
  InteractionsLinkModePage: { text: `Page` },
  InteractionsLinkModeURL: { text: `External Link` },
  InteractionsLinkModeScroll: { text: `Scroll To Element` },
  InteractionsLinkModeEmail: { text: `Send Email` },
  InteractionsLinkPageTarget: { text: `Destination Page` },
  InteractionsLinkURL: { text: `Destination URL` },
  InteractionsLinkScrollTarget: { text: `Scroll Target` },
  InteractionsLinkScrollTop: { text: `Scroll to Top` },
  InteractionsLinkScrollCenter: { text: `Scroll to Center` },
  InteractionsLinkScrollBottom: { text: `Scroll to Bottom` },
  InteractionsShowHide: { text: `Toggle an element's visibility` },

  // Animation Settings Panel
  AnimationStartTime: { text: 'Start Time' },
  AnimationDuration: { text: 'Duration' },
  AnimationEasing: { text: 'Easing' },
  AnimationTransformX: { text: 'Transform X' },
  AnimationTransformY: { text: 'Transform Y' },
  AnimationScale: { text: 'Scale' },
  AnimationRotation: { text: 'Rotation' },
  AnimationOpacity: { text: 'Opacity' },

  // Overrides Panel
  ResetOverrides: { text: 'Reset Overrides' },
  BreakpointDefault: { text: 'Default Styles' },
  BreakpointTablet: { text: 'Tablet', subtext: '1200 or less' },
  BreakpointLandscape: { text: 'Landscape', subtext: '768 or less' },
  BreakpointMobile: { text: 'Mobile', subtext: '480 or less' },

  // States Panel
  StateDefault: { text: 'Default State' },
  StateHover: { text: 'Hover' },

  // Container Sizes
  ContainerSize: { text: 'Screen Width' },
  ContainerDesktop: { text: 'Desktop' },
  ContainerTablet: { text: 'Tablet', subtext: '1200 or less' },
  ContainerLandscape: { text: 'Landscape', subtext: '768 or less' },
  ContainerMobile: { text: 'Mobile', subtext: '480 or less' },

  // Preview
  PreviewDesktop: { text: 'Desktop' },
  PreviewTablet: { text: 'Tablet' },
  PreviewLandscape: { text: 'Landscape' },
  PreviewMobile: { text: 'Mobile' },
  PreviewWidth: { text: 'Screen Width', subtext: 'px' },
  PreviewHeight: { text: 'Screen Height', subtext: 'px' },
  PreviewFullscreen: { text: 'Fullscreen Preview' },

  // UI
  Zoom: { text: 'Zoom' },

  // Publish
  CopyDomain: { text: 'Copy to Clipboard' },
  VisitSite: { text: 'Visit Site' },
}
