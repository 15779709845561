import Icon from 'components/Library/Components/Icon/Icon/Icon'
import { panelShadow } from 'components/Library/Containers/constants'
import { useState } from 'react'
import { DesignColor } from 'themes'

interface ProjectCreateButtonProps {
  createProject: () => void
}

export default function ProjectCreateButton({
  createProject,
}: ProjectCreateButtonProps) {
  const [hover, setHover] = useState(false)

  return (
    <button
      style={{
        padding: '8px 10px',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        background: hover
          ? DesignColor('inverseBackgroundHover')
          : DesignColor('inverseBackground'),
        color: DesignColor('inverseText1'),
        border: 'none',
        boxShadow: panelShadow,
        borderRadius: 8,
        fontSize: 12,
        fontFamily: 'inter',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={createProject}
    >
      <Icon icon="Plus" size={16} color={DesignColor('inverseText1')} />
      New Project
    </button>
  )
}
