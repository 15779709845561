import ToolbarIconButton from 'components/Library/Components/Toolbar/ToolbarIconButton/ToolbarIconButton'
import Tooltip from 'components/Library/Components/Tooltip/Tooltip'
import useNavigatePage from 'hooks/admin/useNavigatePage'
import useIsPlayground from 'hooks/playground/useIsPlayground'

export default function RepaintButton() {
  const isPlayground = useIsPlayground()
  const { toProjects, toSignUp } = useNavigatePage()

  return (
    <Tooltip tooltipKey={'MainMenu'}>
      <ToolbarIconButton
        icon={'RepaintLogo'}
        onClick={isPlayground ? toSignUp : toProjects}
      />
    </Tooltip>
  )
}
